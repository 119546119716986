import { FC, useContext } from 'react';
import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { Box, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  FilterFieldTypeEnum,
  FilterOperationEnum,
  IQueryRemixesArgs,
  IRemixesModel,
  SortDirectionEnum,
  IQueryUserByUserNameArgs,
  IUserSmallModel,
  UserTypeEnum,
  RemixStatusEnum
} from '@/graphql/types/_server';
import { REMIXES } from '@/graphql/gql/store';
import { Context } from '@/helpers/context';
import { USER_BY_USERNAME } from '@/graphql/gql/profile';
import StandartLayout from '@/shared/StandartLayout/StandartLayout';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import styles from './styles';
import Remixs from '../Remixs/Remixs';
import Campaigns from '../Campaigns/Campaigns';
import getCloudImgUrl from '@/utils/getUrlImage';

const Profile: FC<{ userName: string }> = ({ userName }) => {
  const { wallet } = useContext(Context);

  const { loading, data } = useQuery<{ userByUserName: IUserSmallModel }, IQueryUserByUserNameArgs>(
    USER_BY_USERNAME,
    {
      variables: {
        userName
      }
    }
  );

  const user = data?.userByUserName;

  // TODO: добавить бесконечную прокрутку
  const { loading: loadingRemix, data: dataRemix } = useQuery<
    { remixes: IRemixesModel },
    IQueryRemixesArgs
  >(REMIXES, {
    variables: {
      chainId: Number(wallet?.ethers?.getChainId()) || 137,
      payload: {
        filters: [
          {
            columnName: user?.type === UserTypeEnum.Sponsor ? 'sponsor.id' : 'user.id',
            operation: FilterOperationEnum.Equal,
            type: FilterFieldTypeEnum.Number,
            value: [`${user?.id}`]
          }
        ],
        paginate: { skip: 0, take: 100 },
        sorts: [
          {
            columnName: 'createdDate',
            direction: SortDirectionEnum.Desc
          }
        ]
      }
    },
    skip: loading
  });

  const remixes =
    dataRemix?.remixes?.items?.filter((item) => item.status === RemixStatusEnum.Store) || [];

  if (loading) {
    return <BackdropLoading />;
  }

  if (!user) {
    return (
      <StandartLayout>
        <Alert severity="error">There is no such user.</Alert>
      </StandartLayout>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box>
        <Box sx={styles.avatar}>
          {user?.logoUrl ? (
            <img src={getCloudImgUrl(user.logoUrl, 150)} alt="avatar" />
          ) : (
            <AccountCircleIcon sx={styles.emptyAvatar} viewBox="2 2 20 20" />
          )}
        </Box>
        <Typography variant="h1" sx={styles.textName} mt={4}>
          {user.name}
        </Typography>
        <Typography variant="h6" sx={styles.textDescription} mt={0.5}>
          {user.description}
        </Typography>
        <Typography variant="h5" sx={styles.textBlack} mt={9.25} mb={2.5}>
          Tracks by {user.userName || '-'}
        </Typography>
      </Box>
      <Remixs remixes={remixes} loading={loadingRemix} />
      {user?.type === UserTypeEnum.Sponsor && <Campaigns userId={user.id} />}
    </Container>
  );
};

export default Profile;

export const getImageSizes = (file: File) => {
  const img = new Image();

  const promise = new Promise((resolve, reject) => {
    const currImage = URL.createObjectURL(file);
    img.src = currImage;
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      resolve({ width, height });
    };

    img.onerror = reject;
  });

  return promise;
};

import { CampaignStatusEnum } from '@/graphql/types/_server';

const getMappedCurrentStage = (oldStage: CampaignStatusEnum): string => {
  switch (oldStage) {
    case CampaignStatusEnum.Draft:
      return 'Draft';
    case CampaignStatusEnum.Lottery:
    case CampaignStatusEnum.Repayment:
      return 'Earn';
    case CampaignStatusEnum.Remix:
      return 'Remix';
    case CampaignStatusEnum.Voting:
      return 'Vote';
    case CampaignStatusEnum.Check:
      return 'Checked';
    case CampaignStatusEnum.Done:
      return 'Closed';
    case CampaignStatusEnum.Canceled:
      return 'Canceled';
    default:
      return 'Proccessing';
  }
};

export default getMappedCurrentStage;

import { FC, useContext, memo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './styles';
import { ReactComponent as Speaker } from '@/files/icons/speaker.svg';
import { CampaignContext } from '../../../context';
import getCloudImgUrl from '@/utils/getUrlImage';

const LeftCard: FC<{ tune: number }> = ({ tune }) => {
  const { campaign, src } = useContext(CampaignContext);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.containerWhite}>
        <Box sx={{ ...styles.logo, backgroundImage: `url(${getCloudImgUrl(src, 170)})` }} />
        <Box sx={styles.containerText}>
          <Typography variant="span3">Sponsored by {campaign?.sponsor?.name}</Typography>
          <Typography variant="h3">{campaign.name}</Typography>
        </Box>
      </Box>
      <Box sx={styles.containerTop}>
        <Typography variant="span3">Your voting power</Typography>
        <Typography variant="h5" lineHeight={1} mt={1.625} sx={styles.text}>
          {tune} TUNE
        </Typography>
        <Speaker />
      </Box>
      <Box sx={styles.containerBottom}>
        <Typography
          variant="caption"
          component="p"
          fontWeight={300}
          sx={styles.textGrey}
          lineHeight="1.25rem"
        >
          Vote for your favorite remix with your TUNE tokens. You can earn them by freezing your
          LABEL tokens by 1:1 ratio
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(LeftCard);

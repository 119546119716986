const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  containerArrow: {
    width: '48px',
    height: '48px',
    background: '#F3F700',
    borderRadius: ' 50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s',
    ':hover': {
      backgroundColor: 'black',
      '& > svg > path': {
        fill: '#F3F700'
      }
    }
  },
  disabledArrow: {
    background: 'transparent',
    pointerEvents: 'none',
    '& > svg > path': {
      fill: 'rgb(26 27 29 / 30%)'
    }
  },
  arrowLeft: {
    transform: 'rotate(180deg)'
  }
};

export default styles;

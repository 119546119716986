const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    borderRadius: '2.5rem',
    width: '32.875rem',
    color: 'white',
    background: 'black',
    padding: '3.0625rem 2.625rem 2.5625rem',
    position: 'relative',
    '@media screen and (max-width: 500px)': {
      padding: '1.5rem'
    }
  },
  textGrey: {
    color: '#BDC2C8',
    mt: 2,
    fontWeight: 300,
    lineHeight: 1.7
  },
  containerText: {
    textAlign: 'center',
    maxWidth: '19rem',
    margin: 'auto',
    mt: 6
  }
};

export default styles;

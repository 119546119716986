import { FC, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import styles from './styles';
import { ReactComponent as Play } from '@/files/icons/play.svg';
import { ReactComponent as Pause } from '@/files/icons/pause.svg';
import { IRemixModel } from '@/graphql/types/_server';
import { Context } from '@/helpers/context';

const Remix: FC<{
  index: number;
  remix: IRemixModel & { vote: number };
  setChosedRemix: (remix: number) => void;
  amount: string;
}> = ({ index, remix, setChosedRemix, amount }) => {
  const { audio, setAudio, pauseAudioHandler } = useContext(Context);
  const { logoUrl, trackUrl } = remix;

  return (
    <Box key={remix.id} sx={styles.containerOuter}>
      <Box pt={1.75} pb={2} mr={2.5}>
        <Typography variant="body2" fontWeight={600} lineHeight={1} sx={styles.textGrey}>
          {index}
        </Typography>
      </Box>
      <Box width="100%">
        <Box sx={styles.containerInner}>
          <Box sx={styles.containerAudio}>
            {!audio?.isPlaying || audio?.id !== remix.id ? (
              <Play onClick={playAudio} />
            ) : (
              <Pause onClick={pauseAudio} />
            )}
            <Typography variant="body2" fontWeight={400} sx={styles.text} lineHeight={1} mr={1}>
              {remix.name}
            </Typography>
          </Box>
          <Box sx={styles.containerVote}>
            <Typography
              variant="body2"
              color="secondary"
              sx={{ ...styles.textVote, ...(amount !== '' && styles.animationVote) }}
            >
              +{amount}
            </Typography>
          </Box>
          <Box
            sx={{
              ...styles.vote,
              ...styles.textVoted,
              ...(amount !== '' && styles.animationTextVoted)
            }}
          >
            <Typography variant="body2">Voted</Typography>
          </Box>
          <Box sx={styles.vote}>{remix.vote}</Box>
          <Button
            fullWidth
            type="submit"
            color="secondary"
            variant="contained"
            sx={styles.button}
            onClick={() => setChosedRemix(remix?.id)}
          >
            <Typography variant="body2">Vote</Typography>
          </Button>
        </Box>
        <Divider light />
      </Box>
    </Box>
  );

  function playAudio() {
    setAudioHandler(true);
  }

  function pauseAudio() {
    pauseAudioHandler();
    setAudioHandler(false);
  }

  function setAudioHandler(isPlaying: boolean) {
    setAudio({
      name: remix.name,
      user: '',
      id: remix.id,
      showPlayer: true,
      isPlaying,
      logo: logoUrl,
      src: trackUrl
    });
  }
};

export default Remix;

import { FC, useContext, useMemo, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Context } from '@/helpers/context';
import { IFormInputsValidation, ISponsor } from './types';
import { CREATE_REMIX_BY_ADMIN } from '@/graphql/gql/store';
import {
  IMutationCreateRemixByAdminArgs,
  IRemixModel,
  IUsersModel,
  IQueryUsersArgs,
  FilterOperationEnum,
  SortDirectionEnum,
  FilterFieldTypeEnum
} from '@/graphql/types/_server';
import { IView } from '@/shared/DropZone/types';
import DropZone from '@/shared/DropZone/DropZone';
import styles from './styles';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import { USERS } from '@/graphql/gql/users';
import { ReactComponent as DownloadSvg } from '@/files/icons/download.svg';
import { genres, keys } from '@/helpers/directory';

const MAX_SIZE_AUDIO = 150 * 1024 * 1024;

const CreateForm: FC = () => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors, touchedFields }
  } = useForm<IFormInputsValidation>({ mode: 'onTouched' });
  const { wallet } = useContext(Context);
  const navigate = useNavigate();

  const [createRemixByAdmin, { loading }] = useMutation<
    { createRemixByAdmin: IRemixModel },
    IMutationCreateRemixByAdminArgs
  >(CREATE_REMIX_BY_ADMIN);

  const [sponsorName, setSponsorName] = useState<ISponsor | null>(null);
  // const [isComlpeted, setIsCmpleted] = useState<boolean>(false);

  const { data } = useQuery<{ users: IUsersModel }, IQueryUsersArgs>(USERS, {
    variables: {
      payload: {
        filters: [
          {
            columnName: 'type',
            value: ['sponsor'],
            type: FilterFieldTypeEnum.Text,
            operation: FilterOperationEnum.Equal
          }
        ],
        sorts: [{ columnName: 'id', direction: SortDirectionEnum.Asc }]
      }
    }
  });

  const sponsors = useMemo<ISponsor[]>(() => {
    return (data?.users?.items || []).map((item) => {
      return {
        id: String(item.id),
        label: `${item.id}: ${item.name}`
      };
    });
  }, [data]);

  const art = getValues('art');
  const audio = getValues('audio');

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box sx={styles.campaignWrap}>
          <Box sx={styles.campaignLeft}>
            <Box sx={styles.campaignName}>
              <Box>
                <Typography variant="body2" fontWeight={400} sx={styles.campaignLabel}>
                  Track Name
                </Typography>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      color="secondary"
                      size="small"
                      type="name"
                      placeholder="Name"
                      fullWidth
                      id="name"
                      error={touchedFields.name && Boolean(errors.name)}
                      helperText={errors?.name?.message || errors?.name?.type || ''}
                      {...register('name', {
                        required: 'Required',
                        maxLength: {
                          value: 50,
                          message: 'Max length must be 50 symbols'
                        }
                      })}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box mt={4.125}>
                <Typography variant="body2" fontWeight={400} sx={styles.campaignLabel}>
                  Sponsor
                </Typography>
                <Controller
                  name="sponsorId"
                  control={control}
                  defaultValue=""
                  render={() => (
                    <Autocomplete
                      disablePortal
                      id="sponsorId"
                      options={sponsors}
                      value={sponsorName}
                      fullWidth
                      onChange={(_, newValue) => {
                        setSponsorId(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          color="secondary"
                          placeholder="Select"
                          error={touchedFields.sponsorId && Boolean(errors.sponsorId)}
                          helperText={errors?.sponsorId?.message || errors?.sponsorId?.type || ''}
                          {...register('sponsorId', {
                            required: 'Required'
                          })}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Box mt={2.875}>
                <Typography variant="body2" fontWeight={400} mb={0.375}>
                  Description
                </Typography>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      color="secondary"
                      type="description"
                      id="description"
                      placeholder="New release..."
                      fullWidth
                      error={touchedFields.description && Boolean(errors.description)}
                      helperText={errors?.description?.message || errors?.description?.type || ''}
                      multiline
                      rows={6}
                      {...register('description', {
                        required: 'Required',
                        maxLength: {
                          value: 3000,
                          message: 'Max length must be 3000 symbols'
                        }
                      })}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box sx={styles.container} mt={2.875}>
                <Box>
                  <Typography variant="body2" fontWeight={400} mb={0.375}>
                    Genre
                  </Typography>
                  <Controller
                    name="genre"
                    control={control}
                    render={() => (
                      <Select
                        fullWidth
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={register('genre', {
                          required: 'Please select Genre'
                        })}
                        displayEmpty
                        error={Boolean(errors.genre)}
                        renderValue={
                          getValues('genre')
                            ? undefined
                            : () => (
                                <Typography
                                  variant="subtitle2"
                                  lineHeight={1}
                                  sx={styles.textTransparent}
                                >
                                  Select
                                </Typography>
                              )
                        }
                      >
                        {genres.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {Boolean(errors.genre) && (
                    <FormHelperText sx={{ mt: 0.5, mx: 1.75 }} error>
                      {errors.genre?.message}
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  <Typography variant="body2" fontWeight={400} mb={0.375}>
                    Key
                  </Typography>
                  <Controller
                    name="key"
                    control={control}
                    render={() => (
                      <Select
                        fullWidth
                        variant="outlined"
                        size="small"
                        color="secondary"
                        inputProps={register('key', {
                          required: 'Please select Key'
                        })}
                        displayEmpty
                        error={Boolean(errors.key)}
                        renderValue={
                          getValues('key')
                            ? undefined
                            : () => (
                                <Typography
                                  variant="subtitle2"
                                  lineHeight={1}
                                  sx={styles.textTransparent}
                                >
                                  Select
                                </Typography>
                              )
                        }
                      >
                        {keys.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {Boolean(errors.key) && (
                    <FormHelperText sx={{ mt: 0.5, mx: 1.75 }} error>
                      {errors.key?.message}
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  <Typography variant="body2" fontWeight={400} mb={0.375}>
                    BPM
                  </Typography>
                  <Controller
                    name="beats"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        color="secondary"
                        type="text"
                        fullWidth
                        placeholder="Beats"
                        id="beats"
                        error={touchedFields.beats && Boolean(errors.beats)}
                        helperText={errors?.beats?.message || errors?.beats?.type || ''}
                        {...register('beats', {
                          required: 'Required'
                        })}
                        {...field}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={styles.campaignDate}>
              <Box>
                <Typography variant="body2" fontWeight={400} mb={0.375}>
                  Track Price
                </Typography>
                <Controller
                  name="price"
                  control={control}
                  render={({ field: { value, ref } }) => {
                    return (
                      <NumberFormat
                        id="price"
                        color="secondary"
                        placeholder="USDC"
                        value={value}
                        customInput={TextField}
                        disabled={loading}
                        error={Boolean(errors.price)}
                        helperText={errors?.price?.message || errors?.price?.type || ' '}
                        {...register('price', {
                          required: 'Required',
                          min: {
                            value: 1,
                            message: '1 USDC minimum'
                          }
                        })}
                        suffix=" USDC"
                        thousandSeparator={false}
                        isNumericString
                        allowNegative={false}
                        decimalScale={0}
                        onValueChange={setPriceHandler}
                        ref={ref}
                      />
                    ) as JSX.Element;
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Button
                sx={styles.btn}
                fullWidth
                color="secondary"
                variant="contained"
                type="submit"
                disableElevation
              >
                <Typography variant="subtitle2" fontWeight={500}>
                  Upload Track
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box sx={styles.campaignRight}>
            <Box mb={4.25}>
              <Controller
                name="art"
                control={control}
                defaultValue={undefined}
                rules={{ required: 'Required' }}
                render={() => (
                  <DropZone
                    cropImage
                    files={getValues('art') || []}
                    handleFilesAdd={handleArtAdd}
                    handleFileDelete={handleArtDelete}
                    multiple={false}
                    maxFiles={1}
                    maxSize={2000000}
                    variant="black"
                    view={IView.Logo}
                    label={
                      <Box sx={styles.dropZoneLogo}>
                        <Box sx={styles.containerTitleLogo}>
                          <Typography variant="subtitle2" textAlign="start">
                            Track Cover
                          </Typography>
                        </Box>
                        {!art?.length && (
                          <Box mt={10.5} mb={4.25}>
                            <DownloadSvg />
                            <Typography variant="subtitle2" mt={1.25} lineHeight={1}>
                              Drop cover image here
                            </Typography>
                            <Typography variant="caption" sx={styles.textGrey} lineHeight={1}>
                              We accept only JPG or PNG files
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    }
                    error={Boolean(errors.art)}
                    helperText={
                      (errors?.art as unknown as FieldError)?.message ||
                      (errors?.art as unknown as FieldError)?.type ||
                      ''
                    }
                    accept={['image/jpeg', 'image/png']}
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name="audio"
                control={control}
                defaultValue={undefined}
                rules={{ required: 'Required' }}
                render={() => (
                  <DropZone
                    files={getValues('audio') || []}
                    handleFilesAdd={handleAudioAdd}
                    handleFileDelete={handleAudioDelete}
                    multiple={false}
                    maxFiles={1}
                    maxSize={MAX_SIZE_AUDIO}
                    variant="yellow"
                    view={IView.Music}
                    label={
                      <Box sx={styles.dropZoneStem}>
                        <Box sx={styles.containerTitle}>
                          <Typography variant="subtitle2" textAlign="start">
                            Track File
                          </Typography>
                        </Box>
                        {!audio?.length && (
                          <Box sx={styles.containerText}>
                            <DownloadSvg />
                            <Typography variant="subtitle2" mt={1.3} lineHeight={1}>
                              Drop track file here
                            </Typography>
                            <Typography variant="caption" sx={styles.textGrey} component="p" mt={1}>
                              We accept only MP3 or WAV files
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    }
                    error={Boolean(errors.audio)}
                    helperText={
                      (errors?.audio as unknown as FieldError)?.message ||
                      (errors?.audio as unknown as FieldError)?.type ||
                      ''
                    }
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </form>
      {loading && <BackdropLoading />}
    </>
  );

  async function setPriceHandler(values: NumberFormatValues) {
    setValue('price', values.value);
    await trigger('price');
  }

  async function handleAudioAdd(acceptedFiles: File[]) {
    setValue('audio', [...(getValues('audio') || []), ...acceptedFiles]);
    await trigger('audio');
  }

  async function handleAudioDelete() {
    setValue('audio', []);
    await trigger('audio');
  }

  async function handleArtAdd(acceptedFiles: File[]) {
    setValue('art', [...(getValues('art') || []), ...acceptedFiles]);
    await trigger('art');
  }

  async function handleArtDelete(file: File) {
    setValue(
      'art',
      (getValues('art') || []).filter((item) => item !== file)
    );
    await trigger('art');
  }

  async function onSubmit(data: IFormInputsValidation) {
    const beats = Number(data.beats);
    const isBeatsCorrect = isFinite(beats);

    try {
      await createRemixByAdmin({
        variables: {
          chainId: Number(wallet?.ethers?.getChainId()) || 137,
          audio: data.audio[0],
          logo: data.art[0],
          payload: {
            beats: isBeatsCorrect ? beats : 0,
            description: data.description,
            genre: data.genre,
            idSponsor: Number(sponsorName?.id), // TODO: использовать объект data
            name: data.name,
            key: data.key,
            price: Number(data.price)
          }
        }
      });

      toast.success('You have successfully upload a Remix');
      navigate('/store');
    } catch (err) {
      const error = err as Error;
      toast.error(error?.message);
    }
  }

  async function setSponsorId(sponsor: ISponsor | null) {
    setSponsorName(sponsor);
    setValue('sponsorId', sponsor?.id || '');
    await trigger('sponsorId');
  }
};

export default CreateForm;

const styles = {
  container: {
    '& .MuiDrawer-paper': {
      backgroundColor: '#000000',
      boxSizing: 'border-box',
      borderRadius: '50px 0 0 50px'
    }
  },
  drawerHistory: {
    width: '380px',
    height: '100%',
    position: 'relative',
    background: 'black'
  },
  header: {
    m: '16px 34px 6px 34px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& svg': {
      color: 'rgba(255,255,255,.5)'
    }
  },
  title: {
    color: '#fff',
    mt: '50px'
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '10px'
  },
  transactionsTitle: {
    color: 'rgba(255,255,255,.5)',
    mb: '30px',
    display: 'block'
  },
  transactionsBlock: {
    ml: '34px'
  },
  transactionsBlockLine: {
    borderTop: '1px solid rgba(243, 247, 0, .2)',
    pt: '32px',
    ml: '34px'
  },
  transactions: {
    '& > div:first-of-type': {
      borderTop: 'none'
    }
  },
  transactionsWrap: {
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    mr: '24px',
    mb: '30px'
  },
  transactionsLeft: {
    display: 'flex',
    flexDirection: 'column',
    '& span:last-of-type': {
      color: 'rgba(255,255,255,.5)'
    }
  },
  transactionsRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    '& span:first-of-type': {
      mb: '2px'
    },
    '& span:last-of-type': {
      color: 'rgba(255,255,255,.5)'
    }
  },
  positive: {
    color: '#28EB48'
  },
  negative: {
    color: '#FF5F5F'
  }
};

export default styles;

import { FC, useState, useMemo } from 'react';
import Container from '@mui/material/Container';
import SwiperType from 'swiper';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useWindowSize } from '@/utils/useWindowSize';
import Track from '@/shared/card/Track/Track';
import styles from './styles';
import { IProps } from './types';
import SwiperNavigation from '../share/SwiperNavigation/SwiperNavigation';

const TopTracks: FC<IProps> = ({ remixes, handleOpen }) => {
  const [width] = useWindowSize();
  const countSlides = useMemo(() => {
    if (width <= 360) {
      return 1;
    }
    if (width <= 600) {
      return 2;
    }
    if (width <= 850) {
      return 3;
    }
    if (width <= 1200) {
      return 4;
    }
    return 5;
  }, [width]);
  const [swiperRef, setSwiperRef] = useState<SwiperType | undefined>(undefined);

  return (
    <Box mt={8.75}>
      <Container maxWidth="xl">
        <Box sx={styles.container}>
          <Typography variant="h5">Top {remixes.length} Tracks</Typography>
          <SwiperNavigation
            initSwiperNavigation={
              remixes.length
                ? { isBeginning: true, isEnd: remixes.length <= countSlides }
                : undefined
            }
            swiperRef={swiperRef}
          />
        </Box>
      </Container>
      <Box mt={5} sx={styles.containerSwiper}>
        <Box sx={styles.line} />
        <Box sx={styles.line} />
        <Swiper slidesPerView={countSlides} spaceBetween={21} onSwiper={setSwiperRef}>
          {remixes.map((item) => (
            <SwiperSlide key={`${item.id}_topTrack`}>
              <Track remix={item} handleClick={handleOpen} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default TopTracks;

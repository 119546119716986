import { FC, useContext, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './styles';
import { ReactComponent as CheckSvg } from '@/files/icons/check.svg';
import { Context } from '@/helpers/context';

const Step1: FC = () => {
  const client = useApolloClient();
  const { setToken, setWallet, wallet } = useContext(Context);

  useEffect(() => {
    logoutHandler();
  }, []);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.loader}>
        <img src="/img/loader.png" alt="Loader" />
        {wallet.address === '' ? <img src="/img/metamask.png" alt="MetaMask" /> : <CheckSvg />}
      </Box>
      <Typography variant="h3" mt={7.5}>
        Signing in
      </Typography>
      <Typography variant="subtitle2" sx={styles.textTransparent} mt={2}>
        Please check your Metamask Wallet
      </Typography>
    </Box>
  );

  async function logoutHandler() {
    try {
      await client.clearStore();
      setToken('');
      setWallet({
        address: '',
        ethers: undefined
      });
      localStorage.removeItem('TOKEN');
      localStorage.removeItem('MY_ADDRESS');
    } catch (err) {
      const error = err as Error;
      console.error(error);
    }
  }
};

export default Step1;

import { FC, useMemo, useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import formatEthers from '@/utils/formatEthers';
import { IFormInputsValidation } from './types';
import { IRemixModel, RemixStatusEnum, SmartContractTypeEnum } from '@/graphql/types/_server';
import { Context } from '@/helpers/context';
import getErrorMessage from '@/utils/getErrorMessage';
import { CampaignContext } from '../../context';
import Step0 from './steps/Step0/Step0';
import Step1 from './steps/Step1/Step1';
import { getBalanceOf, getCountVotes, vote } from '@/utils/contractRequests';
import EthersSimple from '@/utils/ethers';
import { checkBalanceMatic, checkBalanceTune } from '@/utils/checkBalanceToken';

const Voting: FC = () => {
  const { campaign, setIsStaked, setIsShowMenu } = useContext(CampaignContext);

  const form = useForm<IFormInputsValidation>({ mode: 'onTouched' });

  const [loading, setLoading] = useState<boolean>(false);
  const [chosedRemix, setChosedRemix] = useState<number>(-1);

  const [voteRemix, setVoteRemix] = useState<{ id: number; amount: string }>({
    id: -1,
    amount: ''
  });
  const { wallet, contracts } = useContext(Context);
  const [votePointBalance, setVotePointBalance] = useState(0);
  const [labelBalance, setLabelBalance] = useState<string>('0');
  const [remixes, setRemixes] = useState<(IRemixModel & { vote: number })[]>([]);

  useEffect(() => {
    setIsStaked(true);
    setIsShowMenu(true);
  }, []);

  useEffect(() => {
    if (campaign?.remixes?.length) {
      setRemixes(getRemixes());
    }
  }, [campaign?.remixes]);

  const remix = useMemo(
    () => remixes.find((remix) => remix?.id === chosedRemix),
    [remixes, chosedRemix]
  );

  useEffect(() => {
    if (!contracts.loading && campaign?.remixes?.length) {
      getLabelBalance();
    }
  }, [contracts.loading, campaign?.remixes]);

  return remix && chosedRemix !== -1 ? (
    <Step1
      votePointBalance={votePointBalance}
      remix={remix}
      form={form}
      labelBalance={labelBalance}
      onGetStakeAmount={getLabelBalance}
      setChosedRemix={setChosedRemix}
      onSubmit={onSubmit}
      loading={loading}
    />
  ) : (
    <Step0
      votePointBalance={votePointBalance}
      remixes={remixes}
      setChosedRemix={setChosedRemix}
      voteRemix={voteRemix}
    />
  );

  async function getLabelBalance() {
    try {
      if (!wallet.ethers) return;
      setLoading(true);

      const balance = await getBalanceOf(
        wallet.ethers,
        contracts.contracts[SmartContractTypeEnum.Label],
        { account: wallet.address }
      );
      setLabelBalance(formatEthers(balance, 18));

      await getTuneBalance();
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }

  async function getTuneBalance() {
    try {
      if (!wallet.ethers) return;
      const [tunePoints, balances] = await Promise.all([
        getBalanceOf(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Tune], {
          account: wallet.address
        }),
        getCountVotes(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Campaign], {
          tokenID: campaign.tokenID
        })
      ]);

      setVotePointBalance(Number(formatEthers(tunePoints, 18)));

      const mapping: Record<string, number>[] = balances[0].map((item: bigint, index: number) => {
        return {
          id: item.toString(),
          sum: Number(EthersSimple.ethers.utils.formatUnits(balances[1][index], 18))
        };
      });
      setRemixes(getRemixes(mapping));
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  }

  async function voteHandler(id: number, amount: string) {
    try {
      if (!wallet.ethers) return;

      await Promise.all([
        checkBalanceMatic(wallet),
        checkBalanceTune(wallet, contracts.contracts, Number(amount))
      ]);

      setLoading(true);
      let loadingLocal = true;
      const amountBigInt = EthersSimple.ethers.utils.parseEther(amount);
      const successCallback = async () => {
        if (loadingLocal) {
          loadingLocal = false;
          toast.success('Staking is successful');
          setVoteRemix({ id, amount });
          setLoading(false);
          setChosedRemix(-1);
          await getTuneBalance();
        }
      };

      await vote(
        wallet.ethers,
        contracts.contracts[SmartContractTypeEnum.Campaign],
        {
          tokenID: campaign.tokenID,
          remixID: remix?.tokenID,
          amount: amountBigInt
        },
        {
          Hash: () => {
            toast.info('Waiting for the transaction to complete');
          },
          Error: () => {
            setLoading(false);
          },
          After: successCallback
        }
      );

      successCallback();

      return;
    } catch (error) {
      toast.error(getErrorMessage(error));
      setVoteRemix({ id, amount: '' });
      setLoading(false);
      setChosedRemix(-1);
    }
  }

  async function onSubmit(data: IFormInputsValidation) {
    await voteHandler(chosedRemix, data.amount);
  }

  function getRemixes(balances: Record<string, string | number>[] = []) {
    return (
      campaign?.remixes
        ?.filter((item) => item.status === RemixStatusEnum.Voting)
        .map((remix) => ({
          ...remix,
          vote: Number(balances.find((item) => item.id === remix?.tokenID)?.sum) || 0
        }))
        .sort((a, b) => b.vote - a.vote) || []
    );
  }
};

export default Voting;

import { gql } from '@apollo/client';

const PAGINATE_MODEL = gql`
  fragment paginateModel on PaginateModel {
    skip
    take
    total
  }
`;

export default PAGINATE_MODEL;

const styles = {
  containerForm: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *:first-child': {
      width: '61%'
    },
    '& > *:last-child': {
      backgroundColor: '#fff',
      width: '34%',
      height: '3rem',
      ':hover': {
        backgroundColor: '#F3F700'
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        color: 'rgba(255, 255, 255, 0.3)'
      },
      '&.MuiLoadingButton-loading': {
        color: 'transparent',
        backgroundColor: 'white'
      }
    }
  },
  caption: {
    fontWeight: 300,
    lineHeight: '1.3125rem',
    color: '#BDC2C8'
  }
};

export default styles;

import { FC, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import differenceInDays from 'date-fns/differenceInDays';
import styles from './styles';
import { CampaignContext } from '@/components/Campaigns/Campaign/context';

const UploadProgress: FC = () => {
  const { campaign } = useContext(CampaignContext);
  const countDays = getRemainingDays(campaign.startVotingDate);
  const countDaysToUploadRemix = 45;

  return (
    <Box sx={styles.containerProgress}>
      <CircularProgress
        variant="determinate"
        size="7.75rem"
        thickness={1}
        value={((countDaysToUploadRemix - countDays) / countDaysToUploadRemix) * 100}
        style={styles.progress}
      />
      <Box sx={styles.progressText}>
        <Typography variant="h5" lineHeight={1}>
          {countDays}
        </Typography>
        <Typography
          variant="caption"
          fontWeight={300}
          sx={styles.textGrey}
          lineHeight={1}
          mt={0.375}
        >
          {countDays !== 1 ? 'Days' : 'Day'}
        </Typography>
      </Box>
    </Box>
  );

  function getRemainingDays(startVotingDate: string) {
    return differenceInDays(new Date(startVotingDate), new Date());
  }
};

export default UploadProgress;

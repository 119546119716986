import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './styles';

const Step0: FC<{ setStep: (step: number) => void }> = ({ setStep }) => {
  return (
    <Box sx={styles.container}>
      <Typography variant="h3">Please select wallet</Typography>
      <Box sx={styles.containerWallets}>
        <Box sx={styles.containerWallet}>
          <Box sx={styles.wallet}>
            <Box sx={styles.iconWallet}>
              <img src="/img/coinbase.png" width="100%" alt="Coinbase" />
            </Box>
            <Box>
              <Typography variant="h3" sx={styles.textTransparent} mt={3.5}>
                Coinbase
              </Typography>
              <Typography variant="subtitle2" sx={styles.textTransparent} mt={2.25}>
                Coming soon
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.containerWallet}>
          <Box
            sx={styles.wallet}
            onClick={() => {
              localStorage.setItem('IS_CONNECT_WALLET', 'connectToWallet');
              setStep(1);
            }}
          >
            <Box sx={styles.iconWallet}>
              <img src="/img/metamask.png" width="82.56%" alt="MetaMask" />
            </Box>
            <Typography variant="h3" mt={3.5}>
              Metamask
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Step0;

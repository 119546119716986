import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import Stack from '@mui/material/Stack';
import { IProps } from './types';
import styles from '@/shared/Modal/styles';

const Popup: FC<IProps> = ({ sx, isOpen, onClose, title, children, actionsComponent }) => {
  return (
    <Dialog sx={styles.background} maxWidth="sm" onClose={onClose} open={isOpen}>
      {!!title && (
        <DialogTitle>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            sx={styles.text}
          >
            <Typography variant="h5">{title}</Typography>
            {onClose ? (
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </Stack>
        </DialogTitle>
      )}
      <DialogContent sx={{ ...sx, ...styles.content }} dividers>
        {children}
      </DialogContent>
      {!!actionsComponent && <DialogActions>{actionsComponent}</DialogActions>}
    </Dialog>
  );
};

export default Popup;

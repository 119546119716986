const styles = {
  container: {
    background: 'black',
    color: 'white',
    borderRadius: '20px',
    margin: '0 32px 16px 32px !important',
    width: 'calc(100% - 64px)'
  },
  textGrey: {
    opacity: '0.5'
  },
  containerInner: {
    display: 'flex',
    padding: '40px 35px 24px'
  },
  icon: {
    position: 'absolute',
    top: '43px',
    right: '43px',
    mt: '0px !important',
    '& path': {
      fill: 'black'
    }
  },
  containerText: {
    overflow: 'hidden',
    '& > *': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
};

export default styles;

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    columnGap: 2,
    rowGap: 1,
    pt: 2.625
  },
  title: {
    position: 'relative',
    pt: 5.25,
    pb: 3.25,
    lineHeight: 1
  },
  text: {
    position: 'relative',
    pb: 8
  }
};

export default styles;

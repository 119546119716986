const styles = {
  container: {
    minHeight: '100%',
    background: 'black',
    borderRadius: '2.5rem',
    color: 'white',
    padding: '2.25rem 1.25rem 1.25rem 1.9375rem'
  },
  text: {
    fontFamily: '"Grafita", monospace, sans-serif'
  },
  textGreyTransparent: {
    color: '#BDC2C8',
    opacity: 0.5
  },
  textGrey: {
    color: '#BDC2C8'
  },
  textYellow: {
    color: '#F3F700',
    cursor: 'pointer'
  },
  containerAudio: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& > svg': {
      width: '2rem',
      height: '2rem',
      minWidth: '2rem',
      minHeight: '2rem',
      mr: 1.25,
      fill: '#F3F700'
    }
  },
  containerInner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '0.75rem',
    paddingBottom: '1rem'
  },
  containerOuter: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '1.375rem',
    paddingLeft: '0.875rem',
    flexWrap: 'wrap'
  },
  vote: {
    background: 'rgb(255 255 255 / 10%)',
    minWidth: '4.75rem',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.625rem',
    color: 'rgb(255 255 255)'
  },
  containerScroll: {
    paddingRight: '0.5rem'
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    mt: '2.875rem',
    '& > *:first-child': {
      width: '61%'
    },
    '& > *:last-child': {
      backgroundColor: '#fff',
      width: '34%',
      height: '3rem',
      ':hover': {
        backgroundColor: '#fff'
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        color: 'rgba(255, 255, 255, 0.3)'
      },
      '&.MuiLoadingButton-loading': {
        color: 'transparent',
        backgroundColor: 'white'
      }
    }
  },
  description: {
    fontWeight: 300,
    lineHeight: '1.3125rem',
    color: '#BDC2C8',
    paddingLeft: 0.625
  },
  containerForm: {
    paddingLeft: 1.125,
    paddingRight: 4.5
  },
  divider: {
    width: '100%',
    mb: '1.1875rem'
  },
  icon: {
    position: 'absolute',
    top: '1.125rem',
    right: '2rem',
    cursor: 'pointer',
    color: 'white'
  },
  containerRight: {
    width: '34.625rem'
  }
};

export default styles;

import colors from '@/helpers/theme/colors';

const styles = (theme: 'dark' | 'light') => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 2,
    gap: 0.5,
    '& > a': {
      color: colors.common.black,
      textDecoration: 'none'
    }
  },
  button: {
    display: 'flex',
    alignItems: 'end',
    padding: '0.25rem 0.6875rem',
    color: theme === 'dark' ? '#232323' : 'white',
    '@media screen and (max-width: 1200px)': {
      display: 'none'
    }
  },
  icon: {
    width: '1.5rem',
    height: '1.5rem'
  },
  iconButton: {
    display: 'none',
    height: '2.5rem',
    alignItems: 'center',
    color: theme === 'dark' ? '#232323' : 'white',
    '& > svg': {
      fontSize: 24
    },
    '@media screen and (max-width: 1200px)': {
      display: 'grid'
    }
  }
});

export default styles;

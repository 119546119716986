import { FC, memo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Context, ICartContext } from '@/helpers/context';
import { ReactComponent as EmptyTrashSvg } from '@/files/icons/cart/trash-empty.svg';
import styles from './styles';
import getCloudImgUrl from '@/utils/getUrlImage';

const CartItem: FC<{
  item: ICartContext;
  loading: boolean;
}> = ({ item, loading }) => {
  const navigate = useNavigate();

  const { cart, setCart, setOpenCart } = useContext(Context);

  return (
    <Paper key={item.id} sx={styles.item} variant="outlined">
      <Box onClick={navigateHandler}>
        <img src={getCloudImgUrl(item.logo, 100)} alt={item.name} />
      </Box>
      <Box>
        <Box>
          <Box sx={{ cursor: 'pointer' }} onClick={navigateHandler}>
            <Typography variant="span1">{item.name}</Typography>
          </Box>
          <Box>
            <Typography sx={styles.itemUser} variant="span3">
              by: {item.user}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.itemBtn}>
        <Box sx={styles.cross}>
          <IconButton onClick={removeFormCartHandler} disabled={loading}>
            <EmptyTrashSvg />
          </IconButton>
        </Box>
      </Box>
      <Box sx={styles.priceWrap}>
        <Box sx={styles.cross}>
          <Typography variant="body1">{item.price} USDC</Typography>
        </Box>
      </Box>
    </Paper>
  );

  function removeFormCartHandler() {
    const { id } = item;
    const filteredItems = cart.filter((item) => item.id !== id);
    setCart(filteredItems);

    toast.success('Successfully removed from the Shopping List');
  }

  function navigateHandler() {
    navigate('/store', {
      state: {
        id: item.id
      }
    });
    setOpenCart(false);
  }
};

export default memo(CartItem);

import colors from '@/helpers/theme/colors';

const styles = {
  container: {
    width: '383px',
    '& > svg': {
      fill: 'white'
    },
    '@media screen and (max-width: 1200px)': {
      width: '176px'
    }
  },
  arrow: {
    transform: 'rotate(90deg)',
    '&:hover > svg > path': {
      fill: '#F3F700'
    },
    '& > svg': {
      width: '24px',
      height: '24px',
      '& > path': {
        fill: 'white'
      }
    }
  },
  header: {
    padding: '0 3rem',
    height: '128px',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > :first-of-type': {
      display: 'flex',
      gap: 2,
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > :first-of-type': {
        cursor: 'pointer'
      },
      '> svg': {
        transition: 'transform ease-in-out 0.5s',
        '&:hover': { cursor: 'pointer', transform: 'scale(1.05)' }
      }
    },
    '& > :last-of-type': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'end',
      '& > :last-child': {
        marginLeft: '0.5rem'
      }
    }
  },
  headerMobile: {
    px: 1,
    height: '90px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '> svg': {
      fill: 'white',
      transition: 'transform ease-in-out 0.5s',
      '&:hover': { cursor: 'pointer', transform: 'scale(1.1)' }
    }
  },
  fill: {
    flex: 1
  },
  button: {
    backgroundColor: colors.common.yellow,
    p: '13px 26px 9px',
    '&:hover': {
      backgroundColor: '#dde100'
    }
  },
  containerMobile: {
    minWidth: 'max-content'
  }
};

export default styles;

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  containerArrow: {
    width: '48px',
    height: '48px',
    background: '#F3F700',
    borderRadius: ' 50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s',
    ':hover': {
      backgroundColor: 'black',
      '& > svg > path': {
        fill: '#F3F700'
      }
    }
  },
  disabledArrow: {
    background: 'transparent',
    pointerEvents: 'none',
    '& > svg > path': {
      fill: 'rgb(26 27 29 / 30%)'
    }
  },
  arrowLeft: {
    transform: 'rotate(180deg)'
  },
  textGrey: {
    color: '#232323',
    opacity: 0.5
  },
  link: {
    transition: 'none',
    borderBottom: 'none'
  },
  containerSwiper: {
    position: 'relative',
    '& .swiper-slide:first-child': {
      // marginLeft: '150px'
    }
  },
  line: {
    background: 'linear-gradient(90deg, #F4F4F4 27.62%, rgba(244, 244, 244, 0) 100%);',
    width: '10%',
    maxWidth: '172px',
    height: '100%',
    position: 'absolute',
    zIndex: 2,
    '@media screen and (max-width: 1600px)': {
      display: 'none'
    }
  }
};

export default styles;

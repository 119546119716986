import { FC, useContext, useMemo, memo } from 'react';
import Box from '@mui/material/Box';
import Song from './Song/Song';
import Bar from './Bar/Bar';
import { styles } from './styles';
import { Context } from '@/helpers/context';
import { IProps } from './types';

const AudioPlayer: FC<IProps> = ({ waveformRef, wavesurfer, loading }) => {
  const { audio } = useContext(Context);

  const stylesMemoized = useMemo(() => {
    return styles(audio !== null);
  }, [audio]);

  return (
    <Box sx={stylesMemoized.container}>
      <Box sx={stylesMemoized.player}>
        <Song audio={audio} />
        <Bar waveformRef={waveformRef} wavesurfer={wavesurfer} loading={loading} />
      </Box>
    </Box>
  );
};

export default memo(AudioPlayer);

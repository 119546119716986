import { FC, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { CampaignContext } from '@/components/Campaigns/Campaign/context';
import InformationLabel from '@/shared/InformationLabel/InformationLabel';
import styles from './styles';

const StepInformation: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const { setIsShowMenu, setTimelineChecked } = useContext(CampaignContext);
  setIsShowMenu(false);
  setTimelineChecked(false);

  return (
    <>
      <IconButton sx={styles.closeIcon} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <InformationLabel isAnimation />
    </>
  );
};

export default StepInformation;

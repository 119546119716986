const styles = {
  container: {
    width: 'fit-content',
    clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 64% 0, 49% 0, 32% 0, 32% 0)',

    animationName: 'break',
    animationDuration: '3s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-in',
    animationIterationCount: 1,
    animationDelay: '0s',
    '@keyframes break': {
      '5%': {
        clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 64% 0, 50% 5%, 32% 0, 32% 0)'
      },
      '12%': {
        clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 56% 5%, 50% 12%, 32% 0, 32% 0)'
      },
      '19%': {
        clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 56% 5%, 50% 15%, 39% 5%, 32% 0)'
      },
      '26%': {
        clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 55% 11%, 51% 26%, 43% 10%, 32% 0)'
      },
      '33%': {
        clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 53% 28%, 51% 47%, 44% 25%, 32% 0)'
      },
      '40%': {
        clipPath:
          'polygon(0 0, 0% 100%, 100% 100%, 100% 0, 64% 0, 56% 28%, 53% 64%, 41% 25%, 32% 0)'
      },
      '47%': {
        clipPath:
          'polygon(0 0, 0% 100%, 100% 100%, 100% 0, 64% 0, 55% 31%, 54% 80%, 44% 31%, 32% 0)'
      },
      '54%': {
        clipPath:
          'polygon(0 0, 0% 100%, 100% 100%, 100% 0, 64% 0, 61% 38%, 55% 89%, 40% 37%, 32% 0)'
      },
      '61%': {
        clipPath:
          'polygon(39% 66%, 32% 41%, 32% 0, 0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 71% 42%, 67% 63%, 55% 89%)'
      },
      '68%': {
        clipPath:
          'polygon(31% 73%, 24% 45%, 19% 0, 0 0, 0 100%, 100% 100%, 100% 0, 81% 0, 77% 42%, 76% 73%, 55% 89%)'
      },
      '75%': {
        clipPath:
          'polygon(28% 79%, 14% 43%, 7% 0, 0 0, 0 100%, 100% 100%, 100% 0, 89% 0, 85% 45%, 76% 78%, 56% 95%)'
      },
      '82%': {
        clipPath:
          'polygon(28% 79%, 14% 43%, 7% 0, 0 0, 0 100%, 100% 100%, 100% 0, 89% 0, 85% 45%, 76% 78%, 56% 95%)'
      },
      '89%': {
        clipPath:
          'polygon(19% 86%, 8% 43%, 3% 0, 0 0, 0 100%, 100% 100%, 100% 0, 96% 0, 93% 45%, 88% 88%, 58% 100%)'
      },
      '95%': {
        clipPath:
          'polygon(8% 91%, 3% 48%, 0 0, 0 0, 0 100%, 100% 100%, 100% 0, 100% 0, 100% 46%, 94% 91%, 58% 100%)'
      },
      '100%': {
        clipPath:
          'polygon(0 100%, 0 48%, 0 0, 0 0, 0 100%, 100% 100%, 100% 0, 100% 0, 100% 46%, 100% 100%, 58% 100%)'
      }
    }
  },
  outerContainer: {
    height: '100%',
    position: 'initial',
    perspective: '2000px',
    color: 'white',
    textAlign: 'center'
  },
  container1: {
    margin: 'auto',
    position: 'relative',

    '& > svg:last-of-type': {
      height: 0,
      width: 0
    },

    transform: 'rotateY(-90deg)',
    transformStyle: 'preserve-3d',

    width: 'fit-content',
    animationName: 'rotationY',
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-in',
    animationIterationCount: 1,
    animationDelay: '0s',
    '@keyframes rotationY': {
      '100%': {
        transform: 'rotateY(0deg)'
      }
    }
  },
  content: {
    opacity: 0,
    animationName: 'appearance',
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-in',
    animationIterationCount: 1,
    animationDelay: '0.7s',
    '@keyframes appearance': {
      '100%': {
        opacity: '1'
      }
    }
  },
  button: {
    height: '48px',
    width: '143px',
    backgroundColor: '#fff',
    ':hover': {
      backgroundColor: '#F3F700'
    }
  },
  buttonAnimation: {
    opacity: 1,
    animationName: 'hideButton',
    animationDuration: '0.5s',
    animationFillMode: 'both',
    animationTimingFunction: 'ease-in',
    animationIterationCount: 1,
    animationDelay: '0s',
    '@keyframes hideButton': {
      '100%': {
        opacity: 0
      }
    }
  },
  container2: {
    clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 64% 0, 49% 0, 32% 0, 32% 0)',
    transform: 'rotateY(0deg)',
    margin: 'auto',
    transformStyle: 'preserve-3d',
    width: 'fit-content',
    animationName: 'rotationY2, break',
    animationDuration: '2s, 2s',
    animationFillMode: 'forwards, forwards',
    animationTimingFunction: 'ease-in, ease-in',
    animationIterationCount: '1, 1',
    animationDelay: '1s, 3s',
    '@keyframes rotationY2': {
      '50%': {
        transform: 'rotateY(-90deg)'
      },
      '100%': {
        transform: 'rotateY(-180deg)'
      }
    },
    '& > svg:first-of-type': {
      animationName: 'hide',
      animationDuration: '0s',
      animationFillMode: 'both',
      animationTimingFunction: 'ease-in',
      animationIterationCount: 1,
      animationDelay: '2s',
      '@keyframes hide': {
        '100%': {
          height: 0,
          width: 0
        }
      }
    },
    '& > svg:last-of-type': {
      height: 0,
      width: 0,
      animationName: 'show',
      animationDuration: '0s',
      animationFillMode: 'both',
      animationTimingFunction: 'ease-in',
      animationIterationCount: 1,
      animationDelay: '2s',
      '@keyframes show': {
        '100%': {
          height: 'auto',
          width: 'auto'
        }
      }
    },
    '@keyframes break': {
      '5%': {
        clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 64% 0, 50% 5%, 32% 0, 32% 0)'
      },
      '12%': {
        clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 56% 5%, 50% 12%, 32% 0, 32% 0)'
      },
      '19%': {
        clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 56% 5%, 50% 15%, 39% 5%, 32% 0)'
      },
      '26%': {
        clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 55% 11%, 51% 26%, 43% 10%, 32% 0)'
      },
      '33%': {
        clipPath: 'polygon(0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 53% 28%, 51% 47%, 44% 25%, 32% 0)'
      },
      '40%': {
        clipPath:
          'polygon(0 0, 0% 100%, 100% 100%, 100% 0, 64% 0, 56% 28%, 53% 64%, 41% 25%, 32% 0)'
      },
      '47%': {
        clipPath:
          'polygon(0 0, 0% 100%, 100% 100%, 100% 0, 64% 0, 55% 31%, 54% 80%, 44% 31%, 32% 0)'
      },
      '54%': {
        clipPath:
          'polygon(0 0, 0% 100%, 100% 100%, 100% 0, 64% 0, 61% 38%, 55% 89%, 40% 37%, 32% 0)'
      },
      '61%': {
        clipPath:
          'polygon(39% 66%, 32% 41%, 32% 0, 0 0, 0 100%, 100% 100%, 100% 0, 64% 0, 71% 42%, 67% 63%, 55% 89%)'
      },
      '68%': {
        clipPath:
          'polygon(31% 73%, 24% 45%, 19% 0, 0 0, 0 100%, 100% 100%, 100% 0, 81% 0, 77% 42%, 76% 73%, 55% 89%)'
      },
      '75%': {
        clipPath:
          'polygon(28% 79%, 14% 43%, 7% 0, 0 0, 0 100%, 100% 100%, 100% 0, 89% 0, 85% 45%, 76% 78%, 56% 95%)'
      },
      '82%': {
        clipPath:
          'polygon(28% 79%, 14% 43%, 7% 0, 0 0, 0 100%, 100% 100%, 100% 0, 89% 0, 85% 45%, 76% 78%, 56% 95%)'
      },
      '89%': {
        clipPath:
          'polygon(19% 86%, 8% 43%, 3% 0, 0 0, 0 100%, 100% 100%, 100% 0, 96% 0, 93% 45%, 88% 88%, 58% 100%)'
      },
      '95%': {
        clipPath:
          'polygon(8% 91%, 3% 48%, 0 0, 0 0, 0 100%, 100% 100%, 100% 0, 100% 0, 100% 46%, 94% 91%, 58% 100%)'
      },
      '100%': {
        clipPath:
          'polygon(0 100%, 0 48%, 0 0, 0 0, 0 100%, 100% 100%, 100% 0, 100% 0, 100% 46%, 100% 100%, 58% 100%)'
      }
    }
  },
  container3: {
    opacity: 0,
    borderRadius: '2.5rem',
    position: 'absolute',
    background: 'black',
    padding: '34px 18px 20px',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'initial',

    width: '422px',
    height: '389px',
    top: 0,
    zIndex: -1,

    transform: 'translate(-50%, 0%)',
    left: '50%'
  },
  container4: {
    opacity: 0,
    animationName: 'showStem1, showStem2',
    animationDuration: '0s, 0s',
    animationFillMode: 'both, both',
    animationTimingFunction: 'ease-in, ease-in',
    animationIterationCount: '1, 1',
    animationDelay: '3s, 5s',
    '@keyframes showStem1': {
      '100%': {
        opacity: 1
      }
    },
    '@keyframes showStem2': {
      '100%': {
        zIndex: 0
      }
    }
  },
  containerStem: {
    color: 'black',
    background: '#F3F700',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    padding: '40px 35px',
    cursor: 'pointer',
    '& path': {
      fill: 'black'
    },
    '& > div:last-of-type': {
      ml: 2.5
    }
  },
  containerContent: {
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0 10px 20px'
  },
  textGrey: {
    opacity: 0.5
  }
};

export default styles;

import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles';
import { IProps } from './types';
import Layout from '../Layout/Layout';
import Logo from '../Logo/Logo';
import Campaing from '../Campaing/Campaing';
import { ReactComponent as BanSvg } from '@/files/icons/ban.svg';

const Notification: FC<IProps> = ({ type, text, width }) => {
  return (
    <Layout leftCard={<Logo />}>
      <Campaing isDescription />
      <Box sx={styles.stake}>
        {(() => {
          switch (type) {
            case 'metamask':
              return <img src="/img/metamask.png" width="48px" height="48px" alt="MetaMask" />;
            case 'ban':
              return <BanSvg />;
            default:
              return <CircularProgress size={48} color="secondary" />;
          }
        })()}
        <Typography
          variant="caption"
          component="p"
          sx={{ ...styles.caption, ...(!!width && { width }) }}
        >
          {text}
        </Typography>
      </Box>
    </Layout>
  );
};

export default Notification;

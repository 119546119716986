const styles = {
  menu: {
    mt: '14px',
    '& .MuiList-root': {
      p: 0
    }
  },
  container: {
    width: '310px',
    '& > div:first-of-type': {
      p: '24px 30px 12px 30px'
    },
    '& > div:last-of-type': {
      p: '16px 30px 22px 30px'
    }
  },
  text: {
    fontSize: '14px',
    mb: '20px',
    cursor: 'pointer',
    width: 'fit-content',
    '&:last-of-type': {
      mb: 0
    }
  }
};

export default styles;

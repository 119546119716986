const styles = {
  containerBottom: {
    background: 'black',
    borderBottomRightRadius: '2.5rem',
    borderBottomLeftRadius: '2.5rem',
    color: 'white',
    display: 'flex',
    padding: '2.875rem 3.4375rem 3.75rem 2.875rem',
    justifyContent: 'space-between',
    '@media screen and (max-width: 400px)': {
      flexDirection: 'column',
      alignItems: 'center',
      height: 'auto',
      '& > *:first-child': {
        width: '100%'
      },
      '& > *:last-child': {
        mt: 2
      }
    }
  },
  containerText: {
    width: '64%',
    height: 'fit-content',
    alignSelf: 'center'
  }
};

export default styles;

import { Route, Routes, BrowserRouter } from 'react-router-dom';
import React, { FC, lazy } from 'react';
import Store from '@/components/Store/Store';
import Home from '@/components/Home/Home';
import NotFound from '../NotFound/NotFound';
import Layout from '../Layout/Layout';
import RequireAuth from './RequireAuth/RequireAuth';
import ScrollToTop from '@/shared/ScrollToTop/ScrollToTop';
import Campaigns from '@/components/Campaigns/Campaigns';
import Campaign from '@/components/Campaigns/Campaign/Campaign';
import Assets from '../Assets/Assets';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import ConfirmEmail from '../account/Profile/ConfirmEmail/ConfirmEmail';
import CreateCampaign from '../admin/CreateCampaign/CreateCampaign';
import CreateRemix from '../admin/CreateRemix/CreateRemix';
import EditCampaign from '../admin/EditCampaign/EditCampaign';
import UserProfile from '../UserProfile/UserProfile';
import Collections from '../Collections/Collections';
import Profile from '@/components/account/Profile/Profile';
import Drafts from '@/components/Drafts/Drafts';
import Auth from '../account/Auth/Auth';
import Collection from '../Collection/Collection';

const Users = lazy(() => import('@/components/admin/Users/Users'));
const AdminCampaigns = lazy(() => import('@/components/admin/Campaigns/Campaigns'));

const AppRoutes: FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route index element={<Home />} />
          <Route path="earn/:id" element={<Campaign />} />
          <Route path="auth" element={<Auth initStep={0} />} />
          <Route
            path="auth/profile"
            element={
              <RequireAuth>
                <Auth initStep={2} />
              </RequireAuth>
            }
          />
          <Route path="/" element={<Layout />}>
            <Route path="store" element={<Store />} />
            <Route path="store/:id" element={<Store />} />
            <Route path="earn" element={<Campaigns />} />
            <Route path="collections" element={<Collections />} />
            <Route path="collections/:id" element={<Collection />} />
            <Route path="in/:userName" element={<UserProfile />} />
            <Route path="verify/email/:code" element={<ConfirmEmail />} />
            <Route
              path="users"
              element={
                <RequireAuth>
                  <React.Suspense fallback={<BackdropLoading />}>
                    <Users />
                  </React.Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="campaigns"
              element={
                <RequireAuth>
                  <React.Suspense fallback={<BackdropLoading />}>
                    <AdminCampaigns />
                  </React.Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="campaigns/:id"
              element={
                <RequireAuth>
                  <React.Suspense fallback={<BackdropLoading />}>
                    <AdminCampaigns />
                  </React.Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="users/:id"
              element={
                <RequireAuth>
                  <React.Suspense fallback={<BackdropLoading />}>
                    <Users />
                  </React.Suspense>
                </RequireAuth>
              }
            />
            <Route
              path="profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="assets"
              element={
                <RequireAuth>
                  <Assets />
                </RequireAuth>
              }
            />
            <Route
              path="drafts"
              element={
                <RequireAuth>
                  <Drafts />
                </RequireAuth>
              }
            />
            <Route
              path="create-campaign"
              element={
                <RequireAuth>
                  <CreateCampaign />
                </RequireAuth>
              }
            />
            <Route
              path="create-remix"
              element={
                <RequireAuth>
                  <CreateRemix />
                </RequireAuth>
              }
            />
            <Route
              path="edit-campaign/:id"
              element={
                <RequireAuth>
                  <EditCampaign />
                </RequireAuth>
              }
            />
            <Route path={'*' || '404'} element={<NotFound />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};
export default AppRoutes;

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    pt: 1,
    '& > div': {
      marginLeft: 1,
      marginTop: 2
    },
    '& > div:first-of-type': {
      marginLeft: 0
    }
  }
};

export default styles;

import { FC } from 'react';
import Box from '@mui/material/Box';
import styles from './styles';
import Logo from '@/components/Campaigns/Campaign/shared/Logo/Logo';
import Layout from '@/components/Campaigns/Campaign/shared/Layout/Layout';
import Campaing from '@/components/Campaigns/Campaign/shared/Campaing/Campaing';
import Loader from '@/components/Campaigns/Campaign/shared/Loader/Loader';
import { IProps } from './types';
import StakeUSDC from '@/components/Campaigns/Campaign/shared/StakeUSDC/StakeUSDC';

const Step0: FC<IProps> = ({
  loadingStake,
  loading,
  form,
  onSubmit,
  loadingWithdraw,
  setAmountHandler
}) => {
  return (
    <Layout leftCard={<Logo />}>
      {(loadingStake || loading) && <Loader />}
      <Campaing isDescription />
      <Box sx={styles.stake}>
        <StakeUSDC
          loading={loading}
          form={form}
          onSubmit={onSubmit}
          loadingWithdraw={loadingWithdraw}
          setAmountHandler={setAmountHandler}
        />
      </Box>
    </Layout>
  );
};

export default Step0;

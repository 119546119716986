const styles = {
  teasersSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(/img/teasersBG.jpg)`,
    marginTop: '-8%',
    '@media screen and (max-width: 1350px)': {
      marginTop: '-11%'
    },
    '@media screen and (max-width: 960px)': {
      padding: '3.125rem 1.5rem',
      overflow: 'hidden'
    },
    '@media screen and (max-width: 900px)': {
      marginTop: '-16%'
    },
    mb: 0
  },
  remix: {
    position: 'absolute',
    top: '14rem',
    left: '6.25rem',
    '@media screen and (max-width: 1400px)': {
      left: '3.125rem'
    },
    '@media screen and (max-width: 1300px)': {
      top: '8rem',
      left: '1.25rem'
    },
    '@media screen and (max-width: 600px)': {
      top: '4rem'
    },
    '& svg': {
      opacity: 0
    }
  },
  teasers: {
    display: 'flex',
    maxWidth: '62.5rem',
    gap: '3.125rem',
    margin: '18rem 0 6.25rem',
    '@media screen and (max-width: 1300px)': {
      margin: '18rem auto 6.25rem'
    },
    '@media screen and (max-width: 960px)': {
      gap: '1.25rem'
    },
    '@media screen and (max-width: 760px)': {
      display: 'block'
    },
    '& svg': {
      opacity: 0
    }
  },
  teasersAnimation: {
    '& svg': {
      animationName: 'showTeasers',
      animationDuration: '0.5s',
      animationFillMode: 'forwards',
      animationTimingFunction: 'ease',
      animationIterationCount: 1,
      animationDelay: '0',
      '@keyframes showTeasers': {
        '0%': {
          opacity: 0
        },
        '100%': {
          opacity: 1
        }
      }
    }
  }
};

export default styles;

import { FC, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styles from './styles';
import Layout from '@/components/Campaigns/Campaign/shared/Layout/Layout';
import Logo from '@/components/Campaigns/Campaign/shared/Logo/Logo';
import Campaing from '@/components/Campaigns/Campaign/shared/Campaing/Campaing';
import UploadProgress from '@/components/Campaigns/Campaign/shared/UploadProgress/UploadProgress';
import { CampaignContext } from '@/components/Campaigns/Campaign/context';

const Step1: FC<{ setStep: (step: string) => void }> = ({ setStep }) => {
  const { setTimelineChecked, setIsShowMenu } = useContext(CampaignContext);

  useEffect(() => {
    setIsShowMenu(true);
    setTimelineChecked(true);
  }, []);

  return (
    <Layout leftCard={<Logo />}>
      <Campaing />
      <Box sx={styles.container}>
        <Box sx={styles.containerText}>
          <Typography variant="span3" lineHeight={1}>
            Stems was claimed
          </Typography>
          <Typography variant="h5" lineHeight={1}>
            Waiting for the remix
          </Typography>
          <Typography variant="caption" sx={styles.textGrey} fontWeight={300} lineHeight={1}>
            Make a banger and upload to win!
          </Typography>
          <Button sx={styles.button} onClick={() => setStep('step2')}>
            <Typography variant="body2">Upload your remix</Typography>
          </Button>
        </Box>
        <Box>
          <UploadProgress />
        </Box>
      </Box>
    </Layout>
  );
};

export default Step1;

import { FC } from 'react';
import Box from '@mui/material/Box';
import ConnectWallet from '@/shared/ConnectWallet/ConnectWallet';
import { ReactComponent as LabelLogo } from '@/files/images/logo.svg';
import { ReactComponent as LabelLogoSmall } from '@/files/images/logoSmall.svg';
import styles from './styles';
import { IProps } from './types';

const Authorization: FC<IProps> = ({
  isConnectWallet,
  refetch,
  navigate,
  isMobile,
  isSmallMobile
}) => {
  if (isMobile) {
    return (
      <Box sx={styles.headerMobile}>
        {isSmallMobile ? (
          <LabelLogoSmall onClick={() => navigate('/')} />
        ) : (
          <LabelLogo onClick={() => navigate('/')} />
        )}

        <Box sx={styles.fill} />
        {isConnectWallet && (
          <Box sx={{ mr: 1 }}>
            <ConnectWallet refetch={refetch} theme="medium" isAuth />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box sx={styles.header}>
      <Box sx={styles.container}>
        <LabelLogo onClick={() => navigate('/')} />
      </Box>
      <Box sx={styles.container}>
        {isConnectWallet && (
          <div>
            <ConnectWallet refetch={refetch} theme="medium" isAuth />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default Authorization;

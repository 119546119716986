import { IWalletContext } from '@/helpers/context';
import EthersSimple from './ethers';
import { getBalanceOf } from './contractRequests';
import { SmartContractTypeEnum } from '@/graphql/types/_server';

export const checkBalanceToken = async (
  wallet: IWalletContext,
  contract: {
    abi: string;
    address: string;
  },
  amount: number,
  decimals: number,
  message: string
) => {
  if (!wallet?.ethers) {
    throw new Error('No provider');
  }
  const balance = await getBalanceOf(wallet.ethers, contract, {
    account: wallet.address
  });
  const formatedBalance = EthersSimple.ethers.utils.formatUnits(balance, decimals);
  if (Number(formatedBalance) < amount) {
    throw new Error(message);
  }
};

export const checkBalanceLabel = async (
  wallet: IWalletContext,
  contracts: Record<
    SmartContractTypeEnum,
    {
      abi: string;
      address: string;
    }
  >,
  amount: number
) => {
  await checkBalanceToken(
    wallet,
    contracts[SmartContractTypeEnum.Label],
    amount,
    18,
    "You don't have enough Label to launch the transaction"
  );
};

export const checkBalanceTune = async (
  wallet: IWalletContext,
  contracts: Record<
    SmartContractTypeEnum,
    {
      abi: string;
      address: string;
    }
  >,
  amount: number
) => {
  await checkBalanceToken(
    wallet,
    contracts[SmartContractTypeEnum.Tune],
    amount,
    18,
    "You don't have enough Tune to launch the transaction"
  );
};

export const checkBalanceUsdc = async (
  wallet: IWalletContext,
  contracts: Record<
    SmartContractTypeEnum,
    {
      abi: string;
      address: string;
    }
  >,
  amount: number
) => {
  await checkBalanceToken(
    wallet,
    contracts[SmartContractTypeEnum.Usdc],
    amount,
    6,
    "You don't have enough USDC to launch the transaction"
  );
};

export const checkBalanceMatic = async (wallet: IWalletContext) => {
  const amount = 0.5;
  const provider = wallet?.ethers?.getProvider();
  const balance = await provider.getBalance(wallet.address);
  const formatedAmount = EthersSimple.ethers.utils.formatUnits(balance, 18);
  if (Number(formatedAmount) < amount) {
    throw new Error("You don't have enough Matic to launch the transaction");
  }
};

/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { ReactComponent as ArrowSvg } from '@/files/icons/header/arrow.svg';
import { ReactComponent as ExploreOuterSvg } from '@/files/icons/header/explore.svg';
import { ReactComponent as ExploreInnerSvg } from '@/files/icons/header/explore1.svg';
import styles from './styles';

const Seize: FC = () => {
  const navigate = useNavigate();

  return (
    <Box sx={styles.headerUnder}>
      <Box sx={styles.titleContainer}>
        <Box sx={styles.container1}>
          <Typography variant="h1" component="p" sx={styles.title1}>
            Seize{' '}
            <Typography variant="h1" component="span" sx={styles.span1}>
              the
            </Typography>{' '}
            <Typography variant="h1" component="span" sx={styles.span2}>
              Stems
            </Typography>
          </Typography>
        </Box>
        <Box sx={styles.globalContainer}>
          <Box sx={styles.container2}>
            <Typography variant="h1" component="p" sx={styles.title2}>
              of Production
            </Typography>
          </Box>
          <Box sx={styles.container3}>
            <Typography variant="h6" component="p" sx={styles.additinal1}>
              {[...'Regenerate the industry'].map((item, index) => (
                <span key={`additinal1${item}${index}`} id={`additinal1${index}`}>
                  {item}
                </span>
              ))}
            </Typography>
            <Typography variant="h6" component="p" sx={styles.additinal2}>
              {[...'Curate and Earn Rewards'].map((item, index) => (
                <span key={`additinal2${item}${index}`} id={`additinal2${index}`}>
                  {item}
                </span>
              ))}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.headerCircle} onClick={() => navigate('/earn')}>
        <Box sx={styles.headerArrow}>
          <ArrowSvg />
        </Box>
        <Box sx={styles.headerExploreOuter}>
          <ExploreOuterSvg />
        </Box>
        <Box sx={styles.headerExploreInner}>
          <ExploreInnerSvg />
        </Box>
      </Box>
    </Box>
  );
};

export default Seize;

import { FC, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import Modal from '@/shared/Modal/Modal';
import { Context } from '@/helpers/context';
import getBeautyErrorMessage from '@/utils/getBeautyErrorMessage';
import { ReactComponent as CloseSvg } from '@/files/icons/assets/close.svg';
import { ReactComponent as ArrowSvg } from '@/files/icons/arrow.svg';
import styles from './styles';
import getErrorMessage from '@/utils/getErrorMessage';
import { SmartContractTypeEnum } from '@/graphql/types/_server';
import { IProps, IStakeInputs } from './types';
import { depositLabel } from '@/utils/contractRequests';
import { checkBalanceMatic, checkBalanceLabel } from '@/utils/checkBalanceToken';

const ModalTune: FC<IProps> = ({ modalTune, onModalTune, balanceOf, onGetStakeAmount }) => {
  const { wallet, contracts } = useContext(Context);

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm<IStakeInputs>({ mode: 'onTouched' });

  return (
    <Modal sx={styles.modal} onClose={onModalTune} isOpen={modalTune}>
      <Box sx={styles.modalClose}>
        <CloseSvg onClick={onModalTune} />
      </Box>
      <Box sx={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack direction="row" alignItems="center" gap={1} mb={2}>
            <Typography variant="h5">Get TUNE</Typography>
            {loading && <CircularProgress color="secondary" size={22} />}
          </Stack>
          <Box mt={6.25}>
            <Box sx={styles.input}>
              <Controller
                name="amount"
                control={control}
                render={({ field: { value, ref } }) => {
                  return (
                    <NumberFormat
                      id="amount"
                      variant="outlined"
                      placeholder="LABEL"
                      size="small"
                      value={value}
                      customInput={TextField}
                      disabled={loading}
                      error={Boolean(errors.amount)}
                      helperText={errors?.amount?.message || errors?.amount?.type || ' '}
                      {...register('amount', {
                        required: 'Required',
                        min: {
                          value: 1,
                          message: '1 LBL minimum'
                        },
                        max: {
                          value: balanceOf,
                          message: `You don't have enough LBL`
                        }
                      })}
                      suffix=" LABEL"
                      thousandSeparator={false}
                      isNumericString
                      allowNegative={false}
                      decimalScale={0}
                      onValueChange={setAmountHandler}
                      ref={ref}
                    />
                  ) as JSX.Element;
                }}
              />
            </Box>
            <Box sx={styles.arrow}>
              <ArrowSvg />
            </Box>
            <Box sx={styles.tune}>
              <Typography variant="h6" lineHeight={1}>
                {getValues('amount')}
                <Typography
                  variant="h6"
                  lineHeight={1}
                  component="span"
                  sx={styles.textTransparent}
                >
                  {' '}
                  TUNE /day
                </Typography>
              </Typography>
            </Box>
            <Typography variant="overline" sx={styles.text} component="p">
              Vote for your favorite track with your TUNE. Earn Tune by staking your LABEL tokens.
              TUNEs will be earned daily one day after staking.
            </Typography>
          </Box>
          <Box sx={styles.button}>
            <Button disabled={loading} type="submit" fullWidth variant="outlined" disableElevation>
              <Typography variant="body2" lineHeight={1}>
                GET TUNE
              </Typography>
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );

  async function resetForm() {
    reset({ amount: '' });
    await trigger();
    clearErrors('amount');
  }

  async function setAmountHandler(values: NumberFormatValues) {
    setValue('amount', values.value);
    await trigger('amount');
  }

  async function onSubmit(data: IStakeInputs) {
    try {
      const typedAmount = Number(data.amount);
      const isTypedAmountFinite = isFinite(typedAmount);
      if (!isTypedAmountFinite) {
        throw new Error('Error');
      }
      await Promise.all([
        checkBalanceMatic(wallet),
        checkBalanceLabel(wallet, contracts.contracts, Number(data.amount))
      ]);
      deposit(data.amount);
    } catch (err) {
      const error = err as Error;
      toast.error(getBeautyErrorMessage(error?.message));
    }
  }

  async function deposit(amount: string) {
    try {
      if (!wallet.ethers) return;
      setLoading(true);
      let loadingLocal = true;

      const successCallback = async () => {
        if (loadingLocal) {
          loadingLocal = false;
          setLoading(false);
          toast.success('Staking is successful');
          onModalTune();
          await resetForm();
          await onGetStakeAmount();
        }
      };

      await depositLabel(wallet.ethers, amount, contracts.contracts[SmartContractTypeEnum.Label], {
        Hash: () => {
          toast.info('Staking in progress. This can take up to a minute');
        },
        Error: () => {
          setLoading(false);
        },
        After: successCallback
      });

      successCallback();
    } catch (error) {
      toast.error(getErrorMessage(error));
      setLoading(false);
    }
  }
};

export default ModalTune;

import { FC, useContext } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Context } from '@/helpers/context';
import Cart from '@/components/Cart/Cart';
import ConnectWallet from '@/shared/ConnectWallet/ConnectWallet';
import { ReactComponent as LabelLogo } from '@/files/images/logo.svg';
import { ReactComponent as LabelLogoSmall } from '@/files/images/logoSmall.svg';
import styles from './styles';
import AdminMenu from '@/components/admin/AdminMenu/AdminMenu';
import { IProps } from './types';
import DrawerMenu from '../../shared/DrawerMenu/DrawerMenu';
import ListMenu from '../../shared/ListMenu/ListMenu';

const Standard: FC<IProps> = ({
  menu,
  isAdmin,
  refetch,
  navigate,
  pathname,
  isMobile,
  isSmallMobile,
  openMenuHandler,
  openMenu
}) => {
  const { wallet } = useContext(Context);

  if (isMobile) {
    return (
      <>
        <Box sx={styles.headerMobile}>
          <IconButton color="primary" onClick={openMenuHandler} sx={styles.button}>
            {openMenu ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          {isSmallMobile ? (
            <LabelLogoSmall onClick={() => navigate('/')} />
          ) : (
            <LabelLogo onClick={() => navigate('/')} />
          )}

          <Box sx={styles.fill} />
          <Box sx={{ mr: 1 }}>
            <ConnectWallet refetch={refetch} theme="dark" />
          </Box>
          <Cart theme="dark" />
          {isAdmin && wallet.address !== '' && <AdminMenu theme="dark" />}
        </Box>
        <DrawerMenu
          menu={menu}
          navigate={navigate}
          pathname={pathname}
          openMenuHandler={openMenuHandler}
          openMenu={openMenu}
          theme="dark"
        />
      </>
    );
  }

  return (
    <Box sx={styles.header}>
      <Box sx={styles.container}>
        <LabelLogo onClick={() => navigate('/')} />
      </Box>
      <div>
        <ListMenu
          menu={menu}
          navigate={navigate}
          pathname={pathname}
          theme="dark"
          direction="row"
        />
      </div>

      <Box sx={styles.container}>
        <div>
          <Cart theme="dark" />
        </div>
        {isAdmin && wallet.address !== '' && <AdminMenu theme="dark" />}
        <div>
          <ConnectWallet refetch={refetch} theme="dark" />
        </div>
      </Box>
    </Box>
  );
};

export default Standard;

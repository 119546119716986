import { FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import { IProps } from './types';
import styles from './styles';
import { ReactComponent as EmptyTrashSvg } from '@/files/icons/cart/trash-empty.svg';
import getCloudImgUrl from '@/utils/getUrlImage';

const Logo: FC<IProps> = ({
  files,
  handleFileDelete = () => {},
  baseFile,
  setCroppedImage,
  content
}) => {
  const src = useMemo(() => {
    if (baseFile) {
      return baseFile;
    }
    if (files.length && files[0].name === 'NewFile') {
      return URL.createObjectURL(files[0]);
    }
    return files.length ? getCloudImgUrl(files[0].name, 500) : '';
  }, [baseFile, files]);

  return (
    <>
      <Box
        onClick={(event) => {
          setCroppedImage && setCroppedImage('');
          handleRemove(event, files[0], true);
        }}
        sx={styles.icon}
      >
        <EmptyTrashSvg />
      </Box>
      <Box sx={styles.items}>
        <Box sx={styles.item}>
          <Box style={{ backgroundImage: `url(${src})` }}>{content}</Box>
        </Box>
      </Box>
    </>
  );

  function handleRemove(
    event: React.MouseEvent<HTMLDivElement>,
    file: File,
    isCroppedDelete?: boolean
  ) {
    event.stopPropagation();
    handleFileDelete(file, isCroppedDelete);
  }
};

export default Logo;

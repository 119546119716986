const styles = {
  item: {
    maxWidth: '420px',
    minWidth: '200px',
    background: 'rgb(25, 26, 28)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '40px',
    paddingTop: 'min(280px, 67%)',
    cursor: 'pointer',
    '@media screen and (max-width: 1050px)': {
      paddingTop: 'min(280px, 100%)'
    },
    '@media screen and (max-width: 960px)': {
      paddingTop: 'min(280px, 67%)'
    },
    '@media screen and (max-width: 700px)': {
      paddingTop: 'min(280px, 100%)'
    },
    '@media screen and (max-width: 600px)': {
      paddingTop: 'min(280px, 67%)',
      maxWidth: '100%'
    }
  },
  itemInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    mt: '22px',
    p: '0 16px'
  }
};

export default styles;

import { FC, memo, useMemo, useContext, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import { Context } from '@/helpers/context';
import { ReactComponent as PlaySvg } from '@/files/icons/playLarge.svg';
import { ReactComponent as StopSvg } from '@/files/icons/assets/stop.svg';
import styles from './styles';
import { IProps } from './types';
import getCloudImgUrl from '@/utils/getUrlImage';

const Track: FC<IProps> = ({ remix, handleClick }) => {
  const { audio, setAudio, pauseAudioHandler } = useContext(Context);

  const stylesMemoized = useMemo(() => styles(getCloudImgUrl(remix?.logoUrl || '', 400)), [remix]);

  return (
    <Box>
      <Box sx={stylesMemoized.logo} onClick={() => handleClick(remix.id)}>
        <Box sx={stylesMemoized.linkLogo}>
          <Link to={`/in/${remix?.sponsor?.userName}`} onClick={(e) => e.stopPropagation()}>
            <Box sx={stylesMemoized.containerLogo}>
              <Box
                sx={stylesMemoized.logoSponsor}
                style={{
                  backgroundImage: `url(${getCloudImgUrl(remix?.sponsor?.logoUrl || '', 60)})`
                }}
              >
                {!remix?.sponsor?.logoUrl && <PersonIcon />}
              </Box>
              <Typography variant="body2" sx={stylesMemoized.textSponsor}>
                {remix?.sponsor?.name}
              </Typography>
            </Box>
          </Link>
        </Box>
        <Box sx={{ ...stylesMemoized.audio, ...(audio?.id === remix.id && stylesMemoized.stop) }}>
          {!audio?.isPlaying || audio?.id !== remix.id ? (
            <IconButton
              id="play"
              color="inherit"
              size="large"
              onClick={playAudio}
              disableFocusRipple
              disableTouchRipple
              disableRipple
            >
              <PlaySvg />
            </IconButton>
          ) : (
            <IconButton
              color="inherit"
              size="large"
              onClick={pauseAudio}
              disableFocusRipple
              disableTouchRipple
              disableRipple
            >
              <StopSvg />
            </IconButton>
          )}
        </Box>
        <Box sx={stylesMemoized.amount}>
          <Typography variant="body2">{remix?.price} USDC</Typography>
        </Box>
      </Box>
      <Box ml={3} mt={2.5}>
        <Typography variant="h6" sx={stylesMemoized.text} onClick={() => handleClick(remix.id)}>
          {remix?.name}
        </Typography>

        <Box>
          <Link to={`/in/${remix?.user?.userName}`} style={stylesMemoized.link}>
            <Typography variant="span3" sx={stylesMemoized.text}>
              by {remix?.campaign?.id ? remix?.author : remix?.sponsor?.name}
            </Typography>
          </Link>
          {!!remix?.campaign?.id && (
            <>
              <Typography variant="span3">, </Typography>
              <Link to={`/collections/${remix?.campaign?.id}`} style={stylesMemoized.link}>
                <Typography variant="span3" sx={stylesMemoized.text}>
                  {remix?.sponsor?.name} Collection
                </Typography>
              </Link>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );

  function playAudio(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setAudioHandler(true);
  }

  function pauseAudio(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    pauseAudioHandler();
    setAudioHandler(false);
  }

  function setAudioHandler(isPlaying: boolean) {
    setAudio({
      name: remix.name,
      user: remix.sponsor?.userName || '',
      id: remix.id,
      showPlayer: true,
      isPlaying,
      logo: remix.logoUrl,
      src: remix.trackUrl
    });
  }
};

export default memo(Track);

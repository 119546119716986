import { useParams } from 'react-router-dom';
import Profile from './Profile/Profile';

const UserProfile = () => {
  const { userName } = useParams<'userName'>();
  if (!userName) {
    return null;
  }

  return <Profile userName={userName} />;
};

export default UserProfile;

const styles = {
  container: {
    // overflowY: 'hidden',
    // overflowX: 'auto',
    // '@media screen and (max-width: 1100px)': {
    //   width: 'calc(100vw - 56px)'
    // }
  },
  history: {
    '& > h6:first-of-type': {
      ml: '20px',
      color: '#1A1B1D'
    }
  },
  historyTable: {
    mt: '32px',
    backgroundColor: '#000000',
    padding: '0 20px'
    // minWidth: '678px'
  },
  historyTableTitleRow: {
    '& > th': {
      color: 'rgba(255, 255, 255, .5)',
      fontSize: '12px',
      borderBottom: 'none'
    }
  },
  historyTableRow: {
    '& > td': {
      color: 'rgba(255, 255, 255, 1)',
      borderBottom: '1px solid rgba(255, 255, 255, .2)',
      '& a': {
        color: 'rgba(255, 255, 255, 1)'
      },
      '& p': {
        fontWeight: '400'
      }
    },
    '&:last-child': {
      border: 'none'
    },
    '&:last-child td, &:last-child th': { border: 0 }
  },
  containerLogo: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& > div:last-of-type': {
      ml: 3
    }
  },
  logo: {
    backgroundColor: '#1a1a1a',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '97px',
    height: '87px',
    borderRadius: '20px'
  },
  textTransparent: {
    opacity: 0.5
  },
  pagination: {
    background: 'white',
    borderRadius: '10px',
    '& li button': {
      minWidth: '10px',
      padding: '0 6px',
      margin: 0,
      borderRadius: 0,
      position: 'relative',
      color: '#232323',
      opacity: 0.5,
      height: '30px',
      lineHeight: 'initial'
    },
    '& li button.Mui-selected': {
      backgroundColor: 'transparent',
      opacity: 1,
      '&::after': {
        position: 'absolute',
        bottom: '2px',
        left: '6px',
        width: 'calc(100% - 12px)',
        height: '4px',
        content: '""',
        backgroundColor: '#F3F700'
      }
    }
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    '& > div:last-child': {
      mt: -0.75
    }
  }
};

export default styles;

const styles = {
  backdrop: {
    zIndex: -1,
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.6)',
    backdropFilter: 'blur(24px)',
    '-webkit-tap-highlight-color': 'transparent',
    outline: 0
  },
  container: {
    display: 'flex',
    outline: 0,
    justifyContent: 'center',
    position: 'relative',
    top: '72px',
    margin: 'auto',
    maxWidth: '1042px',
    '@media screen and (max-width: 850px)': {
      flexWrap: 'wrap',
      top: '16px'
    }
  },
  containerError: {
    background: '#F4F4F4',
    padding: '20px',
    borderRadius: '40px',
    width: '40%',
    height: '200px',
    margin: '72px auto 0',
    '@media screen and (max-width: 850px)': {
      maxWidth: '32.875rem',
      width: '100%'
    }
  },
  rightCard: {
    width: '32.875rem',
    borderRadius: '2.5rem',
    position: 'relative',
    '@media screen and (max-width: 534px)': {
      width: '100%'
    },
    background: '#F4F4F4',
    padding: '60px 40px 40px 42px',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  leftCard: {
    position: 'relative',
    width: '31.25rem',
    display: 'flex',
    height: '515px',
    backgroundColor: '#1a1a1a',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '2.5rem',
    marginRight: '1rem',
    '@media screen and (max-width: 850px)': {
      height: '45vh',
      width: '32.875rem',
      marginRight: 0,
      marginBottom: '1rem'
    }
  },
  containerCart: {
    color: 'white',
    background: 'black',
    borderRadius: '20px 20px 40px 40px',
    marginBottom: '-1px',
    padding: '17px 20px 20px 45px',
    display: 'flex',
    justifyContent: 'space-between',
    height: 'fit-content',
    width: '100%',
    alignSelf: 'end',
    '& button': {
      color: '#323232',
      stroke: '#323232',
      height: '48px',
      width: '142px',
      backgroundColor: '#fff',
      ':hover': {
        backgroundColor: '#fff'
      },
      '& svg': {
        mr: 1
      },
      '& path': {
        stroke: '#323232'
      }
    },
    '@media screen and (max-width: 400px)': {
      flexDirection: 'column',
      '& > div:first-child': {
        mb: 2
      },
      '& > div:last-child': {
        alignSelf: 'center'
      }
    }
  },
  containerPlay: {
    position: 'absolute',
    left: '25px',
    top: '25px',
    cursor: 'pointer',
    '& > svg': {
      fill: '#F3F700'
    }
  },
  closeIcon: {
    position: 'absolute',
    right: '30px',
    top: '24px',
    color: 'white',
    '& > svg': {
      fontSize: '32px'
    },
    '@media screen and (max-width: 850px)': {
      display: 'none'
    }
  },
  textTransparent: {
    opacity: '0.75'
  },
  description: {
    maxHeight: '214px',
    overflowY: 'auto',
    '&::first-letter': {
      textTransform: 'uppercase'
    },
    '@media screen and (max-width: 850px)': {
      maxHeight: '18vh',
      height: '18vh'
    }
  },
  closeIconMobile: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    color: 'black',
    display: 'none',
    width: '48px',
    height: '48px',
    '& > svg': {
      fontSize: '32px'
    },
    '@media screen and (max-width: 850px)': {
      display: 'initial'
    }
  },
  containerLoader: {
    '& > div': {
      maxWidth: '1042px',
      '@media screen and (max-width: 850px)': {
        maxWidth: '526px'
      },
      '@media screen and (max-width: 525px)': {
        maxWidth: '526px',
        left: 0
      }
    }
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  }
};

export default styles;

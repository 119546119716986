import { gql } from '@apollo/client';
import PAGINATE_MODEL from './models/paginateModel';

export const CREATE_REMIX = gql`
  mutation createRemix($payload: RemixCreateDTO!, $file: Upload!) {
    createRemix(payload: $payload, file: $file) {
      id
    }
  }
`;

export const REMIXES = gql`
  query remixes($payload: RemixGetDTO!, $chainId: Int!) {
    remixes(payload: $payload, chainId: $chainId) {
      items {
        name
        author
        createdDate
        updatedDate
        id
        status
        tokenID
        price
        logoUrl
        beats
        trackLength
        trackUrl
        user {
          id
          userName
          type
        }
        sponsor {
          id
          userName
          name
          logoUrl
        }
        campaign {
          id
        }
      }
      meta {
        ...paginateModel
      }
    }
  }
  ${PAGINATE_MODEL}
`;

export const REMIXES_TOP = gql`
  query remixesTopForPeriod($chainId: Int!) {
    remixesTopForPeriod(chainId: $chainId) {
      items {
        name
        author
        createdDate
        updatedDate
        id
        tokenID
        price
        logoUrl
        beats
        trackLength
        trackUrl
        user {
          id
          userName
          type
        }
        sponsor {
          id
          userName
          name
          logoUrl
        }
        campaign {
          id
        }
      }
    }
  }
`;

export const REMIX_BY_ID = gql`
  query remixById($payload: RemixIdDTO!, $chainId: Int!) {
    remixById(payload: $payload, chainId: $chainId) {
      id
      description
      countNFT
      countNftBought
      name
      tokenID
      price
      sponsor {
        id
        userName
      }
      genre
      key
      beats
      logoUrl
      name
      price
      trackUrl
      campaign {
        id
        name
      }
    }
  }
`;

export const CREATE_REMIX_BY_ADMIN = gql`
  mutation createRemixByAdmin(
    $payload: RemixCreateDTO!
    $audio: Upload!
    $logo: Upload!
    $chainId: Int!
  ) {
    createRemixByAdmin(payload: $payload, audio: $audio, logo: $logo, chainId: $chainId) {
      id
    }
  }
`;

export const BUY_REMIX = gql`
  mutation buyRemix($payload: BuyRemixDTO!, $chainId: Int!) {
    buyRemix(payload: $payload, chainId: $chainId)
  }
`;

export const UPDATE_STATUS_REMIX_BY_ADMIN = gql`
  mutation updateStatusRemixByAdmin($chainId: Int!, $payload: RemixUpdateStatusDTO!) {
    updateStatusRemixByAdmin(chainId: $chainId, payload: $payload) {
      id
      status
    }
  }
`;

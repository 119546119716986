import { gql } from '@apollo/client';
import PAGINATE_MODEL from './models/paginateModel';

export const USERS = gql`
  query users($payload: UsersGetDTO!) {
    users(payload: $payload) {
      items {
        id
        name
        userName
        email
        address
        description
        type
        isVerifiedKYC
        isAdminCreated
        isVerifiedEmail
        verificationDate
        updatedDate
        logoUrl
      }
      meta {
        ...paginateModel
      }
    }
  }
  ${PAGINATE_MODEL}
`;

export const UPDATE_USER = gql`
  mutation updateUser($logo: Upload, $payload: UserUpdateDTO!) {
    updateUser(logo: $logo, payload: $payload) {
      id
      userName
      email
      isVerifiedEmail
    }
  }
`;

export const CREATE_SPONSOR_BY_ADMIN = gql`
  mutation createSponsorByAdmin($logo: Upload!, $payload: SponsorCreateDTO!) {
    createSponsorByAdmin(logo: $logo, payload: $payload) {
      id
      name
      userName
      address
    }
  }
`;

export const VERIFICATION_EMAIL = gql`
  mutation verificationEmail($payload: UserVerificationCodeDTO!) {
    verificationEmail(payload: $payload)
  }
`;

export const GET_VERIFICATION_EMAIL = gql`
  mutation getVerificationEmailCode {
    getVerificationEmailCode
  }
`;

export const UPDATE_USER_TYPE_TO_ADMIN = gql`
  mutation updateUserTypeToAdmin($payload: UserIdDTO!) {
    updateUserTypeToAdmin(payload: $payload) {
      id
    }
  }
`;

export const UPDATE_USER_TYPE_TO_SPONSOR = gql`
  mutation updateUserTypeToSponsor($payload: UserIdDTO!) {
    updateUserTypeToSponsor(payload: $payload) {
      id
    }
  }
`;

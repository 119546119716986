const styles = {
  campaignsItemsSkeleton: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: 2,
    rowGap: 1,
    pb: '60px',
    '@media screen and (max-width: 960px)': {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    '@media screen and (max-width: 600px)': {
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  },
  campaignsItems: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: 2,
    rowGap: 5.75,
    mb: 7.5,
    '@media screen and (max-width: 960px)': {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    '@media screen and (max-width: 600px)': {
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  }
};

export default styles;

const styles = {
  container: {
    width: '361px',
    ml: '-30px',
    '@media screen and (max-width: 400px)': {
      width: '100%'
    },
    '@media screen and (max-width: 1100px)': {
      ml: '0'
    }
  },
  containerSticky: {
    position: 'sticky',
    top: '24px'
  },
  titleBalance: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mb: 4
  },
  history: {
    cursor: 'pointer',
    mr: 2.5,
    display: 'flex',
    color: 'rgba(0,0,0,.5)',
    '& p': {
      mr: 0.75
    },
    '& svg': {
      stroke: 'rgba(0,0,0,.5)'
    }
  },
  balancesTop: {
    backgroundColor: '#F3F700',
    padding: '14px 20px 7px 32px',
    borderRadius: '20px',
    mb: 2.25,
    width: '361px',
    '@media screen and (max-width: 400px)': {
      width: '100%'
    },
    boxShadow:
      '0px 461px 184px rgba(0, 0, 0, 0.02), 0px 259px 156px rgba(0, 0, 0, 0.07), 0px 115px 115px rgba(0, 0, 0, 0.11), 0px 29px 63px rgba(0, 0, 0, 0.13), 0px 0px 0px rgba(0, 0, 0, 0.13)'
  },
  balancesTopNumber: {
    display: 'flex',
    alignItems: 'end',
    fontWeight: 'bold',
    color: '#232323',
    mt: 1,
    mb: 0.75
  },
  balancesMiddle: {
    padding: '14px 19px 19px 32px',
    backgroundColor: '#232323',
    borderRadius: '20px',
    mb: 2.25,
    boxShadow:
      '0px 461px 184px rgba(0, 0, 0, 0.02), 0px 259px 156px rgba(0, 0, 0, 0.07), 0px 115px 115px rgba(0, 0, 0, 0.11), 0px 29px 63px rgba(0, 0, 0, 0.13), 0px 0px 0px rgba(0, 0, 0, 0.13)'
  },
  balancesMiddleVote: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    '& > span': {
      color: '#fff'
    }
  },
  balancesMiddleNumber: {
    color: '#F3F700',
    display: 'flex',
    alignItems: 'end',
    fontWeight: 'bold',
    mt: 1,
    mb: '12px',
    '& > h5:first-of-type': {
      lineHeight: '44px',
      fontSize: '44px'
    },
    '& > h6:last-of-type': {
      ml: '11px',
      lineHeight: '24px'
    }
  },
  balancesBottom: {
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    boxShadow:
      '0px 461px 184px rgba(0, 0, 0, 0.02), 0px 259px 156px rgba(0, 0, 0, 0.07), 0px 115px 115px rgba(0, 0, 0, 0.11), 0px 29px 63px rgba(0, 0, 0, 0.13), 0px 0px 0px rgba(0, 0, 0, 0.13)'
  },
  text: {
    fontFamily: "'Rubik', sans-serif"
  },
  balancesStakedLabel: {
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    mt: 1.75,
    alignItems: 'end'
  },
  containerIcon: {
    '& > *:first-of-type': {
      mr: 1
    },
    '& > svg': {
      cursor: 'pointer'
    },
    '& path': {
      fill: 'white'
    }
  },
  balancesTopIcon: {
    height: '18px',
    '& svg': {
      cursor: 'pointer',
      width: '18px',
      height: '18px',
      color: '#232323'
    }
  },
  balancesTopTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '21px',
    '& > span': {
      alignSelf: 'end'
    }
  },
  divider: {
    borderStyle: 'dashed',
    borderColor: 'rgb(255 255 255 / 30%)',
    backgroundColor: 'transparent'
  },
  dividerDark: {
    borderStyle: 'dashed',
    borderColor: 'rgb(0 0 0 / 30%)',
    backgroundColor: 'transparent',
    margin: '0 19px 0 32px'
  },
  containerStaked: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 19px 10px 32px'
  },
  containerRewards: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '17px 19px 17px 32px',
    '&:hover p': {
      mr: '27px'
    },
    '&:hover svg': {
      opacity: 1
    }
  },
  containerBalancesRewards: {
    position: 'relative',
    height: '18px',
    '& > p': {
      transition: 'all 0.25s',
      '@media screen and (max-width: 1100px)': {
        mr: '27px'
      }
    },
    '& > svg': {
      cursor: 'pointer',
      position: 'absolute',
      top: '-1px',
      right: 0,
      opacity: 0,
      transition: 'all 0.25s',
      '@media screen and (max-width: 1100px)': {
        opacity: 1
      }
    }
  }
};

export default styles;

import colors from '@/helpers/theme/colors';

const styles = {
  footerMain: {
    backgroundColor: colors.background.paper,
    overflow: 'hidden',
    paddingBottom: 5,
    position: 'relative',
    zIndex: 10
  },
  blackLine: {
    width: '100%',
    height: '115px',
    background: ' #232323',
    '& > div': {
      height: '100%',
      background: 'linear-gradient(180deg, #000000 2.65%, rgba(0, 0, 0, 0) 100%)'
    }
  },
  flex: {
    display: 'flex',
    gap: 25,
    '& > :nth-of-type(1)': {
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      gap: 1.5,
      textAlign: 'right',
      marginTop: 5
    },
    '& > :nth-of-type(2)': {
      width: '20%',
      display: 'flex',
      justifyContent: 'center',
      transform: 'translate(0px, -5px)'
    },
    '& > :nth-of-type(3)': {
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      gap: 2.5,
      marginTop: 5
    },
    '@media screen and (max-width: 700px)': {
      gap: 15
    },
    '@media screen and (max-width: 500px)': {
      gap: 3,
      justifyContent: 'center',
      flexWrap: 'wrap',
      flexDirection: 'column',
      '& > :nth-of-type(1)': {
        order: 2,
        width: '100%',
        textAlign: 'center',
        marginTop: 0
      },
      '& > :nth-of-type(2)': {
        order: 1,
        width: '100%'
      },
      '& > :nth-of-type(3)': {
        order: 2,
        width: '100%',
        textAlign: 'center',
        marginTop: 0
      }
    }
  },
  additionalText: {
    opacity: 0.5,
    lineHeight: '0.74rem'
  },
  footerSocialLogos: {
    marginTop: '64px',
    display: 'flex',
    justifyContent: 'center'
  },
  iconWrapper: {
    position: 'relative',
    minHeight: '20px',
    cursor: 'pointer',
    maxWidth: '24px',
    width: '100%',
    '& > svg': {
      objectFit: 'cover',
      position: 'absolute',
      top: ' 0',
      left: '0',
      right: '0',
      bottom: '0',
      width: '100%',
      height: '100%'
    }
  },
  link: {
    transition: 'none',
    borderBottom: 'none',
    cursor: 'pointer'
  }
};

export default styles;

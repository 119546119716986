const styles = {
  container: {
    overflowY: 'auto',
    pr: 1,
    mr: -1,
    width: '36.9375rem',
    maxWidth: '100%'
  },
  modal: {
    backgroundColor: '#000000',
    position: 'relative',
    color: '#fff',
    p: '40px 44px',
    borderRadius: '40px'
  },
  modalClose: {
    position: 'absolute',
    top: '20px',
    right: '34px',
    cursor: 'pointer'
  },
  inputWrap: {
    mb: '18px'
  },
  dropZone: {
    '& input + div': {
      color: '#fff'
    },
    '& > div > div > div:hover': {
      borderColor: 'rgba(255,255,255, .5)'
    }
  },
  label: {
    color: 'rgba(255,255,255, .3)',
    mb: '4px'
  },
  btnWrap: {
    textAlign: 'center'
  },
  btn: {
    mt: '30px',
    height: '48px',
    width: '142px',
    backgroundColor: '#fff',
    ':hover': {
      backgroundColor: '#fff'
    },
    '&:disabled': {
      backgroundColor: '#fff'
    }
  }
};

export default styles;

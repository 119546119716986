import { FC, memo } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styles from './styles';
import { CampaignStatusEnum } from '@/graphql/types/_server';
import CampaignTime from '@/shared/ui/CampaignTime/CampaignTime';
import { IProps } from './types';
import getCloudImgUrl from '@/utils/getUrlImage';

const Campaign: FC<IProps> = ({ campaign }) => {
  const navigate = useNavigate();

  return (
    <Box sx={styles.item}>
      <Box sx={styles.campaign} onClick={handleClick}>
        <img src={getCloudImgUrl(campaign.logoUrl, 700)} alt={campaign.name} loading="lazy" />
      </Box>
      <Box sx={styles.itemInfo}>
        <Box>
          <Typography variant="h5" component="p" lineHeight={1}>
            {campaign.name}
          </Typography>
          <Typography variant="span3" component="p" mt={0.375}>
            Sponsored by {campaign.sponsor?.name || 'Sponsor'}
          </Typography>
        </Box>
        {campaign.status !== CampaignStatusEnum.Check && (
          <CampaignTime campaign={campaign} handleClick={handleClick} />
        )}
      </Box>
    </Box>
  );

  function handleClick() {
    if (campaign.status === CampaignStatusEnum.Draft) {
      navigate(`/edit-campaign/${campaign.id}`);
    } else {
      navigate(`/earn/${campaign.id}`);
    }
  }
};

export default memo(Campaign);

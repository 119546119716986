import { FC, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './styles';
import Layout from '@/components/Campaigns/Campaign/shared/Layout/Layout';
import Logo from '@/components/Campaigns/Campaign/shared/Logo/Logo';
import Campaing from '@/components/Campaigns/Campaign/shared/Campaing/Campaing';
import UploadProgress from '@/components/Campaigns/Campaign/shared/UploadProgress/UploadProgress';
import { CampaignContext } from '@/components/Campaigns/Campaign/context';

const StepNoWin: FC = () => {
  const { setIsShowMenu } = useContext(CampaignContext);

  useEffect(() => {
    setIsShowMenu(true);
  });

  return (
    <Layout leftCard={<Logo />}>
      <Campaing />
      <Box sx={styles.containerBottom}>
        <Box sx={styles.containerText}>
          <Typography variant="h5" lineHeight={1}>
            Waiting participants to upload their remixes
          </Typography>
        </Box>
        <Box>
          <UploadProgress />
        </Box>
      </Box>
    </Layout>
  );
};

export default StepNoWin;

import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './styles';
import { ReactComponent as MailSvg } from '@/files/icons/mail.svg';
import { IUserModel } from '@/graphql/types/_server';

const Step3: FC<{ me: IUserModel | undefined; setStep: (step: number) => void }> = ({
  me,
  setStep
}) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.loader}>
        <img src="/img/loader.png" alt="Loader" />
        <MailSvg />
      </Box>
      <Typography variant="h3" mt={7.5}>
        Please confirm your email
      </Typography>
      <Typography variant="subtitle2" sx={styles.textTransparent} mt={2}>
        A confirmation link has been sent to your email
      </Typography>
      <Typography variant="subtitle2" sx={styles.textTransparent}>
        {me?.email}
      </Typography>
      <Typography
        variant="caption"
        sx={styles.link}
        fontWeight={500}
        component="p"
        onClick={() => setStep(2)}
      >
        Change email
      </Typography>
    </Box>
  );
};

export default Step3;

import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { ReactComponent as PlusSvg } from '@/files/icons/assets/plus.svg';
import { ReactComponent as ArrowSvg } from '@/files/icons/assets/arrow.svg';
import { ReactComponent as RefreshSvg } from '@/files/icons/assets/refresh.svg';
import { ReactComponent as ClockSvg } from '@/files/icons/assets/clock.svg';
import formatEthers from '@/utils/formatEthers';
import styles from './styles';
import { IProps } from './types';
import ModalUnstakeRewards from './ModalUnstakeRewards/ModalUnstakeRewards';

const Balances: FC<IProps> = ({
  loading,
  onGetStakeAmount,
  balancesN,
  onModal,
  onModalHistory,
  onModalUnstakeLabel,
  rewards
}) => {
  const [modal, setModal] = useState(false);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.titleBalance}>
        <Typography variant="h6" ml={3.75}>
          Balances
        </Typography>
        <Box sx={styles.history} onClick={onModalHistory}>
          <Typography variant="body2">History</Typography>
          <ClockSvg />
        </Box>
      </Box>
      <Box sx={styles.containerSticky}>
        <Box sx={styles.balancesTop}>
          <Box sx={styles.balancesTopTitle}>
            <Typography variant="span3">Label Balance</Typography>
            {loading ? (
              <Box sx={styles.balancesTopIcon}>
                <CircularProgress color="secondary" size={18} />
              </Box>
            ) : (
              <Box sx={styles.balancesTopIcon} onClick={onGetStakeAmount}>
                <RefreshSvg />
              </Box>
            )}
          </Box>
          <Box sx={styles.balancesTopNumber}>
            <Typography variant="h2">{balancesN[0]}</Typography>
            <Typography variant="subtitle1" lineHeight={2} ml={1.375}>
              LABEL
            </Typography>
          </Box>
        </Box>

        <Box sx={styles.balancesMiddle}>
          <Box sx={styles.balancesMiddleVote}>
            <Typography variant="span3">Voting Power</Typography>
            <Box sx={styles.containerIcon}>
              <ArrowSvg onClick={onModalUnstakeLabel} />
              <PlusSvg onClick={onModal} />
            </Box>
          </Box>
          <Box sx={styles.balancesMiddleNumber}>
            <Typography variant="h5" sx={styles.text}>
              {balancesN[1]}
            </Typography>
            <Typography variant="subtitle1">TUNE</Typography>
          </Box>
          <Divider light sx={styles.divider} />
          <Box sx={styles.balancesStakedLabel}>
            <Typography variant="body2" fontWeight={600}>
              Deposited
            </Typography>
            <Typography variant="body1">{balancesN[2]} LABEL</Typography>
          </Box>
        </Box>

        <Box sx={styles.balancesBottom}>
          <Box sx={styles.containerStaked}>
            <Typography variant="body2" fontWeight={600}>
              Deposited in Campaigns
            </Typography>
            <Typography variant="body1">{balancesN[3]} USDC</Typography>
          </Box>
          <Divider sx={styles.dividerDark} />
          <Box sx={styles.containerStaked}>
            <Typography variant="body2" fontWeight={600}>
              Daily Label Rewards
            </Typography>
            <Typography variant="body1">{balancesN[3]} LABEL</Typography>
          </Box>
          <Divider sx={styles.dividerDark} />
          <Box sx={styles.containerRewards}>
            <Typography variant="body2" fontWeight={600}>
              Rewards
            </Typography>
            <Box sx={styles.containerBalancesRewards}>
              <Typography variant="body1" lineHeight={1.1}>
                {formatEthers(rewards, 6)} USDC
              </Typography>
              <ArrowSvg onClick={() => setModal(true)} />
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalUnstakeRewards
        modal={modal}
        onModal={() => setModal(false)}
        balanceOf={rewards}
        onGetStakeAmount={onGetStakeAmount}
      />
    </Box>
  );
};

export default Balances;

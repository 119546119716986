import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import theme from '@/helpers/theme/theme';
import client from '@/helpers/client';
import AppRoutes from '../AppRoutes/AppRoutes';
import InitContextProvider from '../InitContextProvider/InitContextProvider';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <InitContextProvider>
            <CssBaseline />
            <ToastContainer position="bottom-left" theme="dark" />
            <AppRoutes />
          </InitContextProvider>
        </LocalizationProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;

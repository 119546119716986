const styles = {
  container: {
    width: '32.875rem',
    // TODO: сделать глобальные стили для scroll
    '& *::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none'
    },
    '& *::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none'
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& *': {
      'scrollbar-width': 'thin'
    },
    '& *::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      background: 'transparent'
    },
    '& *::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '& *::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '6px'
    },
    '& *::-webkit-scrollbar-thumb:hover': {
      background: '#333'
    }
  },
  containerRemixes: {
    background: 'black',
    color: 'white',
    borderBottomRightRadius: '2.5rem',
    borderBottomLeftRadius: '2.5rem',
    p: '1.5625rem 0.75rem 1.25rem 1rem'
  },
  containerWinners: {
    background: '#F3F700',
    color: 'black',
    borderTopLeftRadius: '2.5rem',
    borderTopRightRadius: '2.5rem',
    p: '1.125rem 0.75rem 0.0625rem 1rem'
  },
  containerTitle: {
    textAlign: 'center'
  },
  containerScroll: {
    maxHeight: '19.25rem',
    overflowY: 'auto',
    pr: '0.25rem'
  },
  containerTop: {
    background: '#F4F4F4',
    borderRadius: '2.5rem',
    color: 'black',
    p: '3.125rem 2.8125rem 2.125rem 2.8125rem',
    mb: 1.75
  }
};

export default styles;

import colors from '@/helpers/theme/colors';

const styles = {
  bgProfile: {
    pb: '80px',
    position: 'relative'
  },
  title: {
    mt: '40px'
  },
  form: {
    display: 'flex',
    gap: 2,
    flexDirection: 'column'
  },
  table: {
    '> tr': {
      '> td:first-of-type': {
        fontWeight: 'bold'
      },
      '&:last-child td, &:last-child th': {
        border: 0
      }
    }
  },
  admin: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5
  },
  img: {
    height: '80px',
    width: '80px',
    objectFit: 'cover',
    '& > img': {
      maxWidth: '100%',
      height: 'auto',
      boxShadow: colors.boxShadow,
      borderRadius: '50%'
    }
  },
  item: {
    display: 'flex',
    alignItems: 'start',
    px: 2,
    py: 2,
    '> :first-of-type': {
      minWidth: '180px'
    },
    '> :last-of-type': {
      width: '100%',
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between'
    },
    '@media screen and (max-width: 700px)': {
      px: 1,
      py: 1,
      '> :first-of-type': {
        mb: 1
      },
      flexWrap: 'wrap'
    }
  },
  profileTop: {
    display: 'flex',
    gap: '48px',
    mt: '40px',
    '@media screen and (max-width: 1000px)': {
      flexDirection: 'column-reverse',
      '& > div': {
        width: '100%'
      }
    }
  },
  user: {
    position: 'relative',
    minHeight: '180px',
    backgroundColor: '#fff',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    p: '14px 30px',
    width: '51%',
    boxShadow:
      '0px 461px 184px rgba(0, 0, 0, 0.02), 0px 259px 156px rgba(0, 0, 0, 0.07), 0px 115px 115px rgba(0, 0, 0, 0.11), 0px 29px 63px rgba(0, 0, 0, 0.13), 0px 0px 0px rgba(0, 0, 0, 0.13)'
  },
  bg: {
    backgroundColor: '#F3F700',
    borderRadius: '20px 57.5px 57.5px 20px',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '116px'
  },
  userImg: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    borderRadius: '50%',
    width: '148px',
    minWidth: '148px',
    height: '148px',
    '& img': {
      width: '100%'
    }
  },
  userName: {
    ml: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  wallet: {
    width: '44%',
    color: '#fff',
    backgroundColor: '#000000',
    p: '43px',
    borderRadius: '20px',
    height: '166px'
  },
  walletDisconnect: {
    display: 'flex',
    mt: '20px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  walletDisconnectNumber: {
    display: 'flex',
    alignItems: 'center',
    '& span:last-of-type': {
      ml: '20px'
    }
  },
  walletDisconnectBtn: {
    backgroundColor: '#fff',
    width: '144px',
    height: '48px',
    ':hover': {
      backgroundColor: '#fff'
    }
  },
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: colors.accent2,
      color: colors.accent2,
      border: `1px solid ${colors.accent1}`,
      width: '14px',
      height: '14px',
      borderRadius: '50%',
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.6s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""'
      }
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1
      },
      '100%': {
        transform: 'scale(3)',
        opacity: 0
      }
    }
  },
  profileBottom: {
    mt: '32px',
    display: 'flex',
    gap: '48px',
    '@media screen and (max-width: 1000px)': {
      flexDirection: 'column',
      '& > div': {
        width: '100%'
      }
    }
  },
  profileBottomWrap: {
    position: 'relative',
    backgroundColor: '#fff',
    width: '51%',
    borderRadius: '20px',
    boxShadow:
      '0px 461px 184px rgba(0, 0, 0, 0.02), 0px 259px 156px rgba(0, 0, 0, 0.07), 0px 115px 115px rgba(0, 0, 0, 0.11), 0px 29px 63px rgba(0, 0, 0, 0.13), 0px 0px 0px rgba(0, 0, 0, 0.13)'
  },
  infoBlock: {
    p: '40px 48px'
  },
  info: {
    mt: '32px',
    '&:first-of-type': {
      mt: 0
    }
  },
  infoText: {
    border: '1px solid rgba(0,0,0,.1)',
    borderRadius: '10px',
    width: '100%',
    display: 'block',
    p: '12px 24px',
    mt: '4px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  editWrap: {
    textAlign: 'center'
  },
  edit: {
    cursor: 'pointer',
    height: '80px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid rgba(0,0,0,.1)',
    color: 'rgba(0,0,0,.5)',
    '& svg': {
      stroke: 'rgba(0,0,0,.4)'
    },
    '& p': {
      ml: '6px',
      mt: '4px'
    }
  },
  empty: {
    width: '44%'
  },
  text: {
    '@media screen and (max-width: 1100px)': {
      fontSize: '1.27rem'
    }
  },
  containerLink: {
    position: 'absolute',
    top: '16px',
    right: '26px',
    display: 'flex',
    alignItems: 'end',
    cursor: 'pointer',
    opacity: 0.5,
    '&:hover': {
      opacity: 0.76
    },
    '& svg': {
      mr: '10px'
    }
  }
};

export default styles;

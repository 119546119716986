import { FC } from 'react';
import { IProps } from './types';
import StepUpload from '../StepUpload/StepUpload';
import StepConfirm from '../StepConfirm/StepConfirm';

const Step2: FC<IProps> = ({ setStep, remix, author, step, setDataStem }) => {
  return step === 'step2' || !remix ? (
    <StepUpload setStep={setStep} author={author} remix={remix} />
  ) : (
    <StepConfirm setStep={setStep} remix={remix} setDataStem={setDataStem} />
  );
};

export default Step2;

import { FC, useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import styles from './styles';
import Tracks from './Tracks/Tracks';
import Stems from './Stems/Stems';
import StakingHistory from './StakingHistory/StakingHistory';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import { ReactComponent as EmptyAssetsSvg } from '@/files/icons/emptyAssets.svg';
import {
  IQueryMyStemsArgs,
  IStemsModel,
  IRemixesModel,
  IQueryMyRemixesArgs,
  ITransactionsModel,
  IQueryTransactionsArgs,
  FilterOperationEnum,
  FilterFieldTypeEnum,
  TransactionTypeEnum,
  TransactionStatusEnum,
  ITransactionModel,
  IStemModel,
  IRemixModel
} from '@/graphql/types/_server';
import { GET_STEMS, GET_TRANSACTIONS, MY_REMIXES } from '@/graphql/gql/blockchain';
import { Context } from '@/helpers/context';

const AssetData: FC = () => {
  const { wallet } = useContext(Context);

  const [currentPageStem, setCurrentPageStem] = useState<number>(0);
  const pageSizeStem = 6;

  const { data: dataStems, loading: loadingStems } = useQuery<
    { myStems: IStemsModel },
    IQueryMyStemsArgs
  >(GET_STEMS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      chainId: Number(wallet?.ethers?.getChainId()) || 137,
      payload: {
        paginate: { skip: currentPageStem * pageSizeStem, take: pageSizeStem }
      }
    }
  });

  const [currentPageRemix, setCurrentPageRemix] = useState<number>(0);
  const pageSizeRemix = 6;

  const { data: dataRemixes, loading: loadingRemixes } = useQuery<
    { myRemixes: IRemixesModel },
    IQueryMyRemixesArgs
  >(MY_REMIXES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      chainId: Number(wallet?.ethers?.getChainId()) || 137,
      payload: {
        paginate: { skip: currentPageRemix * pageSizeRemix, take: pageSizeRemix }
      }
    }
  });

  const [currentPageHistory, setCurrentPageHistory] = useState<number>(0);
  const pageSizeHistory = 9;

  const { data: dataTransactions, loading: loadingTransactions } = useQuery<
    { transactions: ITransactionsModel },
    IQueryTransactionsArgs
  >(GET_TRANSACTIONS, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      chainId: Number(wallet?.ethers?.getChainId()) || 137,
      payload: {
        paginate: { skip: currentPageHistory * pageSizeHistory, take: pageSizeHistory },
        filters: [
          {
            columnName: 'type',
            operation: FilterOperationEnum.Equal,
            type: FilterFieldTypeEnum.Text,
            value: [TransactionTypeEnum.Depositcampaign, TransactionTypeEnum.Withdrawcampaign]
          },
          {
            columnName: 'status',
            operation: FilterOperationEnum.Equal,
            type: FilterFieldTypeEnum.Text,
            value: [TransactionStatusEnum.Done]
          }
        ]
      }
    }
  });

  const [totalStems, setTotalStems] = useState<number>(0);
  const [stems, setStems] = useState<IStemModel[]>([]);
  useEffect(() => {
    const total = dataStems?.myStems?.meta?.total;
    if (!loadingStems && !!total && total > 0) {
      setTotalStems(total);
      setStems(dataStems?.myStems?.items);
    }
  }, [loadingStems, dataStems]);

  const [totalRemixes, setTotalRemixes] = useState<number>(0);
  const [remixes, setRemixes] = useState<IRemixModel[]>([]);
  useEffect(() => {
    const total = dataRemixes?.myRemixes?.meta?.total;
    if (!loadingRemixes && !!total && total > 0) {
      setTotalRemixes(total);
      setRemixes(dataRemixes?.myRemixes.items);
    }
  }, [loadingRemixes, dataRemixes]);

  const [totalTransactions, setTotalTransactions] = useState<number>(0);
  const [transactions, setTransactions] = useState<ITransactionModel[]>([]);
  useEffect(() => {
    const total = dataTransactions?.transactions?.meta?.total;
    if (!loadingTransactions && !!total && total > 0) {
      setTotalTransactions(total);
      setTransactions(dataTransactions?.transactions.items);
    }
  }, [loadingTransactions, dataTransactions]);

  return (
    <Box sx={styles.container}>
      {(loadingStems || loadingRemixes || loadingTransactions) && <BackdropLoading />}
      {!!stems.length && (
        <Stems
          stems={stems}
          total={totalStems}
          currentPage={currentPageStem}
          setCurrentPage={setCurrentPageStem}
          pageSize={pageSizeStem}
        />
      )}
      {!!remixes.length && (
        <Tracks
          remixes={remixes}
          total={totalRemixes}
          currentPage={currentPageRemix}
          setCurrentPage={setCurrentPageRemix}
          pageSize={pageSizeRemix}
        />
      )}
      {!!transactions.length && (
        <StakingHistory
          transactions={transactions}
          total={totalTransactions}
          currentPage={currentPageHistory}
          setCurrentPage={setCurrentPageHistory}
          pageSize={pageSizeHistory}
        />
      )}
      {!loadingStems &&
        !loadingRemixes &&
        !loadingTransactions &&
        !stems.length &&
        !remixes.length &&
        !transactions.length && (
          <Box sx={styles.containerEmpty}>
            <EmptyAssetsSvg />
            <Typography variant="h6" sx={styles.text}>
              You don’t have any assets yet
            </Typography>
            <Typography variant="caption" fontWeight={300} sx={styles.textAdditinal} component="p">
              Stake USDC in campaigns to earn LABEL tokens and Stems, or purchase Music NFTs to view
              them here
            </Typography>
          </Box>
        )}
    </Box>
  );
};

export default AssetData;

const styles = {
  modal: {
    backgroundColor: '#000000',
    position: 'relative',
    padding: '39px 45px',
    pb: '24px'
  },
  modalClose: {
    position: 'absolute',
    top: '20px',
    right: '38px',
    cursor: 'pointer'
  },
  container: {
    color: '#fff',
    width: '464px'
  },
  tune: {
    border: '1px solid rgb(255 255 255 / 0.2)',
    borderRadius: '10px',
    color: 'white',
    py: 2.125,
    textAlign: 'center'
  },
  textTransparent: {
    opacity: 0.5
  },
  button: {
    textAlign: 'center',
    marginTop: '32px',
    '& > button': {
      width: '144px',
      backgroundColor: '#fff',
      color: '#000',
      height: '48px',
      mr: 2,
      '&:disabled': {
        opacity: 0.26
      }
    },
    '& > button:hover': {
      backgroundColor: '#fff'
    }
  }
};

export default styles;

import { FC, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import Modal from '@/shared/Modal/Modal';
import { ReactComponent as CloseSvg } from '@/files/icons/assets/close.svg';
import { IMutationUpdateUserArgs, IUserModel } from '@/graphql/types/_server';
import { GET_VERIFICATION_EMAIL, UPDATE_USER } from '@/graphql/gql/users';
import getBeautyErrorMessage from '@/utils/getBeautyErrorMessage';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import DropZone from '@/shared/DropZone/DropZone';
import { IView } from '@/shared/DropZone/types';
import styles from './styles';
import { IFormInputsValidation, IProps } from './types';

const ModalEdit: FC<IProps> = ({ me, refetch, modal, onClose }) => {
  const {
    register,
    control,
    handleSubmit,
    getValues,
    trigger,
    setValue,
    formState: { errors, touchedFields }
  } = useForm<IFormInputsValidation>({ mode: 'onTouched' });

  const user = me;
  const [logoFile, setLogoFile] = useState<File | undefined>(undefined);

  const [updateUser, { loading: loadingUpdateMessage }] = useMutation<
    { updateUser: IUserModel },
    IMutationUpdateUserArgs
  >(UPDATE_USER);

  const [getVerificationEmail, { loading: loadingCodeSent }] = useMutation<{
    getVerificationEmailCode: boolean;
  }>(GET_VERIFICATION_EMAIL);

  useEffect(() => {
    if (user?.logoUrl) {
      const file = new File([''], user.logoUrl, { type: 'image/*' });
      setLogoFile(file);
    }
  }, [user]);

  if (!user) {
    return <BackdropLoading />;
  }

  return (
    <Modal sx={styles.modal} onClose={onClose} isOpen={modal}>
      <Box sx={styles.modalClose} onClick={onClose}>
        <CloseSvg />
      </Box>
      <Typography variant="h5">Edit profile</Typography>
      <Box sx={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box my={4} sx={styles.dropZone}>
            <Controller
              name="logo"
              control={control}
              defaultValue={undefined}
              render={() => (
                <DropZone
                  cropImage
                  files={getValues('logo') || (logoFile !== undefined ? [logoFile] : [])}
                  handleFilesAdd={handleLogoAdd}
                  handleFileDelete={handleLogoDelete}
                  multiple={false}
                  maxFiles={1}
                  maxSize={2000000}
                  label={
                    <>
                      Drag and drop your avatar here, or click to select file
                      <Typography variant="subtitle1">
                        <em>(Only *.jpg or *.png)</em>
                      </Typography>
                    </>
                  }
                  error={Boolean(errors.logo)}
                  helperText={
                    (errors?.logo as unknown as FieldError)?.message ||
                    (errors?.logo as unknown as FieldError)?.type ||
                    ''
                  }
                  accept={['image/jpeg', 'image/png']}
                  view={IView.Logo}
                />
              )}
            />
          </Box>
          <Box>
            <Box sx={styles.inputWrap}>
              <Typography sx={styles.label} variant="subtitle2">
                Username
              </Typography>
              <Controller
                name="userName"
                control={control}
                defaultValue={user.userName || ''}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="userName"
                    size="small"
                    fullWidth
                    error={touchedFields.userName && Boolean(errors.userName)}
                    helperText={errors?.userName?.message || errors?.userName?.type || ''}
                    {...register('userName', {
                      minLength: {
                        value: 3,
                        message: 'The username must be at least 3 characters'
                      },
                      maxLength: {
                        value: 30,
                        message: 'The username should not be more than 30 characters'
                      },
                      pattern: {
                        value: /^\S*$/,
                        message: 'No spaces allowed'
                      }
                    })}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={styles.inputWrap}>
              <Typography sx={styles.label} variant="subtitle2">
                Name
              </Typography>
              <Controller
                name="name"
                control={control}
                defaultValue={user.name || ''}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="name"
                    size="small"
                    fullWidth
                    error={touchedFields.name && Boolean(errors.name)}
                    helperText={errors?.name?.message || errors?.name?.type || ''}
                    {...register('name', {
                      maxLength: {
                        value: 50,
                        message: 'The name should not be more than 50 characters'
                      }
                    })}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={styles.inputWrap}>
              <Typography sx={styles.label} variant="subtitle2">
                Description
              </Typography>
              <Controller
                name="description"
                control={control}
                defaultValue={user.description || ''}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="description"
                    size="small"
                    fullWidth
                    error={touchedFields.description && Boolean(errors.description)}
                    helperText={errors?.description?.message || errors?.description?.type || ''}
                    {...register('description', {
                      maxLength: {
                        value: 500,
                        message: 'The description should not be more than 500 characters'
                      }
                    })}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={styles.inputWrap}>
              <Typography sx={styles.label} variant="subtitle2">
                Email
              </Typography>
              <Controller
                name="email"
                control={control}
                defaultValue={user.email || ''}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="email"
                    size="small"
                    fullWidth
                    error={touchedFields.email && Boolean(errors.email)}
                    helperText={errors?.email?.message || errors?.email?.type || ''}
                    {...register('email', {
                      maxLength: {
                        value: 100,
                        message: 'The email should not be more than 100 characters'
                      },
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: 'Incorrect e-mail address'
                      }
                    })}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box mt={2} sx={styles.btnWrap}>
              <Button
                disabled={Object.keys(errors).length > 0 || loadingUpdateMessage || loadingCodeSent}
                fullWidth
                color="secondary"
                variant="contained"
                type="submit"
                disableElevation
                sx={styles.btn}
              >
                <Typography variant="body2">Save</Typography>
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
      {(loadingUpdateMessage || loadingCodeSent) && <BackdropLoading />}
    </Modal>
  );

  async function handleLogoAdd(acceptedFiles: File[]) {
    setValue('logo', [...(getValues('logo') || []), ...acceptedFiles]);
    await trigger('logo');
  }
  async function handleLogoDelete(file: File, removeCropped?: boolean) {
    if (logoFile) {
      setLogoFile(undefined);
    }
    if (removeCropped) {
      setValue('logo', undefined);
    } else {
      setValue(
        'logo',
        (getValues('logo') || []).filter((item) => item !== file)
      );
    }

    await trigger('logo');
  }

  async function onSubmit(data: IFormInputsValidation) {
    if (!me) {
      throw new Error('User not found');
    }
    try {
      const newData: IMutationUpdateUserArgs = {
        payload: {
          id: me.id
        }
      };

      if (data?.logo?.length) {
        const logoFile = data.logo[0];
        newData.logo = logoFile;
      }

      newData.payload.name = data.name;
      newData.payload.description = data.description;
      newData.payload.userName = data.userName;

      if (touchedFields.email && data?.email) {
        newData.payload.email = data.email;
      }

      const response = await updateUser({
        variables: newData
      });
      if (response) {
        refetch();
        onClose();
        toast.success('User updated');
        if (touchedFields.email && data?.email) {
          await getCodeHandler();
        }
      }
    } catch (err) {
      const error = err as Error;
      toast.error(getBeautyErrorMessage(error?.message));
    }
  }

  async function getCodeHandler() {
    try {
      const response = await getVerificationEmail();
      if (response?.data?.getVerificationEmailCode) {
        toast.success('Code sent');
      } else {
        toast.error('Code not sent');
      }
    } catch (err) {
      const error = err as Error;
      toast.error(error?.message);
    }
  }
};

export default ModalEdit;

import colors from '@/helpers/theme/colors';

const styles = (theme: 'light' | 'dark') => ({
  dropZone: {
    textAlign: 'center',
    cursor: 'pointer',
    position: 'relative',
    border: `1px dashed ${colors.grey.light}`,
    borderRadius: 1,
    p: 2,
    '&:hover': {
      borderColor: theme === 'light' ? colors.common.black : colors.common.white
    }
  },
  dropZoneBlack: {
    background: 'black',
    border: 'none',
    '&:hover': {
      outline: '1px dashed white',
      outlineOffset: '-4px'
    }
  },
  dropZoneYellow: {
    background: '#F3F700',
    border: 'none',
    '&:hover': {
      outline: '1px dashed black',
      outlineOffset: '-4px'
    }
  },
  disabledDropZone: {
    borderColor: `${colors.border.main} !important`,
    cursor: 'initial',
    outlineStyle: 'initial'
  },
  disabledTypography: {
    opacity: 0.7
  },
  focused: {
    outlineStyle: 'auto'
  },
  accept: {
    outlineStyle: 'auto',
    color: colors.status.success
  },
  reject:
    theme === 'light'
      ? {
          outlineStyle: 'auto',
          color: colors.status.error
        }
      : {
          border: `1px dashed ${colors.status.error}`
        },
  helperText: {
    ml: 1.75,
    color: 'rgb(211, 47, 47);'
  }
});

export default styles;

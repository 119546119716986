import { keyframes } from '@mui/system';
import colors from '@/helpers/theme/colors';

const revealFromBottom = keyframes`
  from {
    bottom: -150px;
  }
  to {
    bottom: 0px;
  }
`;

const hideToBottom = keyframes`
  from {
    bottom: 0px;
  }
  to {
    bottom: -150px;
  }
`;

export const styles = (showPlayer: boolean) => {
  return {
    container: {
      position: 'fixed',
      display: 'flex',
      alignItems: 'center',
      left: 0,
      right: 0,
      bottom: showPlayer ? '0px' : '-150px',
      zIndex: 999,
      animation: `${showPlayer ? revealFromBottom : hideToBottom} 0.5s ease-in-out`,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      backdropFilter: 'blur(24px)',
      color: colors.common.white,
      maxWidth: '1040px',
      margin: 'auto',
      borderTopLeftRadius: '30px',
      borderTopRightRadius: '30px'
    },
    player: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 1,
      margin: '18px 31px 15px 32px',
      '@media screen and (max-width: 960px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 1,
        my: 1.5
      }
    }
  };
};

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    columnGap: 2,
    rowGap: 1,
    pt: 2.625
  },
  title: {
    position: 'relative',
    lineHeight: 1,
    pt: 4.25,
    pb: 9
  },
  text: {
    position: 'relative',
    pb: 8
  }
};

export default styles;

const styles = {
  button: {
    backgroundColor: '#F3F700',
    height: 'auto',
    borderRadius: '30px',
    padding: '4px 12px',
    minHeight: '2rem',
    minWidth: '6rem',
    ':hover': {
      backgroundColor: '#F3F700'
    }
  },
  text: {
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  }
};

export default styles;

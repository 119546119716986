const styles = {
  text: {
    fontFamily: '"Grafita", monospace, sans-serif'
  },
  textGrey: {
    color: '#BDC2C8',
    opacity: 0.5
  },
  containerAudio: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& > svg': {
      width: '2rem',
      height: '2rem',
      minWidth: '2rem',
      minHeight: '2rem',
      mr: 1.25,
      fill: '#F3F700'
    }
  },
  containerInner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '0.75rem',
    paddingBottom: '1rem',
    position: 'relative'
  },
  containerOuter: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingRight: '1.375rem',
    paddingLeft: '0.875rem',
    ':hover': {
      outline: '1px solid #F3F700',
      borderRadius: '1.25rem',
      outlineOffset: '-1px',
      '& > div:first-of-type > p': {
        opacity: 1
      },
      '& > div:last-of-type > div > div:nth-child(4)': {
        display: 'none'
      },
      '& > div:last-of-type > div > button:last-child': {
        display: 'inherit'
      }
    }
  },
  vote: {
    background: 'rgb(255 255 255 / 10%)',
    minWidth: '4.75rem',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.625rem',
    color: 'rgb(255 255 255 / 50%)'
  },
  button: {
    backgroundColor: '#fff',
    minWidth: '4.75rem',
    width: '4.75rem',
    height: '2rem',
    display: 'none',
    ':hover': {
      backgroundColor: '#fff'
    }
  },
  containerVote: {
    display: 'flex',
    overflow: 'hidden'
  },
  textVote: {
    alignSelf: 'center',
    marginRight: 3,
    marginTop: 0.25,
    transform: 'translate(0, 2rem)'
  },
  animationVote: {
    transform: 'translate(0, 2rem)',
    opacity: 0,
    animationName: 'showVote, hideVote',
    animationDuration: '0.85s, 0.65s',
    animationFillMode: 'both, both',
    animationTimingFunction: 'ease-in, ease-in',
    animationIterationCount: '1, 1',
    animationDelay: '0.5s, 4s',
    '@keyframes showVote': {
      '100%': {
        opacity: 1,
        transform: 'translate(0, 0)'
      }
    },
    '@keyframes hideVote': {
      '99%': {
        opacity: 0,
        transform: 'translate(0, 0)'
      },
      '100%': {
        opacity: 0,
        transform: 'translate(0, 2rem)'
      }
    }
  },
  textVoted: {
    position: 'absolute',
    right: 0,
    zIndex: -1,
    background: '#1a1a1a'
  },
  animationTextVoted: {
    animationName: 'buttonVoteShow, buttonVoteHide',
    animationDuration: '0s, 0s',
    animationFillMode: 'both, both',
    animationTimingFunction: 'ease-in, ease-in',
    animationIterationCount: '1, 1',
    animationDelay: '0.5s, 4.65s',
    '@keyframes buttonVoteShow': {
      '100%': {
        zIndex: 0
      }
    },
    '@keyframes buttonVoteHide': {
      '100%': {
        zIndex: -1
      }
    }
  }
};

export default styles;

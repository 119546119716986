const styles = {
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(2px)',
    zIndex: 1,
    backgroundColor: 'rgb(0 0 0 / 12%)',
    borderRadius: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    right: 0,
    '& > span': {
      color: '#F3F700'
    }
  }
};

export default styles;

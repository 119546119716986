import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import styles from './styles';

export interface IPopupWallet {
  open: boolean;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  closeMenuHandler: () => void;
}

const PopupMenu: FC<IPopupWallet> = ({ open, anchorEl, closeMenuHandler }) => {
  const navigate = useNavigate();

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={closeMenuHandler}
      PaperProps={{ sx: styles.menu }}
    >
      <Box sx={styles.container}>
        <Box>
          <Typography variant="body2" sx={styles.text} onClick={() => handleClick('/users')}>
            Users Management
          </Typography>
          <Typography variant="body2" sx={styles.text} onClick={() => handleClick('/campaigns')}>
            Campaigns Management
          </Typography>
        </Box>
        <Divider />
        <Box>
          <Typography
            variant="body2"
            sx={styles.text}
            onClick={() => handleClick('/create-campaign')}
          >
            Create Campaign
          </Typography>
          <Typography variant="body2" sx={styles.text} onClick={() => handleClick('/create-remix')}>
            Create Remix
          </Typography>
        </Box>
      </Box>
    </Menu>
  );

  function handleClick(path: string) {
    closeMenuHandler();
    navigate(path);
  }
};

export default memo(PopupMenu);

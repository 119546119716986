/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Chip from '@mui/material/Chip';
import { IProps } from './types';

const Chips: FC<IProps> = ({ files, handleFileDelete = () => {} }) => {
  return (
    <>
      {files.map((item, index) => (
        <Chip
          key={`${item.name}_${index}`}
          label={item.name}
          size="small"
          onDelete={() => handleFileDelete(item)}
          icon={<InsertDriveFileIcon />}
        />
      ))}
    </>
  );
};

export default Chips;

const styles = {
  popper: {
    zIndex: 999,

    position: 'absolute',
    bottom: '-17px',
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  container: {
    position: 'relative'
  },
  containerVolume: {
    width: '64px',
    height: '257px',
    background: 'black',
    borderRadius: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '22px',
    paddingBottom: '17px',
    '& > div:first-child': {
      height: '180px'
    }
  },
  volume: {
    width: '4px',
    '& .MuiSlider-rail': {
      backgroundColor: 'white',
      opacity: 0.32
    }
  },
  icon: {
    mt: '-6px'
  }
};

export default styles;

const styles = {
  containerAudio: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& > svg': {
      width: '2rem',
      height: '2rem',
      minWidth: '2rem',
      minHeight: '2rem',
      mr: 1.25,
      fill: 'black',
      cursor: 'pointer'
    },
    '& > svg path': {
      fill: '#F3F700'
    }
  },
  containerInner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '0.75rem',
    paddingBottom: '1rem',
    position: 'relative'
  },
  containerOuter: {
    display: 'flex',
    alignItems: 'center',
    px: 2.5,
    ':hover': {
      outline: '1px solid black',
      borderRadius: '1.25rem',
      outlineOffset: '-1px'
    }
  },
  vote: {
    background: 'rgb(0 0 0)',
    minWidth: '4.75rem',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.625rem',
    color: 'rgb(255 255 255)'
  }
};

export default styles;

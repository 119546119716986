import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Container from '@mui/system/Container';
import { useMemo, FC, useState } from 'react';
import Grid from '@mui/material/Grid';
import styles from './styles';
import { ReactComponent as DiscordSvg } from '@/files/icons/discordSvg.svg';
import { ReactComponent as TwitterSvg } from '@/files/icons/twitterSvg.svg';
import { ReactComponent as GithubSvg } from '@/files/icons/githubSvg.svg';
import { ReactComponent as FooterLogoSvg } from '@/files/icons/footerLogoSvg.svg';
import getCurrentYear from '@/utils/getCurrentYear';
import { IProps } from './types';
import InformationLabelModal from './InformationLabelModal/InformationLabelModal';

const Footer: FC<IProps> = ({ isBlackLine = false }) => {
  const currentYearMemoized = useMemo(() => getCurrentYear(), []);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box sx={styles.footerMain}>
      {isBlackLine && (
        <Box sx={styles.blackLine}>
          <Box />
        </Box>
      )}
      <Container maxWidth="md">
        <Box sx={styles.flex}>
          <Box>
            <Link to="/store" style={styles.link}>
              <Typography variant="subtitle2">Buy Now</Typography>
            </Link>
            <Link to="/earn" style={styles.link}>
              <Typography variant="subtitle2">Earn</Typography>
            </Link>
            <Box style={styles.link} onClick={() => setOpen(true)}>
              <Typography variant="subtitle2">What is $LABEL?</Typography>
            </Box>
          </Box>
          <Box>
            <FooterLogoSvg />
          </Box>
          <Box>
            <Typography variant="caption">Label Protocol</Typography>
            <Typography variant="caption">Governance</Typography>
            <Typography variant="caption">Docs</Typography>
            <Typography variant="caption">FAQ</Typography>
            <Typography variant="overline" mt={1} sx={{ ...styles.flex, ...styles.additionalText }}>
              © Label {currentYearMemoized}. <br /> All Rights Reserved.
            </Typography>
          </Box>
        </Box>

        <Box sx={styles.footerSocialLogos}>
          <Grid container justifyContent="center" gap={3}>
            <Grid item sx={styles.iconWrapper}>
              <DiscordSvg />
            </Grid>
            <Grid item sx={styles.iconWrapper}>
              <TwitterSvg />
            </Grid>
            <Grid item sx={styles.iconWrapper}>
              <GithubSvg />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <InformationLabelModal open={open} handleClose={() => setOpen(false)} />
    </Box>
  );
};

export default Footer;

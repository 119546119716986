/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IProps } from './types';
import { ReactComponent as FolderSvg } from '@/files/icons/folder.svg';
import { ReactComponent as MusicSvg } from '@/files/icons/music.svg';
import styles from './styles';
import { ReactComponent as EmptyTrashSvg } from '@/files/icons/cart/trash-empty.svg';
import { bytesToMegaBytes } from '@/utils/bytesToMegaBytes';

const File: FC<IProps> = ({ files, handleFileDelete = () => {}, type, fileSize }) => {
  return (
    <Box width="100%">
      <Box onClick={() => handleFileDelete(undefined as any)} sx={styles.icon}>
        <EmptyTrashSvg />
      </Box>
      <Box sx={styles.container}>
        {files.map((item, index) => (
          <Box key={`${item.name}_${index}`} sx={styles.containerInner}>
            {type === 'archive' && <FolderSvg />}
            {type === 'music' && <MusicSvg />}
            <Box ml={2.75} textAlign="initial" mt={1.75} sx={styles.containerText}>
              <Typography variant="subtitle2" lineHeight={1}>
                {item.name}
              </Typography>
              {(!!item.size || !!fileSize) && (
                <Typography variant="caption" sx={styles.textGrey}>
                  {`${bytesToMegaBytes(item?.size || fileSize || 0)} mb`}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default File;

import { FC } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles';

const Loader: FC = () => {
  return (
    <Box sx={styles.container}>
      <CircularProgress size={48} />
    </Box>
  );
};

export default Loader;

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    overflowX: 'hidden',
    '@media screen and (max-width: 850px)': {
      flexWrap: 'wrap'
    }
  },
  animationLeft: {
    '& > div:first-of-type': {
      opacity: 0,
      transform: 'translate(-55vw, 0)',
      animationName: 'leftCard',
      animationDuration: '0.5s',
      animationFillMode: 'forwards',
      animationIterationCount: 1,
      animationDelay: '0s',
      '@keyframes leftCard': {
        '100%': {
          transform: 'translate(0, 0)',
          opacity: 1
        }
      }
    }
  },
  children: {
    width: '32.875rem',
    borderRadius: '2.5rem',
    position: 'relative',
    '@media screen and (max-width: 534px)': {
      width: '100%'
    }
  },
  animationRight: {
    opacity: 0,
    transform: 'translate(55vw, 0)',
    animationName: 'rightCard',
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
    animationIterationCount: 1,
    animationDelay: '0s',
    '@keyframes rightCard': {
      '100%': {
        transform: 'translate(0, 0)',
        opacity: 1
      }
    }
  }
};

export default styles;

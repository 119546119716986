import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Controller } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import styles from './styles';
import { IProps } from './types';

const StakeUSDC: FC<IProps> = ({ loading, form, onSubmit, loadingWithdraw, setAmountHandler }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = form;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box sx={styles.containerForm}>
          <Controller
            name="amount"
            control={control}
            render={({ field: { value, ref } }) => {
              return (
                <NumberFormat
                  id="amount"
                  variant="outlined"
                  placeholder="USDC Amount"
                  value={value}
                  customInput={TextField}
                  disabled={loading}
                  error={Boolean(errors.amount)}
                  helperText={errors?.amount?.message || errors?.amount?.type || ' '}
                  {...register('amount', {
                    required: 'Required',
                    min: {
                      value: 1,
                      message: '1 USDC minimum'
                    }
                  })}
                  suffix=" USDC"
                  thousandSeparator={false}
                  isNumericString
                  allowNegative={false}
                  decimalScale={0}
                  onValueChange={setAmountHandler}
                  ref={ref}
                />
              ) as JSX.Element;
            }}
          />
          <LoadingButton
            type="submit"
            size="large"
            color="secondary"
            variant="contained"
            disableElevation
            disabled={loadingWithdraw || loading}
          >
            <Typography variant="body2" lineHeight="1.3125rem">
              Deposit
            </Typography>
          </LoadingButton>
        </Box>
      </form>
      <Typography variant="caption" component="p" sx={styles.caption}>
        Deposit USDC in an active campaign to earn rewards and a chance to win a limited edition
        Stem NFT from your favorite artist. Keep all of your money, even if you don&apos;t win.
      </Typography>
    </>
  );
};

export default StakeUSDC;

const styles = {
  container: {
    margin: 'auto',
    marginTop: 'calc(27vh - 128px)',
    width: 'fit-content',
    textAlign: 'center'
  },
  textTransparent: {
    opacity: '0.5'
  },
  loader: {
    height: '235px',
    width: '235px',
    margin: 'auto',
    position: 'relative',
    '& > img:first-child': {
      animation: '2.5s linear 0s normal none infinite running rot',
      '@keyframes rot': {
        '0%': {
          transform: 'rotate(0deg)'
        },
        '100%': {
          transform: 'rotate(360deg)'
        }
      }
    },
    '& > *:last-child': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      position: 'absolute',
      width: '100px'
    }
  },
  link: {
    cursor: 'pointer',
    borderBottom: '1px dashed white',
    width: 'fit-content',
    margin: 'auto',
    mt: 4
  }
};

export default styles;

const styles = {
  menu: {
    mt: '14px',
    '& .MuiList-root': {
      p: 0
    }
  },
  balance: {
    minWidth: '526px'
  },
  balanceTop: {
    display: 'block',
    backgroundColor: '#232323'
  },
  balanceTopTitle: {
    display: 'block',
    color: '#fff',
    p: '30px 10px 15px 30px'
  },
  balanceTopNumber: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media screen and (max-width: 550px)': {
      flexDirection: 'column',
      '& > div': {
        width: '100%',
        borderRadius: '0',
        paddingLeft: '30px'
      }
    }
  },
  balanceTopNumberLeft: {
    borderRadius: '0px 25px 0px 0px',
    display: 'flex',
    alignItems: 'end',
    width: '56%',
    backgroundColor: '#F3F700',
    height: '91px',
    p: '16px 10px 20px 30px',
    '& > h2:first-of-type': {
      lineHeight: '54px'
    },
    '& > h6:last-of-type': {
      ml: '20px'
    }
  },
  balanceTopNumberRight: {
    display: 'flex',
    alignItems: 'end',
    p: '18px 30px 24px 10px',
    color: '#F3F700',
    '& > h4:first-of-type': {
      lineHeight: '44px'
    },
    '& > h6:last-of-type': {
      ml: '20px',
      lineHeight: '30px'
    }
  },
  balanceBottom: {
    p: '24px 30px 22px 30px'
  },
  balanceBottomStalked: {
    display: 'block',
    mb: '15px'
  },
  balanceBottomCampaigns: {
    display: 'flex',
    alignItems: 'end',
    borderBottom: '1px solid rgba(0, 0, 0, .1)',
    pb: '22px',
    mb: '24px',
    '& a': {
      border: 'none',
      borderBottom: '1px dashed rgba(26, 27, 29, .5)'
    }
  },
  balanceBottomTextT: {
    fontSize: '14px',
    mb: '20px',
    cursor: 'pointer',
    width: 'fit-content',
    '&:last-of-type': {
      mb: 0
    }
  }
};

export default styles;

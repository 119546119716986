import { FC, memo, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { CampaignStatusEnum, ICampaignModel } from '@/graphql/types/_server';
import getMappedCurrentStage from '@/utils/getMappedCurrentStage';
import styles from './styles';
import getCloudImgUrl from '@/utils/getUrlImage';

const SliderItem: FC<{ campaign: ICampaignModel; countMissing: number }> = ({
  campaign,
  countMissing
}) => {
  const navigate = useNavigate();

  const status = useMemo(() => {
    return getMappedCurrentStage(campaign.status);
  }, [campaign.status]);

  const textButton = useMemo(() => {
    if (campaign.status === CampaignStatusEnum.Lottery) {
      return 'Deposit';
    }
    if (campaign.status === CampaignStatusEnum.Remix) {
      return 'Upload remix';
    }
    return 'Vote remix';
  }, [campaign.status]);

  return (
    <Box sx={{ ...styles.container, width: `${95 - countMissing * 23.75}%` }}>
      <Box
        sx={styles.sliderLineBlock}
        style={{ backgroundImage: `url(${getCloudImgUrl(campaign.logoUrl, 700)})` }}
      >
        <Box sx={{ ...styles.sliderLineText, ...(!!campaign?.isWhiteName && styles.white) }}>
          <Typography variant="caption" sx={styles.descr} component="p">
            Sponsored by {campaign.sponsor?.name || 'Sponsor'}
          </Typography>
          <Typography variant="h6">{campaign.name}</Typography>
          <Button sx={styles.buttonWhite} onClick={() => navigate(`/earn/${campaign.id}`)}>
            <Typography variant="body2">{textButton}</Typography>
          </Button>
        </Box>
        <Button variant="contained" color="secondary" size="large" disableElevation sx={styles.btn}>
          <Typography variant="caption">{status}</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default memo(SliderItem);

import { FC, useEffect, useRef, useState } from 'react';
import { Box, Container, Button, Typography } from '@mui/material';
import { ReactComponent as AboutLabelSvg } from '@/files/icons/about/aboutLabel.svg';
import styles from './styles';

const About: FC = () => {
  const aboutRef = useRef<HTMLDivElement | undefined>(undefined);
  const [isStartAnimation, setIsStartAnimation] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const el = aboutRef.current || undefined;
      if (el) {
        const delta = 300;
        const topPosition = el.getBoundingClientRect().top + window.scrollY;
        const scrollPosition = window.scrollY + window.innerHeight - delta;
        if (topPosition < scrollPosition) {
          setIsStartAnimation(true);
        }
      }
    });
    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, []);

  return (
    <Box ref={aboutRef} sx={styles.about}>
      <Box sx={{ ...styles.aboutLabel, ...(isStartAnimation && styles.aboutLabelAnimation) }}>
        <Box sx={styles.aboutLabelIcon}>
          <AboutLabelSvg />
        </Box>
        <Box sx={styles.aboutText}>
          <Typography variant="h1" sx={styles.aboutTextElement}>
            What
          </Typography>
          <Box sx={styles.aboutTextElementWrap}>
            <Typography variant="h1" sx={styles.aboutTextElement}>
              is
            </Typography>
            <Box sx={styles.aboutTextElementBlock} />
          </Box>
          <Typography variant="h1" sx={styles.aboutTextElement}>
            Label
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="md" sx={styles.aboutInfoWrap}>
        <Box sx={styles.aboutInfo}>
          <Typography variant="h6" sx={styles.aboutInfoText}>
            Label is a community governed protocol building the foundation for a decentralized music
            industry
          </Typography>
          <Button sx={styles.aboutInfoBtn}>
            <Typography variant="body2">Learn More</Typography>
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default About;

import { FC, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IStemModel } from '@/graphql/types/_server';
import styles from './styles';
import Stem from './Stem/Stem';
import PaginationUi from '@/shared/ui/PaginationUi/PaginationUi';
import { IProps } from './types';
import getCloudImgUrl from '@/utils/getUrlImage';

const Stems: FC<IProps> = ({ stems, total, currentPage, setCurrentPage, pageSize }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [stem, setStem] = useState<IStemModel | undefined>(undefined);
  const calculateTotalPages = useMemo(() => Math.ceil(Number(total) / pageSize), [pageSize, total]);

  return (
    <>
      <Box sx={styles.containerTitle}>
        <Typography variant="h6">Stems</Typography>
        {total > pageSize && (
          <PaginationUi
            count={calculateTotalPages}
            page={currentPage + 1}
            setPage={setCurrentPage}
          />
        )}
      </Box>
      <Box sx={styles.gird}>
        {stems.map((item: IStemModel) => {
          return (
            <Box key={item.id} onClick={() => handleOpen(item)}>
              <Box
                sx={{ ...styles.item, ...(!item.isSpent && styles.itemLocked) }}
                style={{ backgroundImage: `url(${getCloudImgUrl(item.logoUrl || '', 300)})` }}
              />
              <Box ml={2.5} mt={2}>
                <Typography variant="span1" component="p">
                  {item.campaign.name}
                </Typography>
                <Typography variant="span3" component="p" mt={0.5}>
                  by {item.campaign.sponsor && item.campaign.sponsor.name}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Stem open={open} handleClose={handleClose} stem={stem} />
    </>
  );

  function handleOpen(stem: IStemModel) {
    setStem(stem);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setTimeout(() => {
      setStem(undefined);
    }, 300);
  }
};

export default Stems;

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import EditForm from './EditForm/EditForm';

const EditCampaign = () => {
  return (
    <Container maxWidth="xl">
      <Box>
        <Box mt={4}>
          <Typography variant="h1">Edit Campaign</Typography>
        </Box>
        <Box mt={4}>
          <EditForm />
        </Box>
      </Box>
    </Container>
  );
};

export default EditCampaign;

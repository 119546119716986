import { FC, useMemo, memo } from 'react';
import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import { CAMPAIGNS } from '@/graphql/gql/campaigns';
import {
  ICampaignsModel,
  IQueryCampaignsArgs,
  CampaignStatusEnum,
  FilterOperationEnum,
  FilterFieldTypeEnum
} from '@/graphql/types/_server';
import CampaignsItem from '@/components/Campaigns/CampaignsItem/CampaignsItem';
import styles from './styles';

const Drafts: FC = () => {
  const { loading, data, error } = useQuery<{ campaigns: ICampaignsModel }, IQueryCampaignsArgs>(
    CAMPAIGNS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        payload: {
          filters: [
            {
              columnName: 'status',
              operation: FilterOperationEnum.Equal,
              type: FilterFieldTypeEnum.Text,
              value: [CampaignStatusEnum.Draft]
            }
          ]
        }
      }
    }
  );

  const campaigns = useMemo(() => data?.campaigns?.items || [], [data?.campaigns?.items]);

  if (error) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error" variant="outlined">
          Try again later
        </Alert>
      </Container>
    );
  }

  return (
    <Box sx={styles.background}>
      <Container maxWidth="xl">
        <Box sx={styles.container}>
          <Typography variant="h1">Draft campaigns</Typography>
        </Box>
        {!loading ? <CampaignsItem campaigns={campaigns} /> : <BackdropLoading />}
      </Container>
    </Box>
  );
};

export default memo(Drafts);

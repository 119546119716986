import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import colors from './colors';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    title: React.CSSProperties;
    span1: React.CSSProperties;
    span2: React.CSSProperties;
    span3: React.CSSProperties;
    infoBig: React.CSSProperties;
    info: React.CSSProperties;
    span14: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title: React.CSSProperties;
    span1?: React.CSSProperties;
    span2?: React.CSSProperties;
    span3: React.CSSProperties;
    infoBig: React.CSSProperties;
    info: React.CSSProperties;
    span14: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
    span1: true;
    span2: true;
    span3: true;
    infoBig: true;
    info: true;
    span14: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: "'Rubik', sans-serif",
    button: {
      textTransform: 'none',
      lineHeight: '1.4'
    },
    fontSize: 16,
    h1: {
      fontFamily: "'Grafita', monospace, sans-serif",
      fontSize: '4.5625rem', // 73px
      fontWeight: 'normal'
    },
    title: {
      fontFamily: "'Grafita', monospace, sans-serif",
      fontSize: '4rem', // 64px
      fontWeight: 'normal',
      lineHeight: 1
    },
    h2: {
      fontFamily: "'Rubik', sans-serif",
      fontSize: '3.375rem', // 54px
      fontWeight: 500
    },
    h3: {
      fontFamily: "'Grafita', monospace, sans-serif",
      fontSize: '3rem', // 48px
      fontWeight: 'normal'
    },
    h4: {
      fontFamily: "'Rubik', sans-serif",
      fontSize: '2.75rem', // 44px
      fontWeight: 400
    },
    h5: {
      fontFamily: "'Grafita', monospace, sans-serif",
      fontSize: '2rem', // 32px
      fontWeight: 'normal'
    },
    h6: {
      fontFamily: "'Grafita', monospace, sans-serif",
      fontSize: '1.5rem', // 24px
      fontWeight: '400',
      lineHeight: 1
    },
    span1: {
      fontFamily: "'Grafita', monospace, sans-serif",
      fontSize: '1.125rem', // 18px
      fontWeight: 'normal',
      lineHeight: 1
    },
    span2: {
      fontFamily: "'Grafita', monospace, sans-serif",
      fontSize: '1rem', // 16px
      fontWeight: 'normal',
      lineHeight: 1
    },
    span14: {
      fontFamily: "'Grafita', monospace, sans-serif",
      fontSize: '0.875rem', // 14px
      fontWeight: 'normal',
      lineHeight: 1
    },
    span3: {
      fontFamily: "'Grafita', monospace, sans-serif",
      fontSize: '0.75rem', // 12px
      fontWeight: '400',
      lineHeight: 1
    },
    infoBig: {
      fontFamily: "'Rubik', sans-serif",
      fontSize: '2rem', // 32px
      fontWeight: 400
    },
    info: {
      fontFamily: "'Rubik', sans-serif",
      fontSize: '1.5rem', // 24px
      fontWeight: 400
    },
    subtitle1: {
      fontFamily: "'Rubik', sans-serif",
      fontSize: '1.25rem', // 20px
      fontWeight: 600
    },
    subtitle2: {
      fontFamily: "'Rubik', sans-serif",
      fontSize: '1.125rem', // 18px
      fontWeight: 400
    },
    body1: {
      fontFamily: "'Rubik', sans-serif",
      fontSize: '1rem', // 16px
      fontWeight: 600
    },
    body2: {
      fontFamily: "'Rubik', sans-serif",
      fontSize: '0.875rem', // 14px
      fontWeight: 500
    },
    caption: {
      fontFamily: "'Rubik', sans-serif",
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
      lineHeight: 1
    },
    overline: {
      fontFamily: "'Rubik', sans-serif",
      fontSize: '0.625rem', // 10px
      fontWeight: 400,
      textTransform: 'capitalize'
    }
  },
  palette: {
    mode: 'light',
    background: {
      default: colors.background.default,
      paper: colors.background.default
    },
    primary: {
      main: colors.accent1
    },
    secondary: {
      main: '#F3F700'
    },
    info: {
      main: '#000'
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: 'rgba(255,255,255, .1)',
          color: '#fff',
          input: {
            height: 'initial',
            fontFamily: "'Grafita', monospace, sans-serif",
            fontSize: '1.5rem', // 24px
            fontWeight: 'normal',
            lineHeight: 1,
            padding: '10px 14px',
            '&::placeholder': {
              color: 'rgba(255,255,255, .3)',
              marginBottom: '3px',
              fontSize: '1.125rem', // 18px
              opacity: 1
            },
            '&:placeholder-shown': {
              marginTop: '-4px',
              height: '32px'
            },
            '&.Mui-disabled': {
              '-webkit-text-fill-color': 'rgba(255,255,255, .3)'
            }
          },
          '&.Mui-focused fieldset': {
            borderColor: 'rgb(38 38 38) !important'
          },
          '&.Mui-disabled': {
            opacity: 0.56
          }
        },
        sizeSmall: {
          input: {
            padding: '17px 26px',
            fontFamily: "'Rubik', sans-serif",
            fontSize: '1.5rem', // 24px
            fontWeight: 400
          },
          '& .MuiSelect-select': {
            padding: '9px 26px',
            fontFamily: "'Rubik', sans-serif",
            fontSize: '1.5rem', // 24px
            fontWeight: 400,
            lineHeight: 1,
            marginTop: '10px'
          },
          '& .MuiSelect-icon': {
            color: '#F3F700'
          }
        },
        colorSecondary: {
          background: 'rgba(0,0,0, .05)',
          color: 'black',
          '&.Mui-disabled': {
            background: 'white',
            input: {
              '-webkit-text-fill-color': 'rgb(0 0 0 / .5)'
            },
            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.26) !important'
            }
          },
          input: {
            padding: '19px 25px',
            fontFamily: "'Rubik', sans-serif",
            fontSize: '1.125rem', // 18px
            fontWeight: 400,
            color: 'black',
            '&.MuiAutocomplete-input': {
              padding: '10px 4px 10px 15px !important'
            },
            '&::placeholder': {
              color: 'rgb(0 0 0 / .5)',
              marginBottom: '0 !important',
              fontSize: '1.125rem' // 18px
            },
            '&:placeholder-shown': {
              marginTop: '0 !important',
              height: '22px'
            }
          },
          '& .MuiSelect-select': {
            color: 'black',
            padding: '12px 24px',
            fontFamily: "'Rubik', sans-serif",
            fontSize: '1.125rem', // 24px
            fontWeight: 400,
            lineHeight: 1
          },
          '& .MuiSelect-icon': {
            color: 'black'
          },
          '&.MuiInputBase-adornedEnd': {
            '& svg': {
              fontSize: '24px'
            }
          },
          textarea: {
            padding: '2px 10px',
            fontFamily: "'Rubik', sans-serif",
            fontSize: '1.125rem', // 18px
            fontWeight: 400,
            color: 'black'
          },
          fieldset: {
            borderColor: 'transparent'
          },
          '&:hover fieldset': {
            borderColor: 'rgb(0 0 0 / .5) !important'
          },
          '&.Mui-focused fieldset': {
            borderColor: 'rgb(0 0 0 / .5) !important'
          },
          '&.MuiInputBase-sizeSmall': {
            '&:hover fieldset': {
              borderColor: 'rgb(0 0 0 / .5)'
            },
            input: {
              padding: '21px 23px',
              fontFamily: "'Rubik', sans-serif",
              fontSize: '2rem', // 32px
              fontWeight: 400,
              color: 'black',
              '&::placeholder': {
                marginBottom: 0,
                fontSize: '24px'
              },
              '&:placeholder-shown': {
                marginTop: 0,
                height: '38px'
              }
            },
            fieldset: {
              borderColor: 'transparent'
            },
            '&.Mui-focused fieldset': {
              borderColor: 'rgb(0 0 0 / .5) !important'
            }
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(255,255,255, .5)',
          fontFamily: "'Grafita', monospace, sans-serif",
          fontSize: '0.75rem', // 12px
          fontWeight: '400',
          lineHeight: 1,
          '&.Mui-focused': {
            color: 'rgba(255,255,255, .5)',
            fontFamily: "'Grafita', monospace, sans-serif"
          },
          '&.MuiFormLabel-colorSecondary': {
            color: 'rgba(0, 0, 0, 0.87)'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.MuiTableCell-head': {
            paddingBottom: '2px'
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          input: {
            fontFamily: "'Grafita', monospace, sans-serif",
            fontSize: '0.75rem', // 12px
            fontWeight: '400',
            lineHeight: 1,
            color: 'white'
          },
          '&::before': {
            borderBottom: '1px solid rgba(255,255,255, .5) !important'
          },
          '&::after': {
            borderBottom: '1px solid rgba(255,255,255, .5) !important'
          },
          '& .MuiSelect-select': {
            fontFamily: "'Grafita', monospace, sans-serif",
            fontSize: '0.75rem', // 12px
            fontWeight: '400',
            lineHeight: 1,
            color: 'white'
          },
          '& .MuiSelect-icon': {
            color: '#F3F700'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ':disabled': {
            color: 'inherit',
            opacity: 0.26
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontWeight: 400,
          backgroundColor: '#f4f4f4'
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        light: {
          backgroundColor: 'rgb(255 255 255 / 20%)'
        }
      }
    }
  },
  shape: {
    borderRadius: 12
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1100,
      xl: 1340
    }
  }
});

theme.typography.title = {
  ...theme.typography.title,
  [theme.breakpoints.down('md')]: {
    fontSize: '3.125rem'
  }
};

export default responsiveFontSizes(theme, { factor: 2 });

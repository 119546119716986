const styles = {
  campaign: {
    cursor: 'pointer',
    maxWidth: '638px',
    position: 'relative',
    paddingTop: '44%',
    height: 0,
    background: 'rgb(25, 26, 28)',
    borderRadius: '45px',
    '&:before': {
      display: 'block',
      content: '""',
      width: '100%'
    },
    '& > img': {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
      borderRadius: '40px',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    '@media screen and (max-width: 460px)': {
      paddingTop: '102.895%'
    }
  },
  item: {
    zIndex: 1,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    overflow: 'hidden',
    mb: '38px',
    '&:nth-of-type(2n + 1)': {
      gridColumn: '1 / 7'
    },
    '&:nth-of-type(2n + 2)': {
      gridColumn: '7 / 13'
    },
    '@media screen and (max-width: 800px)': {
      '& > div:first-of-type': {
        maxWidth: '731px'
      },
      '&:nth-of-type(1n + 1)': {
        gridColumn: '1 / 13'
      }
    }
  },
  itemInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    mt: 2.25,
    p: '0 24px'
  }
};

export default styles;

const styles = {
  container: {
    background: '#232323',
    minHeight: '100vh'
  },
  containerInner: {
    background: 'linear-gradient(180deg, #000000 2.65%, rgba(0, 0, 0, 0) 70%)',
    minHeight: '100vh',
    color: 'white'
  }
};

export default styles;

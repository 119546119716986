import { FC, useContext } from 'react';
import Box from '@mui/material/Box';
import styles from './styles';
import { CampaignContext } from '../../context';
import getCloudImgUrl from '@/utils/getUrlImage';

const Logo: FC = () => {
  const { src } = useContext(CampaignContext);

  return <Box sx={{ ...styles.logo, backgroundImage: `url(${getCloudImgUrl(src, 500)})` }} />;
};

export default Logo;

import { FC, useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { toast } from 'react-toastify';
import styles from './styles';
import { IRemixModel, RemixStatusEnum, SmartContractTypeEnum } from '@/graphql/types/_server';
import getErrorMessage from '@/utils/getErrorMessage';
import Layout from '../../shared/Layout/Layout';
import { CampaignContext } from '../../context';
import Remix from './Remix/Remix';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import { ReactComponent as WinSvg } from '@/files/icons/win.svg';
import { Context } from '@/helpers/context';
import WinnerRemix from './WinnerRemix/WinnerRemix';
import Logo from '../../shared/Logo/Logo';
import { getCountVotes } from '@/utils/contractRequests';
import pluralize from '@/utils/pluralize';
import EthersSimple from '@/utils/ethers';

const Winner: FC = () => {
  const { campaign, setIsStaked, setIsShowMenu } = useContext(CampaignContext);
  const { wallet, contracts } = useContext(Context);

  const [winRemixes, setWinRemixes] = useState<(IRemixModel & { vote: number })[]>([]);
  const [otherRemixes, setOtherRemixes] = useState<(IRemixModel & { vote: number })[]>([]);

  useEffect(() => {
    setIsStaked(true);
    setIsShowMenu(true);
  }, []);

  useEffect(() => {
    if (!contracts.loading && campaign?.remixes?.length) {
      getTuneBalance();
    }
  }, [contracts.loading, campaign?.remixes]);

  useEffect(() => {
    if (campaign?.remixes?.length) {
      setWinRemixes(getRemixes([RemixStatusEnum.Store, RemixStatusEnum.Check]));
      setOtherRemixes(getRemixes([RemixStatusEnum.Canceled]));
    }
  }, [campaign?.remixes]);

  if (contracts.loading) {
    return <BackdropLoading />;
  }

  return (
    <Layout leftCard={<Logo />}>
      <Box sx={styles.containerTop}>
        <Typography variant="span3" component="p" mb={2}>
          Sponsored by {campaign?.sponsor?.name}
        </Typography>
        <Typography variant="title" component="p" mb={3}>
          {campaign?.name}
        </Typography>
        <Typography variant="body2" fontWeight={300} component="p" lineHeight="175%">
          {campaign?.description}
        </Typography>
      </Box>
      <Box sx={styles.container}>
        <Box sx={styles.containerWinners}>
          <Box sx={styles.containerTitle}>
            <WinSvg />
            <Typography variant="h5" mt={1.25}>
              {pluralize(winRemixes.length, 'Winner')}
            </Typography>
            <Typography variant="span3" component="p" mt={0.5} mb={2.75}>
              Following remixes will be released
            </Typography>
          </Box>

          <Box sx={styles.containerScroll}>
            {winRemixes.length ? (
              winRemixes.map((remix, index) => (
                <WinnerRemix
                  key={`${remix.id}_winnerRemix`}
                  remix={remix}
                  isLast={winRemixes.length === index + 1}
                />
              ))
            ) : (
              <Typography variant="span1" component="p" lineHeight={1} pb={2.5} pl={2.375}>
                No remixes yet.
              </Typography>
            )}
          </Box>
        </Box>

        <Box sx={styles.containerRemixes}>
          <Typography variant="span3" component="p" mb={1.75} ml={2.5}>
            Other Participants
          </Typography>
          <Box sx={styles.containerScroll}>
            {otherRemixes.length ? (
              otherRemixes.map((remix) => <Remix key={`${remix.id}_remix`} remix={remix} />)
            ) : (
              <Typography variant="span1" component="p" lineHeight={1} pb={2.5} pl={2.375}>
                No remixes yet.
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Layout>
  );

  async function getTuneBalance() {
    try {
      if (!wallet.ethers) return;
      const balances = await getCountVotes(
        wallet.ethers,
        contracts.contracts[SmartContractTypeEnum.Campaign],
        {
          tokenID: campaign.tokenID
        }
      );

      const mapping: Record<string, string | number>[] = balances[0].map(
        (item: bigint, index: number) => {
          return {
            id: item.toString(),
            sum: Number(EthersSimple.ethers.utils.formatUnits(balances[1][index], 18))
          };
        }
      );

      setWinRemixes(getRemixes([RemixStatusEnum.Store, RemixStatusEnum.Check], mapping));
      setOtherRemixes(getRemixes([RemixStatusEnum.Canceled], mapping));
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  }

  function getRemixes(
    statuses: RemixStatusEnum[],
    balances: Record<string, string | number>[] = []
  ) {
    return (
      campaign?.remixes
        ?.filter((remix: IRemixModel) => statuses.includes(remix.status))
        .map((remix) => ({
          ...remix,
          vote: Number(balances.find((item) => item.id === remix?.tokenID)?.sum) || 0
        }))
        .sort((a, b) => b.vote - a.vote) || []
    );
  }
};

export default Winner;

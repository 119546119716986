import { FC, useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { SmartContractTypeEnum } from '@/graphql/types/_server';
import { Context } from '@/helpers/context';
import getErrorMessage from '@/utils/getErrorMessage';
import Loader from '@/components/Campaigns/Campaign/shared/Loader/Loader';
import styles from './styles';
import { CampaignContext } from '@/components/Campaigns/Campaign/context';
import { addRemixForVote } from '@/utils/contractRequests';
import { ReactComponent as SpentNft } from '@/files/icons/spentNft.svg';
import { IProps } from './types';
import getCloudImgUrl from '@/utils/getUrlImage';
import { checkBalanceMatic } from '@/utils/checkBalanceToken';

const StepConfirm: FC<IProps> = ({ setStep, remix, setDataStem }) => {
  const { src, campaign, stemId, setIsShowMenu } = useContext(CampaignContext);

  const { wallet, contracts } = useContext(Context);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsShowMenu(false);
  }, []);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.form}>
        {loading && <Loader />}
        <CloseIcon onClick={() => setStep('step2')} sx={styles.icon} />
        <Box sx={styles.stem} style={{ backgroundImage: `url(${getCloudImgUrl(src, 120)})` }}>
          <SpentNft />
        </Box>
        <Typography variant="h5" mt={3.25}>
          Stem NFT will be spent
        </Typography>
        <Typography variant="body2" sx={styles.text}>
          In order to continue uploading your remix you need to spend the Stem NFT
        </Typography>
        <Box sx={styles.containerButton}>
          <Button
            disabled={loading}
            fullWidth
            onClick={() => setStep('step2')}
            variant="outlined"
            disableElevation
            sx={styles.buttonOutlined}
          >
            <Typography variant="body2">Cancel</Typography>
          </Button>
          <Button
            disabled={loading}
            fullWidth
            onClick={spendStem}
            color="secondary"
            variant="contained"
            disableElevation
            sx={styles.button}
          >
            <Typography variant="body2">Confirm</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );

  async function spendStem() {
    try {
      if (!wallet.ethers) return;
      await checkBalanceMatic(wallet);
      setLoading(true);
      let loadingLocal = true;

      const successCallback = () => {
        if (loadingLocal) {
          loadingLocal = false;
          setLoading(false);
          toast.success('Transaction completed. You have successfully upload a Remix');
          setDataStem((dataStem) => ({ ...dataStem, data: { ...dataStem.data, isSpent: true } }));
        }
      };

      await addRemixForVote(
        wallet.ethers,
        contracts.contracts[SmartContractTypeEnum.Campaign],
        {
          tokenID: campaign.tokenID,
          remixID: remix.tokenID,
          stemID: stemId
        },
        {
          Hash: () => {
            toast.info('Waiting for the transaction to execute...');
          },
          Error: () => {
            setLoading(false);
          },
          After: successCallback
        }
      );

      successCallback();
    } catch (error) {
      toast.error(getErrorMessage(error));
      setLoading(false);
    }
  }
};

export default StepConfirm;

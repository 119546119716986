const styles = {
  gird: {
    mb: '68px',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '14px',
    rowGap: '24px',
    '@media screen and (max-width: 740px)': {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    '@media screen and (max-width: 460px)': {
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  },
  item: {
    maxWidth: '301px',
    minWidth: '200px',
    background: 'rgb(25, 26, 28)',
    backgroundSize: 'cover',
    backgroundPositionX: 'left',
    backgroundPositionY: 'bottom',
    borderRadius: '20px',
    position: 'relative',
    paddingTop: 'min(305px, 90%)',
    cursor: 'pointer',
    '@media screen and (max-width: 1100px)': {
      maxWidth: '338px'
    },
    '@media screen and (max-width: 460px)': {
      maxWidth: 'initial',
      width: '100%'
    }
  },
  itemLocked: {
    outline: '3px solid #F3F700'
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    mb: '32px',
    ml: '20px',
    '& > div:last-child': {
      mt: -0.75
    },
    '@media screen and (max-width: 1100px)': {
      mt: 6
    }
  }
};

export default styles;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, UseFormReturn } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import styles from './styles';
import Layout from '../../../../shared/Layout/Layout';
import { ReactComponent as Play } from '@/files/icons/play.svg';
import LeftCard from '../../LeftCard/LeftCard';
import { IRemixModel } from '@/graphql/types/_server';
import { IFormInputsValidation } from '../../types';
import ModalTune from '@/shared/ModalTune/ModalTune';

const Step1: FC<{
  votePointBalance: number;
  remix: IRemixModel & { vote: number };
  form: UseFormReturn<IFormInputsValidation, any>;
  labelBalance: string;
  onGetStakeAmount: () => Promise<void>;
  setChosedRemix: (remix: number) => void;
  onSubmit: (data: IFormInputsValidation) => Promise<void>;
  loading: boolean;
}> = ({
  votePointBalance,
  remix,
  form,
  labelBalance,
  onGetStakeAmount,
  setChosedRemix,
  onSubmit,
  loading
}) => {
  const [modalTune, setModalTune] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors }
  } = form;

  return (
    <Layout rightStyle={styles.containerRight} leftCard={<LeftCard tune={votePointBalance} />}>
      <CloseIcon onClick={() => setChosedRemix(-1)} sx={styles.icon} />
      <ModalTune
        modalTune={modalTune}
        onModalTune={() => setModalTune(false)}
        balanceOf={labelBalance}
        onGetStakeAmount={onGetStakeAmount}
      />
      <Box sx={styles.container}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Typography variant="span3" component="p" mb={2.75}>
            Submited Remixes
          </Typography>
          <Box sx={styles.containerScroll}>
            <Box key={remix.id} sx={styles.containerOuter}>
              <Box pt={1.75} pb={2} mr={2.5}>
                <Typography
                  variant="body2"
                  fontWeight={600}
                  lineHeight={1}
                  sx={styles.textGreyTransparent}
                >
                  1
                </Typography>
              </Box>
              <Box width="calc(100% - 1.6875rem)">
                <Box sx={styles.containerInner}>
                  <Box sx={styles.containerAudio}>
                    <Play />
                    <Typography
                      variant="body2"
                      fontWeight={400}
                      sx={styles.text}
                      lineHeight={1}
                      mr={1}
                    >
                      {remix.name}
                    </Typography>
                  </Box>
                  <Box sx={styles.vote}>{remix.vote}</Box>
                </Box>
              </Box>
              <Divider light sx={styles.divider} />
            </Box>
          </Box>
          <Box sx={styles.containerForm}>
            <Typography variant="caption" sx={styles.description} component="p">
              Vote for your favorite remix with your TUNE tokens. You can earn them by freezing your
              LABEL tokens
            </Typography>
            <Box sx={styles.form}>
              <Controller
                name="amount"
                control={control}
                render={() => {
                  return (
                    <NumberFormat
                      id="amount"
                      variant="outlined"
                      placeholder="TUNE Amount"
                      customInput={TextField}
                      error={Boolean(errors.amount)}
                      helperText={errors?.amount?.message || errors?.amount?.type || ' '}
                      {...register('amount', {
                        required: 'Required',
                        min: {
                          value: 1,
                          message: 'Minimum 1'
                        },
                        max: {
                          value: votePointBalance,
                          message: 'The number of Tunes should not exceed your balance'
                        }
                      })}
                      suffix=" TUNE"
                      thousandSeparator={false}
                      isNumericString
                      allowNegative={false}
                      decimalScale={0}
                      onValueChange={setAmountHandler}
                    />
                  ) as JSX.Element;
                }}
              />
              <LoadingButton
                type="submit"
                size="large"
                color="secondary"
                variant="contained"
                disableElevation
                loading={loading}
              >
                <Typography variant="body2" lineHeight="1.3125rem">
                  Vote
                </Typography>
              </LoadingButton>
            </Box>
            <Typography variant="caption" sx={styles.textGrey} component="p" lineHeight="1.3125rem">
              Don’t have enough TUNE?{' '}
              <Typography
                variant="caption"
                sx={styles.textYellow}
                onClick={() => setModalTune(true)}
              >
                Get more using your LABEL tokens.
              </Typography>
            </Typography>
          </Box>
        </form>
      </Box>
    </Layout>
  );

  async function setAmountHandler(values: NumberFormatValues) {
    setValue('amount', values.value);
    await trigger('amount');
  }
};

export default Step1;

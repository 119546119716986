import { FC, useEffect, useMemo, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useQuery } from '@apollo/client';
import { Context } from '@/helpers/context';
import { IUserModel, UserTypeEnum } from '@/graphql/types/_server';
import menu from './menu';
import { IS_ADMIN } from '@/graphql/gql/me';
import { IProps, MenuTypeEnum } from './types';
import Authorization from './types/Authorization/Authorization';
import Campaign from './types/Campaign/Campaign';
import Standard from './types/Standard/Standard';

const Header: FC<IProps> = ({ menuType, isConnectWallet = true, isStaked = false }) => {
  const { wallet } = useContext(Context);
  const { data, refetch } = useQuery<{ me: IUserModel }>(IS_ADMIN);
  const type = data?.me?.type;

  const isMobile = useMediaQuery('(max-width:960px)');
  const isSmallMobile = useMediaQuery('(max-width:410px)');

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const isAdmin = useMemo(() => type === UserTypeEnum.Admin, [type]);

  const memoMemu = useMemo(() => {
    return menu(isAdmin && wallet.address !== '');
  }, [menu, isAdmin, wallet.address]);

  useEffect(() => {
    if (isMobile) {
      setOpenMenu(false);
    }
  }, [isMobile, pathname]);

  return (
    <header>
      {(() => {
        switch (menuType) {
          case MenuTypeEnum.Authorization:
            return (
              <Authorization
                isConnectWallet={isConnectWallet}
                refetch={refetch}
                navigate={navigate}
                isMobile={isMobile}
                isSmallMobile={isSmallMobile}
              />
            );
          case MenuTypeEnum.Campaign:
            return (
              <Campaign
                menu={memoMemu}
                isAdmin={isAdmin}
                refetch={refetch}
                navigate={navigate}
                pathname={pathname}
                isMobile={isMobile}
                openMenuHandler={openMenuHandler}
                openMenu={openMenu}
                isSmallMobile={isSmallMobile}
                isStaked={isStaked}
              />
            );
          default:
            return (
              <Standard
                menu={memoMemu}
                isAdmin={isAdmin}
                refetch={refetch}
                navigate={navigate}
                pathname={pathname}
                isMobile={isMobile}
                isSmallMobile={isSmallMobile}
                openMenuHandler={openMenuHandler}
                openMenu={openMenu}
              />
            );
        }
      })()}
    </header>
  );

  function openMenuHandler() {
    setOpenMenu(!openMenu);
  }
};

export default Header;

/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './styles';
import Ticket from './Ticket/Ticket';
import { ReactComponent as DoneSvg } from '@/files/icons/done.svg';
import { IProps } from './types';

const Tickets: FC<IProps> = ({ countDays }) => {
  const tickets = [
    { id: 'ticket_1', days: '7d' },
    { id: 'ticket_2', days: '14d' },
    { id: 'ticket_3', days: '21d' },
    { id: 'ticket_4', days: '28d' }
  ].map((item, index) => {
    const percent = ((countDays - index * 7) / 7) * 100;
    return {
      ...item,
      percent: percent > 100 ? 100 : percent < 0 ? 0 : percent
    };
  });

  return (
    <>
      {tickets.map((ticket) => {
        return (
          <Box key={ticket.id}>
            <Ticket
              id={ticket.id}
              percents={ticket.percent}
              color1="rgb(255 255 255 / 0.3)"
              color2="#F3F700"
            />
            <Box sx={styles.contentTiket}>
              {ticket.percent < 100 ? (
                <Typography
                  variant="overline"
                  sx={{
                    ...styles.text,
                    ...(ticket.percent > 0 && styles.containerText)
                  }}
                >
                  {ticket.days}
                </Typography>
              ) : (
                <DoneSvg />
              )}
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default Tickets;

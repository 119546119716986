const styles = {
  pagination: {
    background: 'white',
    borderRadius: '10px',
    '& li button': {
      minWidth: '10px',
      padding: '0 6px',
      margin: 0,
      borderRadius: 0,
      position: 'relative',
      color: '#232323',
      opacity: 0.5,
      height: '30px',
      lineHeight: 'initial'
    },
    '& li button.Mui-selected': {
      backgroundColor: 'transparent',
      opacity: 1,
      '&::after': {
        position: 'absolute',
        bottom: '2px',
        left: '6px',
        width: 'calc(100% - 12px)',
        height: '4px',
        content: '""',
        backgroundColor: '#F3F700'
      }
    }
  }
};

export default styles;

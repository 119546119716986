const styles = (isFull: boolean) => ({
  description: {
    background: '#F4F4F4',
    minHeight: isFull ? '18.875rem' : '10.625rem',
    borderTopLeftRadius: '2.5rem',
    borderTopRightRadius: '2.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
    padding: '2.6875rem 2.8125rem 1.25rem',
    '& > div': {
      width: '100%'
    },
    '@media screen and (max-width: 900px)': {
      minHeight: isFull ? '15rem' : '9.375rem'
    }
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

export default styles;

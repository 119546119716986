import { FC, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';

import styles from './styles';

const Teaser: FC<{ svg: ReactNode; title: string; description: string }> = ({
  svg,
  title,
  description
}) => {
  return (
    <Box>
      {svg}
      <Typography variant="h6" sx={styles.teaserTitle} mb={3} mt={1}>
        {title}
      </Typography>
      <Typography component="span" variant="caption" sx={styles.teaserDescription}>
        {description}
      </Typography>
    </Box>
  );
};

export default Teaser;

const styles = {
  logo: {
    width: '31.25rem',
    backgroundColor: '#1a1a1a',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '2.5rem',
    marginRight: '0.875rem',
    maxHeight: '30rem',
    '@media screen and (max-width: 850px)': {
      height: '30rem',
      width: '32.875rem',
      marginRight: 0,
      marginBottom: '1rem'
    }
  }
};

export default styles;

const styles = {
  items: {
    width: '100%',
    mt: '0px !important'
  },
  item: {
    overflow: 'hidden',
    position: 'relative',
    padding: '0 32px 16px 32px',
    '& > div': {
      width: '100%',
      height: '324px',
      borderRadius: 1,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative'
    }
  },
  icon: {
    position: 'absolute',
    top: '43px',
    right: '43px',
    mt: '0px !important'
  }
};

export default styles;

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CreateForm from './CreateForm/CreateForm';

const CreateRemix = () => {
  return (
    <Container maxWidth="xl">
      <Box>
        <Box mt={4}>
          <Typography variant="h1">Add Track</Typography>
        </Box>
        <Box mt={4}>
          <CreateForm />
        </Box>
      </Box>
    </Container>
  );
};

export default CreateRemix;

import colors from '@/helpers/theme/colors';

const styles = {
  closeIcon: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    color: colors.common.white,
    width: '48px',
    height: '48px',
    '& > svg': {
      fontSize: '32px'
    }
  }
};

export default styles;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Controller, FieldError, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import isDate from 'lodash/isDate';
import add from 'date-fns/add';
import format from 'date-fns/format';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Autocomplete from '@mui/material/Autocomplete';
import styles from './styles';
import DropZone from '@/shared/DropZone/DropZone';
import { IView } from '@/shared/DropZone/types';
import { IFormInputsValidation, ISponsor } from './types';
import {
  FilterOperationEnum,
  FilterFieldTypeEnum,
  ICampaignModel,
  IMutationCreateCampaignArgs,
  IMutationUploadCampaignStemArgs,
  IUsersModel,
  IQueryUsersArgs,
  SortDirectionEnum
} from '@/graphql/types/_server';
import { USERS } from '@/graphql/gql/users';
import { CREATE_CAMPAIGN, UPLOAD_CAMPAIGN_STEM } from '@/graphql/gql/campaigns';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import getDateWithNulledTime from '@/utils/getDateWithNulledTime';
import { Context } from '@/helpers/context';
import { ReactComponent as DownloadSvg } from '@/files/icons/download.svg';
import isDateValid from '@/utils/isDateValid';
import isDateFuture from '@/utils/isDateFuture';

const CreateForm = () => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    watch,
    formState: { errors, touchedFields }
  } = useForm<IFormInputsValidation>({ mode: 'onBlur' });

  const navigate = useNavigate();
  const { wallet } = useContext(Context);

  const [dateStartCampaign, setDateStartCampaign] = useState<Date | null>(null);

  const { data } = useQuery<{ users: IUsersModel }, IQueryUsersArgs>(USERS, {
    variables: {
      payload: {
        filters: [
          {
            columnName: 'type',
            value: ['sponsor'],
            type: FilterFieldTypeEnum.Text,
            operation: FilterOperationEnum.Equal
          }
        ],
        sorts: [{ columnName: 'id', direction: SortDirectionEnum.Asc }]
      }
    }
  });

  const sponsors = useMemo<ISponsor[]>(() => {
    return (data?.users?.items || []).map((item) => {
      return {
        id: String(item.id),
        label: `${item.id}: ${item.name}`,
        name: item.name
      };
    });
  }, [data]);

  const calcDate = useCallback(
    (days: number) => {
      return dateStartCampaign && isDate(dateStartCampaign) && isDateValid(dateStartCampaign)
        ? format(add(dateStartCampaign, { days }), 'MM/dd/yyyy')
        : '';
    },
    [dateStartCampaign]
  );

  const [sponsorName, setSponsorName] = useState<ISponsor | null>(null);

  const now = useMemo(() => {
    const date = getDateWithNulledTime();
    date.setDate(date.getDate() + 1);
    return date;
  }, []);

  const [createCampaign, { loading }] = useMutation<
    { createCampaign: ICampaignModel },
    IMutationCreateCampaignArgs
  >(CREATE_CAMPAIGN);

  const [uploadCampaignStem, { loading: loadingStem }] = useMutation<
    { uploadCampaignStem: ICampaignModel },
    IMutationUploadCampaignStemArgs
  >(UPLOAD_CAMPAIGN_STEM);

  const stem = getValues('stem');
  const logo = getValues('logo');
  const name = watch('name');

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box sx={styles.campaignWrap}>
          <Box sx={styles.campaignLeft}>
            <Box sx={styles.campaignName}>
              <Box>
                <Typography variant="body2" fontWeight={400} sx={styles.campaignLabel}>
                  Campaign Name
                </Typography>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      color="secondary"
                      size="small"
                      type="name"
                      placeholder="Name"
                      fullWidth
                      id="name"
                      error={touchedFields.name && Boolean(errors.name)}
                      helperText={errors?.name?.message || errors?.name?.type || ''}
                      {...register('name', {
                        required: 'Required',
                        maxLength: {
                          value: 100,
                          message: 'Max length must be 100 symbols'
                        }
                      })}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box mt={4.125}>
                <Typography variant="body2" fontWeight={400} sx={styles.campaignLabel}>
                  Sponsor
                </Typography>
                <Controller
                  name="sponsorId"
                  control={control}
                  defaultValue=""
                  render={() => (
                    <Autocomplete
                      disablePortal
                      id="sponsorId"
                      options={sponsors}
                      value={sponsorName}
                      fullWidth
                      onChange={(_, newValue) => {
                        setSponsorId(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          color="secondary"
                          placeholder="Select"
                          error={touchedFields.sponsorId && Boolean(errors.sponsorId)}
                          helperText={errors?.sponsorId?.message || errors?.sponsorId?.type || ''}
                          {...register('sponsorId', {
                            required: 'Required'
                          })}
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Box>
              <Box mt={2.75}>
                <Typography variant="body2" fontWeight={400} mb={0.5}>
                  Description
                </Typography>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      color="secondary"
                      type="description"
                      id="description"
                      placeholder="New release..."
                      fullWidth
                      error={touchedFields.description && Boolean(errors.description)}
                      helperText={errors?.description?.message || errors?.description?.type || ''}
                      multiline
                      rows={6}
                      {...register('description', {
                        required: 'Required',
                        maxLength: {
                          value: 3000,
                          message: 'Max length must be 3000 symbols'
                        }
                      })}
                      {...field}
                    />
                  )}
                />
              </Box>
              <Box sx={styles.container} mt={2.75}>
                <Box>
                  <Typography variant="body2" fontWeight={400} mb={0.5}>
                    Campaign Start Date
                  </Typography>
                  <Controller
                    name="dateStartCampaign"
                    control={control}
                    defaultValue={null}
                    render={({ field }) => (
                      <DesktopDatePicker
                        inputFormat="MM/dd/yyyy"
                        minDate={now}
                        renderInput={(params) => (
                          <TextField
                            color="secondary"
                            id="dateStartCampaign"
                            type="dateStartCampaign"
                            sx={{
                              ...styles.datePicker,
                              ...(errors.dateStartCampaign && styles.errorDatePicker)
                            }}
                            error={
                              touchedFields.dateStartCampaign && Boolean(errors.dateStartCampaign)
                            }
                            {...register('dateStartCampaign', {
                              required: 'Required',
                              validate: {
                                isDateValid: (value) => isDateValid(value) || 'Date is incorrect',
                                isDateFuture: (value) =>
                                  isDateFuture(value) || 'Date should be no less than tomorrow'
                              }
                            })}
                            helperText={
                              errors?.dateStartCampaign?.message ||
                              errors?.dateStartCampaign?.type ||
                              ''
                            }
                            {...params}
                          />
                        )}
                        {...field}
                        onChange={handleDate}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
            <Box sx={styles.campaignDate}>
              <Box sx={styles.container} pb={3.875} pt={0.875}>
                <Box>
                  <Typography variant="body2" fontWeight={400} mb={0.5}>
                    Lottery Start Date
                  </Typography>
                  <TextField
                    color="secondary"
                    type="text"
                    fullWidth
                    placeholder="mm/dd/yyyy"
                    value={calcDate(0)}
                    onChange={() => {}}
                    disabled
                  />
                </Box>
                <Box sx={{ ...styles.container, ...styles.innerGridGap }}>
                  <Box>
                    <Typography variant="body2" fontWeight={400} mb={0.5}>
                      Lottery Winners
                    </Typography>
                    <Controller
                      name="numberWinnersLottery"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          color="secondary"
                          type="text"
                          fullWidth
                          placeholder="50 - 100"
                          id="numberWinnersLottery"
                          error={
                            touchedFields.numberWinnersLottery &&
                            Boolean(errors.numberWinnersLottery)
                          }
                          helperText={
                            errors?.numberWinnersLottery?.message ||
                            errors?.numberWinnersLottery?.type ||
                            ''
                          }
                          {...register('numberWinnersLottery', {
                            required: 'Required',
                            max: {
                              value: 100,
                              message: 'Value must be between 50 and 100'
                            },
                            min: {
                              value: ['local', 'dev'].includes(process.env.REACT_APP_ENV as string)
                                ? 1
                                : 50,
                              message: `Value must be between ${
                                ['local', 'dev'].includes(process.env.REACT_APP_ENV as string)
                                  ? 1
                                  : 50
                              } and 100`
                            },
                            validate: (value) =>
                              isFinite(Number(value)) || 'You can only enter numbers in this field'
                          })}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={styles.container} pt={3.75} pb={2}>
                <Box>
                  <Typography variant="body2" fontWeight={400} mb={0.5}>
                    Remix
                  </Typography>
                  <TextField
                    color="secondary"
                    type="text"
                    fullWidth
                    placeholder="mm/dd/yyyy"
                    value={calcDate(30)}
                    onChange={() => {}}
                    disabled
                  />
                </Box>
                <Box sx={{ ...styles.container, ...styles.innerGridGap }}>
                  <Box>
                    <Typography variant="body2" fontWeight={400} mb={0.5}>
                      Remix Winners
                    </Typography>
                    <Controller
                      name="numberWinnersVoting"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          color="secondary"
                          type="text"
                          fullWidth
                          placeholder="1 - 10"
                          id="numberWinnersVoting"
                          error={
                            touchedFields.numberWinnersVoting && Boolean(errors.numberWinnersVoting)
                          }
                          helperText={
                            errors?.numberWinnersVoting?.message ||
                            errors?.numberWinnersVoting?.type ||
                            ''
                          }
                          {...register('numberWinnersVoting', {
                            required: 'Required',
                            max: {
                              value: 10,
                              message: 'Value must be between 1 and 10'
                            },
                            min: {
                              value: 1,
                              message: 'Value must be between 1 and 10'
                            },
                            validate: (value) =>
                              isFinite(Number(value)) || 'You can only enter numbers in this field'
                          })}
                          {...field}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={styles.numberFormat}>
                    <Typography variant="body2" fontWeight={400} mb={0.5}>
                      Track Cost
                    </Typography>
                    <Controller
                      name="price"
                      control={control}
                      render={({ field: { value, ref } }) => {
                        return (
                          <NumberFormat
                            id="price"
                            color="secondary"
                            placeholder="0 USDC"
                            value={value}
                            customInput={TextField}
                            disabled={loading}
                            error={Boolean(errors.price)}
                            helperText={errors?.price?.message || errors?.price?.type || ' '}
                            {...register('price', {
                              required: 'Required',
                              min: {
                                value: 1,
                                message: '1 USDC minimum'
                              }
                            })}
                            suffix=" USDC"
                            thousandSeparator={false}
                            isNumericString
                            allowNegative={false}
                            decimalScale={0}
                            onValueChange={setPriceHandler}
                            ref={ref}
                          />
                        ) as JSX.Element;
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box sx={styles.container} pt={3.75}>
                <Box>
                  <Typography variant="body2" fontWeight={400} mb={0.5}>
                    Voting
                  </Typography>
                  <TextField
                    color="secondary"
                    type="text"
                    fullWidth
                    placeholder="mm/dd/yyyy"
                    value={calcDate(44)}
                    onChange={() => {}}
                    disabled
                  />
                </Box>
                <Box>
                  <Typography variant="body2" fontWeight={400} mb={0.5}>
                    Winner Selection
                  </Typography>
                  <TextField
                    color="secondary"
                    type="text"
                    fullWidth
                    placeholder="mm/dd/yyyy"
                    value={calcDate(51)}
                    onChange={() => {}}
                    disabled
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Button
                sx={styles.btn}
                fullWidth
                color="secondary"
                variant="contained"
                type="submit"
                disableElevation
              >
                <Typography variant="subtitle2" fontWeight={500}>
                  Create Campaign
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box sx={styles.campaignRight}>
            <Box mb={4.25}>
              <Controller
                name="logo"
                control={control}
                defaultValue={undefined}
                rules={{ required: 'Required' }}
                render={() => (
                  <DropZone
                    cropImage
                    files={getValues('logo') || []}
                    handleFilesAdd={handleLogoAdd}
                    handleFileDelete={handleLogoDelete}
                    multiple={false}
                    maxFiles={1}
                    maxSize={2000000}
                    variant="black"
                    view={IView.Logo}
                    content={
                      <Box sx={styles.containerPreview}>
                        <Box style={{ color: getValues('color') }}>
                          <Typography variant="span3" component="p">
                            {`Sponsored by ${sponsorName?.name || ''}`}
                          </Typography>
                          <Typography variant="h6" mt={0.75}>
                            {name}
                          </Typography>
                        </Box>
                        <Box sx={styles.chip}>
                          <Typography variant="caption" component="p" fontWeight={500}>
                            Draft
                          </Typography>
                        </Box>
                      </Box>
                    }
                    label={
                      <Box sx={styles.dropZoneLogo}>
                        <Box sx={styles.containerTitleLogo}>
                          <Typography variant="subtitle2" textAlign="start">
                            Campaign Cover
                          </Typography>
                          <Controller
                            name="color"
                            control={control}
                            defaultValue="white"
                            render={({ field: { value, ref } }) => {
                              return (
                                <Select
                                  variant="standard"
                                  sx={styles.select}
                                  value={value}
                                  style={{ visibility: logo?.length ? 'visible' : 'hidden' }}
                                  error={Boolean(errors.color)}
                                  onChange={setColorHandler}
                                  ref={ref}
                                >
                                  <MenuItem value="white">White text</MenuItem>
                                  <MenuItem value="black">Black text</MenuItem>
                                </Select>
                              ) as JSX.Element;
                            }}
                          />
                        </Box>
                        {!logo?.length && (
                          <Box mt={10.5} mb={4.25}>
                            <DownloadSvg />
                            <Typography variant="subtitle2" mt={1.25} lineHeight={1}>
                              Drop avatar image here
                            </Typography>
                            <Typography variant="caption" sx={styles.textGrey} lineHeight={1}>
                              We accept only JPG or PNG files
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    }
                    error={Boolean(errors.logo)}
                    helperText={
                      (errors?.logo as unknown as FieldError)?.message ||
                      (errors?.logo as unknown as FieldError)?.type ||
                      ''
                    }
                    accept={['image/jpeg', 'image/png']}
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                name="stem"
                control={control}
                defaultValue={undefined}
                rules={{ required: 'Required' }}
                render={() => (
                  <DropZone
                    files={getValues('stem') || []}
                    handleFilesAdd={handleStemAdd}
                    handleFileDelete={handleStemDelete}
                    multiple={false}
                    maxFiles={1}
                    maxSize={100000000}
                    variant="yellow"
                    view={IView.Archive}
                    label={
                      <Box sx={styles.dropZoneStem}>
                        <Box sx={styles.containerTitle}>
                          <Typography variant="subtitle2" textAlign="start">
                            Stem File
                          </Typography>
                        </Box>
                        {!stem?.length && (
                          <Box sx={styles.containerText}>
                            <DownloadSvg />
                            <Typography variant="subtitle2" mt={1.3} lineHeight={1}>
                              Drop stem file here
                            </Typography>
                            <Typography variant="caption" sx={styles.textGrey} component="p" mt={1}>
                              Only ZIP files are accepted here
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    }
                    error={Boolean(errors.stem)}
                    helperText={
                      (errors?.stem as unknown as FieldError)?.message ||
                      (errors?.stem as unknown as FieldError)?.type ||
                      ''
                    }
                    accept={['application/zip', 'application/x-zip-compressed', 'multipart/x-zip']}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </form>
      {(loading || loadingStem) && <BackdropLoading />}
    </>
  );

  async function onSubmit(data: IFormInputsValidation) {
    try {
      if (!data.dateStartCampaign) {
        throw new Error('Campaign start date missing');
      }

      const result = await createCampaign({
        variables: {
          chainId: Number(wallet?.ethers?.getChainId()) || 137,
          logo: data.logo[0],
          payload: {
            name: data.name,
            description: data.description,
            // sponsorId: Number(data.sponsorId),
            sponsorId: Number(sponsorName?.id), // TODO: использовать объект data
            startLotteryDate: format(data.dateStartCampaign, 'yyyy-MM-dd'),
            numberWinnersLottery: Number(data.numberWinnersLottery),
            numberWinnersVoting: Number(data.numberWinnersVoting),
            price: Number(data.price),
            isWhiteName: data.color === 'white'
          }
        }
      });

      if (!result.data) {
        throw new Error('Campaign not created');
      }

      toast.success('Campaign Created');

      await uploadCampaignStem({
        variables: {
          stem: data.stem[0],
          payload: {
            id: result.data.createCampaign.id
          }
        }
      });

      toast.success('Stem Created');

      navigate('/drafts');
    } catch (err) {
      const error = err as Error;
      toast.error(error?.message);
    }
  }

  async function setPriceHandler(values: NumberFormatValues) {
    setValue('price', values.value);
    await trigger('price');
  }

  async function setColorHandler(event: SelectChangeEvent) {
    setValue('color', event.target.value);
    await trigger('color');
  }

  async function handleDate(date: Date | null) {
    setDateStartCampaign(date);
    setValue('dateStartCampaign', date);
    await trigger('dateStartCampaign');
  }

  async function handleStemAdd(acceptedFiles: File[]) {
    setValue('stem', [...(getValues('stem') || []), ...acceptedFiles]);
    await trigger('stem');
  }

  async function handleStemDelete() {
    setValue('stem', []);
    await trigger('stem');
  }

  async function handleLogoAdd(acceptedFiles: File[]) {
    setValue('logo', [...(getValues('logo') || []), ...acceptedFiles]);
    await trigger('logo');
  }

  async function setSponsorId(sponsor: ISponsor | null) {
    setSponsorName(sponsor);
    setValue('sponsorId', sponsor?.id || '');
    await trigger('sponsorId');
  }

  async function handleLogoDelete(file: File) {
    setValue(
      'logo',
      (getValues('logo') || []).filter((item) => item !== file)
    );
    await trigger('logo');
  }
};

export default CreateForm;

import { FC } from 'react';
import Box from '@mui/material/Box';
import { Controller, useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { IFormInputsValidation } from './types';
import styles from './styles';
import { GET_VERIFICATION_EMAIL, UPDATE_USER } from '@/graphql/gql/users';
import { IMutationUpdateUserArgs, IUserModel } from '@/graphql/types/_server';
import getBeautyErrorMessage from '@/utils/getBeautyErrorMessage';

const Step2: FC<{ me: IUserModel | undefined; setStep: (step: number) => void }> = ({
  me,
  setStep
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, touchedFields }
  } = useForm<IFormInputsValidation>({ mode: 'onTouched' });

  const [updateUser, { loading: loadingUpdateMessage }] = useMutation<
    { updateUser: IUserModel },
    IMutationUpdateUserArgs
  >(UPDATE_USER);

  const [getVerificationEmail, { loading: loadingCodeSent }] = useMutation<{
    getVerificationEmailCode: boolean;
  }>(GET_VERIFICATION_EMAIL);

  if (!me?.id) {
    return null;
  }

  return (
    <Box sx={styles.container}>
      <Typography variant="h3">Please Identify Yourself</Typography>
      <Typography variant="subtitle2" sx={styles.text}>
        In order to continue Label platform requires you to pass verification
      </Typography>
      <Box sx={styles.form}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box>
            <Typography variant="body2" fontWeight={400} sx={styles.label}>
              Username
            </Typography>
            <Controller
              name="userName"
              control={control}
              defaultValue={me?.userName || ''}
              render={({ field }) => (
                <TextField
                  color="secondary"
                  type="text"
                  fullWidth
                  placeholder="Username"
                  id="userName"
                  error={touchedFields.userName && Boolean(errors.userName)}
                  helperText={errors?.userName?.message || errors?.userName?.type || ''}
                  {...register('userName', {
                    required: 'Required',
                    minLength: {
                      value: 3,
                      message: 'The username must be at least 3 characters'
                    },
                    maxLength: {
                      value: 30,
                      message: 'The username should not be more than 30 characters'
                    },
                    pattern: {
                      value: /^\S*$/,
                      message: 'No spaces allowed'
                    }
                  })}
                  {...field}
                />
              )}
            />
          </Box>
          <Box mt={4.1}>
            <Typography variant="body2" fontWeight={400} sx={styles.label}>
              Email
            </Typography>
            <Controller
              name="email"
              control={control}
              defaultValue={me?.email || ''}
              render={({ field }) => (
                <TextField
                  color="secondary"
                  type="email"
                  fullWidth
                  placeholder="Email"
                  id="email"
                  error={touchedFields.email && Boolean(errors.email)}
                  helperText={errors?.email?.message || errors?.email?.type || ''}
                  {...register('email', {
                    required: 'Required',
                    minLength: {
                      value: 3,
                      message: 'The e-mail must be at least 3 characters'
                    },
                    maxLength: {
                      value: 50,
                      message: 'The e-mail should not be more than 50 characters'
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Incorrect e-mail address'
                    }
                  })}
                  {...field}
                />
              )}
            />
          </Box>
          <Box mt={2} sx={styles.btnWrap}>
            <LoadingButton
              fullWidth
              color="secondary"
              variant="contained"
              type="submit"
              disableElevation
              sx={styles.btn}
              loading={loadingCodeSent || loadingUpdateMessage}
            >
              <Typography variant="body2">Confirm and Continue</Typography>
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </Box>
  );

  async function onSubmit(data: IFormInputsValidation) {
    if (!me?.id) {
      throw new Error('User not found');
    }
    try {
      const payload = {
        id: me.id,
        ...(me?.userName !== data.userName && { userName: data.userName }),
        ...(me?.email !== data.email && { email: data.email })
      };
      const response = await updateUser({
        variables: {
          payload
        }
      });
      if (response) {
        toast.success('User updated');
      }
      // if (me.email !== data.email) {
      await getCodeHandler();
      // }
      setStep(3);
    } catch (err) {
      const error = err as Error;
      toast.error(getBeautyErrorMessage(error?.message));
    }
  }

  async function getCodeHandler() {
    try {
      const response = await getVerificationEmail();
      if (response?.data?.getVerificationEmailCode) {
        toast.success('Code sent');
      } else {
        toast.error('Code not sent');
      }
    } catch (err) {
      const error = err as Error;
      toast.error(error?.message);
    }
  }
};

export default Step2;

const styles = (logoUrl: string) => ({
  logo: {
    width: '100%',
    paddingTop: '102.5%',
    mr: '14px',
    position: 'relative',
    cursor: 'pointer',
    '&::before': {
      transition: 'all 0.3s',
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '40px',
      background: 'rgb(25, 26, 28)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: `url(${logoUrl})`
    },
    '&:hover::before': {
      filter: 'brightness(80%)'
    },
    '&:hover > div:nth-child(1) > a > div': {
      transition: 'width 0.5s linear',
      background: 'black',
      width: '100%'
    },
    '&:hover > div:nth-child(1) p': {
      color: 'white',
      opacity: 1,
      transition: 'opacity 0.5s, color 0.5s'
    },
    '&:hover > div:nth-child(2)': {
      opacity: 1,
      transform: 'translate(-50%, -50%) scale(0.99)'
    },
    '&:hover > div:nth-child(3)': {
      opacity: 0
    }
  },
  amount: {
    background: 'black',
    color: 'white',
    minWidth: '72px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '30px',
    px: 1.5,
    transition: 'opacity 0.5s',
    position: 'absolute',
    right: '17px',
    bottom: '15px'
  },
  logoSponsor: {
    minWidth: '48px',
    height: '48px',
    background: 'black',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#f4f4f4',
    cursor: 'pointer',
    transition: 'all 0.3s'
  },
  containerLogo: {
    display: 'flex',
    alignItems: 'center',
    transition: 'width 0.4s linear, background 0.1s ease 0.5s',
    width: '48px',
    background: 'transparent',
    borderRadius: '24px',
    maxWidth: 'max-content',
    position: 'absolute',
    left: '17px',
    bottom: '15px',
    '&:hover + div': {
      background: 'white'
    }
  },
  linkLogo: {
    '&:hover + div': {
      background: 'white'
    },
    '&:hover p': {
      color: '#F3F700 !important'
    }
  },
  textSponsor: {
    ml: 0.5,
    color: 'transparent',
    whiteSpace: 'nowrap',
    transition: 'opacity 0.5s, color 0.5s',
    opacity: 0,
    pr: 2.5
  },
  audio: {
    background: '#F3F700',
    borderRadius: '50%',
    width: '88px',
    height: '88px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'transform 0.5s, opacity 0.5s, background 0.3s',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0.3)',
    opacity: 0
  },
  stop: {
    background: 'white'
  },
  text: {
    transition: 'color 0.3s',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      opacity: 0.76
    },
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  },
  link: {
    transition: 'none',
    borderBottom: 'none'
  }
});

export default styles;

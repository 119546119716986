import colors from '@/helpers/theme/colors';

const styles = {
  about: {
    height: '440px',
    position: 'relative',
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat',
    backgroundImage: 'url(/img/aboutBG.jpg)'
  },
  aboutLabel: {
    background: colors.common.yellow,
    width: '422px',
    height: '470px',
    borderRadius: '0px 40px 0px 0px',
    position: 'absolute',
    left: 0,
    top: '-30px',
    transform: 'translate3d(0px, 470px, 0px)',
    '@media screen and (max-width: 1020px)': {
      width: '302px'
    },
    '@media screen and (max-width: 850px)': {
      width: '202px'
    },
    '@media screen and (max-width: 750px)': {
      position: 'static',
      height: '200px',
      width: '100%',
      mt: -2.5
    }
  },
  aboutLabelAnimation: {
    animationName: 'showAboutLabel',
    animationDuration: '1.5s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease',
    animationIterationCount: 1,
    animationDelay: '0',
    '@keyframes showAboutLabel': {
      '0%': {
        transform: 'translate3d(0px, 470px, 0px)'
      },
      '100%': {
        transform: 'translate3d(0px, 0px, 0px)'
      }
    }
  },
  aboutLabelIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '140px',
    height: '140px',
    backgroundColor: colors.iconColor,
    ml: 6.25,
    borderRadius: '0px 0px 10px 10px',
    '& > svg > path': {
      fill: colors.common.yellow
    },
    '@media screen and (max-width: 850px)': {
      ml: 2
    }
  },
  aboutText: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 0,
    bottom: '5.875rem',
    '@media screen and (max-width: 750px)': {
      bottom: 'auto',
      top: '0.75rem',
      right: 0
    }
  },
  aboutTextElement: {
    lineHeight: '4.25rem',
    '@media screen and (max-width: 1020px)': {
      fontSize: '3.5rem'
    },
    '@media screen and (max-width: 850px)': {
      fontSize: '2.8rem',
      lineHeight: '3.2rem'
    }
  },
  aboutTextElementWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  aboutTextElementBlock: {
    backgroundColor: colors.background1,
    borderRadius: '8px 0px 0px 3px',
    height: '38px',
    width: '270px',
    ml: 3.75,
    mt: 1.2,
    '@media screen and (max-width: 1020px)': {
      width: '150px'
    },
    '@media screen and (max-width: 850px)': {
      width: '82px'
    },
    '@media screen and (max-width: 750px)': {
      width: '102px'
    }
  },
  aboutInfoWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    '@media screen and (max-width: 750px)': {
      justifyContent: 'flex-start',
      flexDirection: 'column'
    }
  },
  aboutInfo: {
    maxWidth: '500px',
    '@media screen and (max-width: 750px)': {
      mt: 4
    }
  },
  aboutInfoText: {
    color: colors.common.white,
    mb: 3,
    lineHeight: 1.75
  },
  aboutInfoBtn: {
    backgroundColor: colors.common.yellow,
    padding: '0.5rem 1.875rem',
    '&:hover': {
      backgroundColor: '#dde100'
    }
  }
};

export default styles;

import { FC, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Context } from '@/helpers/context';
import getErrorMessage from '@/utils/getErrorMessage';
import { IProps } from './types';
import { SmartContractTypeEnum } from '@/graphql/types/_server';
import { claimRewards } from '@/utils/contractRequests';
import ModalUnstake from '@/shared/ModalUnstake/ModalUnstake';
import formatEthers from '@/utils/formatEthers';

const ModalUnstakeRewards: FC<IProps> = ({ modal, onModal, balanceOf, onGetStakeAmount }) => {
  const { wallet, contracts } = useContext(Context);

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <ModalUnstake
      balanceOf={formatEthers(balanceOf, 6)}
      loading={loading}
      withdraw={withdraw}
      modal={{
        isOpen: modal,
        onClose: onModal,
        title: 'Claim Rewards',
        currency: 'USDC Earned'
      }}
    />
  );

  async function withdraw() {
    try {
      if (!wallet.ethers) return;
      setLoading(true);
      let loadingLocal = true;
      const successCallback = async () => {
        if (loadingLocal) {
          loadingLocal = false;
          setLoading(false);
          onModal();
          toast.success('Rewards withdraw is successful');
          await onGetStakeAmount();
        }
      };

      await claimRewards(
        wallet.ethers,
        contracts.contracts[SmartContractTypeEnum.Lpusdc],
        balanceOf,
        {
          Hash: () => {
            toast.info('Rewards withdraw in progress. This can take up to a minute');
          },
          Error: () => {
            setLoading(false);
          },
          After: successCallback
        }
      );
      successCallback();
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  }
};

export default ModalUnstakeRewards;

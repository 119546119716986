const styles = {
  background: {
    height: '100%'
  },
  filterText: {
    color: 'rgba(26, 27, 29, .5)',
    borderBottom: '1px dashed rgba(26, 27, 29, .5)',
    cursor: 'pointer'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    pt: 5,
    mb: 5.875
  }
};

export default styles;

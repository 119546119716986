import { createContext, Dispatch, SetStateAction } from 'react';
import { IUserModel, SmartContractTypeEnum } from '@/graphql/types/_server';
import EthersSimple from '@/utils/ethers';

type IAppContext = {
  me: IUserModel | null;
  setMe: Dispatch<SetStateAction<IUserModel | null>>;
  token: string;
  setToken: Dispatch<SetStateAction<string>>;
  wallet: IWalletContext;
  setWallet: Dispatch<SetStateAction<IWalletContext>>;
  cart: ICartContext[];
  setCart: Dispatch<SetStateAction<ICartContext[]>>;
  openCart: boolean;
  setOpenCart: Dispatch<SetStateAction<boolean>>;
  audio: IAudioContext | null;
  setAudio: Dispatch<SetStateAction<IAudioContext | null>>;
  stopAudioHandler: () => void;
  pauseAudioHandler: () => void;
  contracts: {
    contracts: Record<SmartContractTypeEnum, { abi: string; address: string }>;
    loading: boolean;
  };
};

export type IAudioContext = {
  id: number | string;
  name: string;
  user: string;
  src?: string;
  showPlayer?: boolean;
  isPlaying?: boolean;
  logo?: string | null;
};

export type IWalletContext = {
  address: string;
  signature?: string;
  message?: string;
  ethers?: EthersSimple;
};

export type ICartContext = {
  id: number;
  name: string;
  price: number;
  user: string;
  logo: string;
  track: string | null;
  tokenId: string;
};

export const initContracts = {
  [SmartContractTypeEnum.Aave]: { abi: '', address: '' },
  [SmartContractTypeEnum.Campaign]: { abi: '', address: '' },
  [SmartContractTypeEnum.Label]: { abi: '', address: '' },
  [SmartContractTypeEnum.Remix]: { abi: '', address: '' },
  [SmartContractTypeEnum.Lpusdc]: { abi: '', address: '' },
  [SmartContractTypeEnum.Stem]: { abi: '', address: '' },
  [SmartContractTypeEnum.Tune]: { abi: '', address: '' },
  [SmartContractTypeEnum.Usdc]: { abi: '', address: '' }
};

export const initContext: IAppContext = {
  me: null,
  setMe: () => null,
  token: '',
  setToken: () => null,
  wallet: {
    address: localStorage.getItem('MY_ADDRESS') || '',
    // provider: undefined,
    ethers: undefined
  },
  setWallet: () => null,
  cart: [],
  setCart: () => null,
  openCart: false,
  setOpenCart: () => null,
  audio: null,
  stopAudioHandler: () => null,
  pauseAudioHandler: () => null,
  setAudio: () => null,
  contracts: {
    contracts: initContracts,
    loading: true
  }
};

export const Context = createContext<IAppContext>(initContext);

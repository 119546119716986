const styles = {
  layout: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    minHeight: '100vh',
    background: '#f4f4f4'
  },
  main: {
    position: 'relative',
    '& > main': {
      height: '100%',
      minHeight: 'calc(100vh - 200px)'
    }
  }
};

export default styles;

import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useQuery } from '@apollo/client';
import { Button } from '@mui/material';
import Badge from '@mui/material/Badge';
import { IUserModel } from '@/graphql/types/_server';
import { ME_PROFILE } from '@/graphql/gql/me';
import ModalEdit from '@/components/account/Profile/ModalEdit/ModalEdit';
import { Context } from '@/helpers/context';
import getShortMaticAddress from '@/utils/getShortMaticAddress';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import { ReactComponent as EditSvg } from '@/files/icons/edit.svg';
import { ReactComponent as LinkSvg } from '@/files/icons/link.svg';
import styles from '../styles';
import getCloudImgUrl from '@/utils/getUrlImage';

const Profile: FC = () => {
  const { setWallet, setToken } = useContext(Context);
  const [modal, setModal] = useState(false);
  const { data, refetch, loading } = useQuery<{ me: IUserModel }>(ME_PROFILE);
  const navigate = useNavigate();
  const location = useLocation();
  const me = data?.me;

  useEffect(() => {
    if ((!me?.isVerifiedKYC || !me?.isVerifiedEmail) && !loading) {
      navigate('/auth/profile');
    }
  }, [me, loading]);

  if (!me?.id || loading) {
    return <BackdropLoading />;
  }

  return (
    <Box sx={styles.bgProfile}>
      <Container maxWidth="xl">
        <Typography variant="h1" sx={styles.title}>
          My Profile
        </Typography>
        <Box sx={styles.profileTop}>
          <Box sx={styles.user}>
            <Box sx={styles.bg} />
            <Box sx={styles.userImg}>
              <img src={getCloudImgUrl(me?.logoUrl || '', 150)} alt="" />
            </Box>
            <Typography sx={styles.userName} variant="h3">
              {me && me.userName}
            </Typography>
            <Link to={`/in/${me?.userName}`}>
              <Box sx={styles.containerLink}>
                <LinkSvg />
                <Typography variant="body2">Share</Typography>
              </Box>
            </Link>
          </Box>
          <Box sx={styles.wallet}>
            <Typography variant="subtitle2">Wallet connected</Typography>
            <Box sx={styles.walletDisconnect}>
              <Box sx={styles.walletDisconnectNumber}>
                <Badge
                  sx={styles.badge}
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                  color="secondary"
                >
                  <img src="/img/metamask.png" width="38" height="38" alt="MetaMask" />
                </Badge>
                <Typography variant="info" sx={styles.text}>
                  {me && getShortMaticAddress(me.address)}
                </Typography>
              </Box>
              <Button onClick={() => exitWallet()} sx={styles.walletDisconnectBtn}>
                <Typography variant="body2">Disconnect</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.profileBottom}>
          <Box sx={styles.profileBottomWrap}>
            <Box sx={styles.infoBlock}>
              <Box sx={styles.info}>
                <Typography variant="subtitle2">Name</Typography>
                <Typography sx={styles.infoText} variant="info">
                  {me?.name ? me.name : '-'}
                </Typography>
              </Box>
              <Box sx={styles.info}>
                <Typography variant="subtitle2">Description</Typography>
                <Typography sx={styles.infoText} variant="info">
                  {me?.description ? me.description : '-'}
                </Typography>
              </Box>
              <Box sx={styles.info}>
                <Typography variant="subtitle2">Email</Typography>
                <Typography sx={styles.infoText} variant="info">
                  {me?.email ? me.email : '-'}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.editWrap}>
              <Box sx={styles.edit} onClick={() => setModal(true)}>
                <EditSvg />
                <Typography variant="body2">Edit profile</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.empty} />
        </Box>
        <ModalEdit me={me} refetch={refetch} modal={modal} onClose={() => setModal(false)} />
      </Container>
    </Box>
  );

  function exitWallet() {
    localStorage.removeItem('MY_ADDRESS');
    localStorage.removeItem('TOKEN');
    localStorage.setItem('ROUTE', location.pathname);
    setToken('');
    setWallet({
      address: '',
      ethers: undefined
    });
  }
};

export default Profile;

import { FC, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './styles';
import Layout from '@/components/Campaigns/Campaign/shared/Layout/Layout';
import Logo from '@/components/Campaigns/Campaign/shared/Logo/Logo';
import Campaing from '@/components/Campaigns/Campaign/shared/Campaing/Campaing';
import { ReactComponent as Play } from '@/files/icons/play.svg';
import { ReactComponent as Pause } from '@/files/icons/pause.svg';
import UploadProgress from '@/components/Campaigns/Campaign/shared/UploadProgress/UploadProgress';
import { IRemixModel } from '@/graphql/types/_server';
import { Context } from '@/helpers/context';
import { CampaignContext } from '@/components/Campaigns/Campaign/context';

const Step3: FC<{ remix: IRemixModel }> = ({ remix }) => {
  const { audio, setAudio, pauseAudioHandler } = useContext(Context);
  const { logoUrl, trackUrl } = remix;

  const { setIsShowMenu } = useContext(CampaignContext);

  useEffect(() => {
    setIsShowMenu(true);
  });

  return (
    <Layout leftCard={<Logo />}>
      <Campaing />
      <Box sx={styles.containerTop}>
        <Typography variant="span3">Your Remix</Typography>
        <Box sx={styles.containerControl}>
          {!audio?.isPlaying || audio?.id !== remix.id ? (
            <Play onClick={playAudio} />
          ) : (
            <Pause onClick={pauseAudio} />
          )}
          <Typography variant="h6">{remix?.name}</Typography>
        </Box>
      </Box>
      <Box sx={styles.containerBottom}>
        <Box sx={styles.containerText}>
          <Typography variant="h5" lineHeight={1}>
            Waiting others to upload their remixes
          </Typography>
        </Box>
        <Box>
          <UploadProgress />
        </Box>
      </Box>
    </Layout>
  );

  function playAudio() {
    setAudioHandler(true);
  }

  function pauseAudio() {
    pauseAudioHandler();
    setAudioHandler(false);
  }

  function setAudioHandler(isPlaying: boolean) {
    setAudio({
      name: remix.name,
      user: '',
      id: remix.id,
      showPlayer: true,
      isPlaying,
      logo: logoUrl,
      src: trackUrl
    });
  }
};

export default Step3;

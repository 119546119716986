import { FC, useContext, useState, useMemo, useEffect } from 'react';
import { useNavigate, Navigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import getErrorMessage from '@/utils/getErrorMessage';
import getShortMaticAddress from '@/utils/getShortMaticAddress';
import { connectToMetamask } from '@/utils/connectToMetamask';
import { Context } from '@/helpers/context';
import { IMutationLoginArgs, IUserModel, IUserTokenDto } from '@/graphql/types/_server';
import { LOGIN } from '@/graphql/gql/auth';
import getBeautyErrorMessage from '@/utils/getBeautyErrorMessage';
import PopupWallet from '@/shared/ConnectWallet/PopupWallet/PopupWallet';
import { IProps } from './types';
import styles from './styles';
import { ME_PROFILE } from '@/graphql/gql/me';

const ConnectWallet: FC<IProps> = ({ refetch, theme, isAuth = false }) => {
  const stylesMemoized = useMemo(() => {
    return styles(theme);
  }, [theme]);

  const client = useApolloClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState<boolean>(false);
  const { wallet, setWallet, stopAudioHandler, setToken } = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  const [loadMe, { called, data: dataMe }] = useLazyQuery<{ me: IUserModel }>(ME_PROFILE);
  const [login] = useMutation<{ login: IUserTokenDto }, IMutationLoginArgs>(LOGIN);
  const referrer =
    localStorage.getItem('ROUTE') !== location.pathname
      ? localStorage.getItem('ROUTE')
      : '/profile';

  useEffect(() => {
    if (wallet.address === '' && isAuth && localStorage.getItem('IS_CONNECT_WALLET')) {
      localStorage.removeItem('IS_CONNECT_WALLET');
      connectToWallet();
    }
  }, [wallet.address, isAuth]);

  if (dataMe?.me?.isVerifiedKYC && called && referrer) {
    return <Navigate to={referrer} />;
  }

  if (dataMe?.me?.isVerifiedKYC && called) {
    return <Navigate to="/" />;
  }

  if (dataMe?.me?.id && (!dataMe?.me?.isVerifiedKYC || !dataMe?.me?.isVerifiedEmail) && called) {
    return <Navigate to="/profile" />;
  }

  if (wallet.address !== '') {
    return (
      <Box sx={stylesMemoized.block}>
        <Button
          variant="contained"
          disableElevation
          sx={{ ...stylesMemoized.button, ...stylesMemoized.activeButton }}
          startIcon={
            <Badge
              sx={stylesMemoized.badge}
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
              color="secondary"
            >
              <img src="/img/metamask.png" width="18" height="18" alt="MetaMask" />
            </Badge>
          }
          endIcon={<KeyboardArrowDownIcon />}
          onClick={openMenuHandler}
        >
          <Typography variant="body2">{getShortMaticAddress(wallet.address)}</Typography>
        </Button>
        {open && (
          <PopupWallet
            open={open}
            anchorEl={anchorEl}
            closeMenuHandler={closeMenuHandler}
            exitWallet={exitWallet}
          />
        )}
      </Box>
    );
  }

  return (
    <LoadingButton
      loading={loading}
      disableElevation
      variant="contained"
      onClick={() => (isAuth ? connectToWallet() : redirectToAuth())}
      sx={stylesMemoized.button}
    >
      <Typography variant="body2">Connect Wallet</Typography>
    </LoadingButton>
  );

  function openMenuHandler(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  function closeMenuHandler() {
    setAnchorEl(null);
  }

  function exitWallet() {
    localStorage.removeItem('MY_ADDRESS');
    localStorage.removeItem('TOKEN');
    localStorage.setItem('ROUTE', location.pathname);
    setToken('');
    setWallet({
      address: '',
      ethers: undefined
    });
    setAnchorEl(null);
    refetch();
  }

  async function loginHandler(address: string, signature: string, message: string) {
    try {
      const response = await login({
        variables: {
          payload: {
            address,
            message,
            signature
          }
        }
      });
      const token = response?.data?.login?.token;

      if (token) {
        await client.clearStore();
        localStorage.setItem('TOKEN', token);
        setToken(token);

        toast.success('You have successfully logged in.');
        stopAudioHandler();
        refetch();
        setTimeout(() => {
          loadMe();
        }, 1000);
      } else {
        throw new Error('Something went wrong');
      }
    } catch (err) {
      const error = err as Error;
      toast.error(getBeautyErrorMessage(error?.message));
    }
  }

  function redirectToAuth() {
    localStorage.setItem('IS_CONNECT_WALLET', 'connectToWallet');
    navigate('/auth');
  }

  async function connectToWallet() {
    try {
      setLoading(true);
      const message = `I confirm the ownership of this wallet for LABEL. Current time is ${new Date().toString()}`;
      const response = await connectToMetamask(message);

      await loginHandler(response.myAddress, response.signature, message);

      localStorage.setItem('MY_ADDRESS', response.myAddress);

      if (response) {
        setWallet({
          address: response.myAddress,
          ethers: response.ethers
        });
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }
};

export default ConnectWallet;

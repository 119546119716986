const styles = {
  background: {
    '& .MuiDialog-paper': {
      backgroundColor: 'black',
      backgroundImage: 'none',
      color: 'white'
    },
    '&>div': {
      backdropFilter: 'blur(4px)',
      background: `rgba(0, 0, 0, 0.1)`
    },
    '&>div:nth-of-type(3) > div': {
      maxWidth: '800px',
      borderRadius: '40px'
    }
  },
  text: {
    '& svg': {
      color: 'white'
    }
  },
  content: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    // TODO: сделать глобальные стили для scroll
    '& *::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none'
    },
    '& *::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none'
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& *': {
      'scrollbar-width': 'thin'
    },
    '& *::-webkit-scrollbar': {
      width: '6px',
      height: '6px'
    },
    '& *::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '& *::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '6px'
    },
    '& *::-webkit-scrollbar-thumb:hover': {
      background: '#333'
    }
  }
};

export default styles;

import { FC, useState, useMemo } from 'react';
import Container from '@mui/material/Container';
import SwiperType from 'swiper';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { useWindowSize } from '@/utils/useWindowSize';
import styles from './styles';
import SwiperNavigation from '../share/SwiperNavigation/SwiperNavigation';
import Collection from '@/shared/card/Collection/Collection';
import { IProps } from './types';

const Collections: FC<IProps> = ({ campaigns }) => {
  const [width] = useWindowSize();
  const countSlides = useMemo(() => {
    if (width <= 600) {
      return 1;
    }
    if (width <= 1100) {
      return 2;
    }
    return 3;
  }, [width]);
  const [swiperRef, setSwiperRef] = useState<SwiperType | undefined>(undefined);

  return (
    <Box mt={3}>
      <Container maxWidth="xl">
        <Box sx={styles.container}>
          <Typography variant="h5">Collections</Typography>
          <Box sx={styles.container}>
            <Link to="/collections" style={styles.link}>
              <Typography variant="body2" sx={styles.textGrey} mr={3}>
                Show All Collections
              </Typography>
            </Link>
            <SwiperNavigation
              initSwiperNavigation={
                campaigns.length
                  ? { isBeginning: true, isEnd: campaigns.length <= countSlides }
                  : undefined
              }
              swiperRef={swiperRef}
            />
          </Box>
        </Box>
      </Container>
      <Box mt={5} sx={styles.containerSwiper}>
        <Box sx={styles.line} />
        <Box sx={styles.line} />
        <Swiper slidesPerView={countSlides} spaceBetween={21} onSwiper={setSwiperRef}>
          {campaigns.map((item, index) => (
            <SwiperSlide key={`${item.id}_collection`}>
              <Collection campaign={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default Collections;

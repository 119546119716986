import { FC, useContext, useEffect, useState } from 'react';
import { useForm, Controller, FieldError } from 'react-hook-form';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import FormHelperText from '@mui/material/FormHelperText';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import isEmpty from 'lodash/isEmpty';
import { IFormInputsValidation, IProps } from './types';
import styles from './styles';
import Loader from '@/components/Campaigns/Campaign/shared/Loader/Loader';
import DropZone from '@/shared/DropZone/DropZone';
import { ADD_REMIX_FOR_VOTE, UPDATE_REMIX_FOR_VOTE } from '@/graphql/gql/campaigns';
import { ReactComponent as Note } from '@/files/icons/note.svg';
import { ReactComponent as EmptyTrashSvg } from '@/files/icons/cart/trash-empty.svg';
import { genres, keys } from '@/helpers/directory';
import { IView } from '@/shared/DropZone/types';
import {
  IMutationAddRemixForVoteArgs,
  IMutationUpdateRemixForVoteArgs,
  IRemixModel
} from '@/graphql/types/_server';
import { Context } from '@/helpers/context';
import { CampaignContext } from '@/components/Campaigns/Campaign/context';

const StepUpload: FC<IProps> = ({ setStep, author, remix }) => {
  const {
    setValue,
    trigger,
    register,
    control,
    getValues,
    handleSubmit,
    formState: { errors, touchedFields }
  } = useForm<IFormInputsValidation>({ mode: 'onTouched' });

  const { wallet } = useContext(Context);
  const { campaign, stemId, refetch, setIsShowMenu } = useContext(CampaignContext);

  useEffect(() => {
    setIsShowMenu(false);
  }, []);

  const [logoRemix, setLogoRemix] = useState<File | undefined>(undefined);
  useEffect(() => {
    if (remix?.logoUrl) {
      setLogoRemix(new File([''], remix.logoUrl, { type: 'image/*' }));
    }
  }, [remix]);

  const fileRemix = remix?.trackName
    ? [new File([''], remix.trackName, { type: 'audio/mpeg' })]
    : undefined;
  const audio = getValues('audio') || fileRemix;

  const [addRemixForVote, { loading: loadingAddRemix }] = useMutation<
    { addRemixForVote: IRemixModel },
    IMutationAddRemixForVoteArgs
  >(ADD_REMIX_FOR_VOTE);

  const [updateRemixForVote, { loading: loadingUpdateRemix }] = useMutation<
    { updateRemixForVote: IRemixModel },
    IMutationUpdateRemixForVoteArgs
  >(UPDATE_REMIX_FOR_VOTE);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box sx={styles.container}>
        <Box sx={styles.form}>
          {(loadingAddRemix || loadingUpdateRemix) && <Loader />}
          <CloseIcon onClick={() => setStep('step1')} sx={styles.icon} />

          <Box sx={styles.containerForm}>
            <Box mb={3.5}>
              {!audio?.length ? (
                <Controller
                  name="audio"
                  control={control}
                  defaultValue={undefined}
                  rules={{ required: 'Required' }}
                  render={() => (
                    <DropZone
                      files={getValues('audio') || []}
                      handleFilesAdd={handleAudioAdd}
                      multiple={false}
                      maxFiles={1}
                      maxSize={200000000}
                      theme="dark"
                      label={
                        <Box sx={styles.textWhite}>
                          <FileDownloadOutlinedIcon />
                          <Typography variant="subtitle2">Drop audio file here</Typography>
                          <Typography variant="caption" sx={styles.textTransparent}>
                            We accept only MP3 or WAV files
                          </Typography>
                        </Box>
                      }
                      error={Boolean(errors.audio)}
                      helperText={
                        (errors?.audio as unknown as FieldError)?.message ||
                        (errors?.audio as unknown as FieldError)?.type ||
                        ''
                      }
                    />
                  )}
                />
              ) : (
                <Box sx={styles.containerAudio}>
                  <Box sx={styles.containerTop}>
                    <Note />
                    <Typography variant="h5">{audio[0]?.name}</Typography>
                  </Box>
                  <IconButton onClick={() => handleAudioDelete()}>
                    <EmptyTrashSvg />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Box mb={3.5}>
              <Controller
                name="art"
                control={control}
                defaultValue={undefined}
                rules={logoRemix !== undefined ? { required: false } : { required: 'Required' }}
                render={() => (
                  <DropZone
                    cropImage
                    maxSize={2000000}
                    multiple={false}
                    maxFiles={1}
                    theme="dark"
                    files={getValues('art') || (logoRemix !== undefined ? [logoRemix] : [])}
                    handleFilesAdd={handleArtAdd}
                    handleFileDelete={handleArtDelete}
                    label={
                      <Box sx={styles.textWhite}>
                        <FileDownloadOutlinedIcon />
                        <Typography variant="subtitle2">Drop cover image here</Typography>
                        <Typography variant="caption" sx={styles.textTransparent}>
                          We accept only JPG or PNG files
                        </Typography>
                      </Box>
                    }
                    error={Boolean(errors.art)}
                    helperText={
                      (errors?.art as unknown as FieldError)?.message ||
                      (errors?.art as unknown as FieldError)?.type ||
                      ''
                    }
                    accept={['image/*']}
                    view={IView.Image}
                  />
                )}
              />
            </Box>

            <Box mb={1.5}>
              <Typography variant="subtitle2" sx={styles.textGrey} mb={1}>
                Name your remix
              </Typography>
              <Controller
                name="name"
                defaultValue={remix?.name || ''}
                control={control}
                render={({ field }) => (
                  <TextField
                    type="name"
                    id="name"
                    variant="outlined"
                    error={Boolean(errors.name)}
                    size="small"
                    fullWidth
                    helperText={errors?.name?.message || errors?.name?.type || ' '}
                    {...register('name', {
                      required: 'Required',
                      maxLength: 50
                    })}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box mb={1.5}>
              <Typography variant="subtitle2" sx={styles.textGrey} mb={1}>
                Artist Name
              </Typography>
              <Controller
                name="author"
                control={control}
                defaultValue={remix?.author || author}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="author"
                    variant="outlined"
                    error={Boolean(errors.author)}
                    size="small"
                    fullWidth
                    helperText={errors?.author?.message || errors?.author?.type || ' '}
                    {...register('author', {
                      maxLength: {
                        value: 100,
                        message: 'The author should not be more than 100 characters'
                      }
                    })}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={styles.containerFields} mb={2}>
              <Box>
                <Typography variant="subtitle2" sx={styles.textGrey} mb={1}>
                  Genre
                </Typography>
                <Controller
                  name="genre"
                  control={control}
                  defaultValue={remix?.genre}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      fullWidth
                      variant="outlined"
                      size="small"
                      inputProps={register('genre', {
                        required: 'Please select Genre'
                      })}
                      value={value}
                      onChange={(val) => onChange(val)}
                      error={Boolean(errors.genre)}
                      ref={ref}
                    >
                      {genres.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {Boolean(errors.genre) && (
                  <FormHelperText sx={{ mt: 0.5, mx: 1.75 }} error>
                    {errors.genre?.message}
                  </FormHelperText>
                )}
              </Box>

              <Box>
                <Typography variant="subtitle2" sx={styles.textGrey} mb={1}>
                  Key
                </Typography>
                <Controller
                  name="key"
                  control={control}
                  defaultValue={remix?.key}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      fullWidth
                      variant="outlined"
                      size="small"
                      inputProps={register('key', {
                        required: 'Please select Key'
                      })}
                      value={value}
                      onChange={(val) => onChange(val)}
                      error={Boolean(errors.key)}
                      ref={ref}
                    >
                      {keys.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {Boolean(errors.key) && (
                  <FormHelperText sx={{ mt: 0.5, mx: 1.75 }} error>
                    {errors.key?.message}
                  </FormHelperText>
                )}
              </Box>

              <Box>
                <Typography variant="subtitle2" sx={styles.textGrey} mb={1}>
                  BPM
                </Typography>
                <Controller
                  name="beats"
                  control={control}
                  defaultValue={remix?.beats ? String(remix?.beats) : ''}
                  render={({ field: { value, ref } }) => {
                    return (
                      <NumberFormat
                        id="beats"
                        variant="outlined"
                        size="small"
                        value={value}
                        customInput={TextField}
                        error={Boolean(errors.beats)}
                        helperText={errors?.beats?.message || errors?.beats?.type || ' '}
                        {...register('beats', {
                          required: 'Required',
                          min: {
                            value: 1,
                            message: '1 Beat minimum'
                          },
                          max: {
                            value: 999,
                            message: '999 Beats maximum'
                          }
                        })}
                        thousandSeparator={false}
                        isNumericString
                        allowNegative={false}
                        decimalScale={0}
                        onValueChange={setBeatsHandler}
                        ref={ref}
                      />
                    ) as JSX.Element;
                  }}
                />
              </Box>
            </Box>

            <Box mb={1.5}>
              <Typography variant="subtitle2" sx={styles.textGrey} mb={1}>
                About
              </Typography>
              <Controller
                name="description"
                control={control}
                defaultValue={remix?.description || ''}
                render={({ field }) => (
                  <TextField
                    type="text"
                    id="description"
                    variant="outlined"
                    error={Boolean(errors.description)}
                    size="small"
                    fullWidth
                    multiline
                    sx={styles.textarea}
                    rows={3}
                    helperText={errors?.description?.message || errors?.description?.type || ' '}
                    {...register('description', {
                      required: 'Required',
                      maxLength: {
                        value: 3000,
                        message: 'The description should not be more than 3000 characters'
                      }
                    })}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box mt={2} textAlign="center">
              <Button
                disabled={loadingAddRemix || loadingUpdateRemix}
                fullWidth
                type="submit"
                color="secondary"
                variant="contained"
                disableElevation
                sx={styles.button}
              >
                <Typography variant="body2">Save</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );

  async function onSubmit(data: IFormInputsValidation) {
    const beats = Number(data.beats);
    const isBeatsCorrect = isFinite(beats);

    try {
      if (!remix?.id) {
        await addRemixForVote({
          variables: {
            chainId: Number(wallet?.ethers?.getChainId()) || 137,
            audio: data.audio[0],
            logo: data.art[0],
            payload: {
              beats: isBeatsCorrect ? beats : 0,
              description: data.description,
              genre: data.genre,
              idCampaign: campaign.id,
              name: data.name,
              key: data.key,
              author: data.author,
              tokenIDStem: stemId
            }
          }
        });
      } else if (!isEmpty(touchedFields)) {
        const newData: IMutationUpdateRemixForVoteArgs = {
          chainId: Number(wallet?.ethers?.getChainId()) || 137,
          ...(touchedFields.audio && { audio: data.audio[0] }),
          ...(touchedFields.art && { logo: data.art[0] }),
          payload: {
            id: remix.id,
            idCampaign: campaign.id,
            tokenIDStem: stemId,
            ...(touchedFields.author && data.author && { author: data.author }),
            ...(touchedFields.beats && data.beats && isBeatsCorrect && { beats }),
            ...(touchedFields.description && data.description && { description: data.description }),
            ...(touchedFields.genre && data.genre && { genre: data.genre }),
            ...(touchedFields.key && data.key && { key: data.key }),
            ...(touchedFields.name && data.name && { name: data.name })
          }
        };

        await updateRemixForVote({ variables: newData });
      }

      toast.success('You have successfully upload a Remix');
      refetch();
      setStep('stepConfirm');
    } catch (err) {
      const error = err as Error;
      toast.error(error?.message);
    }
  }

  async function handleArtAdd(acceptedFiles: File[]) {
    setValue('art', [...(getValues('art') || []), ...acceptedFiles], { shouldTouch: true });
    await trigger('art');
  }

  async function handleArtDelete(file: File) {
    if (logoRemix) {
      setLogoRemix(undefined);
    }
    setValue(
      'art',
      (getValues('art') || []).filter((item) => item !== file),
      { shouldTouch: true }
    );
    await trigger('art');
  }

  async function handleAudioAdd(acceptedFiles: File[]) {
    setValue('audio', [...(getValues('audio') || []), ...acceptedFiles], { shouldTouch: true });
    await trigger('audio');
  }

  async function handleAudioDelete() {
    setValue('audio', [], { shouldTouch: true });
    await trigger('audio');
  }

  async function setBeatsHandler(values: NumberFormatValues) {
    setValue('beats', values.value, { shouldTouch: true });
    await trigger('beats');
  }
};

export default StepUpload;

import { useMutation } from '@apollo/client';

import { FC, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import { IMutationVerificationEmailArgs } from '@/graphql/types/_server';

import { VERIFICATION_EMAIL } from '@/graphql/gql/users';

import getBeautyErrorMessage from '@/utils/getBeautyErrorMessage';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';

const ConfirmEmail: FC = () => {
  const { code } = useParams<'code'>();

  const [verificationEmail] = useMutation<
    { verificationEmail: boolean },
    IMutationVerificationEmailArgs
  >(VERIFICATION_EMAIL);

  const navigate = useNavigate();

  useEffect(() => {
    verifyHandler();
  }, []);

  return <BackdropLoading />;

  async function verifyHandler() {
    try {
      if (!code) {
        throw new Error('No code');
      }

      const codeNumber = Number(code);
      const isCodeFinite = Number.isFinite(codeNumber);

      if (!isCodeFinite) {
        throw new Error('Wrong code');
      }

      const response = await verificationEmail({
        variables: {
          payload: {
            code: codeNumber
          }
        }
      });
      if (response) {
        toast.success('Email is verificated');
      } else {
        throw new Error('Email is not verificated');
      }
    } catch (err) {
      const error = err as Error;

      toast.error(getBeautyErrorMessage(error?.message));
    } finally {
      navigate('/profile', { replace: true });
    }
  }
};

export default ConfirmEmail;

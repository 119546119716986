import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import styles from './styles';
import Logo from '../../../../shared/Logo/Logo';
import Layout from '../../../../shared/Layout/Layout';
import Campaing from '../../../../shared/Campaing/Campaing';
import Loader from '@/components/Campaigns/Campaign/shared/Loader/Loader';
import { IProps } from './types';
import Tickets from '@/components/Campaigns/Campaign/shared/Tickets/Tickets';
import StakeUSDC from '@/components/Campaigns/Campaign/shared/StakeUSDC/StakeUSDC';
import { ReactComponent as BanSvg } from '@/files/icons/ban.svg';
import { messages } from '../../../config';

const Step2: FC<IProps> = ({
  loadingStake,
  loading,
  countDays,
  form,
  onSubmit,
  loadingWithdraw,
  setAmountHandler,
  isAfter23Days
}) => {
  return (
    <Layout leftCard={<Logo />}>
      {(loadingStake || loading) && <Loader />}
      <Campaing />
      <Box sx={styles.stake}>
        <Box sx={styles.containerTop}>
          <Box sx={{ ...styles.containerTickets, ...styles.contaierFlexTop }}>
            <Box mt={0.75}>
              <Tickets countDays={countDays} />
            </Box>
            <Typography variant="overline" fontWeight={300} lineHeight={1} sx={styles.textGrey}>
              You don’t have any stakes but your tickets are still here
            </Typography>
          </Box>
        </Box>
        <Divider light sx={styles.divider} />
        {!isAfter23Days && (
          <Box sx={styles.containerBottom}>
            <StakeUSDC
              loading={loading}
              form={form}
              onSubmit={onSubmit}
              loadingWithdraw={loadingWithdraw}
              setAmountHandler={setAmountHandler}
            />
          </Box>
        )}
        {isAfter23Days && (
          <Box sx={styles.containerProhibit}>
            <BanSvg />
            <Typography variant="caption" component="p" sx={styles.caption}>
              {messages.after23Days}
            </Typography>
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default Step2;

import colors from '@/helpers/theme/colors';

const styles = (multiple: boolean) => ({
  items: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: multiple ? 'repeat(auto-fill, 250px)' : '1fr',
    columnGap: 1,
    rowGap: 1,
    alignItems: 'center'
  },
  item: {
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      maxWidth: '250px',
      height: 'auto',
      borderRadius: 1
    },
    ...(!multiple && { width: 'max-content', margin: 'auto' })
  },
  controll: {
    position: 'absolute',
    borderRadius: `12px 12px 0 0`,
    top: 0,
    width: '100%',
    background: colors.grey.background,
    display: 'flex',
    justifyContent: 'flex-end',
    color: colors.common.white
  }
});

export default styles;

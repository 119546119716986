// TODO: this code need to be refactored
const styles = {
  box: {
    minWidth: '450px',
    minHeight: '550px',
    '@media (max-width: 500px)': {
      minWidth: '330px'
    }
  },
  cropper: {
    position: 'absolute',
    top: 85,
    left: 10,
    right: 10,
    bottom: 110
  },
  sliderWrapper: {
    position: 'absolute',
    bottom: 60,
    left: 30,
    right: 30
  },
  slider: {
    width: '100%'
  },
  button: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
    ':hover': {
      backgroundColor: '#fff'
    },
    '&:disabled': {
      backgroundColor: '#fff'
    }
  }
};

export default styles;

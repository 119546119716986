/* eslint-disable no-nested-ternary */
import { FC, useMemo, useState, memo } from 'react';
import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import { CAMPAIGNS } from '@/graphql/gql/campaigns';
import {
  ICampaignsModel,
  IQueryCampaignsArgs,
  CampaignStatusEnum,
  FilterOperationEnum,
  FilterFieldTypeEnum
} from '@/graphql/types/_server';
import CampaignsItem from '@/components/Campaigns/CampaignsItem/CampaignsItem';
import styles from './styles';

export enum CampaignStateEnum {
  Active = 'active',
  Finished = 'finished'
}

const Campaigns: FC = () => {
  const finishedCampaignStatuses = [CampaignStatusEnum.Check, CampaignStatusEnum.Done];

  const [state, setState] = useState<CampaignStateEnum>(CampaignStateEnum.Active);

  const [campaignFilters, secondCampaignFilters] = useMemo(() => {
    if (state === CampaignStateEnum.Active) {
      return [
        [CampaignStatusEnum.Lottery, CampaignStatusEnum.Remix, CampaignStatusEnum.Voting],
        [CampaignStatusEnum.Voting]
      ];
    }
    return [[CampaignStatusEnum.Done, CampaignStatusEnum.Check], [CampaignStatusEnum.Check]];
  }, [state]);

  const { loading, data, error } = useQuery<{ campaigns: ICampaignsModel }, IQueryCampaignsArgs>(
    CAMPAIGNS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        payload: {
          filters: [
            {
              columnName: 'status',
              operation: FilterOperationEnum.Equal,
              type: FilterFieldTypeEnum.Text,
              value: [
                CampaignStatusEnum.Lottery,
                CampaignStatusEnum.Remix,
                CampaignStatusEnum.Voting,
                CampaignStatusEnum.Done,
                CampaignStatusEnum.Check
              ]
            }
          ]
        }
      }
    }
  );

  const campaigns = useMemo(() => data?.campaigns?.items || [], [data?.campaigns?.items]);

  const isFilter = useMemo(() => {
    return !!campaigns.filter((item) => finishedCampaignStatuses.includes(item.status)).length;
  }, [campaigns]);

  const [firstCampaigns, secondCampaigns] = useMemo(() => {
    const filterCampaigns = campaigns.filter((item) => campaignFilters.includes(item.status));

    const firstCampaigns = filterCampaigns.filter(
      (item) => !secondCampaignFilters.includes(item.status)
    );
    const secondCampaigns = filterCampaigns.filter((item) =>
      secondCampaignFilters.includes(item.status)
    );

    return [firstCampaigns, secondCampaigns];
  }, [campaigns, state]);

  if (error) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error" variant="outlined">
          Try again later
        </Alert>
      </Container>
    );
  }

  return (
    <Box sx={styles.background}>
      <Container maxWidth="xl">
        {state === CampaignStateEnum.Active && (
          <Box sx={styles.container}>
            <Box>
              <Typography variant="h1">Active campaigns</Typography>
              <Typography variant="h6" mt={2}>
                Join now and earn rewards
              </Typography>
            </Box>
            {isFilter && (
              <Box>
                <Typography variant="span1" sx={styles.filterText} onClick={changeState}>
                  Show finished Campaigns
                </Typography>
              </Box>
            )}
          </Box>
        )}
        {state === CampaignStateEnum.Finished && (
          <Box sx={styles.container}>
            <Box>
              <Typography variant="h1">Finished campaigns</Typography>
            </Box>
            <Box>
              <Typography variant="span1" sx={styles.filterText} onClick={changeState}>
                Show Active Campaigns
              </Typography>
            </Box>
          </Box>
        )}
        {!loading ? (
          <>
            {!firstCampaigns.length && !secondCampaigns.length && <>No campaigns...</>}
            {!!firstCampaigns.length && <CampaignsItem campaigns={firstCampaigns} />}
            {!!secondCampaigns.length && (
              <>
                {!!firstCampaigns.length && (
                  <Typography variant="h5" sx={styles.title}>
                    {state === CampaignStateEnum.Active ? 'Voting' : 'In review'}
                  </Typography>
                )}
                <CampaignsItem campaigns={secondCampaigns} />
              </>
            )}
          </>
        ) : (
          <BackdropLoading />
        )}
      </Container>
    </Box>
  );

  function changeState() {
    setState(
      state === CampaignStateEnum.Active ? CampaignStateEnum.Finished : CampaignStateEnum.Active
    );
  }
};

export default memo(Campaigns);

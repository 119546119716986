const styles = {
  song: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '> img': {
      width: '64px',
      height: '64px',
      borderRadius: 1,
      mr: '25px'
    },
    maxWidth: '100%'
  },
  typography: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '214px',
    lineHeight: '20px',
    color: 'white',
    '@media screen and (max-width: 960px)': {
      width: 'initial'
    }
  },
  link: {
    textDecoration: 'none'
  },
  container: {
    '@media screen and (max-width: 960px)': {
      display: 'grid'
    }
  }
};

export default styles;

import { Contract, ethers } from 'ethers';
import { separatorEIP712Domain, separatorPermit } from './abi';
import { AddressType, IPermitSignature } from './types';
import EthersSimple from '@/utils/ethers';

export const getSignatureForPermit = async (
  data: { contract: Contract; provider: ethers.providers.Web3Provider; addresses: AddressType },
  amount: number
): Promise<IPermitSignature> => {
  const { owner, spender, usdc: verifyingContract } = data.addresses;

  // get params from usdc contract
  const [name, nonce, version, decimals, network] = await Promise.all([
    data.contract.name(),
    data.contract.nonces(owner),
    data.contract.EIP712_VERSION(),
    data.contract.decimals(),
    data.provider.getNetwork()
  ]);

  const value = (amount * 10 ** Number(decimals)).toString();
  const deadline = Math.trunc((Date.now() + 60 * 5 * 1000) / 1000).toString();
  const salt = EthersSimple.ethers.utils.hexZeroPad(`0x${network.chainId.toString(16)}`, 32);

  // message for permit
  const message = {
    owner,
    spender,
    value,
    nonce: nonce.toHexString(),
    deadline
  };

  // typedData for permit
  const typedData = {
    types: { EIP712Domain: separatorEIP712Domain, Permit: separatorPermit },
    primaryType: 'Permit',
    domain: { name, version, verifyingContract, salt },
    message
  };

  // raw signature for permit
  const signature = await data.provider.send('eth_signTypedData_v4', [
    owner,
    JSON.stringify(typedData)
  ]);

  // clean signature for permit
  const { r, s, v } = EthersSimple.ethers.utils.splitSignature(signature);

  return {
    amount: value,
    deadline,
    v: v.toString(),
    r,
    s
  };
};

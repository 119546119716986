import { FC, memo } from 'react';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { IProps } from './types';
import styles from './styles';

const PaginationUi: FC<IProps> = ({ count, page, setPage }) => {
  return (
    <Box sx={styles.pagination}>
      <Pagination count={count} page={page} onChange={changePageHandler} />
    </Box>
  );

  function changePageHandler(_: React.ChangeEvent<unknown>, value: number) {
    setPage(value - 1);
  }
};

export default memo(PaginationUi);

import { FC, memo, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';
import { SmartContractTypeEnum } from '@/graphql/types/_server';
import { Context } from '@/helpers/context';
import getErrorMessage from '@/utils/getErrorMessage';
import styles from './styles';
import formatEthers from '@/utils/formatEthers';
import { getCountCampaignStaking, getBalanceOf } from '@/utils/contractRequests';
import { IPopupWallet } from './types';

const PopupWallet: FC<IPopupWallet> = ({ open, anchorEl, closeMenuHandler, exitWallet }) => {
  const navigate = useNavigate();
  const { wallet, contracts } = useContext(Context);

  const [balanceOf, setBalanceOf] = useState<bigint>(BigInt('0'));
  const [votePointBalance, setVotePointBalance] = useState<bigint>(BigInt('0'));
  const [usdcStaked, setUsdcStaked] = useState<bigint>(BigInt('0'));
  const [countCampaigns, setCountCampaigns] = useState<number>(0);

  useEffect(() => {
    if (!contracts.loading) {
      getBalance();
    }
  }, [contracts.loading]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={closeMenuHandler}
      PaperProps={{
        sx: styles.menu
      }}
    >
      <Box sx={styles.balance}>
        <Box sx={styles.balanceTop}>
          <Typography variant="span2" sx={styles.balanceTopTitle}>
            Balance
          </Typography>
          <Box sx={styles.balanceTopNumber}>
            <Box sx={styles.balanceTopNumberLeft}>
              <Typography variant="h2">{formatEthers(balanceOf, 18)}</Typography>
              <Typography variant="subtitle1">LABEL</Typography>
            </Box>
            <Box sx={styles.balanceTopNumberRight}>
              <Typography variant="h4">{formatEthers(votePointBalance, 18)}</Typography>
              <Typography variant="subtitle1">TUNE</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.balanceBottom}>
          <Typography variant="span3" sx={styles.balanceBottomStalked}>
            Staked
          </Typography>
          <Box sx={styles.balanceBottomCampaigns}>
            <Typography variant="infoBig" component="p" lineHeight="2rem">
              {formatEthers(usdcStaked, 6)}
              <Typography variant="body2" fontWeight={600} component="span" ml={1.5}>
                USDC&nbsp;&nbsp;&nbsp;IN&nbsp;&nbsp;
                <Link
                  to={{ pathname: '/assets', hash: '#stakingHistory' }}
                  onClick={closeMenuHandler}
                >
                  {countCampaigns} Campaigns
                </Link>
              </Typography>
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={styles.balanceBottomTextT}
            onClick={() => navigateMenu('/assets')}
          >
            Show my Assets & NFTs
          </Typography>
          <Typography
            variant="body2"
            sx={styles.balanceBottomTextT}
            onClick={() => navigateMenu('/profile')}
          >
            Edit Profile
          </Typography>
          <Typography variant="body2" sx={styles.balanceBottomTextT} onClick={exitWallet}>
            Log Out
          </Typography>
        </Box>
      </Box>
    </Menu>
  );

  function navigateMenu(path: string) {
    navigate(path);
    closeMenuHandler();
  }

  async function getBalance() {
    try {
      if (!wallet.ethers) return;

      const [balance, balanceOfTune, balanceOfUsdc, countCampaignStaking] = await Promise.all([
        getBalanceOf(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Label], {
          account: wallet.address
        }),
        getBalanceOf(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Tune], {
          account: wallet.address
        }),
        getBalanceOf(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Campaign], {
          account: wallet.address
        }),
        getCountCampaignStaking(
          wallet.ethers,
          contracts.contracts[SmartContractTypeEnum.Campaign],
          {
            addressClient: wallet.address
          }
        )
      ]);

      setBalanceOf(balance);
      setVotePointBalance(balanceOfTune);
      setUsdcStaked(balanceOfUsdc);
      setCountCampaigns(Number(formatEthers(countCampaignStaking, 0)));
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  }
};

export default memo(PopupWallet);

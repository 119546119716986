import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FC, memo } from 'react';
import styles from './styles';
import { IProps } from './types';
import getCloudImgUrl from '@/utils/getUrlImage';

const Song: FC<IProps> = ({ audio }) => {
  return (
    <Box sx={styles.song}>
      {audio?.logo && <img src={getCloudImgUrl(audio?.logo, 100)} alt={audio?.name} />}
      <Box sx={styles.container}>
        <Typography variant="span1" component="p" sx={styles.typography}>
          {audio?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(Song);

/* eslint-disable no-nested-ternary */
import { FC, useState, useContext, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { IUserModel, SmartContractTypeEnum } from '@/graphql/types/_server';
import { Context } from '@/helpers/context';
import Step0 from './steps/Step0/Step0';
import Step1 from './steps/Step1/Step1';
import Step2 from './steps/Step2/Step2';
import Step3 from './steps/Step3/Step3';
import { ME_PROFILE } from '@/graphql/gql/me';
import getErrorMessage from '@/utils/getErrorMessage';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import { CampaignContext } from '../../context';
import StepNoWin from './steps/StepNoWin/StepNoWin';
import { getBalanceOf, getStatuses } from '@/utils/contractRequests';
import formatEthers from '@/utils/formatEthers';
import { IDataStem } from './types';

const UploadRemix: FC = () => {
  const { campaign, stemId, setIsStaked } = useContext(CampaignContext);

  const { wallet, contracts } = useContext(Context);

  const { loading: loadingMe, data } = useQuery<{ me: IUserModel }>(ME_PROFILE);
  const me = data?.me;
  const remixes = campaign?.remixes || [];
  const isRemixUploaded = remixes.find((item) => item.user.id === me?.id);

  const [dataStem, setDataStem] = useState<IDataStem>({
    loading: true,
    data: { isAllowed: false, isMinted: false, isSpent: false, balanceOf: 0 }
  });

  const [isAllowed, isMinted, isSpent, balanceOf] = useMemo(
    () => Object.values(dataStem.data),
    [dataStem]
  );

  const [step, setStep] = useState<string>('step1');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!contracts.loading && stemId) {
      getStatus();
    }
  }, [contracts.loading, stemId]);

  useEffect(() => {
    setIsStaked(true);
  }, []);

  if (loadingMe || loading || contracts.loading || dataStem.loading) {
    return <BackdropLoading />;
  }

  if (isRemixUploaded && isSpent) {
    return <Step3 remix={isRemixUploaded} />;
  }

  if ((isMinted === true && balanceOf === 0) || (!isAllowed && !isMinted)) {
    return <StepNoWin />;
  }

  return !isMinted ? (
    <Step0 setDataStem={setDataStem} />
  ) : step === 'step1' ? (
    <Step1 setStep={setStep} />
  ) : (
    <Step2
      author={me?.name || ''}
      step={step}
      setStep={setStep}
      remix={isRemixUploaded}
      setDataStem={setDataStem}
    />
  );

  async function getStatus() {
    try {
      if (!wallet.ethers) return;
      setLoading(true);
      try {
        const [statuses, balanceOf] = await Promise.all([
          getStatuses(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Stem], {
            from: wallet.address,
            tokenID: stemId
          }),
          getBalanceOf(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Stem], {
            account: wallet.address,
            id: stemId
          })
        ]);
        setDataStem({
          loading: false,
          data: {
            isAllowed: statuses[0],
            isMinted: statuses[2],
            isSpent: statuses[1],
            balanceOf: Number(formatEthers(balanceOf, 0))
          }
        });
      } catch {
        setDataStem({
          loading: false,
          data: { isAllowed: false, isMinted: false, isSpent: false, balanceOf: 0 }
        });
      }
    } catch (error) {
      console.error(error);
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }
};

export default UploadRemix;

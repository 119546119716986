import { FC, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { Context } from '@/helpers/context';
import getErrorMessage from '@/utils/getErrorMessage';
import { IProps } from './types';
import {
  IMutationWithdrawUsdcArgs,
  IWithdrawUsdcdto,
  SmartContractTypeEnum
} from '@/graphql/types/_server';
import { withdrawUSDC } from '@/utils/contractRequests';
import { WITHDRAW_USDC } from '@/graphql/gql/blockchain';
import ModalUnstake from '@/shared/ModalUnstake/ModalUnstake';
import { checkBalanceMatic } from '@/utils/checkBalanceToken';

// TODO: не учитываю статус TransactionStatusEnum.Waitingaave
const ModalUnstakeUsdc: FC<IProps> = ({
  modalTune,
  onModalTune,
  stakedUsdc,
  campaignId,
  getStakedUsdc
}) => {
  const { contracts, wallet } = useContext(Context);
  const [loading, setLoading] = useState<boolean>(false);

  const [withdrawUsdc, { loading: loadingWithdraw }] = useMutation<
    { withdrawUSDC: IWithdrawUsdcdto },
    IMutationWithdrawUsdcArgs
  >(WITHDRAW_USDC);

  return (
    <ModalUnstake
      balanceOf={String(stakedUsdc)}
      loading={loading}
      withdraw={withdraw}
      modal={{
        isOpen: modalTune,
        onClose: onModalTune,
        title: 'Withdraw USDC',
        currency: 'USDC Staked'
      }}
    />
  );

  async function withdraw() {
    try {
      if (loadingWithdraw) {
        throw new Error('In the process of withdraw');
      }
      if (!wallet.ethers) return;
      await checkBalanceMatic(wallet);
      setLoading(true);
      let loadingLocal = true;
      toast.info('Withdraw... Please wait');

      const response = await withdrawUsdc({
        variables: {
          chainId: Number(wallet.ethers.getChainId()) || 137,
          payload: {
            id: campaignId
          }
        }
      });

      if (!response.data?.withdrawUSDC) {
        throw new Error('Error in withdraw');
      }

      const { reward, signature, tokenID } = response.data.withdrawUSDC;
      const successCallback = async () => {
        if (loadingLocal) {
          loadingLocal = false;
          setLoading(false);
          onModalTune();
          toast.success('The withdraw is completed. Please check your funds');
          await getStakedUsdc();
        }
      };

      await withdrawUSDC(
        wallet.ethers,
        contracts.contracts[SmartContractTypeEnum.Campaign],
        {
          tokenID,
          reward,
          signature
        },
        {
          Hash: () => {
            toast.info('Withdrawing in progress. This can take up to a minute');
          },
          Error: () => {
            setLoading(false);
          },
          After: successCallback
        }
      );
      successCallback();
    } catch (error) {
      setLoading(false);
      toast.error(getErrorMessage(error));
    }
  }
};

export default ModalUnstakeUsdc;

const styles = {
  container: {
    background: 'black',
    borderBottomRightRadius: '2.5rem',
    borderBottomLeftRadius: '2.5rem',
    color: 'white',
    display: 'flex',
    padding: '2.25rem 3.4375rem 1.5rem 2.875rem',
    justifyContent: 'space-between',
    height: '15.3125rem',
    '@media screen and (max-width: 400px)': {
      flexDirection: 'column',
      height: '21.375rem',
      alignItems: 'center',
      '& > *:first-child': {
        width: '100%',
        height: '7.875rem'
      }
    }
  },
  containerText: {
    display: 'flex',
    flexDirection: 'column',
    width: '47%',
    justifyContent: 'space-between'
  },
  textGrey: {
    color: '#BDC2C8'
  },
  button: {
    backgroundColor: '#fff',
    width: '8.9375rem',
    height: '2rem',
    ':hover': {
      backgroundColor: '#fff'
    }
  }
};

export default styles;

import { FC } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import styles from './styles';
import { IProps } from './types';
import Track from '@/shared/card/Track/Track';

const Tracks: FC<IProps> = ({ remixes, handleOpen }) => {
  return (
    <Box mt={8.75}>
      <Container maxWidth="xl">
        <Box sx={styles.container}>
          <Typography variant="h5">New Relesases</Typography>
        </Box>
        <Box mt={5} sx={styles.gird}>
          {remixes.map((item) => (
            <Track key={`${item.id}_track`} remix={item} handleClick={handleOpen} />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Tracks;

import { useContext, useState, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { useNavigate, useParams } from 'react-router-dom';

import { REMIXES, REMIXES_TOP } from '@/graphql/gql/store';
import {
  ICampaignsModel,
  IQueryRemixesArgs,
  IQueryRemixesTopForPeriodArgs,
  IRemixesModel,
  IQueryCampaignsArgs,
  SortDirectionEnum,
  FilterOperationEnum,
  FilterFieldTypeEnum,
  CampaignStatusEnum,
  RemixStatusEnum
} from '@/graphql/types/_server';
import { Context } from '@/helpers/context';
import Collections from './Collections/Collections';
import TopTracks from './TopTracks/TopTracks';
import Tracks from './Tracks/Tracks';
import TrackModal from '@/components/Track/Track';
import { CAMPAIGNS } from '@/graphql/gql/campaigns';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';

const Store = () => {
  const { wallet } = useContext(Context);
  const navigate = useNavigate();

  const params = useParams<'id'>();
  const id = Number(params?.id) || undefined;

  const [open, setOpen] = useState<boolean>(!!id);
  useEffect(() => {
    if (!!id && !open) {
      setOpen(true);
    }
    if (!id && !!open) {
      setOpen(false);
    }
  }, [id]);

  const {
    loading: loadingRemixes,
    data: dataRemixes,
    error: errorRemixes
  } = useQuery<{ remixes: IRemixesModel }, IQueryRemixesArgs>(REMIXES, {
    variables: {
      chainId: Number(wallet?.ethers?.getChainId()) || 137,
      payload: {
        paginate: { skip: 0, take: 100 }
      }
    }
  });
  const remixes = useMemo(() => dataRemixes?.remixes?.items || [], [dataRemixes?.remixes?.items]);

  const {
    loading: loadingRemixesTop,
    data: dataRemixesTop,
    error: errorRemixesTop
  } = useQuery<{ remixesTopForPeriod: IRemixesModel }, IQueryRemixesTopForPeriodArgs>(REMIXES_TOP, {
    variables: {
      chainId: Number(wallet?.ethers?.getChainId()) || 137
    }
  });
  const remixesTop = useMemo(
    () => dataRemixesTop?.remixesTopForPeriod?.items || [],
    [dataRemixesTop?.remixesTopForPeriod?.items]
  );

  const {
    loading: loadingCampaigns,
    data: dataCampaigns,
    error: errorCampaigns
  } = useQuery<{ campaigns: ICampaignsModel }, IQueryCampaignsArgs>(CAMPAIGNS, {
    variables: {
      payload: {
        paginate: { skip: 0, take: 100 },
        sorts: [
          {
            columnName: 'createdDate',
            direction: SortDirectionEnum.Desc
          }
        ],
        filters: [
          {
            columnName: 'status',
            operation: FilterOperationEnum.Equal,
            type: FilterFieldTypeEnum.Text,
            value: [CampaignStatusEnum.Done]
          },
          {
            columnName: 'remixes.status',
            operation: FilterOperationEnum.Equal,
            type: FilterFieldTypeEnum.Text,
            value: [RemixStatusEnum.Store]
          }
        ]
      }
    }
  });
  const campaigns = useMemo(
    () => dataCampaigns?.campaigns?.items || [],
    [dataCampaigns?.campaigns?.items]
  );

  if (errorRemixes || errorRemixesTop || errorCampaigns) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error" variant="outlined">
          Try again later
        </Alert>
      </Container>
    );
  }

  if (loadingRemixes || loadingCampaigns || loadingRemixesTop) {
    return <BackdropLoading />;
  }

  return (
    // TODO: remove width: 'calc(100vw - 10px)'
    <Box sx={{ width: 'calc(100vw - 10px)' }} mb={4}>
      <Collections campaigns={campaigns} />
      {remixesTop.length >= 5 && <TopTracks remixes={remixesTop} handleOpen={handleOpen} />}
      <Tracks remixes={remixes} handleOpen={handleOpen} />
      <TrackModal open={open} handleClose={handleClose} id={id} />
    </Box>
  );

  function handleOpen(id: number) {
    navigate(`/store/${id}`);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setTimeout(() => {
      navigate('/store');
    }, 300);
  }
};

export default Store;

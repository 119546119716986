import { FC, ReactNode, useMemo, useState, useRef, useEffect, MutableRefObject } from 'react';
import { useQuery } from '@apollo/client';
import WaveSurfer from 'wavesurfer.js';
import AudioPlayer from '@/shared/AudioPlayer/AudioPlayer';
import { Context, IAudioContext, initContext, initContracts } from '@/helpers/context';
import {
  ISmartContractConfigModel,
  IUserModel,
  SmartContractTypeEnum
} from '@/graphql/types/_server';
import { CONTRACTS } from '@/graphql/gql/blockchain';
import { connectToMetamask } from '@/utils/connectToMetamask';

const formWaveSurferOptions = (ref: MutableRefObject<null>) => ({
  container: ref,
  barHeight: 1,
  cursorWidth: 1,
  backend: 'WebAudio',
  height: 34,
  progressColor: '#F3F700',
  responsive: true,
  waveColor: 'rgba(255, 255, 255, .32)',
  cursorColor: 'rgba(255, 255, 255, .32)'
});

const InitContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [me, setMe] = useState<IUserModel | null>(null);
  const [token, setToken] = useState<string>(localStorage.getItem('TOKEN') || '');
  const [wallet, setWallet] = useState(initContext.wallet);
  const [cart, setCart] = useState(initContext.cart);
  const [audio, setAudio] = useState<IAudioContext | null>(initContext.audio);
  const [openCart, setOpenCart] = useState<boolean>(false);

  const { data, loading } = useQuery<{ contracts: ISmartContractConfigModel[] }>(CONTRACTS);

  const contracts = useMemo(() => {
    return data && !loading
      ? (data?.contracts.reduce((obj, item) => ({ ...obj, [item.type]: item }), {}) as Record<
          SmartContractTypeEnum,
          ISmartContractConfigModel
        >)
      : initContracts;
  }, [data]);

  const contextMemoized = useMemo(
    () => ({
      me,
      setMe,
      token,
      setToken,
      wallet,
      setWallet,
      cart,
      setCart,
      openCart,
      setOpenCart,
      audio,
      setAudio,
      stopAudioHandler,
      pauseAudioHandler,
      contracts: { contracts, loading }
    }),
    [me, audio, cart, openCart, token, wallet, contracts, loading]
  );

  const waveformRef = useRef(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const [loadingWave, setLoadingWave] = useState<boolean>(false);

  useEffect(() => {
    if (audio?.src && waveformRef?.current) {
      const options = formWaveSurferOptions(waveformRef.current);
      wavesurfer.current = WaveSurfer.create(options);
      setLoadingWave(true);
      wavesurfer.current?.load(audio?.src);
      wavesurfer.current?.on('ready', () => {
        setLoadingWave(false);
        wavesurfer.current?.play();
      });
    }
    return () => {
      if (wavesurfer?.current) {
        wavesurfer.current?.destroy();
      }
    };
  }, [audio?.src]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', callbackExit);
      window.ethereum.on('chainChanged', callbackExit);
    }

    const address = localStorage.getItem('MY_ADDRESS');
    if (address) {
      checkWalletAddress(address);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum?.removeListener('chainChanged', callbackExit);
        window.ethereum?.removeListener('accountsChanged', callbackExit);
      }
    };
  }, []);

  return (
    <Context.Provider value={contextMemoized}>
      {children}
      <AudioPlayer
        waveformRef={waveformRef}
        wavesurfer={wavesurfer?.current}
        loading={loadingWave}
      />
    </Context.Provider>
  );

  function pauseAudioHandler() {
    if (wavesurfer?.current) {
      wavesurfer.current.pause();
    }
  }

  function stopAudioHandler() {
    if (wavesurfer?.current) {
      wavesurfer.current.destroy();
    }
    setAudio(null);
  }

  async function checkWalletAddress(address: string) {
    const response = await connectToMetamask();
    setWallet({ ...wallet, ethers: response.ethers });
    if (response.myAddress !== address) {
      exitWallet();
    }
  }

  function exitWallet() {
    localStorage.setItem('ROUTE', window.location.pathname);
    localStorage.removeItem('MY_ADDRESS');
    localStorage.removeItem('TOKEN');
    setToken('');
    setWallet({
      address: '',
      ethers: undefined
    });
  }

  function callbackExit() {
    exitWallet();
  }
};

export default InitContextProvider;

import { gql } from '@apollo/client';

export const USER_BY_USERNAME = gql`
  query userByUserName($userName: String!) {
    userByUserName(userName: $userName) {
      description
      id
      logoUrl
      name
      userName
      type
    }
  }
`;

import { FC, useCallback, useEffect, useState, useContext, memo } from 'react';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './styles';
import Controls from './Controls/Controls';
import { Context } from '@/helpers/context';
import { ReactComponent as CloseSvg } from '@/files/icons/close.svg';
import Volume from './Volume/Volume';
import { IProps } from './types';

const Bar: FC<IProps> = ({ waveformRef, wavesurfer, loading }) => {
  const [curTime, setCurTime] = useState<number>(0);
  const [maxTime, setMaxTime] = useState<number>(0);
  const { stopAudioHandler, audio, setAudio } = useContext(Context);
  const isMobile = useMediaQuery('(max-width:960px)');

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (audio?.src && wavesurfer && !wavesurfer.isDestroyed) {
      interval = setInterval(() => {
        setCurTime(wavesurfer?.getCurrentTime());
      }, 500);

      setMaxTime(wavesurfer.getDuration());
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [loading, audio?.src]);

  const formatDuration = useCallback((duration: number) => {
    return format(new Date((duration || 0) * 1000), 'mm:ss');
  }, []);

  return (
    <Box sx={styles.container}>
      {audio?.src && (
        <Controls setAudio={setAudio} audio={audio} waver={wavesurfer} loading={loading} />
      )}
      <Box sx={styles.containerInner}>
        {loading && <CircularProgress size={20} color="secondary" sx={styles.loader} />}
        {loading && <Box sx={styles.skeletonBar} />}
        <Box id="waveform" ref={waveformRef} sx={styles.waveform} />
        <Box sx={styles.containerFlex}>
          <Typography component="span" variant="overline" sx={styles.time}>
            {formatDuration(curTime)}
          </Typography>
          <Typography component="span" variant="overline" sx={styles.time}>
            {formatDuration(maxTime)}
          </Typography>
        </Box>
      </Box>
      {!isMobile && audio?.src && <Volume waver={wavesurfer} loading={loading} />}
      <IconButton sx={styles.icon} color="inherit" onClick={stopAudioHandler}>
        <CloseSvg />
      </IconButton>
    </Box>
  );
};

export default memo(Bar);

import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation createUser($payload: UserCreateDTO!) {
    createUser(payload: $payload) {
      id
      name
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($payload: UserForgotPasswordDTO!) {
    forgotPassword(payload: $payload)
  }
`;

export const LOGIN = gql`
  mutation login($payload: UserLoginDTO!) {
    login(payload: $payload) {
      token
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

export const RECOVERY_PASSWORD = gql`
  mutation recoveryPassword($payload: UserRecoveryPasswordDTO!) {
    recoveryPassword(payload: $payload)
  }
`;

import { FC, useState, useEffect, useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import { useQuery } from '@apollo/client';
import styles from './styles';
import Header from '@/shared/Header/Header';
import Step0 from './steps/Step0/Step0';
import Step1 from './steps/Step1/Step1';
import Step2 from './steps/Step2/Step2';
import Step3 from './steps/Step3/Step3';
import Step4 from './steps/Step4/Step4';
import { IUserModel } from '@/graphql/types/_server';
import { ME_PROFILE } from '@/graphql/gql/me';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import { Context } from '@/helpers/context';
import { MenuTypeEnum } from '@/shared/Header/types';

const Auth: FC<{ initStep: number }> = ({ initStep }) => {
  const { wallet } = useContext(Context);
  const { loading, data } = useQuery<{ me: IUserModel }>(ME_PROFILE);
  const [step, setStep] = useState<number>(initStep);
  const me = data?.me;
  const isConnectWallet = useMemo(() => step !== 0, [step]);

  useEffect(() => {
    if (me?.isVerifiedEmail && !loading && wallet.address) {
      setStep(4);
    } else if (me?.email && me?.userName && !loading && wallet.address) {
      setStep(3);
    }
  }, [loading]);

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.containerInner}>
        <Header isConnectWallet={isConnectWallet} menuType={MenuTypeEnum.Authorization} />
        {step === 0 && <Step0 setStep={setStep} />}
        {step === 1 && <Step1 />}
        {step === 2 && <Step2 me={me} setStep={setStep} />}
        {step === 3 && <Step3 me={me} setStep={setStep} />}
        {step === 4 && <Step4 />}
      </Box>
    </Box>
  );
};

export default Auth;

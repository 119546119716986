const styles = {
  text: {
    fontFamily: '"Grafita", monospace, sans-serif'
  },
  containerText: {
    color: 'white'
  },
  contentTiket: {
    position: 'absolute',
    top: '8px',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    '& > svg': {
      mt: '5px'
    }
  }
};

export default styles;

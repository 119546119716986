import { memo, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import styles from './styles';
import {
  IQueryCampaignsArgs,
  ICampaignsModel,
  SortDirectionEnum,
  FilterOperationEnum,
  CampaignStatusEnum,
  FilterFieldTypeEnum,
  RemixStatusEnum
} from '@/graphql/types/_server';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import { CAMPAIGNS } from '@/graphql/gql/campaigns';
import Collection from '@/shared/card/Collection/Collection';

const Collections = () => {
  // TODO: добавить бесконечную прокрутку
  const { loading, data } = useQuery<{ campaigns: ICampaignsModel }, IQueryCampaignsArgs>(
    CAMPAIGNS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        payload: {
          paginate: { skip: 0, take: 100 },
          sorts: [
            {
              columnName: 'createdDate',
              direction: SortDirectionEnum.Desc
            }
          ],
          filters: [
            {
              columnName: 'status',
              operation: FilterOperationEnum.Equal,
              type: FilterFieldTypeEnum.Text,
              value: [CampaignStatusEnum.Done]
            },
            {
              columnName: 'remixes.status',
              operation: FilterOperationEnum.Equal,
              type: FilterFieldTypeEnum.Text,
              value: [RemixStatusEnum.Store]
            }
          ]
        }
      }
    }
  );

  const campaigns = useMemo(() => data?.campaigns?.items || [], [data?.campaigns?.items]);

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h1" sx={styles.title}>
          Collections
        </Typography>
        {campaigns.length ? (
          <Box sx={styles.container}>
            {campaigns.map((item) => (
              <Collection key={`${item.id}_collection`} campaign={item} />
            ))}
          </Box>
        ) : (
          <Typography variant="span1" component="p" sx={styles.text}>
            No campaigns...
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default memo(Collections);

import colors from '@/helpers/theme/colors';

const styles = {
  teaserTitle: {
    color: colors.common.white,
    fontFamily: 'Grafita-Special'
  },
  teaserDescription: {
    color: '#BDC2C8',
    fontWeight: 300,
    lineHeight: 2
  }
};

export default styles;

import { Navigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '@/helpers/context';

function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const { token } = useContext(Context);
  const isTokenCorrect = token && token !== '';

  if (!isTokenCorrect) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;

import { FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import styles from './styles';
import { IProps } from './types';
import ListMenu from '../ListMenu/ListMenu';

const DrawerMenu: FC<IProps> = ({ menu, navigate, pathname, openMenuHandler, openMenu, theme }) => {
  const stylesMemoized = useMemo(() => {
    return styles(theme);
  }, [theme]);

  return (
    <Drawer
      anchor="left"
      open={openMenu}
      onClose={openMenuHandler}
      PaperProps={{
        sx: {
          maxWidth: 240,
          width: '100%'
        }
      }}
    >
      <Box sx={stylesMemoized.slideMenu}>
        <Box sx={stylesMemoized.head}>
          <IconButton onClick={openMenuHandler}>
            <CloseIcon />
          </IconButton>
        </Box>

        <ListMenu
          menu={menu}
          navigate={navigate}
          pathname={pathname}
          theme={theme}
          direction="column"
        />
        <Box sx={stylesMemoized.menu} />
      </Box>
    </Drawer>
  );
};

export default DrawerMenu;

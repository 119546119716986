import { gql } from '@apollo/client';

export const ME_PROFILE = gql`
  query me {
    me {
      id
      name
      userName
      email
      isVerifiedKYC
      isVerifiedEmail
      type
      address
      description
      logoUrl
    }
  }
`;

export const ME_IS_SUPERADMIN = gql`
  query me {
    me {
      isSuperAdmin
    }
  }
`;

export const IS_ADMIN = gql`
  query me {
    me {
      type
    }
  }
`;

export const ME_VERIFY = gql`
  query me {
    me {
      id
      isVerifiedKYC
    }
  }
`;

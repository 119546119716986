/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable no-constructor-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { ethers } from 'ethers';

export type Listener = (...args: Array<string>) => void;

export interface ExecuteFunctionOptions {
  contractAddress: string;
  abi: any;
  functionName: string;
  msgValue?: string;
  params?: Record<string, unknown>;
  listeners?: Record<'Hash' | 'Error' | 'Before' | 'After', Function>;
  nonce?: number;
}

export default class EthersSimple {
  private waitBlock = 4;

  private signer: ethers.Signer;

  private providerRead: any;

  private providerWrite: any;

  constructor({ providerRead, providerWrite, signer }: any) {
    this.providerRead = providerRead;
    this.providerWrite = providerWrite;
    this.signer = signer;
    return this;
  }

  static bytesToBigInt = (bytes: Uint8Array) => {
    let result = BigInt(0);
    for (let i = 0; i < bytes.length; i++) {
      result = (result << 8n) | BigInt(bytes[i]);
    }
    return result;
  };

  public getProvider() {
    return this.providerWrite;
  }

  public getProviderRead() {
    return this.providerRead;
  }

  public getNetwork() {
    return this.providerWrite.getNetwork();
  }

  public getChainId() {
    return this.providerWrite.getNetwork().chainId;
  }

  public getSigner() {
    return this.signer;
  }

  public async getAddress() {
    return this.signer.getAddress();
  }

  public setSigner(signer: ethers.providers.JsonRpcSigner) {
    this.signer = signer;
  }

  static _getParsedInputs = (functionName: string, abi: string | object, params: any) => {
    const overloadedFunction = functionName.match(/^(.+)\((.*)\)$/);
    const abiObj = typeof abi === 'string' ? JSON.parse(abi) : abi;
    let functionData;
    if (overloadedFunction) {
      // Get functiondata from overloaded function
      const nameWithoutTopics = overloadedFunction[1];
      const topics = overloadedFunction[2]
        .split(',')
        .map((topic) => topic.trim())
        .filter((topic) => !!topic);

      const functionDataArray = abiObj.filter((x: any) => x.name === nameWithoutTopics);

      if (functionDataArray.length === 0) {
        throw new Error('Function does not exist in abi');
      }

      functionData = functionDataArray.find(
        (data: any) =>
          (data?.inputs.length ?? 0) === topics.length &&
          data.inputs.every((input: any, index: number) => input.type === topics[index])
      );

      if (!functionData) {
        const possibleTopics = functionDataArray.map(
          (data: any) => `${data.name}(${data.inputs.map((input: any) => input.type).join(',')})`
        );

        throw new Error(
          `Function with the provided topic does not exist in abi. Possible funcationNames: ${possibleTopics.join(
            ' ,'
          )}`
        );
      }
    } else {
      // Get functiondata from 'normal' function
      const functionDataArray = abiObj.filter((x: any) => x.name === functionName);
      if (functionDataArray.length === 0) {
        throw new Error('Function does not exist in abi');
      }

      if (functionDataArray.length > 1) {
        const possibleTopics = functionDataArray.map(
          (data: any) => `${data.name}(${data.inputs.map((input: any) => input.type).join(',')})`
        );

        throw new Error(
          `Multiple function definitions found in the abi. Please include the topic in the functionName. Possible funcationNames: ${possibleTopics.join(
            ' ,'
          )}`
        );
      }

      const [firstItem] = functionDataArray;
      functionData = firstItem;
    }

    const errors = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const input of functionData.inputs) {
      const value = params[input.name];
      if (!value && typeof value !== 'number' && typeof value !== 'boolean' && input.name !== '') {
        errors.push(`${input.name} is required`);
      }
    }

    if (errors.length > 0) {
      throw Error(errors.join(', '));
    }

    return {
      isMutable: functionData.stateMutability !== 'view',
      inputs: functionData.inputs.map((x: any) => params[x.name]),
      abiObj
    };
  };

  public getContractForRead({ contractAddress, abi }: any) {
    return new ethers.Contract(contractAddress, JSON.parse(abi), this.providerRead);
  }

  public getContractForWrite({ contractAddress, abi }: any) {
    return new ethers.Contract(contractAddress, JSON.parse(abi), this.signer);
  }

  async executeFunction<T>(props: ExecuteFunctionOptions): Promise<T> {
    const { functionName, abi, params, contractAddress, listeners } = props;
    const keys = Object.keys(listeners || {});
    const date = Date.now();

    try {
      console.log(`📓: ${functionName}`, 'START');
      const { isMutable, inputs, abiObj } = EthersSimple._getParsedInputs(
        functionName,
        abi,
        params
      );

      const contract = isMutable
        ? new ethers.Contract(contractAddress, abiObj, this.signer)
        : new ethers.Contract(contractAddress, abiObj, this.providerRead);

      const contractMethod = contract[functionName];

      if (!contractMethod) {
        throw Error(`Cannot find function "${functionName}" on the contract`);
      }

      await Promise.all(
        keys.map(async (key) => {
          if (key === 'Before') {
            await listeners?.[key]();
          }
        })
      );

      const response = await contractMethod(...Object.values(inputs));

      if (response.wait) {
        console.log(`📘: ${functionName}`, 'WAIT', `hash: ${response.hash}`);

        await Promise.all(
          keys.map(async (key) => {
            if (key === 'Hash') {
              await listeners?.[key](response);
            }
          })
        );

        await response.wait(this.waitBlock);

        const receipt = await this.providerRead.waitForTransaction(response.hash);

        await Promise.all(
          keys.map(async (key) => {
            if (key === 'After') {
              await listeners?.[key](receipt);
            }
          })
        );

        console.log(
          `📗: ${functionName}`,
          'FINISH',
          `hash: ${response.hash} +${Date.now() - date}ms`
        );
      } else {
        console.log(`📗: ${functionName}`, 'FINISH', `+${Date.now() - date}ms`);
      }

      return response;
    } catch (error) {
      const message = EthersSimple.getError(error);
      console.log(`📕: ${functionName}`, 'ERROR', `${message} +${Date.now() - date}ms`);

      await Promise.all(
        keys.map(async (key) => {
          if (key === 'Error') {
            await listeners?.[key](Error(message));
          }
        })
      );

      throw Error(message);
    }
  }

  static getError(error: any) {
    let message = '';

    if (error?.error?.code) {
      message += `[code: ${error.error.code}] `;
    }
    if (error?.error?.method) {
      message += `[method: ${error.error.method}] `;
    }
    if (error?.error?.reason) {
      message += `[reason: ${error.error.reason}] `;
    }

    if (!message && error?.message) {
      message += `error: ${error.message} `;
    }

    return message;
  }

  static getRandomToken = () => EthersSimple.bytesToBigInt(ethers.utils.randomBytes(32)).toString();

  static ethers = ethers;
}

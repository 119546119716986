import { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as PauseSvg } from '@/files/icons/pause.svg';
import { IProps } from './types';

const style = {
  '& > svg': {
    fill: 'white'
  }
};

const Pause: FC<IProps> = ({ handleClick }) => {
  return (
    <IconButton onClick={handleClick} color="inherit" sx={style}>
      <PauseSvg />
    </IconButton>
  );
};

export default Pause;

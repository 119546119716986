const styles = {
  item: {
    borderBottom: '1px solid #F3F700',
    color: '#fff',
    backgroundColor: 'transparent',
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    p: '0',
    flexDirection: 'row',
    justifyContent: 'start',
    borderRadius: '0',
    pb: '24px',
    '& > :first-of-type': {
      display: 'flex',
      marginRight: '14px',
      img: {
        objectFit: 'cover',
        width: '100px',
        height: '100px',
        cursor: 'pointer',
        borderRadius: '20px'
      }
    },
    '&:last-of-type': {
      borderBottom: 'none'
    },
    '& > :nth-of-type(2)': {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: '1rem'
    },
    '&:hover div:nth-of-type(3)': {
      display: 'flex'
    },
    '&:hover div:nth-of-type(4)': {
      display: 'none'
    }
  },
  itemUser: {
    color: 'rgba(255,255,255, .5)'
  },
  cross: {
    height: '30px',
    width: '100px',
    top: '3px',
    right: '3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      whiteSpace: 'nowrap'
    }
  },
  priceWrap: {},
  buyBtn: {
    color: '#fff'
  },
  itemBtn: {
    display: 'none',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    '& > button': {
      display: 'block'
    }
  }
};

export default styles;

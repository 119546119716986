/* eslint-disable no-nested-ternary */
import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles';
import Logo from '../../../../shared/Logo/Logo';
import Layout from '../../../../shared/Layout/Layout';
import Campaing from '../../../../shared/Campaing/Campaing';
import Loader from '@/components/Campaigns/Campaign/shared/Loader/Loader';
import { TransactionStatusEnum } from '@/graphql/types/_server';
import { IProps } from './types';
import Tickets from '@/components/Campaigns/Campaign/shared/Tickets/Tickets';
import StakeUSDC from '@/components/Campaigns/Campaign/shared/StakeUSDC/StakeUSDC';
import { messages } from '../../../config';
import { ReactComponent as PlusSvg } from '@/files/icons/assets/plus.svg';
import { ReactComponent as ArrowSvg } from '@/files/icons/assets/arrow.svg';

const Step1: FC<IProps> = ({
  loadingStake,
  loading,
  countDays,
  form,
  onSubmit,
  status,
  loadingWithdraw,
  withdrawStakingCampaignMutation,
  stakedUsdc,
  setAmountHandler,
  stakedDate,
  isAfter23Days,
  handleOpenInformation
}) => {
  const [isStake, setIsStake] = useState<boolean>(false);

  return (
    <Layout leftCard={<Logo />}>
      {(loadingStake || loading) && <Loader />}
      <Campaing />
      {!isStake ? (
        <>
          <Box sx={styles.stake}>
            <Box sx={styles.containerTop}>
              <Box sx={{ ...styles.containerText, ...styles.contaierFlexTop }}>
                <Typography variant="span3">Your Stake</Typography>
                <Typography variant="h5" mt={2} lineHeight={1}>
                  {stakedUsdc} USDC
                </Typography>
                <Typography variant="overline" lineHeight={1} sx={styles.text}>
                  {stakedDate}
                </Typography>
              </Box>
              <Box sx={{ ...styles.containerTickets, ...styles.contaierFlexTop }}>
                <Box sx={styles.containerIcon}>
                  <Box
                    title={
                      status === TransactionStatusEnum.Waitingaave
                        ? messages.noWithdraw
                        : messages.withdraw
                    }
                    sx={
                      loading || loadingWithdraw || status === TransactionStatusEnum.Waitingaave
                        ? styles.iconDisabled
                        : styles.icon
                    }
                  >
                    {!loadingWithdraw ? (
                      <ArrowSvg onClick={withdrawStakingCampaignMutation} />
                    ) : (
                      <CircularProgress color="secondary" size={18} />
                    )}
                  </Box>
                  {!isAfter23Days ? (
                    <Box title={messages.deposit} sx={styles.icon}>
                      <PlusSvg onClick={() => setIsStake(true)} />
                    </Box>
                  ) : (
                    <Box title={messages.after23Days} sx={styles.iconDisabled}>
                      <PlusSvg />
                    </Box>
                  )}
                </Box>
                <Box mt={1}>
                  <Tickets countDays={countDays} />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.information}>
            <Typography variant="span3"> Keep your stake for 24h to earn</Typography>
            <Box sx={styles.containerInformation}>
              <Typography variant="h5" lineHeight={1}>
                {stakedUsdc} LABEL
              </Typography>
              <Box>
                <Button sx={styles.button} onClick={handleOpenInformation}>
                  <Typography variant="caption" fontWeight={500}>
                    What is $LABEL?
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box sx={{ ...styles.stake, ...styles.addStake }}>
          <CloseIcon onClick={() => setIsStake(false)} sx={styles.closeIcon} />
          <Typography variant="h5" mt={0.5}>
            Add USDC
          </Typography>
          <Box mt={1}>
            <StakeUSDC
              loading={loading}
              form={form}
              onSubmit={onSubmit}
              loadingWithdraw={loadingWithdraw}
              setAmountHandler={setAmountHandler}
            />
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default Step1;

import { FC, useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';
import ModalTune from '@/shared/ModalTune/ModalTune';
import styles from './styles';
import ModalUnstakeLabel from '@/shared/ModalUnstakeLabel/ModalUnstakeLabel';
import Balances from './Balances/Balances';
import { Context } from '@/helpers/context';
import getErrorMessage from '@/utils/getErrorMessage';
import { getBalanceOf, balanceOfDeposit } from '@/utils/contractRequests';
import { SmartContractTypeEnum } from '@/graphql/types/_server';
import formatEthers from '@/utils/formatEthers';
import DrawerHistory from './DrawerHistory/DrawerHistory';
import AssetData from './AssetData/AssetData';

const Assets: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { wallet, contracts } = useContext(Context);
  const [balancesN, setBalancesN] = useState<[string, string, string, string]>(['', '', '', '']);
  const [rewards, setRewards] = useState<bigint>(BigInt('0'));
  const [modalTune, setModalTune] = useState(false);
  const [modalUnstakeLabel, setModalUnstakeLabel] = useState(false);
  const [openHistory, setOpenHistory] = useState<boolean>(false);

  useEffect(() => {
    if (!contracts.loading) {
      getBalance();
    }
  }, [contracts.loading]);

  if (!wallet.address) {
    return (
      <Alert severity="warning">
        To see your assets and capabilities, please connect your wallet. This is necessary because
        we receive data directly from the Blockchain.
      </Alert>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box my={4} mb={4} sx={styles.assetsWrap}>
        <Box mb={4} sx={styles.titleAssets}>
          <Typography variant="h1">My Assets</Typography>
        </Box>
        <Box sx={styles.assets}>
          <DrawerHistory open={openHistory} onModalClose={() => setOpenHistory(false)} />
          <ModalTune
            modalTune={modalTune}
            onModalTune={() => setModalTune(false)}
            balanceOf={balancesN[0]}
            onGetStakeAmount={getBalance}
          />
          <ModalUnstakeLabel
            modalTune={modalUnstakeLabel}
            onModalTune={() => setModalUnstakeLabel(false)}
            balanceOf={balancesN[2]}
            onGetStakeAmount={getBalance}
          />
          <Balances
            loading={loading}
            onGetStakeAmount={getBalance}
            balancesN={balancesN}
            onModal={() => setModalTune(true)}
            onModalHistory={() => setOpenHistory(true)}
            onModalUnstakeLabel={() => setModalUnstakeLabel(true)}
            rewards={rewards}
          />
          <AssetData />
        </Box>
      </Box>
    </Container>
  );

  async function getBalance() {
    try {
      if (!wallet.ethers) return;
      setLoading(true);
      const [balanceLabel, stakeAmount, balanceOfTune, balanceOfUsdc, rewards] = await Promise.all([
        getBalanceOf(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Label], {
          account: wallet.address
        }),
        balanceOfDeposit(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Label], {
          account: wallet.address
        }),
        getBalanceOf(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Tune], {
          account: wallet.address
        }),
        getBalanceOf(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Campaign], {
          account: wallet.address
        }),
        getBalanceOf(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Lpusdc], {
          account: wallet.address
        })
      ]);

      setBalancesN([
        formatEthers(balanceLabel, 18),
        formatEthers(balanceOfTune, 18),
        formatEthers(stakeAmount, 18),
        formatEthers(balanceOfUsdc, 6)
      ]);
      setRewards(rewards);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }
};

export default Assets;

import { FC, useState, useMemo } from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import styles from './styles';
import PopupMenu from './PopupMenu/PopupMenu';

const AdminMenu: FC<{ theme: 'dark' | 'light' }> = ({ theme }) => {
  const stylesMemoized = useMemo(() => {
    return styles(theme);
  }, [theme]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        variant="text"
        disableElevation
        size="large"
        onClick={openMenuHandler}
        sx={stylesMemoized.button}
        startIcon={<SettingsOutlinedIcon sx={stylesMemoized.icon} />}
      >
        <Typography variant="body2">Administration</Typography>
      </Button>
      <IconButton color="primary" onClick={openMenuHandler} sx={stylesMemoized.iconButton}>
        <SettingsOutlinedIcon sx={{ fontSize: 24 }} />
      </IconButton>
      <PopupMenu open={open} anchorEl={anchorEl} closeMenuHandler={closeMenuHandler} />
    </>
  );

  function openMenuHandler(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function closeMenuHandler() {
    setAnchorEl(null);
  }
};

export default AdminMenu;

import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Header from '@/shared/Header/Header';
import Footer from '@/shared/Footer/Footer';
import styles from './styles';
import { MenuTypeEnum } from '@/shared/Header/types';

const Layout = () => {
  return (
    <Box sx={styles.layout}>
      <Header menuType={MenuTypeEnum.Standard} />
      <Box sx={styles.main}>
        <main>
          <Outlet />
        </main>
      </Box>
      <Footer isBlackLine />
    </Box>
  );
};

export default Layout;

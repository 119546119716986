import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IProps } from './types';
import styles from './styles';
import { ReactComponent as LogoBlackSvg } from '@/files/icons/logoBlack.svg';

const LeftCard: FC<IProps> = ({ balancesN }) => {
  return (
    <Box sx={styles.container}>
      <Box>
        <LogoBlackSvg />
        <Typography variant="h3" mt={4.625}>
          <Box sx={styles.text} component="span">
            LABEL
          </Box>{' '}
          Token
        </Typography>
        <Typography variant="span3" component="p" lineHeight={1.7} mt={3.5}>
          LABEL is the utility token of the Label Protocol. It can be earned by artists and users
          and can be staked to accrue $TUNE, the platforms voting power.
        </Typography>
      </Box>
      <Box sx={styles.containerBalance}>
        <Typography variant="span3">You have</Typography>
        <Box>
          <Typography variant="h5" sx={styles.balanceText}>
            {balancesN[0]} LABEL
          </Typography>
          <Typography variant="span3" component="p" sx={styles.additional}>
            {balancesN[1]} will be earned in 24h
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LeftCard;

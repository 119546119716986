const styles = {
  title: {
    mb: '32px',
    ml: '20px',
    '@media screen and (max-width: 1100px)': {
      mt: 6
    }
  },
  gird: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: '14px',
    rowGap: '24px',
    mb: '68px',
    '@media screen and (max-width: 740px)': {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    '@media screen and (max-width: 460px)': {
      gridTemplateColumns: 'repeat(1, 1fr)'
    }
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    mb: '32px',
    ml: '20px',
    '& > div:last-child': {
      mt: -0.75
    },
    '@media screen and (max-width: 1100px)': {
      mt: 6
    }
  }
};

export default styles;

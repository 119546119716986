/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { ICampaignModel, IQueryCampaignByIdArgs } from '@/graphql/types/_server';

type ICampaignContext = {
  campaign: ICampaignModel;
  src: string;
  refetch: (variables?: Partial<IQueryCampaignByIdArgs> | undefined) => Promise<
    ApolloQueryResult<{
      campaignById: ICampaignModel;
    }>
  >;
  setTimelineChecked: (timelineChecked: boolean) => void;
  stemId: string | any; // TODO
  isStaked: boolean;
  setIsStaked: (isStaked: boolean) => void;
  isShowMenu: boolean;
  setIsShowMenu: (isShowMenu: boolean) => void;
};

export const initContext: ICampaignContext = {
  campaign: {} as unknown as ICampaignModel,
  src: '',
  refetch: () => {
    return new Promise(() => {});
  },
  setTimelineChecked: () => {},
  stemId: '0',
  isStaked: false,
  setIsStaked: () => {},
  isShowMenu: true,
  setIsShowMenu: () => {}
};

export const CampaignContext = createContext<ICampaignContext>(initContext);

const styles = {
  assetsWrap: {
    '@media screen and (max-width: 1100px)': {
      mb: '150px'
    }
  },
  assets: {
    display: 'flex',
    '@media screen and (max-width: 1100px)': {
      alignItems: 'center',
      flexDirection: 'column'
    }
  },
  titleAssets: {
    '@media screen and (max-width: 960px)': {
      mt: '70px'
    }
  },
  container: {
    ml: '47px',
    width: '100%',
    position: 'relative',
    '@media screen and (max-width: 1100px)': {
      m: 'auto'
    }
  }
};

export default styles;

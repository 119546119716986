import IconButton from '@mui/material/IconButton';
import { useContext, useState, FC, useMemo } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';
import { useApolloClient } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Context } from '@/helpers/context';
import CartItem from './CartItem/CartItem';
import { getSignatureForPermit } from '@/helpers/blockchain/permit';
import getErrorMessage from '@/utils/getErrorMessage';
import { SmartContractTypeEnum } from '@/graphql/types/_server';
import { IPermitSignature } from '@/helpers/blockchain/permit/types';
import { ReactComponent as EmptyCartSvg } from '@/files/icons/emptyCart.svg';
import styles from './styles';
import { buyRemixes } from '@/utils/contractRequests';
import { checkBalanceMatic, checkBalanceUsdc } from '@/utils/checkBalanceToken';

const Cart: FC<{ theme: 'dark' | 'light' }> = ({ theme }) => {
  const stylesMemoized = useMemo(() => {
    return styles(theme);
  }, [theme]);

  const { cart, setCart, openCart, setOpenCart, wallet } = useContext(Context);
  const { cache } = useApolloClient();
  const { contracts } = useContext(Context);
  const [loading, setLoading] = useState<boolean>(false);

  const emptyCartMessage = (
    <Box sx={stylesMemoized.emptyCart}>
      <EmptyCartSvg />
      <Typography variant="subtitle2" sx={stylesMemoized.emptyCartText}>
        Your Shopping List is empty.
        <br />
        Use{' '}
        <Link onClick={openCartHandler} to="/store">
          Store
        </Link>{' '}
        to add someting here.
      </Typography>
      <Typography variant="caption" sx={stylesMemoized.emptyCartSmallText}>
        Your NFTs are available{' '}
        <Link onClick={openCartHandler} to="/assets">
          here
        </Link>
      </Typography>
    </Box>
  );

  const totalAmount = useMemo(() => {
    return cart.reduce((total, item) => total + item.price, 0);
  }, [cart]);

  return (
    <>
      <IconButton color="primary" onClick={openCartHandler}>
        <Badge color="secondary" badgeContent={cart.length}>
          <ShoppingCartOutlinedIcon sx={stylesMemoized.icon} />
        </Badge>
      </IconButton>
      <Drawer sx={stylesMemoized.cart} anchor="right" open={openCart} onClose={openCartHandler}>
        <Box sx={stylesMemoized.header}>
          <Typography variant="h6" sx={stylesMemoized.title}>
            Shopping List
          </Typography>
          {(loading || contracts.loading) && (
            <Box ml={2} display="flex" justifyContent="center">
              <CircularProgress size={24} color="secondary" />
            </Box>
          )}
          <IconButton sx={stylesMemoized.closeIcon} onClick={openCartHandler}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box p={2} sx={stylesMemoized.items}>
          {cart.length === 0 && emptyCartMessage}
          {cart.map((item) => {
            return <CartItem key={item.id} item={item} loading={loading} />;
          })}
        </Box>
        {cart.length !== 0 && (
          <Box sx={stylesMemoized.container}>
            <Button
              disabled={loading || contracts.loading}
              sx={stylesMemoized.button}
              fullWidth
              color="secondary"
              variant="contained"
              type="submit"
              disableElevation
              onClick={buyRemixMutation}
            >
              Checkout
            </Button>
            <Typography variant="info" fontWeight={500}>
              {totalAmount} USDC
            </Typography>
          </Box>
        )}
      </Drawer>
    </>
  );

  function openCartHandler() {
    setOpenCart(!openCart);
  }

  async function getUsdcContractResponse() {
    try {
      if (!wallet?.ethers) {
        throw Error('No provider');
      }
      const usdcContract = contracts.contracts[SmartContractTypeEnum.Usdc];

      const addresses: { usdc: string; owner: string; spender: string } = {
        usdc: usdcContract.address,
        owner: wallet.address,
        spender: contracts.contracts[SmartContractTypeEnum.Remix].address
      };

      const contract = wallet.ethers.getContractForRead({
        contractAddress: usdcContract.address,
        abi: usdcContract.abi
      });

      return {
        contract,
        provider: wallet.ethers.getProvider(),
        addresses,
        myAddress: wallet.address
      };
    } catch (error) {
      toast.error(getErrorMessage(error));
      return null;
    }
  }

  async function handlePermit(amount: number): Promise<IPermitSignature | null> {
    try {
      const response = await getUsdcContractResponse();

      if (response) {
        toast.info('Please confirm the transaction in Metamask');

        return getSignatureForPermit(response, amount);
      }
      return null;
    } catch (error) {
      toast.error(getErrorMessage(error));
      return null;
    }
  }

  async function buyRemixMutation() {
    try {
      await Promise.all([
        checkBalanceMatic(wallet),
        checkBalanceUsdc(wallet, contracts.contracts, totalAmount)
      ]);
      const payload = await handlePermit(totalAmount);

      if (payload) {
        if (!wallet.ethers) return;
        setLoading(true);
        let loadingLocal = true;
        const successCallback = () => {
          if (loadingLocal) {
            loadingLocal = false;
            updateRemixStates();
            setCart([]);
            setLoading(false);
            toast.success('Purchase is successful');
          }
        };

        toast.info('Buying... Please wait');
        const tokenIDs = cart.map((item) => item.tokenId);

        const providerSign: any = wallet.ethers?.getSigner();
        wallet.ethers.setSigner(providerSign);

        await buyRemixes(
          wallet.ethers,
          contracts.contracts[SmartContractTypeEnum.Remix],
          {
            tokenIDs,
            amount: payload.amount,
            deadline: payload.deadline,
            v: payload.v,
            r: payload.r,
            s: payload.s
          },
          {
            Hash: () => {
              toast.info('Waiting for the transaction to complete');
            },
            Error: () => {
              setLoading(false);
            },
            After: successCallback
          }
        );

        successCallback();
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
      setLoading(false);
    }
  }

  function updateRemixStates() {
    cart.forEach((item) => {
      cache.modify({
        id: cache.identify({ id: item.id, __typename: 'RemixModel' }),
        fields: {
          countNftBought: (value) => value + 1,
          countNFT: (value) => value - 1
        }
      });
    });
  }
};

export default Cart;

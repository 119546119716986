import { FC, useEffect, useState, memo } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import VolumeIcon from '../../Icon/Volume';
import styles from './styles';
import { IProps } from './types';

const Volume: FC<IProps> = ({ waver, loading }) => {
  const [open, setOpen] = useState(false);
  const [volume, setVolume] = useState<number>(
    waver && !waver.isDestroyed ? waver?.getVolume() : 1
  );
  const [mute, setMute] = useState<boolean>(waver && !waver.isDestroyed ? waver?.getMute() : false);

  // TODO: changing volume changes mute https://github.com/katspaugh/wavesurfer.js/issues/2502
  useEffect(() => {
    if (waver && !waver.isDestroyed && mute !== waver.getMute() && !loading) {
      waver.setMute(mute);
    }
  }, [waver, mute, loading]);

  useEffect(() => {
    if (waver && !waver.isDestroyed && volume !== waver.getVolume()) {
      waver.setVolume(volume);
    }
  }, [waver, volume, loading]);

  return (
    <Box sx={styles.container}>
      <VolumeIcon handleClick={handleClick} mute={mute} value={volume} style={styles.icon} />
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box sx={styles.popper}>
            <Box sx={styles.containerVolume}>
              <Box>
                <Slider
                  aria-label="indicator"
                  size="small"
                  orientation="vertical"
                  color="secondary"
                  min={0}
                  step={1}
                  max={100}
                  sx={styles.volume}
                  value={volume * 100}
                  onChange={(_, value) => handleVolume(value as number)}
                />
              </Box>
              <VolumeIcon handleClick={handleMute} mute={mute} value={volume} />
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );

  function handleClick() {
    setOpen(true);
  }

  function handleMute() {
    setMute(!mute);
  }

  function handleVolume(value: number) {
    setVolume(value / 100);
  }
};

export default memo(Volume);

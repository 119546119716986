import { FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './styles';
import Track from './Track/Track';
import PaginationUi from '@/shared/ui/PaginationUi/PaginationUi';
import { IProps } from './types';

const Tracks: FC<IProps> = ({ remixes, total, currentPage, setCurrentPage, pageSize }) => {
  const calculateTotalPages = useMemo(() => Math.ceil(Number(total) / pageSize), [pageSize, total]);

  return (
    <>
      <Box sx={styles.containerTitle}>
        <Typography variant="h6">Tracks</Typography>
        {total > pageSize && (
          <PaginationUi
            count={calculateTotalPages}
            page={currentPage + 1}
            setPage={setCurrentPage}
          />
        )}
      </Box>
      <Box sx={styles.gird}>
        {remixes.map((item) => (
          <Track key={`${item.id}_track`} remix={item} />
        ))}
      </Box>
    </>
  );
};

export default Tracks;

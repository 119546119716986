import { FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import { IProps } from './types';
import styles from './styles';
import IconButtonUi from '@/shared/ui/IconButtonUi/IconButtonUi';
import getCloudImgUrl from '@/utils/getUrlImage';

const Images: FC<IProps> = ({
  files,
  handleFileDelete = () => {},
  baseFile,
  setCroppedImage,
  multiple
}) => {
  const stylesMemoized = useMemo(() => styles(multiple), [multiple]);

  return (
    <Box sx={stylesMemoized.items}>
      {baseFile !== '' ? (
        <Box sx={stylesMemoized.item} key={baseFile}>
          <img src={baseFile} alt="" />
          <Box sx={stylesMemoized.controll}>
            <IconButtonUi
              handleClick={(event) => {
                setCroppedImage && setCroppedImage('');
                handleRemove(event, files[0], true);
              }}
              fontSize={24}
              Icon={DeleteIcon}
            />
          </Box>
        </Box>
      ) : (
        files.map((item) => (
          <Box sx={stylesMemoized.item} key={`${item.name}_${item.lastModified}`}>
            <img
              src={
                item.name === 'NewFile'
                  ? URL.createObjectURL(item)
                  : getCloudImgUrl(item?.name, 250)
              }
              alt=""
            />
            <Box sx={stylesMemoized.controll}>
              <IconButtonUi
                handleClick={(event) => handleRemove(event, item)}
                fontSize={24}
                Icon={DeleteIcon}
              />
            </Box>
          </Box>
        ))
      )}
    </Box>
  );

  function handleRemove(
    event: React.MouseEvent<HTMLButtonElement>,
    file: File,
    isCroppedDelete?: boolean
  ) {
    event.stopPropagation();
    handleFileDelete(file, isCroppedDelete);
  }
};

export default Images;

const styles = {
  container: {
    position: 'relative',
    zIndex: 1
  },
  sliderLineBlock: {
    overflow: 'hidden',
    background: '#191a1c',
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    maxWidth: '460px',
    m: '0 3rem',
    height: 0,
    paddingTop: 'min(67.6%, 310px)',
    borderRadius: '40px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'all 0.5s',
    '&:hover': {
      zIndex: 999,
      transform: 'scale(1.2)',
      position: 'absolute'
    },
    '&:hover > div:first-of-type': {
      ml: '19px'
      // mb: '15px'
    },
    '&:hover > div:first-of-type > button': {
      mb: 0,
      opacity: 1
    },
    '&:hover > div:first-of-type > p:first-of-type': {
      // mb: 1.25
    }
  },
  sliderLineText: {
    display: 'block',
    position: 'absolute',
    left: '24px',
    bottom: '24px',
    color: 'black',
    transition: 'all 0.3s'
  },
  white: {
    color: '#fff'
  },
  descr: {
    transition: 'all 0.3s',
    fontFamily: '"Grafita", monospace, sans-serif'
  },
  btn: {
    cursor: 'default',
    background: '#F3F700',
    borderRadius: '30px',
    position: 'absolute',
    right: '24px',
    bottom: '24px',
    height: '32px',
    width: '52px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      fontWeight: 500
    },
    '&:hover': {
      backgroundColor: '#F3F700'
    }
  },
  buttonWhite: {
    mt: 3,
    width: '143px',
    backgroundColor: 'white',
    color: 'black',
    position: 'relative',
    height: '32px',
    transition: 'margin-bottom 0.7s, opacity 1s',
    mb: '-100px',
    opacity: 0,
    ':hover': {
      backgroundColor: '#e5e5e5'
    }
  }
};

export default styles;

const styles = {
  backdrop: {
    zIndex: -1,
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.6)',
    backdropFilter: 'blur(24px)',
    '-webkit-tap-highlight-color': 'transparent',
    outline: 0
  },
  container: {
    overflowY: 'auto',
    justifyContent: 'center',
    position: 'relative',
    top: '72px',
    margin: 'auto',
    maxWidth: '951px',
    '&:focus-visible': {
      outline: 'none'
    }
  },
  closeIcon: {
    position: 'absolute',
    right: '30px',
    top: '24px',
    color: 'white',
    '& > svg': {
      fontSize: '32px'
    }
  }
};

export default styles;

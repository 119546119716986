import { Dispatch, FC, SetStateAction, useContext, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import styles from './styles';
import getErrorMessage from '@/utils/getErrorMessage';
import { ReactComponent as TicketWin } from '@/files/images/ticketWin.svg';
import { CampaignContext } from '@/components/Campaigns/Campaign/context';
import Layout from '@/components/Campaigns/Campaign/shared/Layout/Layout';
import Logo from '@/components/Campaigns/Campaign/shared/Logo/Logo';
import Campaing from '@/components/Campaigns/Campaign/shared/Campaing/Campaing';
import StepWin from '../StepWin/StepWin';
import { Context } from '@/helpers/context';
import { SmartContractTypeEnum } from '@/graphql/types/_server';
import { mintStem } from '@/utils/contractRequests';
import { IDataStem } from '../../types';
import { checkBalanceMatic } from '@/utils/checkBalanceToken';

const Step0: FC<{ setDataStem: Dispatch<SetStateAction<IDataStem>> }> = ({ setDataStem }) => {
  const { wallet, contracts } = useContext(Context);
  const { campaign, setTimelineChecked, stemId, setIsShowMenu } = useContext(CampaignContext);

  const [runAnimation, setRunAnimation] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsShowMenu(true);
  });

  return !runAnimation ? (
    <Layout leftCard={<Logo />}>
      <Campaing />
      <Box sx={styles.containerTop}>
        <Typography variant="span3">Congratulations</Typography>
        <Typography variant="h5" lineHeight={1} mt={2}>
          You have won
        </Typography>
        <Typography variant="overline" sx={styles.text} lineHeight={1}>
          One of {campaign.numberWinnersLottery}
        </Typography>
        <TicketWin />
      </Box>
      <Box sx={styles.containerBottom}>
        <Typography
          variant="caption"
          component="p"
          fontWeight={300}
          sx={styles.textGrey}
          lineHeight="1.25rem"
        >
          Now grab your NFT to get an access to the {campaign?.name} Stems, make a banger and upload
          to win!
        </Typography>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          disableElevation
          onClick={openPresent}
        >
          <Typography variant="body2">Claim NFT</Typography>
        </LoadingButton>
      </Box>
    </Layout>
  ) : (
    <StepWin handleDownload={handleDownload} />
  );

  async function openPresent() {
    await mintStemHandler();
  }

  async function handleDownload() {
    setDataStem((dataStem) => ({
      ...dataStem,
      data: { ...dataStem.data, isMinted: true, balanceOf: 1 }
    }));
  }

  async function mintStemHandler() {
    try {
      if (!wallet.ethers) return;
      await checkBalanceMatic(wallet);
      setLoading(true);
      let loadingLocal = true;
      const successCallback = () => {
        if (loadingLocal) {
          loadingLocal = false;
          setLoading(false);
          setTimelineChecked(false);
          setRunAnimation(true);
          toast.success('Transaction completed. Received nft...');
        }
      };

      await mintStem(
        wallet.ethers,
        contracts.contracts[SmartContractTypeEnum.Stem],
        {
          tokenID: stemId
        },
        {
          Hash: () => {
            toast.info('Waiting for the transaction to execute...');
          },
          Error: () => {
            setLoading(false);
          },
          After: successCallback
        }
      );

      successCallback();
    } catch (error) {
      toast.error(getErrorMessage(error));
      setLoading(false);
    }
  }
};

export default Step0;

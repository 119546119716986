const styles = {
  container: {
    width: '29.375rem',
    marginRight: 2,
    '@media screen and (max-width: 850px)': {
      marginRight: 0,
      mb: 2,
      width: '32.875rem'
    }
  },
  textGrey: {
    color: '#BDC2C8'
  },
  containerTop: {
    background: '#F3F700',
    padding: '1.75rem 2.8125rem 1.75rem',
    position: 'relative',
    overflow: 'hidden',
    color: 'black',
    '& > svg': {
      position: 'absolute',
      bottom: '-2rem',
      right: '8.5%',
      width: '38.3555%',
      '@media screen and (max-width: 400px)': {
        right: '-5%'
      }
    }
  },
  containerBottom: {
    background: 'black',
    borderBottomRightRadius: '2.5rem',
    borderBottomLeftRadius: '2.5rem',
    padding: '1.375rem 3.3125rem 1.8125rem 2.875rem',
    display: 'grid',
    '& > button': {
      justifySelf: 'end',
      backgroundColor: '#fff',
      color: 'black',
      width: '8.9375rem',
      height: '2rem',
      ':hover': {
        backgroundColor: '#fff'
      },
      '@media screen and (max-width: 900px)': {
        mt: 2
      },
      '@media screen and (max-width: 850px)': {
        mt: 0
      },
      '@media screen and (max-width: 500px)': {
        mt: 2
      }
    }
  },
  containerWhite: {
    borderTopRightRadius: '2.5rem',
    borderTopLeftRadius: '2.5rem',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '0.625rem 0.8125rem 0.6875rem',
    '@media screen and (max-width: 400px)': {
      flexDirection: 'column'
    }
  },
  logo: {
    height: '10rem',
    minWidth: '10.5rem',
    backgroundColor: '#1a1a1a',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '2.5rem',
    marginRight: '1rem'
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      cursor: 'pointer',
      ml: 1
    }
  },
  containerText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
};

export default styles;

// ------------------------------------------------------
// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export interface IAddRemixForVoteDto {
  /** Remix author */
  author?: InputMaybe<Scalars['String']>;
  /** Beats */
  beats: Scalars['Int'];
  /** Description remix */
  description: Scalars['String'];
  /** Genre */
  genre: Scalars['String'];
  /** Campaign ID */
  idCampaign: Scalars['Float'];
  /** Key */
  key: Scalars['String'];
  /** Remix name */
  name: Scalars['String'];
  /** Token ID Stem */
  tokenIDStem: Scalars['String'];
}

export interface ICampaignCreateDto {
  /** Campaign description */
  description?: InputMaybe<Scalars['String']>;
  /** Is White Name */
  isWhiteName?: InputMaybe<Scalars['Boolean']>;
  /** Campaign name */
  name: Scalars['String'];
  /** Number of winners lottery: @Min(1), @Max(100) */
  numberWinnersLottery: Scalars['Int'];
  /** Number of winners voting: @Min(1), @Max(10) */
  numberWinnersVoting: Scalars['Int'];
  /** Price */
  price?: InputMaybe<Scalars['Float']>;
  sponsorId: Scalars['Int'];
  /** Lottery start date */
  startLotteryDate: Scalars['String'];
}

export interface ICampaignGetDto {
  /** parameters for filtering */
  filters?: InputMaybe<Array<IFilterDto>>;
  /** parameters for pagination */
  paginate?: InputMaybe<IPaginateDto>;
  /** parameters for sort */
  sorts?: InputMaybe<Array<ISortDto>>;
}

export interface ICampaignIdDto {
  /** parameters for filtering */
  filters?: InputMaybe<Array<IFilterDto>>;
  id: Scalars['Int'];
}

export interface ICampaignModel {
  __typename?: 'CampaignModel';
  /** Date of selection of the winner (7 days after startVotingDate) */
  checkDate: Scalars['String'];
  createdDate: Scalars['DateTime'];
  /** Campaign description */
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Is White Name */
  isWhiteName?: Maybe<Scalars['Boolean']>;
  /** Logo url */
  logoUrl: Scalars['String'];
  /** Campaign name */
  name: Scalars['String'];
  /** Network */
  network: INetworkModel;
  /** Number of winners lottery: @Min(1), @Max(100) */
  numberWinnersLottery: Scalars['Int'];
  /** Number of winners voting: @Min(1), @Max(10) */
  numberWinnersVoting: Scalars['Int'];
  /** Price */
  price?: Maybe<Scalars['Float']>;
  remixes?: Maybe<Array<IRemixModel>>;
  /** Sponsor */
  sponsor?: Maybe<IUserModel>;
  /** Lottery start date */
  startLotteryDate: Scalars['String'];
  /** Remix start date (30 days after startLotteryDate) */
  startRemixDate: Scalars['String'];
  /** Voting start date (14 days after startRemixDate) */
  startVotingDate: Scalars['String'];
  status: CampaignStatusEnum;
  /** Stem name */
  stemName: Scalars['String'];
  /** Stem size */
  stemSize: Scalars['Int'];
  /** Stem url */
  stemUrl: Scalars['String'];
  /** Campaign Id Blockchain */
  tokenID?: Maybe<Scalars['String']>;
  updatedDate: Scalars['DateTime'];
  /** Creator */
  user: IUserModel;
}

export enum CampaignStatusEnum {
  Canceled = 'canceled',
  Check = 'check',
  Done = 'done',
  Draft = 'draft',
  Lottery = 'lottery',
  Remix = 'remix',
  Repayment = 'repayment',
  Voting = 'voting'
}

export interface ICampaignUpdateDto {
  /** Campaign description */
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Is White Name */
  isWhiteName?: InputMaybe<Scalars['Boolean']>;
  /** Campaign name */
  name?: InputMaybe<Scalars['String']>;
  /** Number of winners lottery: @Min(1), @Max(100) */
  numberWinnersLottery?: InputMaybe<Scalars['Int']>;
  /** Number of winners voting: @Min(1), @Max(10) */
  numberWinnersVoting?: InputMaybe<Scalars['Int']>;
  /** Price */
  price?: InputMaybe<Scalars['Float']>;
  sponsorId?: InputMaybe<Scalars['Int']>;
  /** Lottery start date */
  startLotteryDate?: InputMaybe<Scalars['String']>;
}

export interface ICampaignUpdatePriceDto {
  id: Scalars['Int'];
  /** Price */
  price?: InputMaybe<Scalars['Float']>;
}

/** TYPE for a list of campaigns with pagination */
export interface ICampaignsModel {
  __typename?: 'CampaignsModel';
  items: Array<ICampaignModel>;
  meta: IPaginateModel;
}

export interface IChangeRemixForVoteDto {
  /** Remix author */
  author?: InputMaybe<Scalars['String']>;
  /** Beats */
  beats?: InputMaybe<Scalars['Int']>;
  /** Description remix */
  description?: InputMaybe<Scalars['String']>;
  /** Genre */
  genre?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Campaign ID */
  idCampaign: Scalars['Float'];
  /** Key */
  key?: InputMaybe<Scalars['String']>;
  /** Remix name */
  name?: InputMaybe<Scalars['String']>;
  /** Token ID Stem */
  tokenIDStem: Scalars['String'];
}

export enum CollectionTypeEnum {
  Remix = 'remix',
  Stem = 'stem'
}

export interface IDeprecatedCampaignUpdateDto {
  id: Scalars['Int'];
}

export interface IDeprecatedGenerateLabelsDto {
  /** Wallet */
  address: Scalars['String'];
  amount: Scalars['Int'];
}

export interface IDeprecatedGenerateTicketsDto {
  /** Wallet */
  address: Scalars['String'];
  amount: Scalars['Int'];
  id: Scalars['Int'];
}

export interface IDeprecatedGenerateTunesDto {
  /** Wallet */
  address: Scalars['String'];
  amount: Scalars['Int'];
}

export interface IFilterDto {
  columnName: Scalars['String'];
  operation: FilterOperationEnum;
  type: FilterFieldTypeEnum;
  value: Array<Scalars['String']>;
}

export enum FilterFieldTypeEnum {
  Null = 'NULL',
  Boolean = 'boolean',
  Date = 'date',
  DateRange = 'dateRange',
  Number = 'number',
  NumberRange = 'numberRange',
  Text = 'text'
}

export enum FilterOperationEnum {
  Contains = 'contains',
  Equal = 'equal',
  NotEqual = 'notEqual'
}

export interface IMetaDataDto {
  description: Scalars['String'];
  name: Scalars['String'];
  type: CollectionTypeEnum;
}

export interface IMetaDataModel {
  __typename?: 'MetaDataModel';
  contractURI_: Scalars['String'];
  name_: Scalars['String'];
  symbol_: Scalars['String'];
}

export interface IMutation {
  __typename?: 'Mutation';
  /** @protected - upload the remix to the campaign */
  addRemixForVote: IRemixModel;
  /** Cancel campaign */
  cancelCampaignByAdmin: ICampaignModel;
  /** @new - create campaign */
  createCampaign: ICampaignModel;
  /** @protected - create remix */
  createRemixByAdmin: IRemixModel;
  /** @public - create sponsor */
  createSponsorByAdmin: IUserModel;
  /** A method for updating campaign status for campaign testing. Will not work on production */
  deprecatedChangeNextStatusCampaign: Scalars['String'];
  /** Method for generating LABELS in test mode. Will not work on production */
  deprecatedGenerateLabels: Scalars['String'];
  /** Method for generating tickets in test mode. Will not work on production */
  deprecatedGenerateTickets: Scalars['String'];
  /** Method for generating TUNEs in test mode. Will not work on production */
  deprecatedGenerateTunes: Scalars['String'];
  /** @public - sends verification code to email */
  getVerificationEmailCode: Scalars['Boolean'];
  /** @public - user authorization */
  login: IUserTokenDto;
  /** Update the price of a remix */
  prepareMetadataContractURI: IMetaDataModel;
  /** @new - create campaign */
  updateCampaign: ICampaignModel;
  /** @protected - обновление данных смартконтракта */
  updateContract: Scalars['Boolean'];
  /** Update the price of a remix from a campaign */
  updatePriceCampaignByAdmin: ICampaignModel;
  /** Update the price of a remix */
  updatePriceRemixByAdmin: IRemixModel;
  /** @protected - upload the remix to the campaign */
  updateRemixForVote: IRemixModel;
  /** @protected - update status remix */
  updateStatusRemixByAdmin: IRemixModel;
  /** @public - update user profile */
  updateUser: IUserModel;
  /** @public - update user type to admin */
  updateUserTypeToAdmin: IUserModel;
  /** @public - update user type to sponsor */
  updateUserTypeToSponsor: IUserModel;
  /** @new - upload stem */
  uploadCampaignStem: ICampaignModel;
  /** @public - change password via code */
  verificationEmail: Scalars['Boolean'];
  /** Withdraw USDC */
  withdrawUSDC: IWithdrawUsdcdto;
}

export type IMutationAddRemixForVoteArgs = {
  audio: Scalars['Upload'];
  chainId: Scalars['Int'];
  logo: Scalars['Upload'];
  payload: IAddRemixForVoteDto;
};

export type IMutationCancelCampaignByAdminArgs = {
  payload: ICampaignIdDto;
};

export type IMutationCreateCampaignArgs = {
  chainId: Scalars['Int'];
  logo: Scalars['Upload'];
  payload: ICampaignCreateDto;
};

export type IMutationCreateRemixByAdminArgs = {
  audio: Scalars['Upload'];
  chainId: Scalars['Int'];
  logo: Scalars['Upload'];
  payload: IRemixCreateDto;
};

export type IMutationCreateSponsorByAdminArgs = {
  logo: Scalars['Upload'];
  payload: ISponsorCreateDto;
};

export type IMutationDeprecatedChangeNextStatusCampaignArgs = {
  payload: IDeprecatedCampaignUpdateDto;
};

export type IMutationDeprecatedGenerateLabelsArgs = {
  payload: IDeprecatedGenerateLabelsDto;
};

export type IMutationDeprecatedGenerateTicketsArgs = {
  payload: IDeprecatedGenerateTicketsDto;
};

export type IMutationDeprecatedGenerateTunesArgs = {
  payload: IDeprecatedGenerateTunesDto;
};

export type IMutationLoginArgs = {
  payload: IUserLoginDto;
};

export type IMutationPrepareMetadataContractUriArgs = {
  logo: Scalars['Upload'];
  payload: IMetaDataDto;
};

export type IMutationUpdateCampaignArgs = {
  logo?: InputMaybe<Scalars['Upload']>;
  payload: ICampaignUpdateDto;
};

export type IMutationUpdateContractArgs = {
  payload: ISmartContractConfigUpdateDto;
};

export type IMutationUpdatePriceCampaignByAdminArgs = {
  payload: ICampaignUpdatePriceDto;
};

export type IMutationUpdatePriceRemixByAdminArgs = {
  payload: IRemixUpdatePriceDto;
};

export type IMutationUpdateRemixForVoteArgs = {
  audio?: InputMaybe<Scalars['Upload']>;
  chainId: Scalars['Int'];
  logo?: InputMaybe<Scalars['Upload']>;
  payload: IChangeRemixForVoteDto;
};

export type IMutationUpdateStatusRemixByAdminArgs = {
  chainId: Scalars['Int'];
  payload: IRemixUpdateStatusDto;
};

export type IMutationUpdateUserArgs = {
  logo?: InputMaybe<Scalars['Upload']>;
  payload: IUserUpdateDto;
};

export type IMutationUpdateUserTypeToAdminArgs = {
  payload: IUserIdDto;
};

export type IMutationUpdateUserTypeToSponsorArgs = {
  payload: IUserIdDto;
};

export type IMutationUploadCampaignStemArgs = {
  payload: ICampaignIdDto;
  stem: Scalars['Upload'];
};

export type IMutationVerificationEmailArgs = {
  payload: IUserVerificationCodeDto;
};

export type IMutationWithdrawUsdcArgs = {
  chainId: Scalars['Int'];
  payload: ICampaignIdDto;
};

export interface INetworkIdDto {
  id: Scalars['Int'];
}

export interface INetworkModel {
  __typename?: 'NetworkModel';
  campaign?: Maybe<Array<ICampaignModel>>;
  createdDate: Scalars['DateTime'];
  id: Scalars['Int'];
  /** Network name */
  name: Scalars['String'];
  remix?: Maybe<Array<IRemixModel>>;
  smartContractConfig?: Maybe<Array<ISmartContractConfigModel>>;
  updatedDate: Scalars['DateTime'];
}

export interface IPaginateDto {
  skip: Scalars['Int'];
  take: Scalars['Int'];
}

export interface IPaginateModel {
  __typename?: 'PaginateModel';
  skip: Scalars['Int'];
  take: Scalars['Int'];
  total: Scalars['Int'];
}

export interface IQuery {
  __typename?: 'Query';
  /** @new - getting a campaign by ID */
  campaignById: ICampaignModel;
  /** @new - getting a list of campaigns */
  campaigns: ICampaignsModel;
  /** @new - getting a list of campaigns by admin */
  campaignsByAdmin: ICampaignsModel;
  contracts: Array<ISmartContractConfigModel>;
  getContract: ISmartContractConfigModel;
  /** @pretected - getting URL for KYC */
  getVerificationURL: IUserKycdto;
  /** @protected - getting the current user */
  me: IUserModel;
  myRemixes: IRemixesModel;
  myStems: IStemsModel;
  /** @public - getting a remix by ID */
  remixById: IRemixModel;
  /** @public - getting a list of remixes */
  remixes: IRemixesModel;
  /** @public - getting a list of remixes */
  remixesByAdmin: IRemixesModel;
  /** @public - getting a list of top 10 remixes */
  remixesTopForPeriod: IRemixesModel;
  /** @public - search */
  search: ISearchModel;
  stakeByAccount: ITransactionModel;
  stemByCampaign: IStemModel;
  /** @public - getting a list of remixes */
  transactions: ITransactionsModel;
  /** @public - get user by userName */
  userByUserName: IUserSmallModel;
  /** @public - getting a list users */
  users: IUsersModel;
}

export type IQueryCampaignByIdArgs = {
  payload: ICampaignIdDto;
};

export type IQueryCampaignsArgs = {
  payload: ICampaignGetDto;
};

export type IQueryCampaignsByAdminArgs = {
  payload: ICampaignGetDto;
};

export type IQueryGetContractArgs = {
  chainId: Scalars['Int'];
  payload: ISmartContractTypeDto;
};

export type IQueryGetVerificationUrlArgs = {
  payload: IUserVerificationDto;
};

export type IQueryMyRemixesArgs = {
  chainId: Scalars['Int'];
  payload: IRemixGetDto;
};

export type IQueryMyStemsArgs = {
  chainId: Scalars['Int'];
  payload: IStemGetDto;
};

export type IQueryRemixByIdArgs = {
  chainId: Scalars['Int'];
  payload: IRemixIdDto;
};

export type IQueryRemixesArgs = {
  chainId: Scalars['Int'];
  payload: IRemixGetDto;
};

export type IQueryRemixesByAdminArgs = {
  chainId: Scalars['Int'];
  payload: IRemixGetDto;
};

export type IQueryRemixesTopForPeriodArgs = {
  chainId: Scalars['Int'];
};

export type IQuerySearchArgs = {
  search: Scalars['String'];
};

export type IQueryStakeByAccountArgs = {
  payload: ICampaignIdDto;
};

export type IQueryStemByCampaignArgs = {
  chainId: Scalars['Int'];
  payload: IStemGetIdDto;
};

export type IQueryTransactionsArgs = {
  chainId: Scalars['Int'];
  payload: ITransactionGetDto;
};

export type IQueryUserByUserNameArgs = {
  userName: Scalars['String'];
};

export type IQueryUsersArgs = {
  payload: IUsersGetDto;
};

export interface IRemixCreateDto {
  /** Remix author */
  author?: InputMaybe<Scalars['String']>;
  /** Beats */
  beats: Scalars['Int'];
  /** Description remix */
  description: Scalars['String'];
  /** Genre */
  genre: Scalars['String'];
  /** Sponsor ID */
  idSponsor: Scalars['Float'];
  /** Key */
  key: Scalars['String'];
  /** Remix name */
  name: Scalars['String'];
  /** Price */
  price: Scalars['Float'];
}

export interface IRemixGetDto {
  /** parameters for filtering */
  filters?: InputMaybe<Array<IFilterDto>>;
  /** parameters for pagination */
  paginate?: InputMaybe<IPaginateDto>;
  /** parameters for sort */
  sorts?: InputMaybe<Array<ISortDto>>;
}

export interface IRemixIdDto {
  id: Scalars['Int'];
}

export interface IRemixModel {
  __typename?: 'RemixModel';
  /** Amount NFT */
  amount?: Maybe<Scalars['Int']>;
  /** Remix author */
  author?: Maybe<Scalars['String']>;
  /** Beats */
  beats: Scalars['Int'];
  /** Campaign */
  campaign?: Maybe<ICampaignModel>;
  /** Count of available NFTs for sale */
  countNFT: Scalars['Int'];
  countNftBought: Scalars['Float'];
  createdDate: Scalars['DateTime'];
  /** Description remix */
  description: Scalars['String'];
  /** Genre */
  genre: Scalars['String'];
  id: Scalars['Int'];
  /** Is First Edition NFT */
  isFirstEdition?: Maybe<Scalars['Boolean']>;
  /** Key */
  key: Scalars['String'];
  /** Logo url */
  logoUrl: Scalars['String'];
  /** Remix name */
  name: Scalars['String'];
  /** Network */
  network: INetworkModel;
  /** Price */
  price: Scalars['Float'];
  /** Sponsor */
  sponsor?: Maybe<IUserModel>;
  /** Status of remix */
  status: RemixStatusEnum;
  /** Token ID in blockchain */
  tokenID: Scalars['String'];
  /** Token ID first edition in blockchain */
  tokenIDFirstEdition?: Maybe<Scalars['String']>;
  /** Track length */
  trackLength: Scalars['Int'];
  /** Track name */
  trackName: Scalars['String'];
  /** Track url */
  trackUrl: Scalars['String'];
  updatedDate: Scalars['DateTime'];
  /** Author */
  user: IUserModel;
  /** Votes */
  votes: Scalars['Int'];
}

export enum RemixStatusEnum {
  Canceled = 'canceled',
  Check = 'check',
  Draft = 'draft',
  Rejected = 'rejected',
  Store = 'store',
  Voting = 'voting'
}

export interface IRemixUpdatePriceDto {
  id: Scalars['Int'];
  /** Price */
  price: Scalars['Float'];
}

export interface IRemixUpdateStatusDto {
  id: Scalars['Int'];
  /** Status of remix */
  status: RemixStatusEnum;
}

/** TYPE for a list of remixes with pagination */
export interface IRemixesModel {
  __typename?: 'RemixesModel';
  items: Array<IRemixModel>;
  meta: IPaginateModel;
}

export interface ISearchModel {
  __typename?: 'SearchModel';
  campaign?: Maybe<Array<ICampaignModel>>;
  remix?: Maybe<Array<IRemixModel>>;
  user?: Maybe<Array<IUserModel>>;
}

export interface ISmartContractConfigModel {
  __typename?: 'SmartContractConfigModel';
  abi: Scalars['String'];
  /** Адрес */
  address: Scalars['String'];
  /** Дата создания */
  createdDate: Scalars['DateTime'];
  id: Scalars['Int'];
  /** Network */
  network: INetworkModel;
  /** Тип смартконтракта */
  type: SmartContractTypeEnum;
  updatedDate: Scalars['DateTime'];
}

export interface ISmartContractConfigUpdateDto {
  abi: Scalars['String'];
  /** Адрес */
  address: Scalars['String'];
  network: INetworkIdDto;
  secretKey: Scalars['String'];
  /** Тип смартконтракта */
  type: SmartContractTypeEnum;
}

export interface ISmartContractTypeDto {
  /** Тип смартконтракта */
  type: SmartContractTypeEnum;
}

export enum SmartContractTypeEnum {
  Aave = 'aave',
  Campaign = 'campaign',
  Label = 'label',
  Lpusdc = 'lpusdc',
  Remix = 'remix',
  Stem = 'stem',
  Tune = 'tune',
  Usdc = 'usdc'
}

export interface ISortDto {
  columnName: Scalars['String'];
  direction: SortDirectionEnum;
}

export enum SortDirectionEnum {
  Asc = 'asc',
  Desc = 'desc'
}

export interface ISponsorCreateDto {
  /** Wallet address */
  address: Scalars['String'];
  /** Description */
  description: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** User Name */
  userName: Scalars['String'];
}

export interface IStemGetDto {
  /** parameters for filtering */
  filters?: InputMaybe<Array<IFilterDto>>;
  /** parameters for pagination */
  paginate?: InputMaybe<IPaginateDto>;
  /** parameters for sort */
  sorts?: InputMaybe<Array<ISortDto>>;
}

export interface IStemGetIdDto {
  campaignId: Scalars['Int'];
}

export interface IStemModel {
  __typename?: 'StemModel';
  /** Campaign */
  campaign: ICampaignModel;
  createdDate: Scalars['DateTime'];
  id: Scalars['Int'];
  /** Is Minted Stem */
  isMinted?: Maybe<Scalars['Boolean']>;
  /** Is Spent Stem */
  isSpent?: Maybe<Scalars['Boolean']>;
  /** Logo url */
  logoUrl?: Maybe<Scalars['String']>;
  /** Network */
  network: INetworkModel;
  /** Stem url */
  stemUrl?: Maybe<Scalars['String']>;
  /** tokenID NFT */
  tokenID: Scalars['String'];
  updatedDate: Scalars['DateTime'];
  /** Creator */
  user: IUserModel;
}

/** TYPE for a list of stems with pagination */
export interface IStemsModel {
  __typename?: 'StemsModel';
  items: Array<IStemModel>;
  meta: IPaginateModel;
}

export interface ITransactionGetDto {
  /** parameters for filtering */
  filters?: InputMaybe<Array<IFilterDto>>;
  /** parameters for pagination */
  paginate?: InputMaybe<IPaginateDto>;
  /** parameters for sort */
  sorts?: InputMaybe<Array<ISortDto>>;
}

export interface ITransactionModel {
  __typename?: 'TransactionModel';
  /** Amount */
  amount: Scalars['String'];
  /** Campaign */
  campaign?: Maybe<ICampaignModel>;
  /** Дата создания */
  createdDate: Scalars['DateTime'];
  /** Fe calc net */
  feeCalcNet: Scalars['String'];
  /** Fee used net */
  feeUsedNet: Scalars['String'];
  /** gasLimit */
  gasLimit: Scalars['String'];
  /** gasPriceGwei */
  gasPriceGwei: Scalars['String'];
  /** gasUsed */
  gasUsed: Scalars['String'];
  /** Transaction hash */
  hash?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** maxFeePerGasGwei */
  maxFeePerGasGwei: Scalars['String'];
  /** maxPriorityFeePerGasGwei */
  maxPriorityFeePerGasGwei: Scalars['String'];
  /** Network */
  network: INetworkModel;
  /** nonce transaction */
  nonce?: Maybe<Scalars['Int']>;
  /** Remix */
  remix?: Maybe<IRemixModel>;
  /** Reward */
  reward: Scalars['String'];
  /** Transaction status */
  status: TransactionStatusEnum;
  /** Stem */
  stem?: Maybe<IStemModel>;
  /** Transaction type */
  type: TransactionTypeEnum;
  updatedDate: Scalars['DateTime'];
  /** user */
  user?: Maybe<IUserModel>;
}

export enum TransactionStatusEnum {
  Canceled = 'canceled',
  Done = 'done',
  Processing = 'processing',
  Processingaave = 'processingaave',
  Waitingaave = 'waitingaave',
  Waitingcampaign = 'waitingcampaign'
}

export enum TransactionTypeEnum {
  Adddatacampaign = 'adddatacampaign',
  Adddataremix = 'adddataremix',
  Adddatastem = 'adddatastem',
  Addremixforvote = 'addremixforvote',
  Buy = 'buy',
  Claimrewards = 'claimrewards',
  Depositaave = 'depositaave',
  Depositcampaign = 'depositcampaign',
  Depositlabel = 'depositlabel',
  Mint = 'mint',
  Rewards = 'rewards',
  Setenddatevote = 'setenddatevote',
  Vote = 'vote',
  Withdrawaave = 'withdrawaave',
  Withdrawcampaign = 'withdrawcampaign',
  Withdrawlabel = 'withdrawlabel'
}

/** TYPE for a list of remixes with pagination */
export interface ITransactionsModel {
  __typename?: 'TransactionsModel';
  items: Array<ITransactionModel>;
  meta: IPaginateModel;
}

export interface IUserIdDto {
  id: Scalars['Int'];
}

export interface IUserKycdto {
  __typename?: 'UserKYCDTO';
  url: Scalars['String'];
}

export interface IUserLoginDto {
  /** Адрес кошелька */
  address: Scalars['String'];
  /** Сообщение */
  message: Scalars['String'];
  /** Подпись */
  signature: Scalars['String'];
}

export interface IUserModel {
  __typename?: 'UserModel';
  /** Wallet address */
  address: Scalars['String'];
  /** Sponsor created by the platform admin */
  admin?: Maybe<IUserModel>;
  /** Date and time of the last code generation attempt */
  codeDate?: Maybe<Scalars['DateTime']>;
  createdDate: Scalars['DateTime'];
  /** Description */
  description: Scalars['String'];
  /** Email */
  email?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Sponsor created by admin */
  isAdminCreated: Scalars['Boolean'];
  /** Block */
  isBlocked: Scalars['Boolean'];
  /** Super admin */
  isSuperAdmin: Scalars['Boolean'];
  /** Verified email */
  isVerifiedEmail: Scalars['Boolean'];
  /** KYC pass flag */
  isVerifiedKYC: Scalars['Boolean'];
  /** Logo */
  logoUrl?: Maybe<Scalars['String']>;
  /** Name */
  name: Scalars['String'];
  type: UserTypeEnum;
  updatedDate: Scalars['DateTime'];
  /** Username */
  userName?: Maybe<Scalars['String']>;
  /** Date and time of verification. */
  verificationDate?: Maybe<Scalars['DateTime']>;
  /** Verification data (geolocation, browser, OS) */
  verificationInfo: Scalars['JSONObject'];
}

export interface IUserSmallModel {
  __typename?: 'UserSmallModel';
  /** Description */
  description: Scalars['String'];
  id: Scalars['Int'];
  /** Logo */
  logoUrl?: Maybe<Scalars['String']>;
  /** Name */
  name: Scalars['String'];
  type: UserTypeEnum;
  /** Username */
  userName?: Maybe<Scalars['String']>;
}

export interface IUserTokenDto {
  __typename?: 'UserTokenDTO';
  token?: Maybe<Scalars['String']>;
}

export enum UserTypeEnum {
  Admin = 'admin',
  Sponsor = 'sponsor',
  User = 'user'
}

export interface IUserUpdateDto {
  /** Description */
  description?: InputMaybe<Scalars['String']>;
  /** Email */
  email?: InputMaybe<Scalars['String']>;
  /** User ID */
  id: Scalars['Float'];
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** Username */
  userName?: InputMaybe<Scalars['String']>;
}

export interface IUserVerificationCodeDto {
  /** Code */
  code: Scalars['Int'];
}

export interface IUserVerificationDto {
  redirect: Scalars['String'];
}

export interface IUsersGetDto {
  /** parameters for filtering */
  filters?: InputMaybe<Array<IFilterDto>>;
  /** parameters for pagination */
  paginate?: InputMaybe<IPaginateDto>;
  /** parameters for sort */
  sorts?: InputMaybe<Array<ISortDto>>;
}

/** Users */
export interface IUsersModel {
  __typename?: 'UsersModel';
  items: Array<IUserModel>;
  meta: IPaginateModel;
}

export interface IWithdrawUsdcdto {
  __typename?: 'WithdrawUSDCDTO';
  /** Client address */
  client: Scalars['String'];
  /** Client reward */
  reward: Scalars['String'];
  /** Backend signature EIP712 */
  signature: Scalars['String'];
  /** Campaign tokenID */
  tokenID: Scalars['String'];
}

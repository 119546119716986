/* eslint-disable react/require-default-props */
import { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as VolumeHigh } from '@/files/icons/volumeHigh.svg';
import { ReactComponent as VolumeLow } from '@/files/icons/volumeLow.svg';
import { ReactComponent as VolumeMid } from '@/files/icons/volumeMid.svg';
import { ReactComponent as VolumeNo } from '@/files/icons/volumeNo.svg';
import { IProps } from './types';

const Volume: FC<IProps & { mute: boolean; value: number; style?: Record<string, string> }> = ({
  handleClick,
  mute,
  value,
  style
}) => {
  return (
    <IconButton onClick={handleClick} color="inherit" sx={style}>
      {(mute || value === 0) && <VolumeNo />}
      {!mute && value > 0 && value <= 0.33 && <VolumeLow />}
      {!mute && value > 0.33 && value <= 0.66 && <VolumeMid />}
      {!mute && value > 0.66 && <VolumeHigh />}
    </IconButton>
  );
};

export default Volume;

const styles = (logoUrl: string) => ({
  logo: {
    maxWidth: '638px',
    paddingTop: '50.1%',
    height: 0,
    '@media screen and (max-width: 460px)': {
      paddingTop: '102.895%'
    },
    position: 'relative',
    cursor: 'pointer',
    '&::before': {
      transition: 'all 0.3s',
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '40px',
      background: 'rgb(25, 26, 28)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage: `url(${logoUrl})`
    },
    '&:hover::before': {
      filter: 'brightness(80%)'
    },
    '&:hover > div > a > div': {
      transition: 'width 0.2s linear',
      background: 'black',
      width: '100%'
    },
    '&:hover > div > a > div p': {
      color: 'white',
      opacity: 1,
      transition: 'opacity 0.5s, color 0.5s'
    }
  },
  item: {
    zIndex: 1,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    overflow: 'hidden',
    mb: '38px',
    '&:nth-of-type(2n + 1)': {
      gridColumn: '1 / 7'
    },
    '&:nth-of-type(2n + 2)': {
      gridColumn: '7 / 13'
    },
    '@media screen and (max-width: 800px)': {
      '& > div:first-of-type': {
        maxWidth: '731px'
      },
      '&:nth-of-type(1n + 1)': {
        gridColumn: '1 / 13'
      }
    }
  },
  icon: {
    background: 'black',
    minWidth: '48px',
    height: '48px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s',
    '&:hover': {
      background: '#232323'
    }
  },
  logoSponsor: {
    minWidth: '48px',
    height: '48px',
    background: 'black',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#f4f4f4',
    cursor: 'pointer',
    transition: 'all 0.3s'
  },
  container: {
    display: 'flex',
    position: 'absolute',
    bottom: '15px',
    left: '17px'
  },
  containerAction: {
    display: 'flex',
    alignItems: 'center',
    transition: 'width 0.1s linear, background 0.1s ease 0.5s',
    width: '48px',
    background: 'transparent',
    borderRadius: '24px',
    maxWidth: 'max-content',
    '&:hover p': {
      color: '#F3F700 !important'
    }
  },
  textSponsor: {
    ml: 0.5,
    color: 'transparent',
    whiteSpace: 'nowrap',
    transition: 'opacity 0.5s, color 0.5s',
    opacity: 0,
    pr: 2.5
  },
  link: {
    transition: 'none',
    borderBottom: 'none'
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&::first-letter': {
      textTransform: 'uppercase'
    }
  }
});

export default styles;

import colors from '@/helpers/theme/colors';

const styles = {
  container: {
    width: '27.25rem',
    background: colors.common.yellow,
    borderRadius: '2.5rem',
    marginRight: '0.875rem',
    maxHeight: '29.25rem',
    color: colors.common.black,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '3rem 2rem 1.5rem',
    '@media screen and (max-width: 850px)': {
      height: '30rem',
      width: '32.875rem',
      marginRight: 0,
      marginBottom: '1rem'
    }
  },
  text: {
    fontFamily: 'Grafita-Special'
  },
  containerBalance: {
    background: colors.iconColor,
    color: colors.common.yellow,
    borderRadius: '1.25rem',
    padding: '0.5625rem 1.8125rem',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      columnGap: '0.875rem'
    }
  },
  balanceText: {
    color: colors.common.white
  },
  additional: {
    width: '7.5rem',
    lineHeight: 1.2,
    maxWidth: '80px'
  }
};

export default styles;

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    borderRadius: '2.5rem',
    width: '41.9375rem',
    color: 'white',
    background: 'black',
    padding: '2.5rem',
    paddingBottom: '1.5rem',
    position: 'relative',
    '@media screen and (max-width: 500px)': {
      padding: '1.5rem'
    }
  },
  containerTop: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 40px)',
    '& > *:first-child': {
      marginRight: '1.25rem',
      minWidth: '3rem',
      fill: '#F3F700'
    },
    '& > *:last-child': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  containerAudio: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > button:last-child': {
      display: 'none'
    },
    ':hover': {
      cursor: 'pointer'
    },
    ':hover > button:last-child': {
      display: 'block'
    }
  },
  textGrey: {
    color: 'rgb(255 255 255 / 30%)'
  },
  textTransparent: {
    color: 'rgb(255 255 255 / 50%)'
  },
  textWhite: {
    color: 'rgb(255 255 255)'
  },
  containerFields: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '1.25rem',
    '@media screen and (max-width: 500px)': {
      gridTemplateColumns: 'initial'
    }
  },
  button: {
    justifySelf: 'end',
    backgroundColor: '#fff',
    color: 'black',
    width: '8.9375rem',
    height: '3rem',
    ':hover': {
      backgroundColor: '#fff'
    },
    '&:disabled': {
      backgroundColor: '#fff',
      opacity: 0.76
    }
  },
  containerForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '0 0.3125rem',
    paddingTop: '1.6875rem'
  },
  icon: {
    position: 'absolute',
    top: '1.125rem',
    right: '2rem',
    cursor: 'pointer'
  },
  textarea: {
    '& > div': {
      p: '2.125 3.25',
      fontFamily: '"Rubik",sans-serif',
      fontSize: '1.5rem',
      fontWeight: '400'
    }
  }
};

export default styles;

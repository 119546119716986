import { FC, useEffect, memo } from 'react';
import Pause from '../../Icon/Pause';
import Play from '../../Icon/Play';
import { IProps } from './types';

const Controls: FC<IProps> = ({ audio, setAudio, waver, loading }) => {
  useEffect(() => {
    if (waver && !waver.isDestroyed && audio?.isPlaying !== waver?.isPlaying()) {
      waver.playPause();
    }
  }, [waver, audio?.isPlaying, loading]);

  if (!audio?.isPlaying) {
    return <Play handleClick={playHandler} />;
  }

  return <Pause handleClick={pauseHandler} />;

  function playHandler() {
    setAudioHandler(true);
  }

  function pauseHandler() {
    setAudioHandler(false);
  }

  function setAudioHandler(isPlaying: boolean) {
    if (!audio) return;
    setAudio({
      name: audio.name,
      user: audio.user,
      id: audio.id,
      showPlayer: true,
      isPlaying,
      logo: audio.logo,
      src: audio.src
    });
  }
};

export default memo(Controls);

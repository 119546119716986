const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr',
    gridGap: '1.5rem'
  },
  campaignWrap: {
    display: 'flex',
    gap: '48px',
    mb: '100px',
    justifyContent: 'space-between',
    '@media screen and (max-width: 1200px)': {
      flexDirection: 'column-reverse',
      alignItems: 'center'
    }
  },
  campaignLeft: {
    width: '50%',
    maxWidth: '671px',
    '@media screen and (max-width: 1200px)': {
      width: '100%',
      maxWidth: 'inherit'
    }
  },
  campaignRight: {
    width: '50%',
    maxWidth: '582px',
    '@media screen and (max-width: 1200px)': {
      width: '100%',
      maxWidth: 'inherit'
    }
  },
  campaignName: {
    borderRadius: '20px',
    p: '48px 48px 32px',
    backgroundColor: '#fff',
    boxShadow:
      '0px 461px 184px rgba(0, 0, 0, 0.02), 0px 259px 156px rgba(0, 0, 0, 0.07), 0px 115px 115px rgba(0, 0, 0, 0.11), 0px 29px 63px rgba(0, 0, 0, 0.13), 0px 0px 0px rgba(0, 0, 0, 0.13)'
  },
  campaignDate: {
    mt: '30px',
    pt: '48px',
    pb: '17px',
    borderRadius: '20px',
    backgroundColor: '#fff',
    boxShadow:
      '0px 461px 184px rgba(0, 0, 0, 0.02), 0px 259px 156px rgba(0, 0, 0, 0.07), 0px 115px 115px rgba(0, 0, 0, 0.11), 0px 29px 63px rgba(0, 0, 0, 0.13), 0px 0px 0px rgba(0, 0, 0, 0.13)',
    '& > div': {
      px: '48px'
    },
    '& > div:nth-of-type(2)': {
      mb: '31px',
      pb: '31px',
      borderBottom: '1px solid rgba(0,0,0,.1)'
    }
  },
  campaignLabel: {
    mb: '11px'
  },
  btn: {
    height: '64px',
    mt: '32px',
    backgroundColor: '#F3F700',
    '&:hover': {
      backgroundColor: '#F3F700'
    }
  },
  dropZoneLogo: {
    color: 'white',
    p: '27px'
  },
  textGrey: {
    opacity: '0.5'
  },
  dropZoneStem: {
    color: 'black',
    p: '27px',
    pb: '11px'
  },
  containerText: {
    mt: 5.5,
    mb: 2.5,
    '& path': {
      stroke: 'black'
    }
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '& path': {
      fill: 'black'
    }
  },
  containerTitleLogo: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  textTransparent: {
    opacity: '0.5'
  }
};

export default styles;

import constants from '@/helpers/constants';
import EthersSimple from './ethers';
// import EthersSimple from '@/utils/ethers';
// import formatEthers from './formatEthers';

export async function connectToMetamask(message?: string) {
  if (!window.ethereum) {
    throw new Error(`Please install Metamask`);
  }
  const providerWrite = new EthersSimple.ethers.providers.Web3Provider(window.ethereum);
  const providerRead = new EthersSimple.ethers.providers.AlchemyProvider(
    process.env.REACT_APP_PROVIDER_NETWORK,
    process.env.REACT_APP_PROVIDER_KEY
  );

  try {
    await providerWrite.send('eth_requestAccounts', []);
  } catch (error) {
    console.error(error);
  }
  const [signer, network, networkPolygon] = await Promise.all([
    providerWrite.getSigner(),
    providerWrite.getNetwork(),
    providerRead.getNetwork()
  ]);

  if (networkPolygon.chainId !== network.chainId) {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [constants.networks.polygonMainnet]
    });
  }

  const ethers = new EthersSimple({ providerRead, providerWrite, signer });
  const [signature, myAddress] = await Promise.all([
    message ? signer.signMessage(message) : '',
    ethers.getAddress()
  ]);

  const data = {
    myAddress,
    signature,
    ethers
  };

  return data;
}

export async function addTokenToMetamask(token: 'LABEL' | 'USDC', contractAddress: string) {
  await connectToMetamask();

  const addToken = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: contractAddress,
        ...(process.env.REACT_APP_NETWORK === 'mumbai'
          ? constants.tokens[token].testnet
          : constants.tokens[token].mainnet)
      }
    }
  });
  if (!addToken) {
    throw new Error('Token was not added');
  }
}

import { useCallback, useMemo, useState } from 'react';
import { getImageSizes } from '@/utils/getImageSizes';
import { toBase64 } from '@/shared/ImageCropper/cropImage';

export const useImageCrop = () => {
  const [sizeError, setSizeError] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [currentFile, setCurrentFile] = useState<any>(null);
  const [currBase64, setCurrBase64] = useState<string>('');
  const [croppedImage, setCroppedImage] = useState<string>('');

  const handleFileCropping = (acceptedFile: File) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getImageSizes(acceptedFile).then(async (resp: any): Promise<void> => {
      if (resp.width < 600 || resp.height < 600) {
        setSizeError(true);
      } else {
        setSizeError(false);
        setCurrentFile(acceptedFile);
        const baseFile = (await toBase64(acceptedFile)) as string;
        setCurrBase64(baseFile);
      }
    });
  };
  const canCropImage = useMemo(() => {
    return Boolean(currentFile && !!currBase64);
  }, [currentFile, currBase64]);

  const handleClose = useCallback(() => {
    setCurrBase64('');
    setCurrentFile(null);
  }, [setCurrBase64, setCurrentFile]);
  return {
    sizeError,
    currentFile,
    handleFileCropping,
    currBase64,
    setCroppedImage,
    croppedImage,
    canCropImage,
    handleClose
  };
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    borderRadius: '2.5rem',
    width: '34.5625rem',
    color: 'white',
    background: 'black',
    padding: '1.625rem 2.875rem 2rem',
    position: 'relative',
    textAlign: 'center',
    '@media screen and (max-width: 500px)': {
      padding: '1.5rem'
    }
  },
  icon: {
    position: 'absolute',
    top: '1.125rem',
    right: '2rem',
    cursor: 'pointer'
  },
  button: {
    justifySelf: 'end',
    backgroundColor: '#fff',
    color: 'black',
    width: '8.9375rem',
    height: '3rem',
    ':hover': {
      backgroundColor: '#fff'
    },
    '&:disabled': {
      backgroundColor: '#fff',
      opacity: 0.76
    }
  },
  buttonOutlined: {
    justifySelf: 'end',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    color: '#fff',
    width: '8.9375rem',
    height: '3rem',
    ':hover': {
      border: '1px solid rgba(255, 255, 255, 0.3)'
    },
    '&:disabled': {
      border: '1px solid rgba(255, 255, 255, 0.2)',
      color: '#fff',
      opacity: 0.56
    }
  },
  text: {
    color: '#BDC2C8',
    lineHeight: '24.5px',
    px: 11.25,
    fontWeight: 400,
    mt: 2.5,
    '@media screen and (max-width: 600px)': {
      px: 0
    }
  },
  containerButton: {
    mt: 7,
    textAlign: 'center',
    '@media screen and (max-width: 360px)': {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      marginTop: 4
    },
    '& > button:first-of-type': {
      mr: 2.25,
      '@media screen and (max-width: 360px)': {
        mr: 0,
        mt: 2
      }
    }
  },
  stem: {
    borderRadius: '1.25rem',
    backgroundSize: 'cover',
    minHeight: '6.625rem',
    backgroundPosition: 'center',
    width: '6.8125rem',
    margin: 'auto',
    position: 'relative',
    '& > svg': {
      position: 'absolute',
      bottom: 0,
      right: 0
    }
  }
};

export default styles;

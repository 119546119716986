import { gql } from '@apollo/client';
import PAGINATE_MODEL from './models/paginateModel';

export const STAKING_USDC_CAMPAIGN = gql`
  mutation stakingCampaign($payload: StakingCampaignDTO!, $chainId: Int!) {
    stakingCampaign(payload: $payload, chainId: $chainId)
  }
`;

export const WITHDRAW_USDC = gql`
  mutation withdrawUSDC($payload: CampaignIdDTO!, $chainId: Int!) {
    withdrawUSDC(payload: $payload, chainId: $chainId) {
      client
      reward
      signature
      tokenID
    }
  }
`;

export const STAKE_BY_ACCOUNT = gql`
  query stakeByAccount($payload: CampaignIdDTO!) {
    stakeByAccount(payload: $payload) {
      id
      status
      amount
    }
  }
`;

export const GET_VERIFICATION_WALLET = gql`
  query getVerificationWallet($payload: WalletVerifyingDTO!) {
    getVerificationWallet(payload: $payload)
  }
`;

export const MY_REMIXES = gql`
  query myRemixes($chainId: Int!, $payload: RemixGetDTO!) {
    myRemixes(chainId: $chainId, payload: $payload) {
      items {
        id
        name
        createdDate
        updatedDate
        isFirstEdition
        logoUrl
        trackUrl
        trackName
        user {
          id
          userName
        }
        sponsor {
          id
          name
        }
      }
      meta {
        ...paginateModel
      }
    }
  }
  ${PAGINATE_MODEL}
`;

export const GET_STEMS = gql`
  query myStems($payload: StemGetDTO!, $chainId: Int!) {
    myStems(payload: $payload, chainId: $chainId) {
      items {
        id
        logoUrl
        isSpent
        stemUrl
        campaign {
          name
          description
          stemSize
          stemName
          sponsor {
            name
          }
        }
      }
      meta {
        skip
        take
        total
      }
    }
  }
`;

export const GET_TRANSACTIONS = gql`
  query transactions($payload: TransactionGetDTO!, $chainId: Int!) {
    transactions(payload: $payload, chainId: $chainId) {
      items {
        id
        createdDate
        amount
        type
        campaign {
          id
          name
          status
          logoUrl
          sponsor {
            id
            name
          }
        }
      }
      meta {
        skip
        take
        total
      }
    }
  }
`;

export const CONTRACTS = gql`
  query contracts {
    contracts {
      id
      abi
      address
      type
    }
  }
`;

import { FC } from 'react';
import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { Link, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { ICampaignModel, IQueryCampaignByIdArgs, RemixStatusEnum } from '@/graphql/types/_server';
import StandartLayout from '@/shared/StandartLayout/StandartLayout';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import styles from './styles';
import Remixs from './Remixs/Remixs';
import Campaigns from './Campaigns/Campaigns';
import { CAMPAIGN_BY_ID } from '@/graphql/gql/campaigns';
import getCloudImgUrl from '@/utils/getUrlImage';

const Collection: FC = () => {
  const params = useParams<'id'>();
  const campaignId = Number(params?.id) || -1;

  const { loading, data, error } = useQuery<
    { campaignById: ICampaignModel },
    IQueryCampaignByIdArgs
  >(CAMPAIGN_BY_ID, {
    variables: {
      payload: {
        id: campaignId
      }
    }
  });

  const user = data?.campaignById.sponsor;

  const remixes =
    data?.campaignById?.remixes?.filter((item) => item.status === RemixStatusEnum.Store) || [];

  if (loading) {
    return <BackdropLoading />;
  }

  if (error || !user) {
    return (
      <StandartLayout>
        <Alert severity="error">There is no such user.</Alert>
      </StandartLayout>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h1" sx={styles.textGrey} mt={3}>
          {data?.campaignById?.name} Collection
        </Typography>
        <Link to={`/in/${user?.userName}`}>
          <Box sx={styles.container}>
            <Box sx={styles.avatar}>
              {user?.logoUrl ? (
                <img src={getCloudImgUrl(user.logoUrl, 80)} alt="avatar" />
              ) : (
                <AccountCircleIcon sx={styles.emptyAvatar} viewBox="2 2 20 20" />
              )}
            </Box>
            <Typography variant="h6" sx={styles.textGrey} ml={1.25}>
              by {user.name}
            </Typography>
          </Box>
        </Link>
      </Box>
      <Remixs remixes={remixes} loading={loading} />
      <Campaigns userId={user.id} userName={user.name} />
    </Container>
  );
};

export default Collection;

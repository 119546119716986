import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './styles';
import Layout from '../../../../shared/Layout/Layout';
import LeftCard from '../../LeftCard/LeftCard';
import { IRemixModel } from '@/graphql/types/_server';
import Remix from './Remix/Remix';

const Step0: FC<{
  votePointBalance: number;
  remixes: (IRemixModel & { vote: number })[];
  setChosedRemix: (remix: number) => void;
  voteRemix: {
    id: number;
    amount: string;
  };
}> = ({ votePointBalance, remixes, setChosedRemix, voteRemix }) => {
  return (
    <Layout rightStyle={styles.containerRight} leftCard={<LeftCard tune={votePointBalance} />}>
      <Box sx={styles.container}>
        <Typography variant="span3" component="p" mb={2.75}>
          Submited Remixes
        </Typography>
        <Box sx={styles.containerScroll}>
          {remixes.length ? (
            remixes.map((remix, index) => {
              return (
                <Remix
                  key={remix.id}
                  index={index + 1}
                  remix={remix}
                  setChosedRemix={setChosedRemix}
                  amount={remix.id === voteRemix.id ? voteRemix.amount : ''}
                />
              );
            })
          ) : (
            <Typography variant="subtitle2" fontWeight={400} sx={styles.text} lineHeight={1} mr={1}>
              No remixes yet.
            </Typography>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default Step0;

import { FC, useMemo, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IProps } from './types';
import styles from './styles';
import { CampaignContext } from '../../context';

const Campaing: FC<IProps> = ({ isDescription = false }) => {
  const stylesMemoized = useMemo(() => styles(isDescription), [isDescription]);
  const { campaign } = useContext(CampaignContext);

  return (
    <Box sx={stylesMemoized.description}>
      <Box>
        <Box>
          <Typography variant="span3">Sponsored by {campaign?.sponsor?.name}</Typography>
        </Box>
        <Box ml={-0.375} pt={1.5} pb={1} sx={stylesMemoized.title}>
          <Typography variant="title">{campaign.name}</Typography>
        </Box>
      </Box>
      {isDescription && (
        <Box>
          <Typography variant="body2" fontWeight={300} lineHeight="1.5rem">
            {campaign.description}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Campaing;

import colors from '@/helpers/theme/colors';

const styles = (theme: 'dark' | 'light' | 'medium') => ({
  block: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '1rem',
    textAlign: 'right',
    position: 'relative'
  },

  withIcons: {
    display: 'flex',
    justifyContent: 'end'
  },

  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: colors.accent2,
      color: colors.accent2,
      border: `1px solid ${colors.accent1}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.6s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""'
      }
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1
      },
      '100%': {
        transform: 'scale(3)',
        opacity: 0
      }
    }
  },
  menu: {
    mt: '14px',
    '& .MuiList-root': {
      p: 0
    }
  },
  balance: {
    width: '526px'
  },
  balanceTop: {
    display: 'block',
    backgroundColor: '#232323'
  },
  balanceTopTitle: {
    display: 'block',
    color: '#fff',
    p: '30px 10px 15px 30px'
  },
  balanceTopNumber: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media screen and (max-width: 550px)': {
      flexDirection: 'column',
      '& > div': {
        width: '100%',
        borderRadius: '0',
        paddingLeft: '30px'
      }
    }
  },
  balanceTopNumberLeft: {
    borderRadius: '0px 25px 0px 0px',
    display: 'flex',
    alignItems: 'end',
    width: '56%',
    backgroundColor: '#F3F700',
    height: '91px',
    p: '16px 10px 20px 30px',
    '& > h2:first-of-type': {
      lineHeight: '54px'
    },
    '& > h6:last-of-type': {
      ml: '20px'
    }
  },
  balanceTopNumberRight: {
    display: 'flex',
    alignItems: 'end',
    p: '18px 30px 24px 10px',
    color: '#F3F700',
    '& > h4:first-of-type': {
      lineHeight: '44px'
    },
    '& > h6:last-of-type': {
      ml: '20px',
      lineHeight: '30px'
    }
  },
  balanceBottom: {
    p: '24px 30px 22px 30px'
  },
  balanceBottomStalked: {
    display: 'block',
    mb: '15px'
  },
  balanceBottomCampaigns: {
    display: 'flex',
    alignItems: 'end',
    borderBottom: '1px solid rgba(0, 0, 0, .1)',
    pb: '22px',
    mb: '24px',
    '& a': {
      border: 'none',
      borderBottom: '1px dashed rgba(26, 27, 29, .5)'
    }
  },
  balanceBottomTextT: {
    fontSize: '14px',
    mb: '20px',
    cursor: 'pointer',
    width: 'fit-content',
    '&:last-of-type': {
      mb: 0
    }
  },
  balanceBottomTextB: {
    fontSize: '14px'
  },
  activeButton: {
    border: '2px solid #F3F700'
  },
  button: {
    width: '175px',
    ...(theme === 'light' && { background: 'white' }),
    ...(theme === 'dark' && { background: 'black' }),
    ...(theme === 'medium' && { background: '#232323' }),
    ...(theme === 'light' && { color: 'black' }),
    ...(theme === 'dark' && { color: 'white' }),
    ...(theme === 'medium' && { color: 'white' }),
    '&:hover': {
      ...(theme === 'light' && { background: '#ededed' }),
      ...(theme === 'dark' && { background: 'rgb(24, 24, 24)' }),
      ...(theme === 'medium' && { background: '#515151' })
    },
    '&:disabled': {
      ...(theme === 'medium' && {
        background: '#515151',
        '& .MuiLoadingButton-loadingIndicator': {
          color: 'black'
        }
      })
    }
  }
});

export default styles;

import { gql } from '@apollo/client';

export const WALLET_CONNECT_TO_ACCOUNT = gql`
  mutation walletConnectToAccount($payload: WalletConnectToAccountDTO!) {
    walletConnectToAccount(payload: $payload)
  }
`;

export const GET_VERIFICATION_URL = gql`
  query getVerificationURL($payload: UserVerificationDTO!) {
    getVerificationURL(payload: $payload) {
      url
    }
  }
`;

const styles = {
  layout: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#1a1a1a',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed'
  },
  main: {
    flex: 1,
    '&::after': {
      content: '""',
      display: 'block',
      height: '10rem'
    }
  },
  containerFilter: {
    minHeight: '100vh',
    backdropFilter: 'blur(30px)'
  },
  hide: {
    visibility: 'hidden'
  }
};

export default styles;

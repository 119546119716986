/* eslint-disable react/display-name */
/* eslint-disable react/require-default-props */
import { forwardRef, FC } from 'react';
import styled from '@mui/system/styled';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import Fade from '@mui/material/Fade';

import Box from '@mui/material/Box';

import styles from './styles';
import InformationLabel from '@/shared/InformationLabel/InformationLabel';

const BackdropUnstyled = forwardRef<HTMLDivElement, { open?: boolean; className: string }>(
  (props, ref) => {
    const { open, className, ...other } = props;

    return (
      <Box className={open ? 'MuiBackdrop-open' : ''} sx={styles.backdrop} ref={ref} {...other}>
        <IconButton sx={styles.closeIcon} {...other}>
          <CloseIcon />
        </IconButton>
      </Box>
    );
  }
);

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 998;
  display: initial;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  outline: 0;
`;

const Backdrop = styled(BackdropUnstyled)``;

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const InformationLabelModal: FC<IProps> = ({ open, handleClose }) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        components={{ Backdrop }}
        componentsProps={{ backdrop: { onClick: handleClose } }}
      >
        <Fade in={open} timeout={300}>
          <Box sx={styles.container}>
            <InformationLabel />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default InformationLabelModal;

const styles = (theme: 'dark' | 'light') => ({
  cart: {
    '& .MuiDrawer-paper': {
      backgroundColor: '#000000',
      boxSizing: 'border-box',
      borderRadius: '50px 0 0 50px'
    },
    // TODO: сделать глобальные стили для scroll
    '& *::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none'
    },
    '& *::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none'
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& *': {
      'scrollbar-width': 'thin'
    },
    '& *::-webkit-scrollbar': {
      width: '6px',
      height: '6px'
    },
    '& *::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '& *::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '6px'
    },
    '& *::-webkit-scrollbar-thumb:hover': {
      background: '#333'
    }
  },
  title: {
    color: '#fff'
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    top: '10px'
  },
  items: {
    p: '24px 0 0 34px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: '1.5rem',
    width: '400px',
    overflowY: 'auto',
    '@media screen and (max-width: 960px)': {
      width: '300px'
    }
  },
  header: {
    m: '66px 34px 34px 34px',
    display: 'flex',
    alignItems: 'center',
    '& > button > svg': {
      color: 'rgba(255,255,255,.5)'
    }
  },
  emptyCart: {
    color: 'rgba(255,255,255, .5)',
    stroke: 'rgba(255,255,255, .5)',
    mt: '86px',
    '& svg': {
      mb: '52px',
      ml: '10px'
    },
    '& span:last-of-type': {
      mt: '24px',
      display: 'inline-block'
    }
  },
  emptyCartText: {
    '& a': {
      color: 'rgba(255, 255, 255, 0.5)',
      '&:hover': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
      }
    }
  },
  emptyCartSmallText: {
    '& a': {
      color: 'rgba(255, 255, 255, 0.5)',
      '&:hover': {
        borderBottom: '1px solid rgba(255, 255, 255, 0.5)'
      }
    }
  },
  icon: {
    fontSize: 24,
    color: theme === 'dark' ? '#232323' : 'white'
  },
  button: {
    height: '48px',
    width: '142px',
    backgroundColor: '#fff',
    ':hover': {
      backgroundColor: '#fff'
    },
    '&:disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.56)'
    }
  },
  container: {
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '33px 28px'
  }
});

export default styles;

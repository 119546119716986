import { FC, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import styles from './styles';
import { ReactComponent as CancelSvg } from '@/files/icons/cancel.svg';
import { CampaignContext } from '../../context';

const Cancel: FC = () => {
  const { campaign, setIsStaked, setIsShowMenu, setTimelineChecked } = useContext(CampaignContext);

  useEffect(() => {
    setIsStaked(true);
    setIsShowMenu(true);
    setTimelineChecked(false);
  }, []);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.form}>
        <Typography variant="span3" component="p">
          Sponsored by {campaign?.sponsor?.name}
        </Typography>
        <Typography variant="title" component="p" mt={2}>
          {campaign?.name}
        </Typography>
        <Box sx={styles.containerText}>
          <CancelSvg />
          <Typography variant="caption" sx={styles.textGrey} component="p">
            This Campaign was canceled by Label Administration. You still can withdraw your funds.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Cancel;

import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IProps } from './types';
import styles from './styles';
import { CampaignStatusEnum } from '@/graphql/types/_server';
import CampaignTime from '@/shared/ui/CampaignTime/CampaignTime';
import getCloudImgUrl from '@/utils/getUrlImage';

const CampaignItem: FC<IProps> = ({ campaign }) => {
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        id={`${campaign.id}_campaign`}
        key={campaign.id}
        sx={styles.item}
        onClick={handleClick}
        style={{ backgroundImage: `url(${getCloudImgUrl(campaign.logoUrl, 420)})` }}
      />
      <Box sx={styles.itemInfo}>
        <Box>
          <Typography variant="h6">{campaign.name}</Typography>
          <Typography variant="span3">
            Sponsored by {campaign.sponsor?.name || 'Sponsor'}
          </Typography>
        </Box>
        {campaign.status !== CampaignStatusEnum.Check && (
          <CampaignTime campaign={campaign} handleClick={handleClick} />
        )}
      </Box>
    </Box>
  );

  function handleClick() {
    if (campaign.status === CampaignStatusEnum.Draft) {
      navigate(`/edit-campaign/${campaign.id}`);
    } else {
      navigate(`/earn/${campaign.id}`);
    }
  }
};

export default memo(CampaignItem);

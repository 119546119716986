import colors from '@/helpers/theme/colors';

const styles = {
  datePicker: {
    width: '100%'
  },
  errorDatePicker: {
    svg: { color: colors.status.error },
    input: { color: colors.status.error },
    label: { color: colors.status.error },
    '& > p': { color: colors.status.error },
    fieldset: { borderColor: colors.status.error }
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '1.625rem',
    '@media screen and (max-width: 700px)': {
      gridTemplateColumns: '1fr'
    }
  },
  numberFormat: {
    '& > div': {
      '@media screen and (max-width: 700px)': {
        width: '100%'
      }
    }
  },
  innerGridGap: {
    gridGap: '0.875rem'
  },
  campaignWrap: {
    display: 'flex',
    gap: '48px',
    mb: '100px',
    justifyContent: 'space-between',
    '@media screen and (max-width: 1200px)': {
      flexDirection: 'column-reverse',
      alignItems: 'center'
    }
  },
  campaignLeft: {
    width: '50%',
    maxWidth: '671px',
    '@media screen and (max-width: 1200px)': {
      width: '100%',
      maxWidth: 'inherit'
    }
  },
  campaignRight: {
    width: '50%',
    maxWidth: '582px',
    '@media screen and (max-width: 1200px)': {
      width: '100%',
      maxWidth: 'inherit'
    }
  },
  campaignName: {
    borderRadius: '20px',
    p: '48px 48px 32px',
    backgroundColor: '#fff',
    boxShadow:
      '0px 461px 184px rgba(0, 0, 0, 0.02), 0px 259px 156px rgba(0, 0, 0, 0.07), 0px 115px 115px rgba(0, 0, 0, 0.11), 0px 29px 63px rgba(0, 0, 0, 0.13), 0px 0px 0px rgba(0, 0, 0, 0.13)'
  },
  campaignDate: {
    mt: '43px',
    pt: '41px',
    pb: '32px',
    borderRadius: '20px',
    backgroundColor: '#fff',
    boxShadow:
      '0px 461px 184px rgba(0, 0, 0, 0.02), 0px 259px 156px rgba(0, 0, 0, 0.07), 0px 115px 115px rgba(0, 0, 0, 0.11), 0px 29px 63px rgba(0, 0, 0, 0.13), 0px 0px 0px rgba(0, 0, 0, 0.13)',
    '& > div': {
      px: '48px',
      borderBottom: '1px solid rgba(0,0,0,.1)'
    },
    '& > div:last-of-type': {
      borderBottom: 'none'
    }
  },
  campaignLabel: {
    mb: '11px'
  },
  btn: {
    height: '64px',
    mt: '32px',
    backgroundColor: '#F3F700',
    '&:hover': {
      backgroundColor: '#F3F700'
    }
  },
  dropZoneLogo: {
    color: 'white',
    p: '27px'
  },
  textGrey: {
    opacity: '0.5'
  },
  dropZoneStem: {
    color: 'black',
    p: '27px',
    pb: '11px'
  },
  containerText: {
    mt: 5.5,
    mb: 2.5,
    '& path': {
      stroke: 'black'
    }
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    '& path': {
      fill: 'black'
    }
  },
  containerTitleLogo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  select: {
    mr: 4.75,
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    '&::before, &::after': {
      borderBottom: 'none'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '& path': {
      fill: 'white'
    }
  },
  containerPreview: {
    display: 'flex',
    justifyContent: 'space-between',
    p: 3,
    alignItems: 'end',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    '& > :first-of-type': {
      textAlign: 'left'
    }
  },
  chip: {
    background: '#F3F700',
    borderRadius: '30px',
    padding: '11px 16px',
    color: '#1A1B1D'
  }
};

export default styles;

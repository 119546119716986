import { memo, FC, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useQuery } from '@apollo/client';
import styles from './styles';
import {
  FilterFieldTypeEnum,
  FilterOperationEnum,
  IQueryCampaignsArgs,
  ICampaignsModel,
  SortDirectionEnum,
  CampaignStatusEnum
} from '@/graphql/types/_server';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import Campaign from './Campaign/Campaign';
import { CAMPAIGNS } from '@/graphql/gql/campaigns';
import { IProps } from './types';

const Campaigns: FC<IProps> = ({ userId }) => {
  // TODO: добавить бесконечную прокрутку
  const { loading, data } = useQuery<{ campaigns: ICampaignsModel }, IQueryCampaignsArgs>(
    CAMPAIGNS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        payload: {
          filters: [
            {
              columnName: 'sponsor.id',
              operation: FilterOperationEnum.Equal,
              type: FilterFieldTypeEnum.Number,
              value: [`${userId}`]
            },
            {
              columnName: 'status',
              operation: FilterOperationEnum.Equal,
              type: FilterFieldTypeEnum.Text,
              value: [
                CampaignStatusEnum.Lottery,
                CampaignStatusEnum.Remix,
                CampaignStatusEnum.Voting,
                CampaignStatusEnum.Done,
                CampaignStatusEnum.Check
              ]
            }
          ],
          paginate: { skip: 0, take: 100 },
          sorts: [
            {
              columnName: 'createdDate',
              direction: SortDirectionEnum.Desc
            }
          ]
        }
      }
    }
  );

  const campaigns = useMemo(() => data?.campaigns?.items || [], [data?.campaigns?.items]);

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <Box>
      <Typography variant="h5" component="p" sx={styles.title}>
        Campaigns Sponsored
      </Typography>
      {campaigns.length ? (
        <Box sx={styles.container}>
          {campaigns.map((item) => (
            <Campaign key={`${item.id}_campaign`} campaign={item} />
          ))}
        </Box>
      ) : (
        <Typography variant="span1" component="p" sx={styles.text}>
          No campaigns...
        </Typography>
      )}
    </Box>
  );
};

export default memo(Campaigns);

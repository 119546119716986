import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { FC, memo, useMemo } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { ReactComponent as QueueSvg } from '@/files/icons/queue.svg';
import styles from './styles';
import { IProps } from './types';
import getCloudImgUrl from '@/utils/getUrlImage';

const Collection: FC<IProps> = ({ campaign }) => {
  const stylesMemoized = useMemo(() => styles(getCloudImgUrl(campaign?.logoUrl, 700)), [campaign]);

  return (
    <Box sx={stylesMemoized.item}>
      <Link to={`/collections/${campaign?.id}`}>
        <Box sx={stylesMemoized.logo}>
          <Box sx={stylesMemoized.container}>
            <Box sx={stylesMemoized.icon} mr={1}>
              <QueueSvg />
            </Box>
            <Link to={`/in/${campaign?.sponsor?.userName}`} style={stylesMemoized.link}>
              <Box sx={stylesMemoized.containerAction}>
                <Box
                  sx={stylesMemoized.logoSponsor}
                  style={{
                    backgroundImage: `url(${getCloudImgUrl(campaign?.sponsor?.logoUrl || '', 60)})`
                  }}
                >
                  {!campaign?.sponsor?.logoUrl && <PersonIcon />}
                </Box>

                <Typography variant="body2" sx={stylesMemoized.textSponsor}>
                  {campaign?.sponsor?.name}
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>
      </Link>
      <Box ml={3} mt={2.5}>
        <Typography variant="h6" sx={stylesMemoized.text} lineHeight={1.1}>
          {campaign?.name}
        </Typography>
        <Typography variant="span3">Collection by {campaign?.sponsor?.name}</Typography>
      </Box>
    </Box>
  );
};

export default memo(Collection);

const styles = {
  containerTop: {
    background: '#F3F700',
    padding: '1.5rem 2.875rem 1.5rem 2.875rem',
    '& svg': {
      fill: 'black',
      minWidth: '2rem',
      height: '2rem',
      mr: 1,
      '& path': {
        fill: '#F3F700'
      }
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      mt: 1.25
    }
  },
  containerBottom: {
    background: 'black',
    borderBottomRightRadius: '2.5rem',
    borderBottomLeftRadius: '2.5rem',
    color: 'white',
    display: 'flex',
    padding: '0.5rem 1.5rem 1.5rem 2.875rem',
    justifyContent: 'space-between',
    height: '10.375rem',
    '@media screen and (max-width: 400px)': {
      flexDirection: 'column',
      alignItems: 'center',
      height: 'auto',
      '& > *:first-child': {
        width: '100%'
      },
      '& > *:last-child': {
        mt: 2
      }
    }
  },
  containerText: {
    width: '64%',
    height: 'fit-content',
    alignSelf: 'center'
  },
  containerControl: {
    '& > svg': {
      cursor: 'pointer'
    }
  }
};

export default styles;

const constants = {
  ipfs: {
    link: 'https://api.lbl.dev.architech.nyc/ipfs/'
  },
  networks: {
    polygonMainnet: {
      chainId: '0x89',
      rpcUrls: ['https://polygon-rpc.com'],
      chainName: 'Polygon Mainnet',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18
      },
      blockExplorerUrls: ['https://polygonscan.com']
    }
  },
  tokens: {
    LABEL: {
      testnet: {
        symbol: 'LABEL',
        decimals: 18,
        image: 'https://lbl.dev.architech.nyc/logo192.png'
      },
      mainnet: {
        symbol: 'LABEL',
        decimals: 18,
        image: 'https://lbl.stage.architech.nyc/logo192.png'
      }
    },
    USDC: {
      testnet: {
        symbol: 'USDC',
        decimals: 18,
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png'
      },
      mainnet: {
        symbol: 'USDC',
        decimals: 18,
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png'
      }
    }
  }
};

export default constants;

/* eslint-disable react/require-default-props */
/* eslint-disable react/display-name */
import { forwardRef, FC, useContext } from 'react';
import styled from '@mui/system/styled';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useQuery } from '@apollo/client';
import Alert from '@mui/material/Alert';
import { ReactComponent as Play } from '@/files/icons/play.svg';
import { ReactComponent as Pause } from '@/files/icons/pause.svg';
import { ReactComponent as EmptyCartSvg } from '@/files/icons/cardSmall.svg';
import styles from './styles';
import { REMIX_BY_ID } from '@/graphql/gql/store';
import { IQueryRemixByIdArgs, IRemixModel } from '@/graphql/types/_server';
import { Context } from '@/helpers/context';
import Loader from '@/shared/ui/Loader/Loader';
import getCloudImgUrl from '@/utils/getUrlImage';

const BackdropUnstyled = forwardRef<HTMLDivElement, { open?: boolean; className: string }>(
  (props, ref) => {
    const { open, className, ...other } = props;

    return (
      <Box className={open ? 'MuiBackdrop-open' : ''} sx={styles.backdrop} ref={ref} {...other}>
        <IconButton sx={styles.closeIcon} {...other}>
          <CloseIcon />
        </IconButton>
      </Box>
    );
  }
);

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 998;
  display: initial;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  outline: 0;
`;

const Backdrop = styled(BackdropUnstyled)``;

interface IProps {
  open: boolean;
  handleClose: () => void;
  id: number | undefined;
}

const Track: FC<IProps> = ({ open, handleClose, id }) => {
  const { wallet } = useContext(Context);

  const { loading, data } = useQuery<{ remixById: IRemixModel }, IQueryRemixByIdArgs>(REMIX_BY_ID, {
    variables: {
      chainId: Number(wallet?.ethers?.getChainId()) || 137,
      payload: {
        id: Number(id)
      }
    },
    skip: !id
  });

  const remix = data?.remixById;

  const { audio, setAudio, cart, setCart, setOpenCart, pauseAudioHandler } = useContext(Context);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        components={{ Backdrop }}
        componentsProps={{ backdrop: { onClick: () => handleClose() } }}
      >
        <Fade in={open} timeout={300}>
          {!remix && !loading ? (
            <Box sx={styles.containerError}>
              <Alert severity="error">Error</Alert>
            </Box>
          ) : (
            <Box sx={styles.container}>
              {loading && (
                <Box sx={styles.containerLoader}>
                  <Loader />
                </Box>
              )}
              <Box
                sx={{
                  ...styles.leftCard,
                  backgroundImage: `url(${getCloudImgUrl(remix?.logoUrl || '', 500)})`
                }}
              >
                <Box
                  sx={styles.containerPlay}
                  onClick={() =>
                    !audio?.isPlaying || audio?.id !== remix?.id ? playAudio() : pauseAudio()
                  }
                >
                  {!audio?.isPlaying || audio?.id !== remix?.id ? <Play /> : <Pause />}
                </Box>
                <Box sx={styles.containerCart}>
                  <Box>
                    <Typography variant="h3">{remix?.price} USDC</Typography>
                    <Typography variant="subtitle2">
                      {`${remix?.countNftBought}/${
                        (remix?.countNFT || 0) + (remix?.countNftBought || 0)
                      }`}
                    </Typography>
                  </Box>
                  <Button onClick={addToCartHandler}>
                    <EmptyCartSvg />
                    <Typography variant="body2" mt={0.5}>
                      Add to Cart
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box sx={styles.rightCard}>
                <IconButton sx={styles.closeIconMobile} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
                <Box>
                  <Typography variant="h3" lineHeight={1} sx={styles.text}>
                    {remix?.name}
                  </Typography>
                  <Typography variant="span3" mt={2.5} component="p">
                    by {remix?.sponsor?.name || remix?.sponsor?.userName || '-'}
                  </Typography>
                  <Typography variant="body2" my={3} fontWeight={300} sx={styles.description}>
                    {remix?.description}
                  </Typography>
                </Box>
                <Box>
                  <Divider light />
                  <Box sx={styles.textTransparent} mt={2.5}>
                    <Typography variant="caption" fontWeight={300} component="p" mb={1}>
                      Genre: {remix?.genre}
                    </Typography>
                    <Typography variant="caption" fontWeight={300} component="p" mb={1}>
                      Key: {remix?.key}
                    </Typography>
                    <Typography variant="caption" fontWeight={300} component="p">
                      BMP: {remix?.beats}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Fade>
      </Modal>
    </div>
  );

  async function addToCartHandler() {
    try {
      if (
        !remix?.id ||
        !remix?.name ||
        !remix?.price ||
        !remix?.sponsor?.userName ||
        !remix?.tokenID
      ) {
        throw new Error('Not enough data');
      }
      if (cart.some((item) => item.id === remix?.id)) {
        setOpenCart(true);
        return null;
      }
      setCart([
        {
          id: remix.id,
          name: remix.name,
          price: remix.price,
          user: remix?.sponsor?.name || remix.sponsor.userName,
          track: remix.trackUrl,
          logo: remix?.logoUrl,
          tokenId: remix.tokenID
        },
        ...cart
      ]);
      setOpenCart(true);
      return null;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  function playAudio() {
    setAudioHandler(true);
  }

  function pauseAudio() {
    pauseAudioHandler();
    setAudioHandler(false);
  }

  function setAudioHandler(isPlaying: boolean) {
    setAudio({
      name: remix?.name || '',
      user: remix?.sponsor?.userName || '',
      id: remix?.id || -1,
      showPlayer: true,
      isPlaying,
      logo: remix?.logoUrl,
      src: remix?.trackUrl
    });
  }
};

export default Track;

const styles = {
  backdrop: {
    zIndex: -1,
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.6)',
    backdropFilter: 'blur(24px)',
    '-webkit-tap-highlight-color': 'transparent',
    outline: 0
  },
  container: {
    display: 'flex',
    outline: 0,
    justifyContent: 'center',
    position: 'relative',
    top: '72px',
    margin: 'auto',
    maxWidth: '1042px',
    '@media screen and (max-width: 850px)': {
      flexWrap: 'wrap',
      top: '16px'
    }
  },
  containerError: {
    background: '#F4F4F4',
    padding: '20px',
    borderRadius: '40px',
    width: '40%',
    height: '200px',
    margin: '72px auto 0',
    '@media screen and (max-width: 850px)': {
      maxWidth: '32.875rem',
      width: '100%'
    }
  },
  rightCard: {
    width: '32.875rem',
    borderRadius: '2.5rem',
    position: 'relative',
    '@media screen and (max-width: 534px)': {
      width: '100%'
    },
    background: 'black',
    padding: '60px 18px 18px',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  leftCard: {
    position: 'relative',
    width: '31.25rem',
    display: 'flex',
    height: '470px',
    backgroundColor: '#1a1a1a',
    borderRadius: '2.75rem',
    marginRight: '1rem',
    '@media screen and (max-width: 850px)': {
      height: '45vh',
      width: '32.875rem',
      marginRight: 0,
      marginBottom: '1rem'
    }
  },
  closeIcon: {
    position: 'absolute',
    right: '30px',
    top: '24px',
    color: 'white',
    '& > svg': {
      fontSize: '32px'
    },
    '@media screen and (max-width: 850px)': {
      display: 'none'
    }
  },
  containerStem: {
    color: 'black',
    background: '#F3F700',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    padding: '40px 35px',
    cursor: 'pointer',
    '& path': {
      fill: 'black'
    },
    '& > div:last-child': {
      ml: 2.5
    }
  },
  containerText: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    px: 3,
    pb: 4.375
  },
  containerImg: {
    backgroundSize: 'cover',
    backgroundPositionX: 'left',
    backgroundPositionY: 'bottom',
    borderRadius: '2.5rem',
    width: '100%'
  },
  containerImgNotLocked: {
    border: '3px solid #F3F700'
  },
  closeIconMobile: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    color: 'white',
    display: 'none',
    width: '48px',
    height: '48px',
    '& > svg': {
      fontSize: '32px'
    },
    '@media screen and (max-width: 850px)': {
      display: 'initial'
    }
  },
  textGrey: {
    opacity: 0.5
  }
};

export default styles;

import EthersSimple from './ethers';

const getDateFromBigNumber = (epoch: bigint): Date | undefined => {
  if (!epoch) {
    return undefined;
  }
  const convertedDate = EthersSimple.ethers.utils.formatUnits(epoch, 0);

  const convertedDateNumber = Number(convertedDate);

  if (!convertedDateNumber) {
    return undefined;
  }

  const multipliedDate = convertedDateNumber * 1000;

  return new Date(multipliedDate);
};

export default getDateFromBigNumber;

import { FC } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Modal from '@/shared/Modal/Modal';
import { ReactComponent as CloseSvg } from '@/files/icons/assets/close.svg';
import styles from './styles';
import { IProps } from './types';

const ModalUnstake: FC<IProps> = ({ balanceOf, loading, withdraw, modal }) => {
  return (
    <Modal sx={styles.modal} onClose={modal.onClose} isOpen={modal.isOpen}>
      <Box sx={styles.modalClose}>
        <CloseSvg onClick={modal.onClose} />
      </Box>
      <Box sx={styles.container}>
        <Stack direction="row" alignItems="center" gap={1} mb={2}>
          <Typography variant="h5">{modal.title}</Typography>
          {loading && <CircularProgress color="secondary" size={22} />}
        </Stack>
        <Box mt={6.25}>
          <Box sx={styles.tune}>
            <Typography variant="h6" lineHeight={1}>
              {balanceOf}
              <Typography variant="h6" lineHeight={1} component="span" sx={styles.textTransparent}>
                {' '}
                {modal.currency}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.button}>
          <Button
            disabled={loading || !Number(balanceOf)}
            fullWidth
            variant="outlined"
            onClick={withdraw}
            disableElevation
          >
            <Typography variant="body2" lineHeight={1}>
              Withdraw All
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalUnstake;

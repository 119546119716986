import { memo, FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './styles';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import Track from '@/shared/card/Track/Track';
import { IProps } from './types';
import TrackModal from '@/components/Track/Track';

const Remixs: FC<IProps> = ({ remixes, loading }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<number | undefined>(undefined);

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <Box sx={styles.container} mt={5.75}>
      {remixes.length ? (
        <Box sx={styles.gird}>
          {remixes.map((item) => (
            <Track key={`${item.id}_remix`} remix={item} handleClick={handleOpen} />
          ))}
        </Box>
      ) : (
        <Typography variant="span1" component="p" pb={4}>
          No tracks...
        </Typography>
      )}
      <TrackModal open={open} handleClose={handleClose} id={id} />
    </Box>
  );

  function handleOpen(id: number) {
    setId(id);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setTimeout(() => {
      setId(undefined);
    }, 300);
  }
};

export default memo(Remixs);

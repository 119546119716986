/* eslint-disable react/display-name */
import { useState, FC, useEffect, useContext, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation } from '@apollo/client';
import { add, formatDistance, isAfter } from 'date-fns';
import { useForm } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';
import { getSignatureForPermit } from '@/helpers/blockchain/permit';
import {
  IMutationWithdrawUsdcArgs,
  IQueryStakeByAccountArgs,
  ITransactionModel,
  IWithdrawUsdcdto,
  SmartContractTypeEnum,
  TransactionStatusEnum
} from '@/graphql/types/_server';
import formatEthers from '@/utils/formatEthers';
import getErrorMessage from '@/utils/getErrorMessage';
import Step0 from './steps/Step0/Step0';
import Step1 from './steps/Step1/Step1';
import Step2 from './steps/Step2/Step2';
import { Context } from '@/helpers/context';
import { WITHDRAW_USDC, STAKE_BY_ACCOUNT } from '@/graphql/gql/blockchain';
import { CampaignContext } from '../../context';
import getDateWithNulledTime from '@/utils/getDateWithNulledTime';
import { IPermitSignature } from '@/helpers/blockchain/permit/types';
import getDateFromBigNumber from '@/utils/getDateFromBigNumber';
import Notification from '../../shared/Notification/Notification';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import {
  depositUSDC,
  getDepositByAddress,
  getTicketsSecondsByAddressAndCampaign,
  withdrawUSDC
} from '@/utils/contractRequests';
import { IFormInputsValidation } from '../../shared/StakeUSDC/types';
import { messages } from '../config';
import StepInformation from './steps/StepInformation/StepInformation';
import { checkBalanceMatic, checkBalanceUsdc } from '@/utils/checkBalanceToken';

const Lottery: FC = () => {
  const form = useForm<IFormInputsValidation>();

  const { reset, setValue, trigger } = form;

  const { campaign, setIsStaked, setIsShowMenu, setTimelineChecked } = useContext(CampaignContext);
  const { contracts, wallet } = useContext(Context);

  const [countDays, setCountDays] = useState<{ days: number; loading: boolean }>({
    days: 0,
    loading: true
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [stakedUsdc, setStakedUsdc] = useState<number>(0);
  const [stakedDate, setStakedDate] = useState<string>('-');

  const [status, setStatus] = useState<string>('');

  const [isInformation, setIsInformation] = useState<boolean>(false);

  const [withdrawUsdc, { loading: loadingWithdraw }] = useMutation<
    { withdrawUSDC: IWithdrawUsdcdto },
    IMutationWithdrawUsdcArgs
  >(WITHDRAW_USDC);

  const [loadStakeByAccount, { loading: loadingStake }] = useLazyQuery<
    { stakeByAccount: ITransactionModel },
    IQueryStakeByAccountArgs
  >(STAKE_BY_ACCOUNT);

  const startDate = getDateWithNulledTime(new Date(campaign.startLotteryDate));
  const startDayWith23Day = add(startDate, { days: 23 });

  const currentDate = getDateWithNulledTime();

  const isAfter23DaysMemoized = useMemo(() => isAfter(currentDate, startDayWith23Day), []);

  useEffect(() => {
    if (!contracts.loading) {
      getAddressStatus();
    }
  }, [contracts.loading]);

  useEffect(() => {
    setIsShowMenu(true);
    setTimelineChecked(true);
  }, [isInformation]);

  useEffect(() => {
    if (isAfter23DaysMemoized && countDays.days === 0) {
      setIsStaked(true);
    } else {
      setIsStaked(false);
    }
  }, [isAfter23DaysMemoized, countDays.days]);

  if (countDays.loading) {
    return <BackdropLoading />;
  }

  if (isInformation) {
    return <StepInformation handleClose={() => setIsInformation(false)} />;
  }

  if (isAfter23DaysMemoized && countDays.days === 0) {
    return <Notification type="ban" text={messages.after23Days} width="55%" />;
  }

  if (status === TransactionStatusEnum.Processing) {
    return <Notification type="ban" text={messages.processing} />;
  }

  if (countDays.days === 0) {
    return (
      <Step0
        loadingStake={loadingStake}
        loading={loading}
        form={form}
        onSubmit={onSubmit}
        loadingWithdraw={loadingWithdraw}
        setAmountHandler={setAmountHandler}
      />
    );
  }

  return stakedUsdc > 0 ? (
    <Step1
      countDays={countDays.days}
      loadingStake={loadingStake}
      loading={loading}
      form={form}
      onSubmit={onSubmit}
      status={status}
      loadingWithdraw={loadingWithdraw}
      withdrawStakingCampaignMutation={withdrawStakingCampaignMutation}
      stakedUsdc={stakedUsdc}
      stakedDate={stakedDate}
      setAmountHandler={setAmountHandler}
      isAfter23Days={isAfter23DaysMemoized}
      handleOpenInformation={() => setIsInformation(true)}
    />
  ) : (
    <Step2
      countDays={countDays.days}
      loadingStake={loadingStake}
      loading={loading}
      form={form}
      onSubmit={onSubmit}
      loadingWithdraw={loadingWithdraw}
      setAmountHandler={setAmountHandler}
      isAfter23Days={isAfter23DaysMemoized}
    />
  );

  async function setAmountHandler(values: NumberFormatValues) {
    setValue('amount', values.value);
    await trigger('amount');
  }

  function onSubmit(data: IFormInputsValidation) {
    stakingCampaignMutation(data);
  }

  async function stakingCampaignMutation(formData: IFormInputsValidation) {
    try {
      await Promise.all([
        checkBalanceMatic(wallet),
        checkBalanceUsdc(wallet, contracts.contracts, Number(formData.amount))
      ]);

      const payload = await handlePermit(Number(formData.amount));
      if (payload) {
        toast.info('Staking... Please wait');

        if (payload?.amount) {
          if (!wallet.ethers) return;
          setLoading(true);
          let loadingLocal = true;
          const successCallback = () => {
            if (loadingLocal) {
              loadingLocal = false;
              reset();
              getAddressStatus();
              setLoading(false);
              setStatus(TransactionStatusEnum.Done);
              toast.success('Staking successful');
            }
          };

          await depositUSDC(
            wallet.ethers,
            contracts.contracts[SmartContractTypeEnum.Campaign],
            {
              tokenID: campaign.tokenID,
              ...payload
            },
            {
              Hash: () => {
                toast.info('Staking in progress. This can take up to a minute');
              },
              Error: () => {
                setLoading(false);
              },
              After: successCallback
            }
          );

          successCallback();
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(getErrorMessage(error));
    }
  }

  async function getAddressStatus() {
    try {
      const stakeByAccountQuery = await loadStakeByAccount({
        variables: {
          payload: {
            id: campaign.id
          }
        }
      });

      if (!stakeByAccountQuery) {
        throw new Error('Error in loading staking status');
      }

      await getCurrentStatusFromBlockchain();

      setStatus(stakeByAccountQuery?.data?.stakeByAccount?.status || TransactionStatusEnum.Done);
    } catch (error) {
      toast.error(getErrorMessage(error));
      setStatus('');
    }
  }

  function getCountDays(ticketsSeconds: bigint) {
    Number(formatEthers(ticketsSeconds, 0));
    return Number(formatEthers(ticketsSeconds, 0)) / (60 * 60 * 24);
  }

  async function getCurrentStatusFromBlockchain() {
    try {
      if (!wallet.ethers) return;
      setLoading(true);

      const [ticketsSeconds, stakeByAddress] = await Promise.all([
        getTicketsSecondsByAddressAndCampaign(
          wallet.ethers,
          contracts.contracts[SmartContractTypeEnum.Campaign],
          {
            tokenID: campaign.tokenID,
            addressClient: wallet.address
          }
        ),
        getDepositByAddress(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Campaign], {
          tokenID: campaign.tokenID,
          addressClient: wallet.address
        })
      ]);
      setCountDays({ days: getCountDays(ticketsSeconds), loading: false });

      const amounts = stakeByAddress.amounts.map((item: bigint, index: number) => {
        if (stakeByAddress.isWithdraw[index]) return 0;
        return formatEthers(item, 6); // USDC
      });

      const sumOfAmount = amounts.reduce((a: string, b: string) => Number(a) + Number(b), 0);
      setStakedUsdc(sumOfAmount);

      if (stakeByAddress.startDates.length) {
        const startDate = getDateFromBigNumber(
          stakeByAddress.startDates[stakeByAddress.startDates.length - 1]
        );
        if (startDate) {
          const dateFormatDistance = formatDistance(startDate, new Date(), {
            addSuffix: true
          });
          setStakedDate(dateFormatDistance);
        }
      }
    } catch (error) {
      setCountDays({ days: 0, loading: false });
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }

  async function getUsdcContractResponse() {
    try {
      if (!wallet?.ethers) {
        throw Error('No provider');
      }
      const usdcContract = contracts.contracts[SmartContractTypeEnum.Usdc];

      const addresses: { usdc: string; owner: string; spender: string } = {
        usdc: usdcContract.address,
        owner: wallet.address,
        spender: contracts.contracts[SmartContractTypeEnum.Campaign].address
      };

      const contract = wallet.ethers.getContractForRead({
        contractAddress: usdcContract.address,
        abi: usdcContract.abi
      });

      return {
        contract,
        provider: wallet.ethers.getProvider(),
        addresses,
        myAddress: wallet.address
      };
    } catch (error) {
      toast.error(getErrorMessage(error));
      return null;
    }
  }

  async function handlePermit(amount: number): Promise<IPermitSignature | null> {
    try {
      const response = await getUsdcContractResponse();

      if (response?.contract) {
        toast.info('Please confirm the transaction in Metamask');

        return getSignatureForPermit(response, amount);
      }
      return null;
    } catch (error) {
      toast.error(getErrorMessage(error));
      return null;
    }
  }

  async function withdrawStakingCampaignMutation() {
    try {
      if (loadingWithdraw) {
        throw new Error('In the process of withdraw');
      }
      if (!wallet.ethers) return;
      setLoading(true);
      let loadingLocal = true;
      toast.info('Withdraw... Please wait');

      const response = await withdrawUsdc({
        variables: {
          chainId: Number(wallet?.ethers?.getChainId()) || 137,
          payload: {
            id: campaign.id
          }
        }
      });

      if (!response.data?.withdrawUSDC) {
        throw new Error('Error in withdraw');
      }

      const { reward, signature, tokenID } = response.data.withdrawUSDC;

      const successCallback = () => {
        if (loadingLocal) {
          loadingLocal = false;
          reset();
          getAddressStatus();
          setLoading(false);
          setStatus(TransactionStatusEnum.Done);
          toast.success('The withdraw is completed. Please check your funds');
        }
      };

      await withdrawUSDC(
        wallet.ethers,
        contracts.contracts[SmartContractTypeEnum.Campaign],
        {
          tokenID,
          reward,
          signature
        },
        {
          Hash: () => {
            toast.info('Withdrawing in progress. This can take up to a minute');
          },
          Error: () => {
            setLoading(false);
          },
          After: successCallback
        }
      );

      successCallback();
    } catch (error) {
      setLoading(false);
      toast.error(getErrorMessage(error));
    }
  }
};

export default Lottery;

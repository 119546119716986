import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useLazyQuery, useQuery } from '@apollo/client';
import Alert from '@mui/material/Alert';
import CachedIcon from '@mui/icons-material/Cached';
import Stack from '@mui/material/Stack';
import { Navigate } from 'react-router-dom';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import { GET_VERIFICATION_URL } from '@/graphql/gql/verify';
import StandartLayout from '@/shared/StandartLayout/StandartLayout';
import { ME_VERIFY } from '@/graphql/gql/me';
import { IUserModel } from '@/graphql/types/_server';
import styles from './styles';

const Step4: FC = () => {
  const { loading: loadingMe, data: dataMe, refetch } = useQuery<{ me: IUserModel }>(ME_VERIFY);

  const [loadKyc, { called, loading, data, error }] = useLazyQuery(GET_VERIFICATION_URL, {
    variables: {
      payload: {
        redirect: `${process.env.REACT_APP_SITE_URL}/kyc-success.html`
      }
    }
  });
  const kycUrl: string | null = data?.getVerificationURL?.url || null;

  if (loading) {
    return <BackdropLoading />;
  }

  if (dataMe?.me?.isVerifiedKYC) {
    return <Navigate to="/" />;
  }

  if (error) {
    return (
      <StandartLayout>
        <Alert variant="outlined" severity="error" sx={styles.error}>
          {error.message}
        </Alert>
      </StandartLayout>
    );
  }

  if (called && kycUrl) {
    return (
      <StandartLayout>
        <>
          <Stack
            flexWrap="wrap"
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
            gap={1}
            mb={1}
          >
            <Button
              endIcon={<CachedIcon />}
              color="secondary"
              variant="text"
              onClick={() => refetch()}
            >
              Update status
            </Button>
          </Stack>
          <Box style={{ borderRadius: '20px', overflow: 'hidden' }}>
            <iframe
              allow="camera;microphone"
              frameBorder="0"
              title="KYC"
              src={kycUrl}
              width="100%"
              height="620"
            />
          </Box>

          {/* <Typography align="center">
            Any technical problems? <a href={kycUrl}>Fill out the form</a> in a separate window.
          </Typography> */}
          {loadingMe && <BackdropLoading />}
        </>
      </StandartLayout>
    );
  }

  return (
    <Box sx={styles.container}>
      <Typography variant="h3">KYC Identification</Typography>
      <Typography variant="subtitle2" sx={styles.text}>
        In order to continue Label platform requires you to pass verification
      </Typography>
      <Box sx={styles.form}>
        <Typography variant="h3" sx={styles.textTransparent} py={10}>
          KYC related info
        </Typography>
        <Box mt={2} sx={styles.btnWrap}>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            type="submit"
            disableElevation
            sx={styles.btn}
            onClick={() => loadKyc()}
          >
            <Typography variant="body2">Start KYC Verification</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Step4;

export const genres = [
  '2 Step',
  'Acid',
  'Ambient',
  'Balearic House',
  'Breakbeat',
  'Breaks',
  'Dance',
  'Deep House',
  'Deep Tech',
  'Disco',
  'Downtempo',
  'Drum & Bass',
  'Electro',
  'Electronica',
  'Experimental',
  'Future Jazz',
  'Garage',
  'Hip hop',
  'House',
  'Indie',
  'Italo Disco',
  'Jackin House',
  'Lofi Hip Hop',
  'Lofi House',
  'Melodic Techno',
  'Minimal',
  'Minimal House',
  'Minimal Techno',
  'Other',
  'Progressive House',
  'Tech House',
  'Techno',
  'Trance',
  'Trip hop'
];

export const keys = [
  'A Major',
  'E Major',
  'B Major',
  'F Major',
  'C Major',
  'G Major',
  'D Major',
  'A-Flat Major',
  'E-Flat Major',
  'B-Flat Major',
  'F-Sharp Major',
  'D-Flat Major',
  'A Minor',
  'E Minor',
  'B Minor',
  'F Minor',
  'C Minor',
  'G Minor',
  'D Minor',
  'A-Flat Minor',
  'E-Flat Minor',
  'B-Flat Minor',
  'F-Sharp Minor',
  'D-Flat Minor'
];

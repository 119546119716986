import { FC, useContext, useCallback } from 'react';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import { useQuery } from '@apollo/client';
import isArray from 'lodash/isArray';
import { Context } from '@/helpers/context';
import AbsoluteLoading from '@/shared/ui/AbsoluteLoading/AbsoluteLoading';
import styles from './styles';
import {
  FilterFieldTypeEnum,
  FilterOperationEnum,
  IQueryTransactionsArgs,
  ITransactionModel,
  ITransactionsModel,
  TransactionStatusEnum,
  TransactionTypeEnum
} from '@/graphql/types/_server';
import { GET_TRANSACTIONS } from '@/graphql/gql/blockchain';

type TransactionPublic =
  | TransactionTypeEnum.Adddatastem
  | TransactionTypeEnum.Buy
  | TransactionTypeEnum.Depositcampaign
  | TransactionTypeEnum.Depositlabel
  | TransactionTypeEnum.Mint
  | TransactionTypeEnum.Withdrawlabel
  | TransactionTypeEnum.Vote
  | TransactionTypeEnum.Withdrawcampaign
  | TransactionTypeEnum.Addremixforvote
  | TransactionTypeEnum.Rewards
  | TransactionTypeEnum.Claimrewards;

const transactionTypes: Record<TransactionPublic, Record<string, string | string[]>> = {
  [TransactionTypeEnum.Adddatastem]: {
    sign: '+',
    currency: 'Stem',
    amount: '1',
    title: 'Add Stem'
  },
  [TransactionTypeEnum.Buy]: { sign: '-', currency: 'USDC', title: 'Buy Nft' },
  [TransactionTypeEnum.Depositcampaign]: {
    sign: ['+', '-'],
    currency: ['Label', 'USDC'],
    title: 'Deposit Campaign'
  },
  [TransactionTypeEnum.Depositlabel]: { sign: '-', currency: 'Label', title: 'Deposit Label' },
  [TransactionTypeEnum.Mint]: { title: 'Lock Stem' },
  [TransactionTypeEnum.Withdrawlabel]: { sign: '+', currency: 'LABEL', title: 'Withdraw Label' },
  [TransactionTypeEnum.Vote]: { sign: '-', currency: 'TUNE', title: 'Vote' },
  [TransactionTypeEnum.Rewards]: { sign: '+', currency: 'USDC', title: 'Rewards' },
  [TransactionTypeEnum.Withdrawcampaign]: {
    sign: '+',
    currency: 'USDC',
    title: 'Withdraw Campaign'
  },
  [TransactionTypeEnum.Claimrewards]: {
    sign: '+',
    currency: 'USDC',
    title: 'Claim rewards'
  },
  [TransactionTypeEnum.Addremixforvote]: { title: 'Add remix for vote' }
};

const DrawerHistory: FC<{ onModalClose: () => void; open: boolean }> = ({ onModalClose, open }) => {
  const { wallet } = useContext(Context);

  const { data, loading } = useQuery<{ transactions: ITransactionsModel }, IQueryTransactionsArgs>(
    GET_TRANSACTIONS,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        chainId: Number(wallet?.ethers?.getChainId()) || 137,
        payload: {
          paginate: { skip: 0, take: 100 },
          filters: [
            {
              columnName: 'type',
              operation: FilterOperationEnum.Equal,
              type: FilterFieldTypeEnum.Text,
              value: [
                TransactionTypeEnum.Adddatastem,
                TransactionTypeEnum.Buy,
                TransactionTypeEnum.Depositcampaign,
                TransactionTypeEnum.Depositlabel,
                TransactionTypeEnum.Mint,
                TransactionTypeEnum.Withdrawlabel,
                TransactionTypeEnum.Vote,
                TransactionTypeEnum.Withdrawcampaign,
                TransactionTypeEnum.Addremixforvote,
                TransactionTypeEnum.Rewards,
                TransactionTypeEnum.Claimrewards
              ]
            },
            {
              columnName: 'status',
              operation: FilterOperationEnum.Equal,
              type: FilterFieldTypeEnum.Text,
              value: [TransactionStatusEnum.Done]
            }
          ]
        }
      }
    }
  );

  const transactions = data?.transactions?.items || [];

  const memoGetDescriptionTransaction = useCallback(getDescriptionTransaction, []);
  const memoGetFormatNumber = useCallback(getFormatNumber, []);

  const renderTransactions = () => {
    let date = '';
    let flag = false;
    return transactions.map((transaction: ITransactionModel) => {
      flag = format(new Date(transaction.createdDate), 'd') !== date;
      date = format(new Date(transaction.createdDate), 'd');
      const transactionType = transactionTypes[transaction.type as TransactionPublic];

      const amount = memoGetDescriptionTransaction(transactionType, transaction);

      return (
        <Box
          key={transaction.id}
          sx={flag ? styles.transactionsBlockLine : styles.transactionsBlock}
        >
          {flag && (
            <Typography variant="span3" sx={styles.transactionsTitle}>
              {format(new Date(transaction.createdDate), 'MMM d')}
            </Typography>
          )}
          <Box sx={styles.transactionsWrap}>
            <Box sx={styles.transactionsLeft}>
              <Typography variant="span14">{transactionType.title}</Typography>
              <Typography variant="overline">
                {transaction?.campaign?.name} by {transaction?.campaign?.sponsor?.name || 'Sponsor'}
              </Typography>
            </Box>
            <Box sx={styles.transactionsRight}>
              {amount}
              <Typography variant="overline">
                {format(new Date(transaction.createdDate), 'HH:MM aaaa')}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    });
  };

  return (
    <Drawer sx={styles.container} anchor="right" open={open} onClose={onModalClose}>
      <Box sx={styles.drawerHistory}>
        <Box sx={styles.header}>
          <Typography variant="h6" sx={styles.title}>
            Transactions
          </Typography>
          <IconButton sx={styles.closeIcon} onClick={onModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={styles.transactions}>{renderTransactions()}</Box>
        {loading && <AbsoluteLoading />}
      </Box>
    </Drawer>
  );

  function getDescriptionTransaction(
    transactionType: Record<string, string | string[]>,
    transaction: ITransactionModel
  ) {
    if (!transactionType?.sign) {
      return (
        <Typography fontWeight={500} variant="caption" sx={styles.positive}>
          {transactionType.title}
        </Typography>
      );
    }
    if (isArray(transactionType.sign)) {
      return (
        <>
          <Typography fontWeight={500} variant="caption" sx={styles.positive}>
            {`${transactionType.sign[0]} ${memoGetFormatNumber(transaction.amount)} ${
              transactionType.currency[0]
            }`}
          </Typography>
          <Typography fontWeight={500} variant="caption" sx={styles.negative}>
            {`${transactionType.sign[1]} ${memoGetFormatNumber(transaction.amount)} ${
              transactionType.currency[1]
            }`}
          </Typography>
        </>
      );
    }
    return (
      <Typography
        fontWeight={500}
        variant="caption"
        sx={transactionType.sign === '+' ? styles.positive : styles.negative}
      >
        {`${transactionType.sign} ${
          transactionType?.amount || memoGetFormatNumber(transaction.amount)
        } ${transactionType.currency}`}
      </Typography>
    );
  }

  function getFormatNumber(number: string) {
    return Number(Number(number).toFixed(5)).toString();
  }
};
export default DrawerHistory;

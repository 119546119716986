import { FC, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './styles';
import { ReactComponent as Play } from '@/files/icons/play.svg';
import { ReactComponent as Pause } from '@/files/icons/pause.svg';
import { IRemixModel } from '@/graphql/types/_server';
import { Context } from '@/helpers/context';

const Remix: FC<{ remix: IRemixModel & { vote: number } }> = ({ remix }) => {
  const { audio, setAudio, pauseAudioHandler } = useContext(Context);

  return (
    <Box key={remix.id} sx={styles.containerOuter}>
      <Box width="100%">
        <Box sx={styles.containerInner}>
          <Box sx={styles.containerAudio}>
            {!audio?.isPlaying || audio?.id !== remix.id ? (
              <Play onClick={playAudio} />
            ) : (
              <Pause onClick={pauseAudio} />
            )}
            <Typography variant="span14" component="p" lineHeight={1} mr={1}>
              {remix.name}
            </Typography>
          </Box>
          <Box sx={styles.vote}>{remix.vote}</Box>
        </Box>
      </Box>
    </Box>
  );

  function playAudio() {
    setAudioHandler(true);
  }

  function pauseAudio() {
    pauseAudioHandler();
    setAudioHandler(false);
  }

  function setAudioHandler(isPlaying: boolean) {
    setAudio({
      name: remix.name,
      user: '',
      id: remix.id,
      showPlayer: true,
      isPlaying,
      logo: remix.logoUrl,
      src: remix.trackUrl
    });
  }
};

export default Remix;

const menu = (isAdmin: boolean) => {
  return [
    {
      link: '/drafts',
      label: 'Drafts',
      isAdmin: true
    },
    {
      link: '/earn',
      label: 'Earn',
      isAdmin: false
    },
    {
      link: '/store',
      label: 'Buy Now',
      isAdmin: false
    }
  ]
    .filter((item) => (item.isAdmin ? isAdmin : true))
    .map((item) => ({
      link: item.link,
      label: item.label
    }));
};

export default menu;

import { FC, memo, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import styles from './styles';
import { TransactionTypeEnum } from '@/graphql/types/_server';
import getMappedCurrentStage from '@/utils/getMappedCurrentStage';
import { IProps } from './types';
import PaginationUi from '@/shared/ui/PaginationUi/PaginationUi';
import getCloudImgUrl from '@/utils/getUrlImage';

const StakingHistory: FC<IProps> = ({
  transactions,
  total,
  currentPage,
  setCurrentPage,
  pageSize
}) => {
  const calculateTotalPages = useMemo(() => Math.ceil(Number(total) / pageSize), [pageSize, total]);

  const history = useMemo(() => {
    return transactions.map((item) => {
      return {
        id: item.id,
        campaign: item.campaign,
        amount: Number(item.amount).toFixed(2),
        date: item.createdDate ? format(new Date(item.createdDate), 'yyyy-MM-dd') : '-',
        stage: item?.campaign?.status ? getMappedCurrentStage(item?.campaign?.status) : '-',
        src: item.campaign?.logoUrl,
        type: item.type === TransactionTypeEnum.Depositcampaign ? 'deposit' : 'withdraw'
      };
    });
  }, [transactions]);

  return (
    <Box sx={styles.history} id="stakingHistory">
      <Box sx={styles.containerTitle}>
        <Typography variant="h6">Staking history</Typography>
        {total > pageSize && (
          <PaginationUi
            count={calculateTotalPages}
            page={currentPage + 1}
            setPage={setCurrentPage}
          />
        )}
      </Box>
      <Box sx={styles.container}>
        <TableContainer sx={styles.historyTable} component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={styles.historyTableTitleRow}>
                <TableCell>
                  <Typography variant="span3">Campaign</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="span3">Deposit/Withdraw</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="span3">Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="span3">Current stage</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="span3">Type</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((item) => {
                return (
                  <TableRow key={item.id} sx={styles.historyTableRow}>
                    <TableCell>
                      <Link to={`/earn/${item?.campaign?.id}`}>
                        <Box sx={styles.containerLogo}>
                          <Box
                            sx={{
                              ...styles.logo,
                              backgroundImage: `url(${getCloudImgUrl(item.src || '', 100)})`
                            }}
                          />
                          <Box>
                            <Typography variant="body2" fontWeight={400}>
                              {item.campaign?.name}
                            </Typography>
                            <Typography
                              variant="overline"
                              sx={styles.textTransparent}
                              lineHeight={1}
                            >
                              by {item.campaign?.sponsor?.name}
                            </Typography>
                          </Box>
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{item.amount} USDC</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{item.date}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{item.stage}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{item.type}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default memo(StakingHistory);

import { FC, useContext, useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import styles from './styles';
import Layout from '@/components/Campaigns/Campaign/shared/Layout/Layout';
import { ReactComponent as InformationCirclesSvg } from '@/files/icons/informationCircles.svg';
import { ReactComponent as InformationVoiceSvg } from '@/files/icons/informationVoice.svg';
import { ReactComponent as InformationArrowSvg } from '@/files/icons/informationArrow.svg';
import LeftCard from './LeftCard/LeftCard';
import ModalTune from '@/shared/ModalTune/ModalTune';
import getErrorMessage from '@/utils/getErrorMessage';
import { SmartContractTypeEnum } from '@/graphql/types/_server';
import { getBalanceOf } from '@/utils/contractRequests';
import formatEthers from '@/utils/formatEthers';
import { Context } from '@/helpers/context';
import { IProps } from './types';

const InformationLabel: FC<IProps> = ({ isAnimation = false }) => {
  const { wallet, contracts } = useContext(Context);
  const [balancesN, setBalancesN] = useState<[string, string]>(['', '']);
  const [modalTune, setModalTune] = useState(false);

  useEffect(() => {
    if (!contracts.loading) {
      getBalance();
    }
  }, [contracts.loading]);

  const isDisabledButton = useMemo(() => Number(balancesN[0]) === 0, [balancesN]);

  return (
    <>
      <Layout
        leftCard={<LeftCard balancesN={balancesN} />}
        isAnimation={isAnimation}
        rightStyle={styles.rightStyle}
      >
        <Box sx={styles.container}>
          <Box sx={styles.containerIcon}>
            <InformationCirclesSvg />
          </Box>
          <Box>
            <Typography variant="h6" mb={3} mt={2}>
              Earn LABEL by Staking USDC
            </Typography>
            <Typography variant="span3" lineHeight={1.3} component="p">
              Staking USDC into active campaigns makes you eligible to earn a limited edition NFT
              and yield in LABEL tokens.
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.container} mt={1.75}>
          <Box sx={styles.containerIcon}>
            <InformationVoiceSvg />
          </Box>
          <Box>
            <Typography variant="h6" mb={3} mt={2}>
              Stake LABEL to earn TUNE
            </Typography>
            <Typography variant="span3" lineHeight={1.3} component="p">
              TUNE is the platform’s native voting power. It can only be earned by staking LABEL for
              a certain period of time. TUNE holders curate the marketplace.
            </Typography>
            <Box sx={styles.containerButton}>
              <Box>
                <Button
                  sx={styles.button}
                  disabled={isDisabledButton}
                  onClick={() => setModalTune(true)}
                >
                  <Typography variant="caption" fontWeight={500}>
                    {isDisabledButton ? 'Try in 24h' : 'Get Tunes'}
                  </Typography>
                </Button>
              </Box>
              {isDisabledButton && (
                <Typography variant="overline" sx={styles.text} lineHeight={1.3} component="p">
                  Come back once your first LABEL will be earned
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={styles.container} mt={1.75}>
          <Box sx={styles.containerIcon}>
            <InformationArrowSvg />
          </Box>
          <Box>
            <Typography variant="h6" mb={3} mt={2}>
              How to earn LABEL?
            </Typography>
            <Typography variant="span3" lineHeight={1.3} component="p">
              Stake USDC in an active campaign and earn LABEL tokens. The more LABEL tokens you
              earn, the more you can stake to earn TUNE.
            </Typography>
            <Box width="175px" mt={1.75}>
              <Link to="/earn">
                <Button sx={styles.button} fullWidth>
                  <Typography variant="caption" fontWeight={500}>
                    Get Started
                  </Typography>
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Layout>
      <ModalTune
        modalTune={modalTune}
        onModalTune={() => setModalTune(false)}
        balanceOf={balancesN[0]}
        onGetStakeAmount={getBalance}
      />
    </>
  );

  async function getBalance() {
    try {
      if (!wallet.ethers) return;

      const [balanceLabel, balanceOfUsdc] = await Promise.all([
        getBalanceOf(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Label], {
          account: wallet.address
        }),
        getBalanceOf(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Campaign], {
          account: wallet.address
        })
      ]);

      setBalancesN([formatEthers(balanceLabel, 18), formatEthers(balanceOfUsdc, 6)]);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  }
};

export default InformationLabel;

import { FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { ReactComponent as ArrowLongSvg } from '@/files/icons/arrowLong.svg';
import styles from './styles';
import { IProps } from './types';

const SwiperNavigation: FC<IProps> = ({ initSwiperNavigation, swiperRef }) => {
  const [swiperNavigation, setSwiperNavigation] = useState<{
    isBeginning: boolean;
    isEnd: boolean;
  }>({ isBeginning: true, isEnd: true });

  useEffect(() => {
    if (initSwiperNavigation) {
      setSwiperNavigation(initSwiperNavigation);
    }
  }, [initSwiperNavigation]);

  useEffect(() => {
    if (swiperRef) {
      swiperRef?.on('slideChange', () => {
        setSwiperNavigation({ isBeginning: !!swiperRef?.isBeginning, isEnd: !!swiperRef?.isEnd });
      });
    }
    return () => {
      if (swiperRef) {
        swiperRef?.destroy();
      }
    };
  }, [swiperRef]);

  if (swiperNavigation.isBeginning && swiperNavigation.isEnd) {
    return null;
  }

  return (
    <Box sx={styles.container}>
      <Box
        sx={{
          ...styles.containerArrow,
          ...(swiperNavigation.isBeginning && styles.disabledArrow)
        }}
        mr={0.5}
        onClick={handlePrev}
      >
        <ArrowLongSvg style={styles.arrowLeft} />
      </Box>
      <Box
        sx={{
          ...styles.containerArrow,
          ...(swiperNavigation.isEnd && styles.disabledArrow)
        }}
        onClick={handleNext}
      >
        <ArrowLongSvg />
      </Box>
    </Box>
  );

  function handlePrev() {
    swiperRef?.slidePrev();
  }

  function handleNext() {
    swiperRef?.slideNext();
  }
};

export default SwiperNavigation;

const styles = {
  item: {
    maxWidth: '301px',
    minWidth: '200px',
    background: 'rgb(25, 26, 28)',
    backgroundSize: 'cover',
    backgroundPositionX: 'left',
    backgroundPositionY: 'bottom',
    borderRadius: '20px',
    position: 'relative',
    paddingTop: 'min(305px, 90%)',
    cursor: 'pointer',
    '@media screen and (max-width: 1100px)': {
      maxWidth: '338px'
    },
    '@media screen and (max-width: 460px)': {
      maxWidth: 'initial',
      width: '100%'
    }
  },
  play: {
    position: 'absolute',
    left: '16px',
    bottom: '16px',
    background: `#F3F700`,
    borderRadius: '50%',
    width: '48px',
    height: '48px',
    display: 'flex',
    backdropFilter: 'blur(2px)',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background 0.5s'
  },
  stop: {
    position: 'absolute',
    left: '16px',
    bottom: '16px',
    background: `#fff`,
    borderRadius: '50%',
    width: '48px',
    height: '48px',
    display: 'flex',
    backdropFilter: 'blur(2px)',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background 0.5s'
  },
  download: {
    position: 'absolute',
    right: '16px',
    bottom: '16px',
    background: '#F3F700',
    borderRadius: '50%',
    width: '48px',
    height: '48px',
    cursor: 'default',
    '& button:disabled': {
      backgroundColor: '#c4c700',
      opacity: 1
    }
  }
};

export default styles;

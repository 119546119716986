import { FC, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import Teaser from '@/components/Home/Teasers/Teaser/Teaser';
import { ReactComponent as Teaser1Svg } from '@/files/icons/teasers/teaser1.svg';
import { ReactComponent as Teaser2Svg } from '@/files/icons/teasers/teaser2.svg';
import { ReactComponent as Teaser3Svg } from '@/files/icons/teasers/teaser3.svg';
import { ReactComponent as Teaser4Svg } from '@/files/icons/teasers/teaser4.svg';
import { ReactComponent as RemixSvg } from '@/files/icons/teasers/remix.svg';
import styles from './styles';

const Teasers: FC = () => {
  const teasersRef = useRef<HTMLDivElement | undefined>(undefined);
  const [isStartAnimation, setIsStartAnimation] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const el = teasersRef.current || undefined;
      if (el) {
        const delta = 400;
        const topPosition = el.getBoundingClientRect().top + window.scrollY;
        const scrollPosition = window.scrollY + window.innerHeight - delta;
        if (topPosition < scrollPosition) {
          setIsStartAnimation(true);
        }
      }
    });
    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, []);

  return (
    <Box ref={teasersRef} sx={styles.teasersSection} py={6} px={11.25} mb={5}>
      <Box sx={{ ...(isStartAnimation && styles.teasersAnimation) }}>
        <Box sx={styles.remix}>
          <RemixSvg />
        </Box>
        <Box sx={styles.teasers}>
          <Teaser
            svg={<Teaser1Svg />}
            title="Deposit"
            description="Deposit USDC in an active campaign to earn rewards and a chance to win a limited edition Stem NFT from your favorite artist. Keep all of your money, even if you don’t win."
          />
          <Teaser
            svg={<Teaser2Svg />}
            title="Download"
            description="Stem NFTs are ERC721 tokens that unlock downloads to audio files provided by a campaign’s sponsor. Music producers use these files to create their own content."
          />
          <Teaser
            svg={<Teaser3Svg />}
            title="Upload"
            description="Made a banger? Visit the campaign that corresponds to your Stem NFT and upload your track. Your Stem NFT unlocks permission to submit your track to the Label Protocol, don’t lose it."
          />
          <Teaser
            svg={<Teaser4Svg />}
            title="Vote and Win"
            description="Deposit LABEL to earn TUNE and vote on your favorite songs. Selected winners get their music listed as a limited edition Music NFT collab with the campaign sponsor."
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Teasers;

const styles = {
  stake: {
    background: '#000000',
    minHeight: '15.25rem',
    borderBottomRightRadius: '2.5rem',
    borderBottomLeftRadius: '2.5rem',
    padding: '1.5625rem 2.3125rem 0.75rem 2.875rem',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
    '@media screen and (max-width: 360px)': {
      padding: '1.5625rem 0.95rem 1.5625rem 0.95rem'
    }
  },
  containerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 1,
    height: '5.4375rem',
    mb: 3,
    '@media screen and (max-width: 900px)': {
      flexWrap: 'wrap',
      height: '9.75rem',
      '& > *:first-child': {
        mb: 2
      }
    },
    '@media screen and (max-width: 850px)': {
      flexWrap: 'initial',
      height: '5.4375rem',
      '& > *:first-child': {
        mb: 0
      }
    },
    '@media screen and (max-width: 460px)': {
      flexWrap: 'wrap',
      height: '9.75rem',
      '& > *:first-child': {
        mb: 2
      }
    }
  },
  containerBottom: {
    pt: 3,
    pb: 2
  },
  containerTickets: {
    color: 'rgb(255 255 255 / 0.5)',
    '& > div:first-of-type': {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: '260px',
      '& > div': {
        position: 'relative'
      }
    }
  },
  divider: {
    width: '100%'
  },
  textGrey: {
    color: '#BDC2C8'
  },
  contaierFlexTop: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: 1
    },
    '& > *:last-child': {
      alignSelf: 'end'
    }
  },
  containerProhibit: {
    textAlign: 'center',
    mt: 3.375,
    mb: 2.5
  },
  caption: {
    width: '55%',
    fontWeight: 300,
    lineHeight: '1.3125rem',
    color: '#BDC2C8',
    margin: 'auto',
    mt: 1.5
  }
};

export default styles;

import { FC } from 'react';
import Box from '@mui/material/Box';
import { IProps } from './types';
import Images from './view/Images/Images';
import Chips from './view/Chips/Chips';
import Logo from './view/Logo/Logo';
import File from './view/File/File';
import styles from './styles';
import { IView } from '../types';

const ListFiles: FC<IProps> = ({
  files,
  view,
  handleFileDelete = () => {},
  baseFile,
  setCroppedImage,
  fileSize,
  content,
  multiple
}) => {
  return files.length || baseFile ? (
    <Box sx={styles.container}>
      {(() => {
        switch (view) {
          case IView.Image:
            return (
              <Images
                setCroppedImage={setCroppedImage}
                baseFile={baseFile}
                files={files}
                handleFileDelete={handleFileDelete}
                multiple={multiple}
              />
            );
          case IView.Logo:
            return (
              <Logo
                setCroppedImage={setCroppedImage}
                baseFile={baseFile}
                files={files}
                handleFileDelete={handleFileDelete}
                content={content}
              />
            );
          case IView.Archive:
            return (
              <File
                files={files}
                handleFileDelete={handleFileDelete}
                fileSize={fileSize}
                type="archive"
              />
            );
          case IView.Music:
            return <File files={files} handleFileDelete={handleFileDelete} type="music" />;
          default:
            return <Chips files={files} handleFileDelete={handleFileDelete} />;
        }
      })()}
    </Box>
  ) : null;
};

export default ListFiles;

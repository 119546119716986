/* eslint-disable no-nested-ternary */
import { FC, memo, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { differenceInDays, differenceInMinutes, formatDuration, differenceInHours } from 'date-fns';
import getMappedCurrentStage from '@/utils/getMappedCurrentStage';
import { IProps } from './types';
import styles from './styles';
import { CampaignStatusEnum } from '@/graphql/types/_server';

const CampaignTime: FC<IProps> = ({ campaign, handleClick }) => {
  const textButton = useMemo(() => {
    const mapStatus = getMappedCurrentStage(campaign.status);
    if (campaign.status === CampaignStatusEnum.Draft) {
      const endTime = getDateDifferenceInDays(
        new Date(campaign.startLotteryDate),
        new Date(),
        false
      );
      return <Typography variant="caption">Starts in {endTime}</Typography>;
    }
    if (
      [
        CampaignStatusEnum.Lottery,
        CampaignStatusEnum.Repayment,
        CampaignStatusEnum.Remix,
        CampaignStatusEnum.Voting
      ].includes(campaign.status)
    ) {
      const obj: Record<
        string,
        'startRemixDate' | 'startRemixDate' | 'startVotingDate' | 'checkDate'
      > = {
        [CampaignStatusEnum.Lottery]: 'startRemixDate',
        [CampaignStatusEnum.Repayment]: 'startRemixDate',
        [CampaignStatusEnum.Remix]: 'startVotingDate',
        [CampaignStatusEnum.Voting]: 'checkDate'
      };
      const endTime = getDateDifferenceInDays(new Date(campaign[obj[campaign.status]]), new Date());
      return (
        <Typography variant="caption" fontWeight={500}>
          {mapStatus}{' '}
          <Typography variant="caption" fontWeight={300}>
            {endTime}
          </Typography>
        </Typography>
      );
    }
    if (campaign.status === CampaignStatusEnum.Done) {
      const endTime = getDateDifferenceInDays(new Date(campaign.checkDate), new Date());
      return (
        <Typography variant="caption" sx={styles.text}>
          {endTime}
        </Typography>
      );
    }
    return '';
  }, [campaign]);

  return (
    <Button sx={styles.button} onClick={handleClick}>
      {textButton}
    </Button>
  );

  function getDateDifferenceInDays(dateLeft: Date, dateRight: Date, prefix = true) {
    const countDays = differenceInDays(dateLeft, dateRight);
    if (countDays !== 0) {
      return `${formatDuration({ days: Math.abs(countDays) })}${
        prefix ? (countDays > 0 ? ' left' : ' ago') : ''
      }`;
    }
    const countHours = differenceInHours(dateLeft, dateRight);
    if (countHours !== 0) {
      return `${formatDuration({ hours: Math.abs(countHours) })}${
        prefix ? (countHours > 0 ? ' left' : ' ago') : ''
      }`;
    }
    const countMinutes = differenceInMinutes(dateLeft, dateRight);
    return `${formatDuration({ minutes: Math.abs(countHours) || 1 })}${
      prefix ? (countMinutes >= 0 ? ' left' : ' ago') : ''
    }`;
  }
};

export default memo(CampaignTime);

const styles = (theme: 'dark' | 'light') => ({
  listItem: {
    cursor: 'pointer',
    '&:hover p::after': {
      width: '100%'
    }
  },
  listItemText: {
    position: 'relative',
    textAlign: 'center',
    width: 'max-content',
    '@media screen and (min-width: 961px)': {
      ...(theme === 'light' && { color: 'white' })
    },
    '&::after': {
      position: 'absolute',
      bottom: '-8px',
      left: 0,
      width: '0%',
      height: '4px',
      content: '""',
      backgroundColor: '#F3F700',
      transition: 'all 300ms'
    }
  },
  activeListItemText: {
    fontWeight: 700,
    '&::after': {
      position: 'absolute',
      bottom: '-8px',
      left: 0,
      width: '100%',
      height: '4px',
      content: '""',
      backgroundColor: '#F3F700',
      transition: 'all 300ms'
    }
  }
});

export default styles;

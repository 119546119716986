import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { toast } from 'react-toastify';
import { DropZoneProps, FileRejType, IView } from './types';
import styles from './styles';
import AbsoluteLoading from '../ui/AbsoluteLoading/AbsoluteLoading';
import ListFiles from './ListFiles/ListFiles';
import colors from '@/helpers/theme/colors';

import ImageCropper from '@/shared/ImageCropper/ImageCropper';
import Modal from '@/shared/Modal/Modal';
import { useImageCrop } from '@/utils/useImageCrop';

const DropZone: FC<DropZoneProps> = ({
  files,
  label = 'Drag and drop file here',
  loading = false,
  handleFilesAdd = () => {},
  handleFileDelete = () => {},
  maxFiles = 10,
  maxSize = 10000000,
  multiple = true,
  accept = ['audio/mpeg', 'audio/wav'],
  error = false,
  helperText = '',
  view = IView.Chip,
  cropImage = false,
  variant = 'standart',
  theme = 'light',
  fileSize = 0,
  content = undefined
}) => {
  const {
    sizeError,
    currentFile,
    canCropImage,
    handleFileCropping,
    currBase64,
    setCroppedImage,
    croppedImage,
    handleClose
  } = useImageCrop();

  const stylesMemoized = useMemo(() => styles(theme), [theme]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (cropImage) {
        return handleFileCropping(acceptedFiles[0]);
      }
      if ([...files, ...acceptedFiles].length <= maxFiles) {
        handleFilesAdd(acceptedFiles);
      } else {
        toast.error(`Maximum number of files: ${maxFiles}`);
      }
      return null;
    },
    [cropImage, files, handleFilesAdd, maxFiles, sizeError]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop, maxSize, multiple, accept });

  useEffect(() => {
    if (fileRejections.length) {
      fileRejections.forEach((item: FileRejType) => {
        item?.errors?.forEach((e) => {
          const message =
            e?.code === 'file-too-large'
              ? `The file must not exceed ${Number(maxSize) / 1000000} mb.`
              : `Error. ${e.message}`;

          toast.error(message);
        });
      });
    }
  }, [fileRejections, maxSize]);

  const disabled: boolean = useMemo(
    () => !currBase64 && files.length >= maxFiles,
    [files.length, maxFiles, currBase64]
  );

  const dropZoneState = useMemo(
    () => ({
      ...(isFocused && !disabled && stylesMemoized.focused),
      ...(isDragAccept && !disabled && stylesMemoized.accept),
      ...((isDragReject || error || sizeError) && !disabled && stylesMemoized.reject)
    }),
    [isFocused, disabled, isDragAccept, isDragReject, error, sizeError]
  );
  return (
    <Box>
      <Box position="relative">
        {cropImage && (
          <Modal title="Crop Image" isOpen={canCropImage} onClose={handleClose}>
            <ImageCropper
              currentFile={currentFile}
              handleFilesAdd={handleFilesAdd}
              currBase64={currBase64}
              setCroppedImage={setCroppedImage}
              handleClose={handleClose}
            />
          </Modal>
        )}
        <Box
          component="div"
          sx={{
            ...((disabled || loading) && stylesMemoized.disabledDropZone),
            ...stylesMemoized.dropZone,
            ...(variant === 'black' && stylesMemoized.dropZoneBlack),
            ...(variant === 'yellow' && stylesMemoized.dropZoneYellow)
          }}
          {...getRootProps({
            style: dropZoneState,
            onClick: (event) => (disabled ? event.stopPropagation() : undefined)
          })}
        >
          <input disabled={disabled} {...getInputProps()} />
          <Box
            textAlign="center"
            color={colors.grey.text}
            sx={{
              ...(disabled &&
                !['yellow', 'black'].includes(variant) &&
                stylesMemoized.disabledTypography)
            }}
          >
            {label}
          </Box>
          <ListFiles
            baseFile={croppedImage}
            files={files}
            handleFileDelete={handleFileDelete}
            view={view}
            fileSize={fileSize}
            setCroppedImage={setCroppedImage}
            content={content}
            multiple={multiple}
          />
          {isDragActive}
        </Box>
        {loading && <AbsoluteLoading size={32} />}
      </Box>
      {helperText && (
        <Typography variant="caption" color="error" sx={stylesMemoized.helperText}>
          {helperText}
        </Typography>
      )}
      {sizeError && (
        <Typography variant="caption" color="error" sx={stylesMemoized.helperText}>
          Please upload a file which size exceeds 600x600 pixels
        </Typography>
      )}
    </Box>
  );
};

export default DropZone;

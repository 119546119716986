import { FC } from 'react';
import Box from '@mui/material/Box';
import styles from './styles';
import { IProps } from './types';

const Layout: FC<IProps> = ({ children, leftCard, rightStyle, isAnimation = true }) => {
  return (
    <Box sx={{ ...styles.container, ...(isAnimation && styles.animationLeft) }}>
      {leftCard}
      <Box sx={{ ...styles.children, ...rightStyle, ...(isAnimation && styles.animationRight) }}>
        {children}
      </Box>
    </Box>
  );
};

export default Layout;

import getCurrentUTCTime from '@/utils/getCurrentUTCTime';

const isDateFuture = (date: Date | null | undefined) => {
  const tomorrowDate = getCurrentUTCTime();
  tomorrowDate.setHours(0, 0, 0);
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  if (date) {
    return date >= tomorrowDate;
  }
  return true;
};

export default isDateFuture;

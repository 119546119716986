const colors = {
  accent1: '#232323',
  accent2: '#00D27B',
  font: {
    main: '',
    light: '',
    link: '',
    inverted: '#fff'
  },
  border: {
    main: '#ddd',
    light: '#eeeeee',
    inverted: '#777' // not used
  },
  grey: {
    background: 'rgba(0, 0, 0, 0.26)',
    text: 'rgba(0, 0, 0, 0.6)',
    light: '#aeaeae',
    main: '#6B6B6B',
    textTeaser: '#BDC2C8' // not used
  },
  common: {
    white: '#fff',
    yellow: '#F3F700',
    black: '#000'
  },
  background: {
    default: '#fff',
    paper: '#fff'
  },
  iconColor: '#1A1B1D',
  background1: '#232323',
  background2: '#000000c7', // not used
  background3: '#aaa', // not used
  background4: '#1C1C1C', // not used
  modalBackground: 'rgba(120, 120, 120, 0.54)', // not used
  loading: {
    background: 'rgba(0, 0, 0, 0.1)',
    blur: 'blur(2px)'
  },
  boxShadow: '0px 8.42064px 16.8413px rgba(0, 0, 0, 0.15);',
  status: {
    success: '#2e7d32',
    info: '#0288d1', // not used
    error: '#d32f2f',
    warning: ''
  }
};

export default colors;

import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Box, Slider } from '@mui/material';
import { Area } from 'react-easy-crop/types';
import Button from '@mui/material/Button';
import { IImageCropperProps } from '@/shared/ImageCropper/types';
import styles from './styles';
import { CROPPER_DATA } from '@/shared/ImageCropper/data';
import getCroppedImg from '@/shared/ImageCropper/cropImage';

const ImageCropper: React.FC<IImageCropperProps> = ({
  setCroppedImage,
  currBase64,
  handleFilesAdd,
  currentFile,
  handleClose
}) => {
  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0
  });

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      const data: { base64: string; file: File | null } | null = await getCroppedImg(
        currentFile,
        currBase64,
        croppedAreaPixels
      );

      handleFilesAdd([data.file ? data.file : {}]);
      setCroppedImage(data.base64 || '');
      handleClose();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <Box sx={styles.box}>
      <Box sx={styles.cropper}>
        <Cropper
          image={currBase64}
          crop={crop}
          zoom={zoom}
          aspect={CROPPER_DATA.aspect}
          minZoom={CROPPER_DATA.maxZoom}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
        />
      </Box>

      <Box sx={styles.sliderWrapper}>
        <Slider
          aria-labelledby="Zoom"
          value={zoom}
          sx={styles.slider}
          color="secondary"
          min={1}
          max={CROPPER_DATA.zoomValue}
          step={CROPPER_DATA.zoomStep}
          onChange={(e: Event, value: number | number[]) => {
            setZoom(Number(value));
          }}
        />
      </Box>
      <Button
        sx={styles.button}
        color="primary"
        variant="outlined"
        type="submit"
        disableElevation
        size="large"
        onClick={showCroppedImage}
      >
        Crop
      </Button>
    </Box>
  );
};

export default React.memo(ImageCropper);

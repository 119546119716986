import { FC, memo, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import ItemsCarousel from 'react-items-carousel';
import { ICampaignModel } from '@/graphql/types/_server';
import SliderItem from '../SliderItem/SliderItem';
import styles from './styles';

const SliderBottom: FC<{
  bottomCampaigns: ICampaignModel[];
  slidesPerView: number;
  countSlides: number;
}> = ({ bottomCampaigns, slidesPerView, countSlides }) => {
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const stylesMemoized = useMemo(
    () => styles(width * bottomCampaigns.length || 0, bottomCampaigns.length * 24 || 0),
    [width, bottomCampaigns.length]
  );

  const items = useMemo(() => {
    return bottomCampaigns.map((item) => {
      return (
        <SliderItem
          key={`${item.id}_bottom`}
          campaign={item}
          countMissing={countSlides - slidesPerView}
        />
      );
    });
  }, [bottomCampaigns]);

  return (
    <Box sx={{ ...stylesMemoized.container, ...stylesMemoized.firstAnimation }}>
      <ItemsCarousel
        infiniteLoop
        numberOfCards={countSlides}
        activeItemIndex={activeItemIndex}
        requestToChangeActive={setActiveItemIndex}
        chevronWidth={0}
        calculateActualTranslateX={(translateX: number) => {
          setWidth(
            bottomCampaigns.length < countSlides
              ? translateX / bottomCampaigns.length
              : translateX / countSlides
          );
        }}
      >
        {items}
      </ItemsCarousel>
    </Box>
  );
};

export default memo(SliderBottom);

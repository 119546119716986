const styles = {
  container: {
    width: '383px',
    '& > svg': {
      fill: '#232323'
    },
    '@media screen and (max-width: 1200px)': {
      width: '272px'
    }
  },
  header: {
    padding: '0 3rem',
    height: '128px',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > :first-of-type': {
      display: 'flex',
      gap: 2,
      minHeight: '3rem',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > :first-of-type': {
        cursor: 'pointer'
      },
      '> svg': {
        transition: 'transform ease-in-out 0.5s',
        '&:hover': { cursor: 'pointer', transform: 'scale(1.05)' }
      }
    },
    '& > :last-of-type': {
      display: 'flex',
      minHeight: '3rem',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'end',
      '& > :last-child': {
        marginLeft: '0.5rem'
      }
    }
  },
  headerMobile: {
    px: 1,
    height: '90px',
    display: 'flex',
    alignItems: 'center',
    '> svg': {
      fill: '#232323',
      transition: 'transform ease-in-out 0.5s',
      '&:hover': { cursor: 'pointer', transform: 'scale(1.1)' }
    }
  },
  button: {
    mr: 1,
    '> svg': {
      fill: '#232323'
    }
  },
  fill: {
    flex: 1
  }
};

export default styles;

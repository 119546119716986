import colors from '@/helpers/theme/colors';

const styles = {
  container: {
    background: colors.common.white,
    borderRadius: '1.875rem',
    padding: '2.6875rem 2.25rem 1.5625rem',
    display: 'flex',
    columnGap: '0.75rem'
  },
  button: {
    background: colors.iconColor,
    color: colors.common.white,
    padding: '0.625rem 1.625rem 0.6875rem',
    borderRadius: '0.625rem',
    '&:hover': {
      backgroundColor: colors.background1
    },
    '&:disabled': {
      backgroundColor: colors.common.white,
      border: `1px solid ${colors.iconColor}`,
      opacity: 0.5,
      color: colors.iconColor
    }
  },
  containerButton: {
    display: 'flex',
    columnGap: '1rem',
    marginTop: '0.875rem'
  },
  containerIcon: {
    minWidth: '4.0625rem'
  },
  rightStyle: {
    width: '31.25rem'
  },
  closeIcon: {
    position: 'absolute',
    right: '20px',
    top: '20px',
    color: colors.common.white,
    width: '48px',
    height: '48px',
    '& > svg': {
      fontSize: '32px'
    }
  },
  text: {
    fontFamily: '"Grafita", monospace, sans-serif',
    opacity: 0.5,
    maxWidth: '10.3125rem'
  }
};

export default styles;

import { FC, useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import ConnectWallet from '@/shared/ConnectWallet/ConnectWallet';
import styles from './styles';
import { IProps } from './types';
import { ReactComponent as ArrowSvg } from '@/files/icons/arrow.svg';
import ModalUnstakeUsdc from './ModalUnstakeUsdc/ModalUnstakeUsdc';
import getErrorMessage from '@/utils/getErrorMessage';
import {
  ICampaignModel,
  IQueryCampaignByIdArgs,
  SmartContractTypeEnum
} from '@/graphql/types/_server';
import { CAMPAIGN_BY_ID } from '@/graphql/gql/campaigns';
import { getDepositByAddress } from '@/utils/contractRequests';
import formatEthers from '@/utils/formatEthers';
import { Context } from '@/helpers/context';

const Campaign: FC<IProps> = ({ refetch, navigate, isMobile, isSmallMobile, isStaked }) => {
  const { id } = useParams<'id'>();
  const campaignId = Number(id);

  const { wallet, contracts } = useContext(Context);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [stakedUsdc, setStakedUsdc] = useState<number>(0);

  const { loading: loadingCampaign, data } = useQuery<
    { campaignById: ICampaignModel },
    IQueryCampaignByIdArgs
  >(CAMPAIGN_BY_ID, {
    variables: {
      payload: {
        id: campaignId
      }
    }
  });
  const campaign = data?.campaignById;

  useEffect(() => {
    if (!contracts.loading && !loadingCampaign) {
      getStakedUsdc();
    }
  }, [contracts.loading, loadingCampaign]);

  if (isMobile) {
    return (
      <Box sx={styles.headerMobile}>
        <Box sx={styles.containerMobile}>
          <IconButton id="arrow" size="large" sx={styles.arrow} onClick={() => navigate(-1)}>
            <ArrowSvg />
          </IconButton>
          {isStaked && !!stakedUsdc && (
            <LoadingButton sx={styles.button} onClick={() => setIsOpen(true)} loading={loading}>
              <Typography variant="body2" lineHeight={1}>
                {!isSmallMobile ? `${stakedUsdc} USDC Staked` : `${stakedUsdc} USDC`}
              </Typography>
            </LoadingButton>
          )}
        </Box>
        <Box sx={{ mr: 1 }}>
          <ConnectWallet refetch={refetch} theme="light" />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box sx={styles.header}>
        <Box sx={styles.container}>
          <IconButton id="arrow" size="large" sx={styles.arrow} onClick={() => navigate(-1)}>
            <ArrowSvg />
          </IconButton>
        </Box>
        {isStaked && !!stakedUsdc && (
          <LoadingButton sx={styles.button} onClick={() => setIsOpen(true)} loading={loading}>
            <Typography variant="body2" lineHeight={1}>
              {stakedUsdc} USDC Staked
            </Typography>
          </LoadingButton>
        )}
        <Box sx={styles.container}>
          <div>
            <ConnectWallet refetch={refetch} theme="light" />
          </div>
        </Box>
      </Box>
      <ModalUnstakeUsdc
        modalTune={isOpen}
        onModalTune={() => setIsOpen(false)}
        stakedUsdc={stakedUsdc}
        campaignId={campaignId}
        getStakedUsdc={getStakedUsdc}
      />
    </>
  );

  async function getStakedUsdc() {
    try {
      setLoading(true);
      if (!wallet.ethers) return;

      const stakeByAddress = await getDepositByAddress(
        wallet.ethers,
        contracts.contracts[SmartContractTypeEnum.Campaign],
        {
          tokenID: campaign?.tokenID,
          addressClient: wallet.address
        }
      );

      const amounts = stakeByAddress.amounts.map((item: bigint, index: number) => {
        if (stakeByAddress.isWithdraw[index]) return 0;
        return formatEthers(item, 6); // USDC
      });

      const sumOfAmount = amounts.reduce((a: string, b: string) => Number(a) + Number(b), 0);
      setStakedUsdc(sumOfAmount);
    } catch (error) {
      toast.error(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  }
};

export default Campaign;

import Box from '@mui/material/Box';
import { FC, memo } from 'react';

const Ticket: FC<{ id: string; percents: number; color1: string; color2: string }> = ({
  id,
  percents,
  color1,
  color2
}) => {
  return (
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44"
        height="56"
        viewBox="0 0 44 56"
        fill={`url(#grad${id})`}
      >
        <defs>
          <linearGradient id={`grad${id}`}>
            <stop offset={`${percents}%`} stopColor={color2} />
            <stop offset={`${percents}%`} stopColor={color1} />
          </linearGradient>
        </defs>

        <path d="M28 55L27 55C27 55.5523 27.4477 56 28 56L28 55ZM16 55L16 56C16.5523 56 17 55.5523 17 55L16 55ZM16 0.999999L17 0.999999C17 0.447714 16.5523 -1.15607e-06 16 -1.18021e-06L16 0.999999ZM28 0.999999L28 -6.55671e-07C27.4477 -6.79812e-07 27 0.447715 27 0.999999L28 0.999999ZM43 38C43.5523 38 44 37.5523 44 37C44 36.4477 43.5523 36 43 36L43 38ZM37 36C36.4477 36 36 36.4477 36 37C36 37.5523 36.4477 38 37 38L37 36ZM25 38C25.5523 38 26 37.5523 26 37C26 36.4477 25.5523 36 25 36L25 38ZM19 36C18.4477 36 18 36.4477 18 37C18 37.5523 18.4477 38 19 38L19 36ZM7 38C7.55228 38 8 37.5523 8 37C8 36.4477 7.55228 36 7 36L7 38ZM0.999998 36C0.447714 36 -1.54947e-06 36.4477 -1.57361e-06 37C-1.59775e-06 37.5523 0.447714 38 0.999998 38L0.999998 36ZM44 7C44 3.13401 40.866 -9.32804e-08 37 -2.62268e-07L37 2C39.7614 2 42 4.23858 42 7L44 7ZM44 49L44 7L42 7L42 49L44 49ZM37 56C40.866 56 44 52.866 44 49L42 49C42 51.7614 39.7614 54 37 54L37 56ZM28 56L37 56L37 54L28 54L28 56ZM22 50C24.7614 50 27 52.2386 27 55L29 55C29 51.134 25.866 48 22 48L22 50ZM17 55C17 52.2386 19.2386 50 22 50L22 48C18.134 48 15 51.134 15 55L17 55ZM7 56L16 56L16 54L7 54L7 56ZM-2.09815e-06 49C-2.26713e-06 52.866 3.134 56 7 56L7 54C4.23857 54 2 51.7614 2 49L-2.09815e-06 49ZM-2.62268e-07 7L-2.09815e-06 49L2 49L2 7L-2.62268e-07 7ZM7 -1.57361e-06C3.13401 -1.7426e-06 -9.32804e-08 3.134 -2.62268e-07 7L2 7C2 4.23857 4.23857 2 7 2L7 -1.57361e-06ZM16 -1.18021e-06L7 -1.57361e-06L7 2L16 2L16 -1.18021e-06ZM22 6C19.2386 6 17 3.76142 17 0.999999L15 0.999999C15 4.86599 18.134 8 22 8L22 6ZM27 0.999999C27 3.76142 24.7614 6 22 6L22 8C25.866 8 29 4.86599 29 0.999999L27 0.999999ZM37 -2.62268e-07L28 -6.55671e-07L28 2L37 2L37 -2.62268e-07ZM43 36L37 36L37 38L43 38L43 36ZM25 36L19 36L19 38L25 38L25 36ZM7 36L0.999998 36L0.999998 38L7 38L7 36Z" />
      </svg>
    </Box>
  );
};

export default memo(Ticket);

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  gird: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(100px, 1fr))',
    columnGap: '14px',
    rowGap: '24px',
    '@media screen and (max-width: 1000px)': {
      gridTemplateColumns: 'repeat(3, minmax(100px, 1fr))'
    },
    '@media screen and (max-width: 740px)': {
      gridTemplateColumns: 'repeat(2, minmax(100px, 1fr))'
    },
    '@media screen and (max-width: 460px)': {
      gridTemplateColumns: 'repeat(1, minmax(100px, 1fr))'
    }
  }
};

export default styles;

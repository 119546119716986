const styles = {
  containerTop: {
    background: '#F3F700',
    padding: '2.0625rem 2.8125rem 1rem',
    position: 'relative',
    zIndex: -1,
    '& > svg': {
      position: 'absolute',
      bottom: '-2.8125rem',
      right: '2.4%',
      width: '38.3555%'
    }
  },
  containerBottom: {
    background: 'black',
    borderBottomRightRadius: '2.5rem',
    borderBottomLeftRadius: '2.5rem',
    padding: '1.375rem 3.3125rem 1.125rem 2.875rem',
    display: 'grid',
    '& > button': {
      justifySelf: 'end',
      backgroundColor: '#fff',
      color: 'black',
      width: '8.9375rem',
      height: '2rem',
      ':hover': {
        backgroundColor: '#fff'
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        color: 'rgba(255, 255, 255, 0.3)'
      },
      '&.MuiLoadingButton-loading': {
        color: 'transparent',
        backgroundColor: 'white'
      },
      '@media screen and (max-width: 900px)': {
        mt: 2
      },
      '@media screen and (max-width: 850px)': {
        mt: 0
      },
      '@media screen and (max-width: 500px)': {
        mt: 2
      }
    }
  },
  text: {
    fontFamily: '"Grafita", monospace, sans-serif',
    textTransform: 'initial'
  },
  textGrey: {
    color: '#BDC2C8'
  }
};

export default styles;

import { gql } from '@apollo/client';
import PAGINATE_MODEL from './models/paginateModel';

export const ADD_REMIX_FOR_VOTE = gql`
  mutation addRemixForVote(
    $payload: AddRemixForVoteDTO!
    $audio: Upload!
    $logo: Upload!
    $chainId: Int!
  ) {
    addRemixForVote(payload: $payload, audio: $audio, logo: $logo, chainId: $chainId) {
      id
    }
  }
`;

export const UPDATE_REMIX_FOR_VOTE = gql`
  mutation updateRemixForVote(
    $payload: ChangeRemixForVoteDTO!
    $audio: Upload
    $logo: Upload
    $chainId: Int!
  ) {
    updateRemixForVote(payload: $payload, audio: $audio, logo: $logo, chainId: $chainId) {
      id
    }
  }
`;

export const CAMPAIGNS = gql`
  query campaigns($payload: CampaignGetDTO!) {
    campaigns(payload: $payload) {
      items {
        createdDate
        id
        logoUrl
        name
        description
        sponsor {
          id
          name
          logoUrl
          userName
        }
        isWhiteName
        startLotteryDate
        startRemixDate
        startVotingDate
        checkDate
        status
        updatedDate
        user {
          id
          name
        }
      }
      meta {
        ...paginateModel
      }
    }
  }
  ${PAGINATE_MODEL}
`;

export const CAMPAIGNS_BY_ADMIN = gql`
  query campaignsByAdmin($payload: CampaignGetDTO!) {
    campaignsByAdmin(payload: $payload) {
      items {
        createdDate
        id
        logoUrl
        name
        description
        sponsor {
          id
          name
        }
        isWhiteName
        startLotteryDate
        startRemixDate
        startVotingDate
        checkDate
        status
        updatedDate
        user {
          id
          name
        }
        remixes {
          id
          name
          logoUrl
          trackUrl
          status
          author
          description
          genre
          key
          beats
        }
      }
      meta {
        ...paginateModel
      }
    }
  }
  ${PAGINATE_MODEL}
`;

export const CAMPAIGN_BY_ID = gql`
  query campaignById($payload: CampaignIdDTO!) {
    campaignById(payload: $payload) {
      createdDate
      id
      logoUrl
      stemUrl
      stemSize
      stemName
      name
      description
      remixes {
        id
        logoUrl
        genre
        beats
        key
        name
        author
        price
        description
        trackUrl
        trackName
        status
        tokenID
        user {
          id
          name
          type
          userName
        }
        sponsor {
          id
          name
          logoUrl
          userName
        }
        campaign {
          id
        }
      }
      sponsor {
        id
        name
        userName
        logoUrl
      }
      startLotteryDate
      startRemixDate
      startVotingDate
      checkDate
      status
      updatedDate
      user {
        id
        name
      }
      numberWinnersLottery
      numberWinnersVoting
      tokenID
    }
  }
`;

export const CAMPAIGN_BY_ID_FOR_EDIT = gql`
  query campaignById($payload: CampaignIdDTO!) {
    campaignById(payload: $payload) {
      createdDate
      numberWinnersLottery
      numberWinnersVoting
      price
      id
      logoUrl
      name
      description
      sponsor {
        id
        name
      }
      isWhiteName
      startLotteryDate
      startRemixDate
      startVotingDate
      status
      updatedDate
      checkDate
      stemUrl
      stemSize
      stemName
    }
  }
`;

export const STEM_BY_CAMPAIGN = gql`
  query stemByCampaign($chainId: Int!, $payload: StemGetIdDTO!) {
    stemByCampaign(chainId: $chainId, payload: $payload) {
      id
      logoUrl
      stemUrl
      tokenID
      isMinted
      isSpent
    }
  }
`;

export const CREATE_CAMPAIGN = gql`
  mutation createCampaign($logo: Upload!, $payload: CampaignCreateDTO!, $chainId: Int!) {
    createCampaign(logo: $logo, payload: $payload, chainId: $chainId) {
      id
    }
  }
`;

export const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign($logo: Upload, $payload: CampaignUpdateDTO!) {
    updateCampaign(logo: $logo, payload: $payload) {
      createdDate
      numberWinnersLottery
      numberWinnersVoting
      id
      logoUrl
      name
      price
      sponsor {
        id
        name
      }
      isWhiteName
      startLotteryDate
      startRemixDate
      startVotingDate
      status
      updatedDate
      user {
        id
        name
      }
      checkDate
    }
  }
`;

export const UPLOAD_CAMPAIGN_STEM = gql`
  mutation uploadCampaignStem($payload: CampaignIdDTO!, $stem: Upload!) {
    uploadCampaignStem(payload: $payload, stem: $stem) {
      id
    }
  }
`;

export const CANCEL_CAMPAIGN_BY_ADMIN = gql`
  mutation cancelCampaignByAdmin($payload: CampaignIdDTO!) {
    cancelCampaignByAdmin(payload: $payload) {
      id
    }
  }
`;

const styles = {
  container: {
    textAlign: 'center'
  },
  containerWallets: {
    display: 'flex',
    justifyContent: 'center',
    mt: 6.75,
    '> :first-of-type': {
      mr: 6,
      '@media screen and (max-width: 650px)': {
        mr: 0
      }
    },
    '> :last-of-type > div': {
      cursor: 'pointer',
      transition: 'all ease-in-out 0.5s',
      '&:hover': {
        transform: 'scale(1.1)'
      }
    },
    '@media screen and (max-width: 650px)': {
      flexWrap: 'wrap'
    }
  },
  containerWallet: {
    height: '520px',
    width: '388px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media screen and (max-width: 650px)': {
      height: '388px'
    }
  },
  wallet: {
    background: 'black',
    borderRadius: '40px',
    height: '90.7%',
    width: '90.7%',
    display: 'grid',
    gridTemplateRows: '63.6% 36.4%',
    '@media screen and (max-width: 650px)': {
      gridTemplateRows: '55% 45%'
    }
  },
  textTransparent: {
    opacity: '0.3'
  },
  iconWallet: {
    width: '48.87%',
    display: 'grid',
    alignItems: 'center',
    margin: 'auto',
    '& > img': {
      margin: 'auto'
    },
    '@media screen and (max-width: 650px)': {
      width: '40%'
    }
  }
};

export default styles;

import { FC } from 'react';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as PlaySvg } from '@/files/icons/play.svg';
import { IProps } from './types';

const style = {
  '& > svg': {
    fill: 'white'
  }
};

const Play: FC<IProps> = ({ handleClick }) => {
  return (
    <IconButton onClick={handleClick} color="inherit" sx={style}>
      <PlaySvg />
    </IconButton>
  );
};

export default Play;

import colors from '@/helpers/theme/colors';

const styles = {
  stake: {
    background: colors.common.black,
    padding: '1.5625rem 2.3125rem 0.75rem 2.875rem',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
    '@media screen and (max-width: 360px)': {
      padding: '1.5625rem 0.95rem 1.5625rem 0.95rem'
    }
  },
  addStake: {
    pb: 3.375,
    pr: 6.875,
    position: 'relative',
    color: colors.common.white,
    minHeight: '15.375rem',
    borderBottomRightRadius: '2.5rem',
    borderBottomLeftRadius: '2.5rem'
  },
  containerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 1,
    height: '5.4375rem',
    mb: 3,
    '@media screen and (max-width: 900px)': {
      flexWrap: 'wrap',
      height: '9.75rem',
      '& > *:first-child': {
        mb: 2
      }
    },
    '@media screen and (max-width: 850px)': {
      flexWrap: 'initial',
      height: '5.4375rem',
      '& > *:first-child': {
        mb: 0
      }
    },
    '@media screen and (max-width: 460px)': {
      flexWrap: 'wrap',
      height: '9.75rem',
      '& > *:first-child': {
        mb: 2
      }
    }
  },
  text: {
    fontFamily: '"Grafita", monospace, sans-serif'
  },
  containerText: {
    color: colors.common.white
  },
  containerTickets: {
    color: 'rgb(255 255 255 / 0.5)',
    width: '100%',
    maxWidth: '260px',
    '& > div:last-of-type': {
      display: 'flex',
      justifyContent: 'space-between',
      '& > div': {
        position: 'relative'
      }
    }
  },
  containerIcon: {
    display: 'flex',
    justifyContent: 'end',
    columnGap: '0.5rem'
  },
  icon: {
    '& > svg': {
      cursor: 'pointer'
    },
    '& g': {
      opacity: 1
    },
    '& path': {
      fill: colors.common.yellow
    }
  },
  iconDisabled: {
    '& > svg': {
      pointerEvents: 'none'
    },
    '& g': {
      opacity: 0.5
    },
    '& path': {
      fill: colors.common.white
    }
  },
  button: {
    background: colors.common.black,
    color: colors.common.yellow,
    padding: '0.5rem 1rem 0.5625rem',
    borderRadius: '0.625rem',
    '&:hover': {
      backgroundColor: colors.iconColor
    }
  },
  information: {
    background: colors.common.yellow,
    width: '100%',
    borderBottomRightRadius: '2.5rem',
    borderBottomLeftRadius: '2.5rem',
    padding: '0.8125rem 2.3125rem 1.875rem 2.875rem',
    '@media screen and (max-width: 360px)': {
      padding: '0.8125rem 0.95rem 1rem 0.95rem'
    }
  },
  containerInformation: {
    marginTop: '0.25rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  contaierFlexTop: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: 1
    },
    '& > *:last-child': {
      alignSelf: 'end'
    }
  },
  closeIcon: {
    position: 'absolute',
    top: '1.125rem',
    right: '1.125rem',
    cursor: 'pointer'
  }
};

export default styles;

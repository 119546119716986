import { HTMLAttributes } from 'react';
import { FileError } from 'react-dropzone';

export enum IView {
  Chip = 'chip',
  Image = 'image',
  Archive = 'archive',
  Music = 'music',
  Logo = 'logo' // TODO: изменить на image
}

export interface DropZoneProps {
  files: File[];
  label?: string | JSX.Element;
  loading?: boolean;
  handleFilesAdd?: (files: File[]) => void;
  handleFileDelete?: (file: File) => void;
  maxFiles?: number;
  maxSize?: number;
  multiple?: boolean;
  accept?: string[];
  error?: boolean;
  helperText?: string;
  view?: IView;
  cropImage?: boolean;
  variant?: 'standart' | 'black' | 'yellow';
  theme?: 'light' | 'dark';
  fileSize?: number;
  content?: JSX.Element;
}

export type FileRejType = {
  file: {
    path?: string;
    size?: number;
  };
  errors?: FileError[];
};

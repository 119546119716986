const styles = {
  container: {
    background: '#232323'
  },
  layout: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    background: '#f4f4f4',
    opacity: 0,
    backgroundImage:
      'url(/img/line.svg), url(/img/line.svg), url(/img/line.svg), url(/img/line.svg), url(/img/line.svg), url(/img/line.svg)',
    backgroundRepeat: 'no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat'
  },
  layoutAnimation: {
    animationName: 'showMain',
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-out',
    animationIterationCount: 1,
    animationDelay: 0,
    '@keyframes showMain': {
      '0%': {
        transform: 'translate3d(0px, 250px, 0px)',
        opacity: 1
      },
      '100%': {
        transform: 'translate3d(0px, 0px, 0px)',
        opacity: 1
      }
    }
  },
  main: {
    flex: 1,
    overflow: 'hidden'
  }
};

export default styles;

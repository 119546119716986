import { FC, useContext, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Header from '@/shared/Header/Header';
import { CAMPAIGN_BY_ID, STEM_BY_CAMPAIGN } from '@/graphql/gql/campaigns';
import {
  IQueryCampaignByIdArgs,
  CampaignStatusEnum,
  ICampaignModel,
  IStemModel,
  IQueryStemByCampaignArgs
} from '@/graphql/types/_server';
import BackdropLoading from '@/shared/ui/BackdropLoading/BackdropLoading';
import styles from './styles';
import Timeline from '@/shared/Timeline/Timeline';
import { MenuTypeEnum } from '@/shared/Header/types';
import UploadRemix from './stages/UploadRemix/UploadRemix';
import Lottery from './stages/Lottery/Lottery';
import Voting from './stages/Voting/Voting';
import Winner from './stages/Winner/Winner';
import { Context } from '@/helpers/context';
import { CampaignContext, initContext } from './context';
import Notification from './shared/Notification/Notification';
import Cancel from './stages/Cancel/Cancel';
import { messages } from './stages/config';

const Campaign: FC = () => {
  const { id } = useParams<'id'>();
  const campaignId = Number(id);
  const isIdCorrect = isFinite(campaignId);
  const [timelineChecked, setTimelineChecked] = useState(true);
  const [isStaked, setIsStaked] = useState(false);
  const [isShowMenu, setIsShowMenu] = useState(true);
  const { wallet } = useContext(Context);

  const { loading, data, refetch } = useQuery<
    { campaignById: ICampaignModel },
    IQueryCampaignByIdArgs
  >(CAMPAIGN_BY_ID, {
    variables: {
      payload: {
        id: campaignId
      }
    }
  });
  const campaign = data?.campaignById;

  const {
    loading: loadingStem,
    data: dataStem,
    error
  } = useQuery<{ stemByCampaign: IStemModel }, IQueryStemByCampaignArgs>(STEM_BY_CAMPAIGN, {
    variables: {
      chainId: Number(wallet?.ethers?.getChainId()) || 137,
      payload: {
        campaignId
      }
    }
  });
  const stem = dataStem?.stemByCampaign;

  const [phase, setPhase] = useState<CampaignStatusEnum>();

  useEffect(() => {
    if (campaign?.status) {
      setPhase(campaign?.status);
    }
  }, [campaign?.status]);

  const contextMemoized = useMemo(() => {
    return campaign
      ? {
          campaign,
          src: campaign.logoUrl,
          refetch,
          setTimelineChecked,
          stemId: !error ? stem?.tokenID : '-1',
          isStaked,
          setIsStaked,
          isShowMenu,
          setIsShowMenu
        }
      : initContext;
  }, [campaign, stem, error]);

  if (loading || loadingStem) {
    return <BackdropLoading />;
  }

  if (!isIdCorrect || !campaign) {
    return (
      <Box mt={4}>
        <Container maxWidth="md">
          <Alert severity="error" variant="outlined">
            Campaign not found
          </Alert>
        </Container>
      </Box>
    );
  }

  return (
    <CampaignContext.Provider value={contextMemoized}>
      <Box>
        {campaign && (
          <Timeline campaign={campaign} checked={timelineChecked} setChecked={setTimelineChecked} />
        )}
        <Box
          sx={{
            ...styles.layout,
            backgroundImage: `linear-gradient(to right, rgba(0,0,0, 0.6) 0 100%), url(${campaign.logoUrl})`
          }}
        >
          <Box sx={styles.containerFilter}>
            <Box sx={{ ...(!isShowMenu && styles.hide) }}>
              <Header menuType={MenuTypeEnum.Campaign} isStaked={isStaked} />
            </Box>
            <Box sx={timelineChecked ? styles.main : undefined}>
              {wallet.address === '' &&
              ![CampaignStatusEnum.Done, CampaignStatusEnum.Check].includes(
                phase as CampaignStatusEnum
              ) ? (
                <Notification type="metamask" text={messages.noConnectionToWallet} />
              ) : (
                <>
                  {(phase === CampaignStatusEnum.Lottery ||
                    phase === CampaignStatusEnum.Repayment) && <Lottery />}
                  {phase === CampaignStatusEnum.Remix && <UploadRemix />}
                  {phase === CampaignStatusEnum.Voting && <Voting />}
                  {(phase === CampaignStatusEnum.Done || phase === CampaignStatusEnum.Check) && (
                    <Winner />
                  )}
                  {phase === CampaignStatusEnum.Canceled && <Cancel />}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </CampaignContext.Provider>
  );
};

export default Campaign;

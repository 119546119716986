import { FC, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as PlaySvg } from '@/files/icons/assets/play.svg';
import { ReactComponent as StopSvg } from '@/files/icons/assets/stop.svg';
import { ReactComponent as CloudDownloadSvg } from '@/files/icons/cloudDownload.svg';
import styles from './styles';
import { Context } from '@/helpers/context';
import { IProps } from './types';
import getCloudImgUrl from '@/utils/getUrlImage';

const Track: FC<IProps> = ({ remix }) => {
  const { audio, setAudio, pauseAudioHandler } = useContext(Context);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Box key={remix.id}>
      <Box
        sx={styles.item}
        style={{ backgroundImage: `url(${getCloudImgUrl(remix.logoUrl, 300)})` }}
      >
        <Box sx={audio?.id !== remix.id ? styles.play : styles.stop}>
          {!audio?.isPlaying || audio?.id !== remix.id ? (
            <IconButton
              id="play"
              color="inherit"
              size="large"
              onClick={playAudio}
              disableFocusRipple
              disableTouchRipple
              disableRipple
            >
              <PlaySvg />
            </IconButton>
          ) : (
            <IconButton
              color="inherit"
              size="large"
              onClick={pauseAudio}
              disableFocusRipple
              disableTouchRipple
              disableRipple
            >
              <StopSvg />
            </IconButton>
          )}
        </Box>
        <Box sx={styles.download}>
          <IconButton
            color="inherit"
            size="large"
            onClick={handleDownload}
            disableFocusRipple
            disableTouchRipple
            disableRipple
            disabled={loading}
          >
            {!loading ? <CloudDownloadSvg /> : <CircularProgress size={24} />}
          </IconButton>
        </Box>
      </Box>
      <Box ml={2.5} mt={2}>
        <Typography variant="span1" component="p">
          {remix.name}
        </Typography>
        <Typography variant="span3" component="p" mt={0.5}>
          by {remix.user.userName}
        </Typography>
      </Box>
    </Box>
  );

  function handleDownload() {
    setLoading(true);
    fetch(remix?.trackUrl || '')
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = remix?.trackName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }

  function playAudio() {
    setAudioHandler(true);
  }

  function pauseAudio() {
    pauseAudioHandler();
    setAudioHandler(false);
  }

  function setAudioHandler(isPlaying: boolean) {
    setAudio({
      name: remix.name,
      user: remix.user.userName || '',
      id: remix.id,
      showPlayer: true,
      isPlaying,
      logo: remix.logoUrl,
      src: remix.trackUrl
    });
  }
};

export default Track;

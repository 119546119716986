const styles = {
  container: {
    ml: '47px',
    width: '100%',
    position: 'relative',
    '@media screen and (max-width: 1100px)': {
      m: 'auto'
    }
  },
  containerEmpty: {
    textAlign: 'center',
    mt: 8.6
  },
  text: {
    color: '#1A1B1D'
  },
  textAdditinal: {
    maxWidth: '325px',
    margin: 'auto',
    mt: 1.5,
    color: '#1A1B1D',
    lineHeight: 1.65
  }
};

export default styles;

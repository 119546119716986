import { FC, useMemo, memo } from 'react';
import 'swiper/css';
import { ICampaignModel } from '@/graphql/types/_server';
import { useWindowSize } from '@/utils/useWindowSize';
import SliderTop from './SliderTop/SliderTop';
import SliderBottom from './SliderBottom/SliderBottom';

const Slider: FC<{ campaigns: ICampaignModel[] }> = ({ campaigns }) => {
  const [width] = useWindowSize();
  const countSlides = useMemo(() => {
    if (width <= 900) {
      return 2;
    }
    if (width <= 1350) {
      return 3;
    }
    return 4;
  }, [width]);

  const topCampaigns = useMemo(() => {
    if (campaigns.length < countSlides * 2) {
      return campaigns;
    }
    return campaigns.slice(0, Math.ceil(campaigns.length / 2));
  }, [campaigns, countSlides]);
  const bottomCampaigns = useMemo(() => {
    if (campaigns.length < countSlides * 2) {
      return [];
    }
    return campaigns.slice(Math.ceil(campaigns.length / 2));
  }, [campaigns, countSlides]);
  const slidesPerView = useMemo(
    () => (campaigns.length >= countSlides ? countSlides : campaigns.length),
    [campaigns, countSlides]
  );

  return (
    <>
      <SliderTop
        topCampaigns={topCampaigns}
        slidesPerView={slidesPerView}
        countSlides={countSlides}
        countCampaigns={campaigns.length}
      />
      <SliderBottom
        bottomCampaigns={bottomCampaigns}
        slidesPerView={slidesPerView}
        countSlides={countSlides}
      />
    </>
  );
};

export default memo(Slider);

import { FC, memo, useMemo, useContext, useEffect } from 'react';
import { format } from 'date-fns';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import IProps from './types';
import styles from './styles';
import { Context } from '@/helpers/context';
import { timestamps } from './config';

const Timeline: FC<IProps> = ({ campaign, checked, setChecked }) => {
  const nowDateMemoized = useMemo(() => new Date().getTime(), []);
  const { audio } = useContext(Context);

  useEffect(() => {
    setChecked(!audio);
  }, [audio]);

  const marksMemoized = useMemo(() => {
    return timestamps.map((timestamp) => {
      const startDate = new Date(campaign[timestamp.key]).getTime();
      return {
        value: startDate,
        label: (
          <Box sx={styles.mark}>
            <Typography variant="span1" component="p">
              {timestamp.title}
            </Typography>
            <Typography variant="overline" component="p">
              {format(startDate, 'MMM d')}
            </Typography>
          </Box>
        )
      };
    });
  }, [campaign]);

  const { startLotteryDate, checkDate } = useMemo(() => {
    return {
      startLotteryDate: marksMemoized.length > 0 ? marksMemoized[0].value : 0,
      checkDate: marksMemoized.length > 3 ? marksMemoized[3].value : 0
    };
  }, [marksMemoized]);

  return (
    <Box sx={{ ...styles.container, ...(!checked && { height: 0 }) }}>
      <Slide direction="up" in={checked} timeout={500}>
        <Box sx={styles.sliderWrapper}>
          <Typography variant="span3" color="white">
            Campaign Timeline
          </Typography>
          <Slider
            min={startLotteryDate - 200000000}
            max={checkDate + 200000000}
            value={nowDateMemoized}
            marks={marksMemoized}
            valueLabelDisplay="auto"
            valueLabelFormat={() => `Now: ${format(nowDateMemoized, 'd MMM yyyy')}`}
            size="small"
          />
        </Box>
      </Slide>
    </Box>
  );
};

export default memo(Timeline);

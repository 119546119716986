const styles = {
  stake: {
    background: '#000000',
    minHeight: '11.125rem',
    borderBottomRightRadius: '2.5rem',
    borderBottomLeftRadius: '2.5rem',
    padding: '1.5625rem 3.4375rem 1.5625rem 2.875rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center'
  },
  caption: {
    fontWeight: 300,
    lineHeight: '1.3125rem',
    color: '#BDC2C8'
  }
};

export default styles;

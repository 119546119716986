import { FC, memo, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import ItemsCarousel from 'react-items-carousel';
import { ICampaignModel } from '@/graphql/types/_server';
import SliderItem from '../SliderItem/SliderItem';
import styles from './styles';

const SliderTop: FC<{
  topCampaigns: ICampaignModel[];
  slidesPerView: number;
  countSlides: number;
  countCampaigns: number;
}> = ({ topCampaigns, slidesPerView, countSlides, countCampaigns }) => {
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const stylesMemoized = useMemo(
    () => styles(width * topCampaigns.length || 0, topCampaigns.length * 24 || 0),
    [width, topCampaigns.length]
  );

  const items = useMemo(() => {
    return topCampaigns.map((item) => {
      return (
        <SliderItem
          key={`${item.id}_top`}
          campaign={item}
          countMissing={countSlides - slidesPerView}
        />
      );
    });
  }, [topCampaigns]);

  return (
    <Box
      sx={{
        ...stylesMemoized.container,
        ...stylesMemoized.firstAnimation,
        ...stylesMemoized.loopAnimation,
        ...(!countCampaigns && stylesMemoized.defaultHeight)
      }}
    >
      <ItemsCarousel
        infiniteLoop
        numberOfCards={slidesPerView !== 0 ? slidesPerView : countSlides}
        activeItemIndex={activeItemIndex}
        requestToChangeActive={setActiveItemIndex}
        chevronWidth={0}
        calculateActualTranslateX={(translateX: number) => {
          setWidth(
            topCampaigns.length < countSlides
              ? translateX / topCampaigns.length
              : translateX / countSlides
          );
        }}
      >
        {items}
      </ItemsCarousel>
    </Box>
  );
};

export default memo(SliderTop);

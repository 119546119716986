const styles = {
  modal: {
    backgroundColor: '#000000',
    position: 'relative',
    padding: '39px 45px',
    pb: '24px'
  },
  modalClose: {
    position: 'absolute',
    top: '20px',
    right: '38px',
    cursor: 'pointer'
  },
  container: {
    color: '#fff',
    width: '464px'
  },
  tune: {
    border: '1px solid rgb(255 255 255 / 0.2)',
    borderRadius: '10px',
    color: 'white',
    py: 2.125,
    textAlign: 'center'
  },
  textTransparent: {
    opacity: 0.5
  },
  input: {
    '& > div': {
      width: '100%'
    },
    '& input': {
      textAlign: 'center',
      '&::placeholder': {
        fontSize: '1.5rem !important',
        marginBottom: '0px !important'
      },
      '&:placeholder-shown': {
        marginTop: '0px !important',
        height: 'initial'
      }
    }
  },
  text: {
    color: '#BDC2C8',
    lineHeight: 1.75,
    m: '24px auto 0',
    textAlign: 'center',
    width: '90%',
    textTransform: 'initial'
  },
  arrow: {
    textAlign: 'center',
    mb: 1.5
  },
  button: {
    textAlign: 'center',
    marginTop: '32px',
    '& > button': {
      width: '144px',
      backgroundColor: '#fff',
      color: '#000',
      height: '48px',
      '&:disabled': {
        opacity: 0.26
      }
    },
    '& > button:hover': {
      backgroundColor: '#fff'
    }
  }
};

export default styles;

const getBeautyErrorMessage = (oldMessage: string): string => {
  switch (oldMessage) {
    case 'ENTITY_NO_EXIST':
      return 'The user is not found or verification has already been passed';
    case 'USER_EMAIL_ALREADY_EXIST':
      return 'Email already exist';
    case 'USER_NAME_ALREADY_EXIST':
      return 'Username already exist';
    case 'ENTITY_IS_DUPLICATED':
      return 'E-mail is already taken';
    case 'ENTITY_LOGIN_PASSWORD_WRONG':
      return 'Incorrect username or password';
    default:
      return oldMessage.toLocaleLowerCase();
  }
};

export default getBeautyErrorMessage;

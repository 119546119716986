const styles = (translateX: number, duration: number) => ({
  container: {
    position: 'relative',
    zIndex: 1,
    '&:hover': {
      zIndex: 2
    },
    '& > div > div': {
      overflowX: 'initial'
    },
    '& > div > div > div > div:hover': {
      zIndex: 1000000000
    }
  },
  loopAnimation: {
    '& > div > div > div': {
      transform: 'translateX(0px)',
      animationName: 'auto2',
      animationDuration: `${duration}s`,
      animationFillMode: 'backwards',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
      animationDelay: '3.7s',
      '@keyframes auto2': {
        '0%': {
          transform: 'translateX(0px)'
        },
        '100%': {
          transform: `translateX(-${translateX}px)`
        }
      },
      '&:hover': {
        animationPlayState: 'paused'
      }
    }
  },
  firstAnimation: {
    animationName: 'auto1',
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease',
    animationIterationCount: 1,
    animationDelay: '3.2s',
    opacity: 0,
    '@keyframes auto1': {
      '0%': {
        transform: 'translate3d(1845px, 0px, 0px)',
        opacity: 0
      },
      '100%': {
        transform: 'translate3d(0px, 0px, 0px)',
        opacity: 1
      }
    }
  },
  defaultHeight: {
    height: '12.5rem'
  }
});

export default styles;

const styles = {
  headerUnder: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2.625rem 11rem 3.25rem 4.1875rem',
    alignItems: 'center',
    '@media screen and (max-width: 1350px)': {
      margin: '2.625rem 3.25rem 2.4375rem 1.5rem'
    },
    '@media screen and (max-width: 1100px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '2.625rem 1.5rem 2.4375rem 1.5rem'
    }
  },
  headerCircle: {
    cursor: 'pointer',
    position: 'relative',
    paddingTop: '15.86%',
    width: '15.86%',
    transform: 'scale(0)',
    opacity: 0,
    animationName: 'zoom',
    animationDuration: '0.8s',
    animationFillMode: 'both',
    animationTimingFunction: 'ease-in',
    animationIterationCount: 1,
    animationDelay: '3.5s',
    '@keyframes zoom': {
      '100%': {
        transform: 'scale(1)',
        opacity: 1
      }
    },
    '@media screen and (max-width: 1100px)': {
      marginTop: '2rem',
      paddingTop: 0,
      width: '12rem',
      height: '12rem'
    },
    '@media screen and (max-width: 900px)': {
      width: '9.375rem',
      height: '9.375rem'
    }
  },
  headerArrow: {
    backgroundColor: '#F3F700',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    width: '57%',
    paddingTop: '57%',
    height: 0,
    '& > svg': {
      width: '40.35%',
      position: 'absolute',
      alignItems: 'center',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto'
    }
  },
  headerExploreOuter: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    animation: '25s linear 0s normal none infinite running clockwiseMovement',
    '@keyframes clockwiseMovement': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    },
    '& > svg': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto'
    }
  },
  headerExploreInner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    width: '78.75%',
    animation: '25s linear 0s normal none infinite running counterclockwiseMovement',
    '@keyframes counterclockwiseMovement': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(-360deg)'
      }
    },
    '& > svg': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto'
    }
  },
  titleContainer: {
    '@media screen and (max-width: 1100px)': {
      width: '100%'
    }
  },
  container1: {
    display: 'inline-block'
  },
  container2: {
    display: 'inline-block',
    '@media screen and (max-width: 600px)': {
      width: '100%'
    }
  },
  title1: {
    color: '#232323',
    position: 'relative',
    padding: '0 0.34em',
    visibility: 'hidden',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      height: '84.54%',
      background: '#F3F700',
      width: '2.515%',
      top: '0.01em',
      visibility: 'visible',
      animationName: 'cursor1, expand1',
      animationDuration: '0.5s, 1s',
      animationTimingFunction: 'step-end, ease-in-out',
      animationIterationCount: '4, 1',
      animationFillMode: 'none, both',
      animationDelay: '0s, 2s',
      '@keyframes cursor1': {
        '50%': {
          background: 'transparent'
        }
      },
      '@keyframes expand1': {
        '50%': {
          width: '100%'
        },
        '100%': {
          width: 0,
          right: 0,
          left: 'initial'
        }
      }
    },
    animationName: 'show1',
    animationDuration: '0s',
    animationFillMode: 'both',
    animationTimingFunction: 'step-end',
    animationIterationCount: 1,
    animationDelay: '2.5s',
    '@keyframes show1': {
      '100%': {
        visibility: 'visible'
      }
    },
    '@media screen and (max-width: 730px)': {
      fontSize: '2.78125rem'
    },
    '@media screen and (max-width: 600px)': {
      fontSize: '2.2rem'
    },
    '@media screen and (max-width: 450px)': {
      padding: 0
    }
  },
  title2: {
    position: 'relative',
    color: '#232323',
    visibility: 'hidden',
    padding: '0 0.34em  ',
    width: 'max-content',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      height: '84.54%',
      background: '#F3F700',
      width: 0,
      top: '0.01em',
      visibility: 'visible',

      animationName: 'expand2',
      animationDuration: '1s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 1,
      animationFillMode: 'both',
      animationDelay: '3s',
      '@keyframes expand2': {
        '50%': {
          width: '100%'
        },
        '100%': {
          right: 0,
          width: 0,
          left: 'initial'
        }
      }
    },
    animationName: 'show2',
    animationDuration: '0s',
    animationFillMode: 'both',
    animationTimingFunction: 'step-end',
    animationIterationCount: 1,
    animationDelay: '3.5s',
    '@keyframes show2': {
      '100%': {
        visibility: 'visible'
      }
    },
    '@media screen and (max-width: 730px)': {
      fontSize: '2.78125rem'
    },
    '@media screen and (max-width: 600px)': {
      fontSize: '2.2rem'
    },
    '@media screen and (max-width: 450px)': {
      padding: 0
    }
  },
  span1: {
    position: 'relative',
    fontFamily: 'Grafita-Special',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      borderRadius: '1em',
      bottom: '0.145em',
      height: '0.15',
      background: '#F3F700',
      zIndex: '-1',
      width: 0,

      animationName: 'expandSpan1',
      animationDuration: '0.65s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 1,
      animationFillMode: 'both',
      animationDelay: '4.2s',
      '@keyframes expandSpan1': {
        '100%': {
          width: '1'
        }
      }
    },
    '@media screen and (max-width: 730px)': {
      fontSize: '2.78125rem'
    },
    '@media screen and (max-width: 600px)': {
      fontSize: '2.2rem'
    }
  },
  span2: {
    position: 'relative',
    fontFamily: 'Grafita-Special',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      borderRadius: '1em',
      bottom: '0.145em',
      width: 0,
      height: '0.15',
      background: '#F3F700',
      zIndex: '-1',

      animationName: 'expandSpan2',
      animationDuration: '0.65s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 1,
      animationFillMode: 'both',
      animationDelay: '4.2s',
      '@keyframes expandSpan2': {
        '100%': {
          width: '1'
        }
      }
    },
    '@media screen and (max-width: 730px)': {
      fontSize: '2.78125rem'
    },
    '@media screen and (max-width: 600px)': {
      fontSize: '2.2rem'
    }
  },
  additinal1: {
    color: '#232323',
    position: 'absolute',
    '@media screen and (max-width: 730px)': {
      fontSize: '1.25rem'
    },
    '@media screen and (max-width: 600px)': {
      fontSize: '1.125rem'
    },
    ...[...'Regenerate the industry'].reduce((obj, _, index) => {
      return {
        ...obj,
        [`& > #additinal1${index}`]: {
          opacity: 0,
          animationName: 'showAdditinal1',
          animationTimingFunction: 'ease-in-out',
          animationIterationCount: 'infinite',
          animationDuration: '8s',
          animationFillMode: 'forwards',
          animationDelay: `${index * 50 + 5000}ms`,
          '@keyframes showAdditinal1': {
            '0%, 49.5% ': {
              opacity: 1
            },
            '49.6%, 100%': {
              opacity: 0
            }
          }
        }
      };
    }, {})
  },
  additinal2: {
    color: '#232323',
    '@media screen and (max-width: 730px)': {
      fontSize: '1.25rem'
    },
    '@media screen and (max-width: 600px)': {
      fontSize: '1.125rem'
    },
    ...[...'Curate and Earn Rewards'].reduce((obj, _, index) => {
      return {
        ...obj,
        [`& > #additinal2${index}`]: {
          opacity: 0,
          animationName: 'showAdditinal2',
          animationTimingFunction: 'ease-in-out',
          animationIterationCount: 'infinite',
          animationDuration: '8s',
          animationFillMode: 'forwards',
          animationDelay: `${index * 50 + 9000}ms`,
          '@keyframes showAdditinal2': {
            '0%, 49.5% ': {
              opacity: 1
            },
            '49.6%, 100%': {
              opacity: 0
            }
          }
        }
      };
    }, {})
  },
  globalContainer: {
    display: 'flex',
    alignItems: 'end',
    marginTop: '2%',
    '@media screen and (max-width: 600px)': {
      flexWrap: 'wrap'
    }
  },
  container3: {
    marginLeft: '1.375rem',
    marginBottom: '0.625rem',
    '@media screen and (max-width: 600px)': {
      marginTop: '0.5rem',
      marginLeft: '1rem'
    },
    '@media screen and (max-width: 450px)': {
      marginLeft: 0
    }
  }
};

export default styles;

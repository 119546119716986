const styles = {
  containerProgress: {
    position: 'relative',
    display: 'inline-flex',
    mt: 1.25
  },
  progress: {
    borderRadius: '100%',
    boxShadow: 'inset 0 0 0px 3px rgb(126 134 145 / 50%)',
    backgroundColor: 'transparent',
    color: '#F3F700'
  },
  progressText: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  textGrey: {
    color: '#BDC2C8'
  }
};

export default styles;

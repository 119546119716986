import colors from '@/helpers/theme/colors';

const styles = {
  position: 'absolute',
  borderRadius: 1,
  background: colors.loading.background,
  backdropFilter: colors.loading.blur,
  zIndex: 11,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  top: 0
};

export default styles;

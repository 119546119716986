import { FC, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Context } from '@/helpers/context';
import getErrorMessage from '@/utils/getErrorMessage';
import { IProps } from './types';
import { SmartContractTypeEnum } from '@/graphql/types/_server';
import { withdrawLabel } from '@/utils/contractRequests';
import ModalUnstake from '@/shared/ModalUnstake/ModalUnstake';
import { checkBalanceMatic } from '@/utils/checkBalanceToken';

const ModalUnstakeLabel: FC<IProps> = ({ modalTune, onModalTune, balanceOf, onGetStakeAmount }) => {
  const { wallet, contracts } = useContext(Context);

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <ModalUnstake
      balanceOf={balanceOf}
      loading={loading}
      withdraw={withdraw}
      modal={{
        isOpen: modalTune,
        onClose: onModalTune,
        title: 'Withdraw LABEL',
        currency: 'LABEL deposited'
      }}
    />
  );

  async function withdraw() {
    try {
      if (!wallet.ethers) return;
      await checkBalanceMatic(wallet);
      setLoading(true);
      let loadingLocal = true;
      const successCallback = async () => {
        if (loadingLocal) {
          loadingLocal = false;
          onModalTune();
          setLoading(false);
          toast.success('Label withdraw is successful');
          await onGetStakeAmount();
        }
      };

      await withdrawLabel(wallet.ethers, contracts.contracts[SmartContractTypeEnum.Label], {
        Hash: () => {
          toast.info('Label withdraw in progress. This can take up to a minute');
        },
        Error: () => {
          setLoading(false);
        },
        After: successCallback
      });

      successCallback();
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  }
};

export default ModalUnstakeLabel;

import { FC, useMemo } from 'react';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import styles from './styles';
import { IProps } from './types';

const ListMenu: FC<IProps> = ({ menu, navigate, pathname, theme, direction }) => {
  const stylesMemoized = useMemo(() => {
    return styles(theme);
  }, [theme]);

  return (
    <nav>
      <List component={direction === 'column' ? 'ul' : Stack} direction="row">
        {menu.map((item) => (
          <ListItem
            key={item.link}
            sx={stylesMemoized.listItem}
            onClick={() => navigate(item.link)}
          >
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={{
                    ...stylesMemoized.listItemText,
                    ...(pathname === item.link && stylesMemoized.activeListItemText)
                  }}
                >
                  {item.label}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </nav>
  );
};

export default ListMenu;

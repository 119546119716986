const styles = {
  avatar: {
    mt: 2.5,
    '& > img': {
      width: '150px',
      height: '150px',
      borderRadius: '50%'
    }
  },
  emptyAvatar: {
    fontSize: '9.375rem',
    fill: 'rgb(0 0 0 / 50%)'
  },
  textBlack: {
    color: 'black'
  },
  textWhite: {
    color: 'white'
  },
  textName: {
    color: '#1A1B1D',
    lineHeight: 1
  },
  textDescription: {
    color: '#1A1B1D',
    lineHeight: '28px'
  }
};

export default styles;

const styles = {
  container: {
    minHeight: '100%',
    background: 'black',
    borderRadius: '2.5rem',
    color: 'white',
    padding: '2.25rem 1.25rem 1.25rem 1.9375rem',
    // TODO: сделать глобальные стили для scroll
    '& *::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none'
    },
    '& *::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none'
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& *': {
      'scrollbar-width': 'thin'
    },
    '& *::-webkit-scrollbar': {
      width: '6px',
      height: '6px'
    },
    '& *::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '& *::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '6px'
    },
    '& *::-webkit-scrollbar-thumb:hover': {
      background: '#333'
    }
  },
  text: {
    fontFamily: '"Grafita", monospace, sans-serif'
  },
  containerScroll: {
    height: '19.25rem',
    overflowY: 'auto',
    paddingRight: '0.5rem'
  },
  containerRight: {
    width: '34.625rem'
  }
};

export default styles;

const styles = {
  containerAudio: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& > svg': {
      width: '2rem',
      height: '2rem',
      minWidth: '2rem',
      minHeight: '2rem',
      mr: 1.25,
      fill: '#F3F700',
      cursor: 'pointer'
    }
  },
  containerInner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '0.8125rem',
    paddingBottom: '1rem',
    position: 'relative'
  },
  containerOuter: {
    display: 'flex',
    alignItems: 'center',
    px: 2.5,
    ':hover': {
      outline: '1px solid #F3F700',
      borderRadius: '1.25rem',
      outlineOffset: '-1px',
      '& > div:first-of-type > p': {
        opacity: 1
      },
      '& > div:last-of-type > div > div': {
        color: 'white'
      }
    }
  },
  vote: {
    background: 'rgb(255 255 255 / 10%)',
    minWidth: '4.75rem',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.625rem',
    color: 'rgb(255 255 255 / 50%)'
  }
};

export default styles;

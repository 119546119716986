import colors from '@/helpers/theme/colors';

const styles = {
  mark: {
    '& > *:first-child': {
      transform: 'translateY(-2.875rem)',
      '@media screen and (max-width: 650px)': {
        fontSize: '0.9rem',
        transform: 'translateY(-2.375rem)'
      },
      '@media screen and (max-width: 450px)': {
        fontSize: '0.65rem',
        transform: 'translateY(-2.125rem)'
      }
    },
    '& > *:last-child': {
      transform: 'translateY(-2.125rem)',
      fontFamily: '"Grafita", monospace, sans-serif',
      '@media screen and (max-width: 650px)': {
        transform: 'translateY(-1.875rem)'
      },
      '@media screen and (max-width: 450px)': {
        fontSize: '0.5rem',
        transform: 'translateY(-1.625rem)'
      }
    }
  },
  container: {
    width: '100%',
    position: 'fixed',
    zIndex: 1,
    bottom: 0
  },
  sliderWrapper: {
    maxWidth: '65rem',
    width: '100%',
    background: 'black',
    position: 'relative',
    height: '129px',
    borderTopLeftRadius: '2.5rem',
    borderTopRightRadius: '2.5rem',
    margin: 'auto',
    padding: '1rem 3.125rem 0',
    '& .MuiSlider-root': {
      cursor: 'unset',
      color: '#F3F700',
      marginTop: '2.5rem'
    },
    '& .MuiSlider-thumb': {
      width: 0,
      height: 0,
      '&:hover, &.Mui-focusVisible': {
        boxShadow: `none`
      },
      '&$focused, &$activated, &$jumped': {
        boxShadow: `none`
      },
      '&.Mui-active': {
        boxShadow: `none`
      }
    },
    '& .MuiSlider-rail': {
      color: colors.background.paper,
      opacity: 0.1
    },
    '& .MuiSlider-mark': {
      visibility: 'hidden'
    },
    '& .MuiSlider-markLabel': {
      color: 'rgb(255 255 255 / 50%)'
    },
    '& .MuiSlider-markLabelActive': {
      color: '#F3F700'
    }
  }
};

export default styles;

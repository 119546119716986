const styles = {
  avatar: {
    '& > img': {
      width: '68px',
      height: '68px',
      borderRadius: '50%'
    }
  },
  emptyAvatar: {
    fontSize: '4.25rem',
    fill: 'rgb(0 0 0 / 50%)'
  },
  textBlack: {
    color: 'black'
  },
  textGrey: {
    color: '#1A1B1D'
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    mt: 1.625
  }
};

export default styles;

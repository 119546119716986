/* eslint-disable @typescript-eslint/ban-types */
import EthersSimple from './ethers';

export const depositLabel = async (
  ethersSimple: EthersSimple,
  amount: string,
  contract: {
    abi: string;
    address: string;
  },
  listeners: Record<string, Function>
) => {
  await ethersSimple.executeFunction({
    contractAddress: contract.address,
    functionName: 'depositLabel',
    abi: contract.abi,
    params: {
      amount: EthersSimple.ethers.utils.parseEther(amount)
    },
    listeners
  });
};

export const withdrawLabel = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  listeners: Record<string, Function>
) => {
  await ethersSimple.executeFunction({
    contractAddress: contract.address,
    functionName: 'withdrawLabel',
    abi: contract.abi,
    listeners
  });
};

export const buyRemixes = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  params: Record<string, unknown>,
  listeners: Record<string, Function>
) => {
  await ethersSimple.executeFunction({
    contractAddress: contract.address,
    functionName: 'buyRemixes',
    abi: contract.abi,
    params,
    listeners
  });
};

export const depositUSDC = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  params: Record<string, unknown>,
  listeners: Record<string, Function>
) => {
  await ethersSimple.executeFunction({
    contractAddress: contract.address,
    functionName: 'depositUSDC',
    abi: contract.abi,
    params,
    listeners
  });
};

export const withdrawUSDC = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  params: Record<string, unknown>,
  listeners: Record<string, Function>
) => {
  await ethersSimple.executeFunction({
    contractAddress: contract.address,
    functionName: 'withdrawUSDC',
    abi: contract.abi,
    params,
    listeners
  });
};

export const mintStem = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  params: Record<string, unknown>,
  listeners: Record<string, Function>
) => {
  await ethersSimple.executeFunction({
    contractAddress: contract.address,
    functionName: 'mintStem',
    abi: contract.abi,
    params,
    listeners
  });
};

export const addRemixForVote = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  params: Record<string, unknown>,
  listeners: Record<string, Function>
) => {
  await ethersSimple.executeFunction({
    contractAddress: contract.address,
    functionName: 'addRemixForVote',
    abi: contract.abi,
    params,
    listeners
  });
};

export const vote = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  params: Record<string, unknown>,
  listeners: Record<string, Function>
) => {
  await ethersSimple.executeFunction({
    contractAddress: contract.address,
    functionName: 'vote',
    abi: contract.abi,
    params,
    listeners
  });
};

export const getCountCampaignStaking = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  params: Record<string, unknown>
) => {
  return ethersSimple.executeFunction<bigint>({
    contractAddress: contract.address,
    functionName: 'getCountCampaignStaking',
    abi: contract.abi,
    params
  });
};

export const getBalanceOf = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  params: Record<string, unknown>
) => {
  return ethersSimple.executeFunction<bigint>({
    contractAddress: contract.address,
    functionName: 'balanceOf',
    abi: contract.abi,
    params
  });
};

export const balanceOfDeposit = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  params: Record<string, unknown>
) => {
  return ethersSimple.executeFunction<bigint>({
    contractAddress: contract.address,
    functionName: 'balanceOfDeposit',
    abi: contract.abi,
    params
  });
};

export const getTicketsSecondsByAddressAndCampaign = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  params: Record<string, unknown>
) => {
  return ethersSimple.executeFunction<bigint>({
    contractAddress: contract.address,
    functionName: 'getTicketsSecondsByAddressAndCampaign',
    abi: contract.abi,
    params
  });
};

export const getDepositByAddress = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  params: Record<string, unknown>
) => {
  return ethersSimple.executeFunction<any>({
    contractAddress: contract.address,
    functionName: 'getDepositByAddress',
    abi: contract.abi,
    params
  });
};

export const getStatuses = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  params: Record<string, unknown>
) => {
  return ethersSimple.executeFunction<[boolean, boolean, boolean]>({
    contractAddress: contract.address,
    functionName: 'getStatuses',
    abi: contract.abi,
    params
  });
};

export const getCountVotes = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  params: Record<string, unknown>
) => {
  return ethersSimple.executeFunction<any>({
    contractAddress: contract.address,
    functionName: 'getCountVotes',
    abi: contract.abi,
    params
  });
};

export const claimRewards = async (
  ethersSimple: EthersSimple,
  contract: {
    abi: string;
    address: string;
  },
  amount: bigint,
  listeners: Record<string, Function>
) => {
  await ethersSimple.executeFunction({
    contractAddress: contract.address,
    functionName: 'claimRewards',
    abi: contract.abi,
    params: { amount },
    listeners
  });
};

import { FC } from 'react';
import Box from '@mui/material/Box';
import CampaignItem from '@/components/Campaigns/CampaignItem/CampaignItem';
import { ICampaignModel } from '@/graphql/types/_server';
import styles from './styles';

interface ICampaignsItem {
  campaigns: ICampaignModel[];
}

const CampaignsItem: FC<ICampaignsItem> = ({ campaigns }) => {
  if (!campaigns.length) {
    return <>No campaigns...</>;
  }

  return (
    <Box sx={styles.campaignsItems}>
      {campaigns.map((campaign) => {
        return <CampaignItem key={campaign.id} campaign={campaign} />;
      })}
    </Box>
  );
};

export default CampaignsItem;

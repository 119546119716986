import { FC, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as PresentFront } from '@/files/icons/presentFront.svg';
import { ReactComponent as PresentBack } from '@/files/icons/presentBack.svg';
import { ReactComponent as FolderSvg } from '@/files/icons/folder.svg';
import styles from './styles';
import { CampaignContext } from '@/components/Campaigns/Campaign/context';
import { bytesToMegaBytes } from '@/utils/bytesToMegaBytes';

const StepWin: FC<{ handleDownload: () => Promise<void> }> = ({ handleDownload }) => {
  const { campaign, setIsShowMenu } = useContext(CampaignContext);
  const { stemUrl } = campaign;

  useEffect(() => {
    setIsShowMenu(false);
  }, []);

  return (
    <Box sx={styles.outerContainer}>
      <Box>
        <Box sx={{ ...styles.container1, ...styles.container2 }}>
          <PresentFront />
          <PresentBack />
        </Box>
        <Box sx={{ ...styles.container3, ...styles.container4 }}>
          <Box sx={styles.containerContent}>
            <Typography variant="body1" fontWeight={300}>
              Congratulations! You have won the Stem NFT! Now you can download it to create your
              incredible remixes! You can take part in the campaign and try your luck in fair voting
              or sell your Stem NFT on external platforms
            </Typography>
            <Typography variant="span3">Download stems to create the remix</Typography>
          </Box>
          <Box sx={styles.containerStem} onClick={downloadStem}>
            <FolderSvg />
            <Box>
              <Typography variant="span1">{campaign.stemName}</Typography>
              <Typography component="p" variant="caption" sx={styles.textGrey}>
                {`${bytesToMegaBytes(campaign.stemSize)}mb`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={styles.content}>
        <Typography variant="h6" my={3}>
          {campaign?.name} Stems NFT
        </Typography>
      </Box>
    </Box>
  );

  async function downloadStem() {
    window.open(stemUrl || '/');
    await handleDownload();
  }
};

export default StepWin;

export enum MenuTypeEnum {
  Standard = 'standard',
  Campaign = 'campaign',
  Authorization = 'authorization'
}

export type IProps = {
  menuType: MenuTypeEnum;
  isConnectWallet?: boolean;
  isStaked?: boolean;
};

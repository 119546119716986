import { useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import range from 'lodash/range';
import Teasers from '@/components/Home/Teasers/SectionTeasers/Teasers';
import About from '@/components/Home/About/About';
import Slider from '@/components/Home/Slider/Slider';
import {
  CampaignStatusEnum,
  FilterFieldTypeEnum,
  FilterOperationEnum,
  ICampaignsModel,
  IQueryCampaignsArgs,
  SortDirectionEnum
} from '@/graphql/types/_server';
import { CAMPAIGNS } from '@/graphql/gql/campaigns';
import Seize from '@/components/Home/Seize/Seize';
import Header from '@/shared/Header/Header';
import Footer from '@/shared/Footer/Footer';
import styles from './styles';
import { useWindowSize } from '@/utils/useWindowSize';
import { MenuTypeEnum } from '@/shared/Header/types';

const Home = () => {
  const [width] = useWindowSize();
  const memoRemToPixels = useCallback(remToPixels, []);

  const backgroundPosition = useMemo(() => {
    const count = 5;
    const padding = memoRemToPixels(3);
    const spacing = (width - padding * 2) / count;
    return range(1, count).reduce((string, current) => {
      const linePosition = `${string}, ${current * spacing + padding}px top`;
      if (current === count - 1) {
        return `${linePosition}, top 0px right 3rem`;
      }
      return linePosition;
    }, '3rem top');
  }, [width]);

  const { data, loading } = useQuery<{ campaigns: ICampaignsModel }, IQueryCampaignsArgs>(
    CAMPAIGNS,
    {
      variables: {
        payload: {
          filters: [
            {
              columnName: 'status',
              operation: FilterOperationEnum.Equal,
              type: FilterFieldTypeEnum.Text,
              value: [
                CampaignStatusEnum.Lottery,
                CampaignStatusEnum.Remix,
                CampaignStatusEnum.Voting
              ]
            }
          ],
          sorts: [
            {
              columnName: 'createdDate',
              direction: SortDirectionEnum.Desc
            }
          ]
          // paginate: { skip: 0, take: 3 }
        }
      }
    }
  );
  const campaigns = data?.campaigns.items || [];

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.layout, backgroundPosition, ...(!loading && styles.layoutAnimation) }}>
        <Header menuType={MenuTypeEnum.Standard} />
        <Box sx={styles.main}>
          <main>
            <Seize />
            <Slider campaigns={campaigns} />
            <Teasers />
            <About />
          </main>
        </Box>
        <Footer />
      </Box>
    </Box>
  );

  function remToPixels(rem: number) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }
};

export default Home;

const styles = {
  container: {
    margin: 'auto',
    marginTop: 'calc(14.7vh - 128px)',
    textAlign: 'center'
  },
  text: {
    opacity: '0.5',
    maxWidth: '414px',
    margin: 'auto',
    mt: 2.75,
    mb: 5.75
  },
  label: {
    mb: '11px'
  },
  form: {
    background: 'white',
    padding: '48px',
    pb: 3,
    color: '#232323',
    borderRadius: '20px',
    maxWidth: '671px',
    margin: 'auto'
  },
  btnWrap: {
    textAlign: 'center'
  },
  btn: {
    mt: '16px',
    height: '48px',
    width: '253px',
    backgroundColor: 'black',
    color: 'white',
    ':hover': {
      backgroundColor: 'black'
    },
    '&:disabled': {
      backgroundColor: 'black'
    }
  },
  textTransparent: {
    opacity: 0.2
  },
  error: {
    color: '#ef5350'
  }
};

export default styles;

/* eslint-disable react/require-default-props */
/* eslint-disable react/display-name */
import { forwardRef, FC } from 'react';
import styled from '@mui/system/styled';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { ReactComponent as FolderSvg } from '@/files/icons/folder.svg';
import styles from './styles';
import { IStemModel } from '@/graphql/types/_server';
import { bytesToMegaBytes } from '@/utils/bytesToMegaBytes';

const BackdropUnstyled = forwardRef<HTMLDivElement, { open?: boolean; className: string }>(
  (props, ref) => {
    const { open, className, ...other } = props;

    return (
      <Box className={open ? 'MuiBackdrop-open' : ''} sx={styles.backdrop} ref={ref} {...other}>
        <IconButton sx={styles.closeIcon} {...other}>
          <CloseIcon />
        </IconButton>
      </Box>
    );
  }
);

const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 998;
  display: initial;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  outline: 0;
`;

const Backdrop = styled(BackdropUnstyled)``;

interface IProps {
  open: boolean;
  handleClose: () => void;
  stem: IStemModel | undefined;
}

const Stem: FC<IProps> = ({ open, handleClose, stem }) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        components={{ Backdrop }}
        componentsProps={{ backdrop: { onClick: () => handleClose() } }}
      >
        <Fade in={open} timeout={300}>
          {!stem ? (
            <Box sx={styles.containerError}>
              <Alert severity="error">Error</Alert>
            </Box>
          ) : (
            <Box sx={styles.container}>
              <Box sx={styles.leftCard}>
                <Box
                  style={{
                    ...styles.containerImg,
                    backgroundImage: `url(${stem.logoUrl})`,
                    ...(!stem.isSpent && styles.containerImgNotLocked)
                  }}
                />
              </Box>
              <Box sx={{ ...styles.rightCard }}>
                <IconButton sx={styles.closeIconMobile} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
                <Box sx={styles.containerText}>
                  <Box>
                    <Typography variant="h3">{stem?.campaign?.name}</Typography>
                    <Typography variant="span3" mt={2} component="p">
                      by {stem?.campaign?.sponsor?.name}
                    </Typography>
                  </Box>
                  <Typography variant="body2" fontWeight={300} mt={3}>
                    {stem?.campaign?.description}
                  </Typography>
                </Box>
                <Box sx={styles.containerStem} onClick={() => window.open(stem?.stemUrl || '')}>
                  <FolderSvg />
                  <Box>
                    <Typography variant="span1">{stem?.campaign?.stemName}</Typography>
                    <Typography component="p" variant="caption" sx={styles.textGrey}>
                      {`${bytesToMegaBytes(stem?.campaign?.stemSize)}mb`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Fade>
      </Modal>
    </div>
  );
};

export default Stem;

import { Area } from 'react-easy-crop/types';

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

export function rotateSize(width: number, height: number) {
  return {
    width: Math.abs(Math.cos(0) * width) + Math.abs(Math.sin(0) * height),
    height: Math.abs(Math.sin(0) * width) + Math.abs(Math.cos(0) * height)
  };
}
export const dataType64toFile = (dataurl: string, filename = 'NewFile') => {
  const arr = dataurl.split(',');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const newFile = new File([u8arr], filename, {
    type: mime
  });
  return newFile;
};

export default async function getCroppedImg(currentFile: File, imageSrc: string, pixelCrop: Area) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const image: any = await createImage(imageSrc);

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return { base64: '', file: null };
  }

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height);

  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.drawImage(image, 0, 0);

  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(data, 0, 0);

  const base64: string = canvas.toDataURL(currentFile.type);
  const file: File = dataType64toFile(base64);
  return {
    base64,
    file
  };
}

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

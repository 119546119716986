const styles = {
  container: {
    userSelect: 'none',
    display: 'flex',
    flex: 1,
    minWidth: '200px',
    alignItems: 'center',
    gap: '10px',
    '@media screen and (max-width: 960px)': {
      width: '100%'
    }
  },
  containerInner: {
    position: 'relative',
    width: '100%'
  },
  // TODO: use the Typography varinat
  time: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '2.5rem',
    lineHeight: 1
  },
  icon: {
    mt: '-6px'
  },
  containerFlex: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  loader: {
    position: 'absolute',
    top: '-16px',
    left: '8px'
  },
  skeletonBar: {
    width: '100%',
    background: '#6a6a6a',
    height: '2px',
    borderRadius: '50%',
    position: 'absolute',
    top: '16px'
  },
  waveform: {
    '& > *': {
      overflow: 'hidden !important'
    }
  }
};

export default styles;
